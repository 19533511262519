import React from 'react';
import { connect } from 'react-redux';

import Permissions from 'views/Components/Permissions';
import { startInvitation } from 'store/actions/investmentsActions';
import { openSolicitationDialog } from 'store/actions/navigationActions';
import config from 'config';

function warningMap(role, medium) {
  if (medium === 'direct')
    return config.solicitationWarningInvestment[role];
  else
    return config.solicitationWarningInvestmentByEmail[role];
}

const mapStateToProps = (state, ownProps) => {
  if (state.investments.currentInvestment) {
    return {
      id: state.investments.currentInvestment.cid,
      user: state.user.user,
      users: state.user.users.dataRows,
      investments: state.investments.investments.dataRows,
      threads: state.threads.threads.dataRows,
      currentObject: state.investments.currentInvestment,
      warningMap
    };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendInvite: (warningText, cid, email, data) => dispatch(openSolicitationDialog(warningText, startInvitation(cid, email, data)))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);

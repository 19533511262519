
  
  // Orders Data
  const pendingInvestments = {
    columns: [        
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "DESCRIPTION",
        accessor: "description"      
      },
      {
        Header: "IMAGE",
        accessor: "image"      
      },
      {
        Header: "LINK",
        accessor: "link"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    pendingInvestments 
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_PENDING_INVESTMENTS_LIST':
        console.log('SET_PENDING_INVESTMENTS_LIST')     
        return {
          ...state,
          pendingInvestments: {
            ...state.pendingInvestments,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };  
        case 'GET_CURRENT_INVESTMENT':
          console.log('GET_CURRENT_INVESTMENT')     
          return {
            ...state,
            currentInvestment: {
              ...state.currentInvestment,
              dataRows: action.dataRows,
              dataLoaded: true
            }
          };    

      default:
        return state
    }
  }
  
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import loader from "assets/img/loader.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";

import EventsDetailsMap from "./EventsDetailsMap"
// Forms

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: ''
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadData = () => {
        let usersData = this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop }
            const volUID = colObj.volUID
            const username = colObj.username
            // Load Data for Summary Cards and add buttons     
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={
                            () => this.handleClickOpen("assignActionModal",
                                volUID
                            )}
                    >ASSIGN ACTION</Button>)
            }
        })

        this.setState({
            usersData,
            dataLoaded: true
        })
    }

    handleChange = (docID) => {
        console.log(docID)
    }

    render() {
        const { AuthBool, classes, user, userProfile, investments, id, users } = this.props;
        console.log(investments)
        const searchButton =
            classes.top +
            " " +
            classes.searchButton;

        if (AuthBool) {
            if (this.props.users.dataLoaded
                && !this.state.dataLoaded) {
                this.loadData()
            }
        }

        return (
            <div>
                {
                    this.state.dataLoaded ? (
                        <GridContainer justify="center">
                            <GridItem xs={11}>
                                <EventsDetailsMap uid={user.uid} id={id} userType={userProfile.userType} style={{ marginTop: "-30px" }} />
                            </GridItem>
                        </GridContainer>

                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        AuthBool: !!state.auth.user,
        users: state.user.users,
        userProfile: state.user.user,
        user: state.auth.user,
        investments: state.investments.investments,
        id: ownProps.match.params.id
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Users);

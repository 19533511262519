import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// react plugin for creating charts
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { addQuizXpPoints } from "store/actions/eventsActions"

export class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraversionScore: (this.props.location.state.state.values.extravertedEnthusiastic + this.props.location.state.state.values.reservedQuiet) / 2,
      agreeablenessScore: (this.props.location.state.state.values.criticalQuarralsome + this.props.location.state.state.values.sympatheticWarm) / 2,
      conscientiousnessScore: (this.props.location.state.state.values.dependableSelfDisciplined + this.props.location.state.state.values.disorganizedCareless) / 2,
      emotionalStabilityScore: (this.props.location.state.state.values.anxiousEasilyUpset + this.props.location.state.state.values.calmEmotionallyStable) / 2,
      opennessExperiencesScore: (this.props.location.state.state.values.openComplex + this.props.location.state.state.values.conventionalUncreative) / 2,
    }
  }
  goNextPage = (e) => {
    e.preventDefault();
    this.props.history.push('/pages/signup-page', {
      state: {
        extraversionScore: this.state.extraversionScore,
        agreeablenessScore: this.state.agreeablenessScore,
        conscientiousnessScore: this.state.conscientiousnessScore,
        emotionalStabilityScore: this.state.emotionalStabilityScore,
        opennessExperiencesScore: this.state.opennessExperiencesScore,
        values: this.props.location.state.state.values
      }
    });
    // console.log('results pre', this.props.location.state.state.values)
    // const values = this.props.location.state.state.values
    // return db.collection('global-test-results').doc('fiveFactorQuiz').get().then((snapshot) => {
    //   const results = snapshot.data()
    //   for (var key in results) {
    //     const scoresObj = results[key]
    //     scoresObj[values[key]]++
    //   }
    //   console.log('results post', results)
    //   return db.collection('global-test-results').doc('fiveFactorQuiz').update({ ...results }).then(() => {
    //     return db.collection("users").doc(this.props.uid).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").add({
    //       quiz: "Five Factor Quiz",
    //       extraversionScore: this.state.extraversionScore,
    //       agreeablenessScore: this.state.agreeablenessScore,
    //       conscientiousnessScore: this.state.conscientiousnessScore,
    //       emotionalStabilityScore: this.state.emotionalStabilityScore,
    //       opennessExperiencesScore: this.state.opennessExperiencesScore,
    //       timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    //       quizID: "Placeholder"
    //     })
    //       .then((docRef) => {
    //         return db.collection("users").doc(this.props.uid).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").doc(docRef.id).update({
    //           quizID: docRef.id
    //         })
    //       })
    //       .then(() => {
    //         this.props.addQuizXpPoints(this.props.uid, this.props.events, 'Five Factor Quiz')
    //         this.props.history.push('/pages/five-factor-success');
    //       })
    //   })
    // })
  }

  continue = () => {
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  retakeQuiz = e => {
    e.preventDefault();
    this.props.history.push('/pages/five-factor');
  }

  render() {
    const { classes, values } = this.props;
    const sortedResults = [
      { extraversionScore: this.state.extraversionScore },
      { agreeablenessScore: this.state.agreeablenessScore },
      { conscientiousnessScore: this.state.conscientiousnessScore },
      { emotionalStabilityScore: this.state.emotionalStabilityScore },
      { opennessExperiencesScore: this.state.opennessExperiencesScore }
    ].sort((a, b) => {
      console.log('aaaaaa', Object.values(a)[0], Object.values(b)[0])
      return Object.values(b)[0] - Object.values(a)[0]
    })

    console.log('this.pors', sortedResults)

    return (
      <div className={classes.fiveFactorQuizResults133vhPublic}>
        <GridContainer style={{ justifyContent: "center" }}>
          <GridItem xs={6}>
            <div className={classes.radar}>
              <Radar
                width={400}
                height={400}
                padding={70}
                domainMax={10}
                highlighted={null}
                onHover={(point) => {
                  if (point) {
                    //console.log('hovered over a data point');
                  } else {
                    //console.log('not over anything');
                  }
                }}
                data={{
                  variables: [
                    { key: 'extraversion', label: 'Extraversion' },
                    { key: 'agreeableness', label: 'Agreeableness' },
                    { key: 'conscientiousness', label: 'Conscientiousness' },
                    { key: 'emotionalStability', label: 'Emotional Stability' },
                    { key: 'opennessToExperiences', label: 'Openness' },
                  ],
                  sets: [
                    {
                      key: 'everyone',
                      label: 'Everyone',
                      values: {
                        extraversion: this.state.extraversionScore,
                        agreeableness: this.state.agreeablenessScore,
                        conscientiousness: this.state.conscientiousnessScore,
                        emotionalStability: this.state.emotionalStabilityScore,
                        opennessToExperiences: this.state.opennessExperiencesScore
                      },
                    },
                  ],
                }}
              />
            </div>
          </GridItem>
          <GridItem xs={6}>
            <Table
              tableData={[
                ["Openness", this.state.opennessExperiencesScore],
                ["Conscientiousness", this.state.conscientiousnessScore],
                ["Extraversion", this.state.extraversionScore],
                ["Agreeableness", this.state.agreeablenessScore],
                ["Emotional Stability", this.state.emotionalStabilityScore],
              ]}
              coloredColls={[3]}
              colorsColls={["primary"]}
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: "-15px" }}>
            {Object.keys(sortedResults[0])[0] === 'extraversionScore' ?
              (<div>
                <h3 className={classes.questionHeaderResults}>You scored highest in Extroversion</h3>
                <p className={classes.questionDescriptionResults}>
                  Individuals who score high in extroversion are characterized by their sociability and
                  talkativeness. They tend to be outgoing and gain energy in social situations. When speaking with
                  others, they are often optimistic, cheerful, and enthusiastic about whatever topic they are
                  speaking about. Due to this charismatic nature, they feel comfortable talking to anyone -
                  including new people! Their emotional expressiveness means you can easily interpret how an
                  extrovert is feeling by their facial expressions, words, or body language.
                  Extroverts thrive on being involved in discussion and activities with others! However, they tend
                  to get lost in the details. When they are speaking with others they tend to 'think to speak',
                  meaning they talk through their ideas and thoughts.
                </p>
              </div>) : (null)
            }
            {Object.keys(sortedResults[0])[0] === 'emotionalStabilityScore' ?
              (<div>
                <h3 className={classes.questionHeaderResults}>You scored highest in Emotional Stability</h3>
                <p className={classes.questionDescriptionResults}>
                  People who score high in emotional stability can effectively manage negative
                  feelings. They are unlikely to be overtaken by fear and react impulsively to adverse
                  situations. Their ability to calmly react to a crisis or emergency in their job, personal
                  or financial life leads to greater financial stability and happiness.
                </p>
              </div>) : (null)
            }
            {Object.keys(sortedResults[0])[0] === 'agreeablenessScore' ?
              (<div>
                <h3 className={classes.questionHeaderResults}>You scored highest in Agreeableness</h3>
                <p className={classes.questionDescriptionResults}>
                  Those who are high in agreeableness tend to be focused on social harmony. They find it
                  important to get along with other people and put aside their interests for the greater goal of the
                  people - what we can refer to as 'we'-focused as opposed to 'me'-focused. These individuals
                  exhibit many prosocial behaviors including being cooperative, kind, and friendly. They try their
                  best to help those in need due to their empathetic nature, and try to understand the emotions of
                  others. They are always listening to the opinions of those around them and looking for the
                  common good in others.
                </p>
              </div>) : (null)
            }
            {Object.keys(sortedResults[0])[0] === 'conscientiousnessScore' ?
              (<div>
                <h3 className={classes.questionHeaderResults}>You scored highest in Conscientiousness</h3>
                <p className={classes.questionDescriptionResults}>
                  People who are high in conscientiousness are highly organized individuals. They are focused on
                  and enjoy the details of whatever they are working on or talking through. Their focus is typically
                  one item at a time, and they like to dive into that item fully and exhaust it before moving on to
                  the next topic. Due to this nature, they are highly structured individuals who enjoy following a
                  schedule that aligns with their goals. This also aligns with other personal traits such as their high
                  levels of thoughtfulness for others and strong impulse control.
                  Their focus tends to be on logical and rational thinking, and they desire information to be clear
                  and precise. Due to this focus on detail, they always plan ahead and are mindful of deadlines.
                </p>
              </div>) : (null)
            }
            {Object.keys(sortedResults[0])[0] === 'opennessExperiencesScore' ?
              (<div>
                <h3 className={classes.questionHeaderResults}>You scored highest in Openness to Experience</h3>
                <p className={classes.questionDescriptionResults}>
                  Individuals who score high in openness to experience are those who are imaginative, insightful,
                  and willing to take risks. They have a broad range of interests and are always trying to expand
                  these even more by trying out new experiences and learning new things.
                  Due to their adventurous nature, they tend to be extremely creative, self-reliant, and quick
                  thinking! However, they tend to dislike monotony and can get bored on the same topic quickly,
                  so they are always looking for ways to approach a task in a new way.
                </p>
              </div>) : (null)
            }
          </GridItem>
          <div className={classes.iframeQuizButton}>
            <Button
              onClick={this.retakeQuiz}
              className={classes.backButton}
              variant="contained"
            >Retake Quiz
            </Button>

            <Button
              className={classes.submitButton}
              onClick={(e) => this.goNextPage(e)}
            >Go Sign Up
            </Button>
          </div>
        </GridContainer>

        {/* <GridContainer>
          <GridItem xs={12}>
            <Button
              onClick={this.retakeQuiz}
              className={classes.backButton}
              variant="contained"
            >Retake Quiz
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Button
              className={classes.submitButton}
              onClick={(e) => this.goNextPage(e)}
            >Go Sign Up</Button>
          </GridItem>
        </GridContainer> */}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  console.log("useruser: ", user.user)
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Confirm);
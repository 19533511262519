import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';

// @material-ui/icons
import loader from 'assets/img/loader.gif';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Style from 'assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx';

import ExploreMap from './ExploreMap';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentModal: false,
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      query: '',
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  /*
    mapData = (dataRows) => {
      return this.props.users.dataRows.map((prop, key) => {
        let colObj = { ...prop };
        const volUID = colObj.volUID;
        const username = colObj.username;
        // Load Data for Summary Cards and add buttons
        return {
          ...colObj,
          // status: (<span style={{ color: "red" }}>assigned</span>),
          id: key,
          actions: (
            <Button
              color="primary"
              fullWidth
              onClick={() => this.handleClickOpen('assignActionModal', volUID)}
            >
              ASSIGN ACTION
            </Button>
          ),
        };
      });
    };
  */

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    //console.log(this.state)
  };

  render() {
    const { classes, user, loading } = this.props;
    //console.log(investments)
    const searchButton = classes.top + ' ' + classes.searchButton;

    return (
      <div>
        {!loading ? (
          <div>
            <GridContainer justify="center">
              {/*<GridItem xs={2}>
              <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
          </GridItem>*/}
              {/* <GridItem style={{ marginTop: '-40px' }} xs={11}>
                <Link to="/investments">
                  <SnackbarContent
                    message={
                      <b>
                        Welcome to Explore! If you don't yet have an investment, click here
                        to create one. If you already have an investment made, search the
                        platform for a user to add them to that investment.
                      </b>
                    }
                    color="info"
                  />
                </Link>
              </GridItem>
              <GridItem xs={11}>
                <Card
                  style={{
                    margin: '0',
                  }}
                >
                  <div
                    style={{
                      width: '95%',
                      margin: '0 auto',
                      marginTop: '0px',
                      marginBottom: '0px',
                    }}
                  >
                    <Search
                      style={{
                        color: '#bbb',
                        marginBottom: '-8px',
                        marginRight: '10px',
                        fontSize: '1.3rem',
                      }}
                      className={classes.headerLinksSvg + ' ' + classes.searchIcon}
                    />

                    <CustomInput
                      formControlProps={{
                        className: classes.top + ' ' + classes.search,
                        style: { width: '85%', paddingTop: '10px' },
                      }}
                      id="exploreQuery"
                      inputProps={{
                        placeholder: 'Search...',
                        onChange: (event) => this.handleChange(event),
                        inputProps: {
                          'aria-label': 'Search',
                          className: classes.searchInput,
                        },
                      }}
                    />
                  </div>
                </Card>
              </GridItem> */}
              <GridItem xs={11}>
                <ExploreMap uid={user.uid} query={this.state.exploreQuery} />
              </GridItem>
            </GridContainer>

            {/* <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.handleClickOpen("newInvestmentModal")}>
                <AddIcon />
              </Fab> */}

            {/* NEW INVESTMENT MODAL */}
            {/* <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal + " " + classes.modalLarge
                }}
                open={this.state.newInvestmentModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("newInvestmentModal")}
                aria-labelledby="shipment-modal-slide-title"
                aria-describedby="shipment-modal-slide-description"
              >

                <DialogContent
                  id="shipment-modal-slide-description"
                  className={classes.modalBody}
                  style={{paddingLeft: "0", paddingRight: "0"}}
                >
                <Button
                  style={{
                      margin: "3.5% 1% 0% 0%",
                      color: "black",
                    }}
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("newInvestmentModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button> */}

            {/* FORM */}
            {/* <CreateInvestmentForm /> */}
            {/* </DialogContent> */}
            {/* </Dialog> */}
            <div style={{ margin: '50px' }} />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#f0f1f5',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              style={{ margin: '20% 0% 0% 41%', position: 'relative', width: '20%' }}
              src={loader}
              alt="..."
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.user.users,
    user: state.auth.user,
    investments: state.investments.investments,
    loading: false
  };
};


export default compose(
  connect(
    mapStateToProps
  ),
  withStyles(Style)
)(Explore);

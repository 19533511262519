import React from 'react';

import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Style from 'assets/jss/material-dashboard-pro-react/modalStyle.jsx';

// @material-ui/icons
import Close from '@material-ui/icons/Close';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import Transition from 'components/Transition';
import { closeNotificationActionDialog } from '../../store/actions/navigationActions';

export function NotificationActionDialog(props) {
    const { classes, open, onCancel, onContinue, onReject, warningText, doAction } = props;
    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal + ' ' + classes.modalSmall,
            }}
            id="notificationAction"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="notificationAction-modal-title"
            aria-describedby="notificationAction-modal-description"
        >
            <Card
                style={{
                    margin: '0px 0% 0px 5%',
                    width: '90%',
                    backgroundColor: '#264653',
                    borderRadius: '10px',
                }}
            >
                <DialogTitle
                    id="notificationAction-modal-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={onCancel}
                    >
                        {' '}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h2 className={classes.modalTitle}>
                        <span style={{ color: 'white' }}>
                            <center>Solicitation Advice</center>
                        </span>
                    </h2>
                </DialogTitle>
                <DialogContent
                    id="notificationAction-modal-description"
                    className={classes.modalBody}
                >
                    {warningText}
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            className={classes.ltrButtonWhite}
                            style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", width: '90%' }}
                            key="cancel"
                            aria-label="Cancel"
                            onClick={() => doAction(onCancel)}
                        >
                            <b>CANCEL</b>
                        </Button>
                        {onReject &&
                            <Button
                                className={classes.ltrButtonWhite}
                                style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", width: '90%' }}
                                key="reject"
                                aria-label="Cancel"
                                onClick={() => doAction(onReject)}
                            >
                                <b>REJECT</b>
                            </Button>
                        }
                        <Button
                            className={classes.ltrButtonWhite}
                            style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", width: '90%' }}
                            key="continue"
                            aria-label="Continue"
                            onClick={() => doAction(onContinue)}
                        >
                            <b>CONTINUE</b>
                        </Button>
                    </div>
                </DialogContent>
            </Card>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        warningText: state.navigation.warningText,
        onContinue: state.navigation.onContinue,
        onCancel: state.navigation.onCancel,
        onReject: state.navigation.onReject,
        open: state.navigation.notificationActionDialogOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        doAction: action => action ? dispatch(action).then(dispatch(closeNotificationActionDialog())) : dispatch(closeNotificationActionDialog())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(NotificationActionDialog);

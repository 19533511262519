// Server service as a singleton
import { v1 as uuidv1 } from 'uuid';
import dbConfig from 'config/db';
import { firebase, storage } from 'firebase/fbConfig.js';

const { collection } = dbConfig;
const { arrayUnion } = firebase.firestore.FieldValue;

class ServerService {
  constructor() {
    if (!ServerService.instance) {
      ServerService.instance = this;
    }
    this.db = firebase.firestore();
    this.usersCollection = this.db.collection(collection.users);
    this.investmentsCollection = this.db.collection(collection.investments);
  }

  getUser = async (uid) => {
    const snapshot = await this.db
      .collection(collection.users)
      .doc(uid)
      .get();
    return { ...snapshot.data(), id: snapshot.id };
  };

  getInvestments = async (ids, status) => {
    let query = await this.investmentsCollection.where('cid', 'in', ids);
    if (status) {
      query = query.where('status', '==', status);
    }
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => {
      return doc.data();
    });
  };

  getInvestmentData = async (cid) => {
    const snapshot = await this.investmentsCollection.doc(cid).get();
    return { ...snapshot.data() };
  };

  setUser = async (uid, data) => {
    const createdAt = new Date().getTime();
    await this.usersCollection.doc(uid).set({ ...data, createdAt }, { merge: true });
  };

  setInvestmentStatus = async (id, status) => {
    await this.db
      .collection(collection.investments)
      .doc(id)
      .set(
        {
          status,
          statusChanged: new Date().getTime(),
        },
        { merge: true }
      );
  };

  updateUser = async (uid, data) => {
    console.log("updateUser", {uid, data});
    const updatedAt = new Date().getTime();
    await this.usersCollection.doc(uid).set({ ...data, updatedAt }, { merge: true });
  };

  addInvestment = async (uid, data) => {
    const cid = uuidv1();
    const entry = { ...data, cid, createdAt: new Date().getTime() };
    // Add to investments colleciton with the id set to cid
    await this.db
      .collection(collection.investments)
      .doc(cid)
      .set(entry, { merge: true });
    // Add the investment id to the users collection investments field
    await this.db
      .collection(collection.users)
      .doc(uid)
      .set({ investments: arrayUnion(cid) }, { merge: true });
    return cid;
  };

  uploadToStorage = ({ data, destination, onProgress }) => {
    const uploadTask = storage.ref(destination).put(data);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        ({ bytesTransferred, totalBytes }) => {
          // Get task progress
          onProgress && onProgress(bytesTransferred / totalBytes);
        },
        (error) => {
          onProgress && onProgress(null);
          reject(error);
        },
        async () => {
          onProgress && onProgress(null);
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });
  };
}

const instance = new ServerService();
Object.freeze(instance);

export default instance;

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

export const ButtonCreateInvestment = ({ classes }) => (
  <Fab color="primary" aria-label="add" className={classes.fab}>
    <Link to="/create-investment">
      <AddIcon style={{ color: 'white' }} />
    </Link>
  </Fab>
);

ButtonCreateInvestment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(ButtonCreateInvestment);

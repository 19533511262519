import { firebase, googleAuthProvider, facebookAuthProvider, twitterAuthProvider, db } from '../../firebase/fbConfig';
import { push } from 'connected-react-router';
import { sendEmail, sendWelcomeEmail } from "firebase/fbConfig.js"
import { addSignUpXpPoints, addQuizXpPoints } from "./eventsActions"

export const emailSignup = (authObject, scoreArr) => {
  return (dispatch) => {
    console.log('checking uthObjectauthObjectauthObject', authObject)
    console.log("checking checking", scoreArr)
    firebase
      .auth()
      .createUserWithEmailAndPassword(authObject.email, authObject.password)
      .then((result) => {
        console.log("checking results", result)
        dispatch(addSignUpXpPoints(result, authObject.email))
        // dispatch(push(`/pages/usertype`));
        const values = scoreArr[5]
        const uid = result.user.uid
        db.collection('global-test-results').doc('fiveFactorQuiz').get().then((snapshot) => {
          const results = snapshot.data()
          for (var key in results) {
            const scoresObj = results[key]
            scoresObj[values[key]]++
          }
          console.log('checking post', results)
          db.collection('global-test-results').doc('fiveFactorQuiz').update({ ...results })
            .then(() => {
              console.log("checking uid: ", uid)
              db.collection("users").doc(uid).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").add({
                quiz: "Five Factor Quiz",
                extraversionScore: scoreArr[0],
                agreeablenessScore: scoreArr[1],
                conscientiousnessScore: scoreArr[2],
                emotionalStabilityScore: scoreArr[3],
                opennessExperiencesScore: scoreArr[4],
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                quizID: "Placeholder"
              })
                .then((docRef) => {
                  return db.collection("users").doc(uid).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").doc(docRef.id).update({
                    quizID: docRef.id
                  })
                })
                .then(() => {
                  db.collection('users').doc(uid).set({
                    events: firebase.firestore.FieldValue.arrayUnion(...[{
                      label: "Five Factor Quiz",
                      event: 'quiz',
                      type: 'reward',
                      tokens: 300,
                    }])
                  }, { merge: true })
                  // this.props.history.push('/pages/five-factor-success');
                  dispatch(push(`/pages/usertype`));
                })
            })
        })
      })
      .catch((error) => {
        dispatch(signupError(error));
      });
  };
};

export const emailSignupInviteAdvisor = (authObject) => {
  return (dispatch) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(authObject.email, authObject.password)
      .then((id) => {
        db.collection('advisor')
          .doc(id.user.uid)
          .set(
            {
              email: authObject.email,
              advisorID: id.user.uid,
              userType: 'advisor',
              accountCreated: new Date(),
            },
            { merge: true }
          )
          .then(() => {
            db.collection('advisor')
              .doc(id.user.uid)
              .collection('investments')
              .doc(authObject.investmentID)
              .set(
                {
                  investmentID: authObject.investmentID,
                  investorID: authObject.id,
                  advisorAssigned: new Date(),
                  advisorID: id.user.uid,
                },
                { merge: true }
              )
              .then(() => {
                dispatch(push(`/investment/${authObject.investmentID}`));
              });
          });
      })
      .catch((error) => {
        dispatch(signupError(error));
      });
  };
};

export const signupError = (error) => ({
  type: 'SIGNUP_ERROR',
  error,
});

export const emailSignIn = (credentials) => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch(push('/profile'));
      })
      .catch((error) => {
        dispatch(SignError(error));
      });
  };
};

export const SignError = (error) => ({
  type: 'SIGNIN_ERROR',
  error,
});

export const loginError = (error) => ({
  type: 'LOGIN_ERROR',
  error,
});

export const passwordReset = (emailAddress) => {
  return (dispatch) => {
    console.log('password reset', emailAddress);
    var auth = firebase.auth();
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        dispatch(setPasswordReset())
        console.log('Reset email sent');
      })
      .catch((error) => {
        console.log('Error: ' + error);
      });
  }
};

export const setPasswordReset = () => ({
  type: 'PASSWORD_RESET',
});

export const startGoogleLogin = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(() => {
        dispatch(push('/profile'));
      });
  };
};

export const startFacebookLogin = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then(() => {
        dispatch(push('/profile'));
      });
  };
}

export const startTwitterLogin = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(twitterAuthProvider)
      .then(() => {
        dispatch(push('/profile'));
      });
  };
}

export const login = (user = {}) => ({
  type: 'LOGIN',
  user,
});

export const startLogout = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(push('/pages'));
      })
      .then(() => {
        dispatch(logout());
      })
      .then(() => {
        window.location.reload();
      });
  };
};

export const logout = () => ({ type: 'LOGOUT' });

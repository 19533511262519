import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom'
import SwipeableViews from "react-swipeable-views";
import Carousel from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Slide from "@material-ui/core/Slide";

import loader from "assets/img/loader.gif";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from 'components/Card/CardAvatar.jsx';
import Radar from 'react-d3-radar';

import CardHeader from "components/Card/CardHeader.jsx";

import Style from "assets/jss/material-dashboard-pro-react/views/profileStyle";

import blueSteel from "assets/img/faces/avatar.jpg"

import DefaultProfile from 'assets/img/default-avatar.png';


import SolicitationDialog from "../Components/SolicitationDialog.jsx"
import { openSolicitationDialog } from "../../store/actions/navigationActions.jsx";
import config from 'config';
import { db } from '../../firebase/fbConfig.js';

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class ExploreMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: '',
            active: props.active
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    usersData = () => {
        return this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop }
            const volUID = colObj.volUID
            const username = colObj.username
            // Load Data for Summary Cards and add buttons     
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={
                            () => this.handleClickOpen("assignActionModal",
                                volUID
                            )}
                    >ASSIGN ACTION</Button>)
            }
        })
    }

    static compareIgnoreCase(a, b) {
        if (a === b) return true;
        if (!a || !b) return false;
        return (a.toLowerCase().includes(b.toLowerCase()));
    }

    fetchUsersMatches = () => {
        if (this.props.user.id) {
            let matchesArray = []
            db.collection("users").doc(this.props.user.id).collection("matches")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        matchesArray.push(doc.data())
                    });
                    this.setState({
                        matches: matchesArray
                    })
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    fetchUsersWhiteList = () => {
        if (this.props.user.id) {
            let whiteListArray = []
            db.collection("users").doc(this.props.user.id).collection("whiteList")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        whiteListArray.push(doc.data())
                    });
                    this.setState({
                        whiteList: whiteListArray
                    })
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    fetchUsersBlackList = () => {
        if (this.props.user.id) {
            let blackListArray = []
            db.collection("users").doc(this.props.user.id).collection("blackList")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        blackListArray.push(doc.data())
                    });
                    this.setState({
                        blackList: blackListArray
                    })
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    advisorsData = () => {
        let usersToFilter = []
        const { query, users } = this.props;
        let allFilteredUsers = users.dataRows.filter((user) => {
            let nonMatchedUsers = this.state.matches.map((matchedUser) => {
                if (matchedUser.id != user.uid) {
                    usersToFilter.push(matchedUser)
                }
            })
            let nonWhiteListUsers = this.state.whiteList.map((whiteListUser) => {
                if (whiteListUser.id != user.uid) {
                    usersToFilter.push(whiteListUser)
                }
            })
            let nonBlackListUsers = this.state.blackList.map((blackListUser) => {
                if (blackListUser.id != user.uid) {
                    usersToFilter.push(blackListUser)
                }
            })
        })
        let nonDuplicates = [...new Set(usersToFilter)]
        let allUsers = users.dataRows.filter((user) => {
            return (
                ExploreMap.compareIgnoreCase(user.firstName, query) ||
                ExploreMap.compareIgnoreCase(user.lastName, query) ||
                ExploreMap.compareIgnoreCase(user.volUID, query)
            )
        });
        let nonBlackWhiteMatchedUsers = users.dataRows.filter((user) => {
            let removedUsers = usersToFilter.map((filteredUser) => {
                // console.log('filter', filteredUser)
                // console.log('user', user)
                return filteredUser.id !== user.uid
            })
            return removedUsers
        })
        // console.log('non', nonBlackWhiteMatchedUsers)
        if (this.props.user.userType === 'investor') {
            let filteredUsers = allUsers.filter((user) => {
                return user.firstName !== 'N/A' && user.userType === 'advisor';
            });
            return filteredUsers
        } else {
            let filteredUsers = allUsers.filter((user) => {
                return user.firstName !== 'N/A' && user.userType === 'investor'
            })
            return filteredUsers
        }
    }

    handleChange = (docID) => {
        console.log(docID)
    }

    render() {
        const { AuthBool, classes, query, loading, solicitationDialogOpen, openSolicitationDialog, cancelSolicitationDialog, continueSolicitationDialog, direction } = this.props;

        const searchButton =
            classes.top +
            " " +
            classes.searchButton;

        const testArray = ['test1', 'test2', 'test3']

        if (!this.state.matches) {
            this.fetchUsersMatches()
        }

        if (!this.state.whiteList) {
            this.fetchUsersWhiteList()
        }

        if (!this.state.blackList) {
            this.fetchUsersBlackList()
        }

        // if(investments) {this.filterData(query, investments.dataRows)}
        //console.log(investments.dataRows)

        const settings = {
            dots: true,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <div>
                <SolicitationDialog />
                {
                    !loading && this.state.matches && this.state.whiteList && this.state.blackList ? (
                        <div>
                            { }
                            {/* <SwipeableViews
                                enableMouseEvents
                                axis={direction === "rtl" ? "x-reverse" : "x"}
                                index={this.state.active}
                                onChangeIndex={this.handleChangeIndex}
                            > */}
                            {/* Add carousel options to go through users */}
                            {/* {testArray.map((el) => {
                                    return (
                                        <div style={{ color: 'black' }}>{el}</div>
                                    )
                                })} */}
                            <Carousel {...settings} id="home">
                                {this.advisorsData().map(advisorRow => {
                                    return (
                                        <div className={classes.exploreCard} key={advisorRow.uid}>
                                            <GridContainer>
                                                <GridItem xs={12}>
                                                    <Card profile style={{ marginTop: '30px', paddingBottom: '20px' }}>
                                                        <CardAvatar>
                                                            <img
                                                                className={classes.userPic}
                                                                src={advisorRow.profileURL || DefaultProfile}
                                                                alt="User Photo"
                                                            />
                                                        </CardAvatar>
                                                        <h3 className={classes.body}>
                                                            {advisorRow.firstName} {advisorRow.lastName || null}
                                                        </h3>
                                                        <GridContainer>
                                                            <GridItem xs={12}>
                                                                <p>{advisorRow.city}, {advisorRow.state}</p>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridContainer>
                                                            <GridItem xs={12}>
                                                                <p>{advisorRow.bio}</p>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridItem xs={12}>
                                                            <Link to={{
                                                                pathname: `advisor/${advisorRow.uid}`,
                                                                state: { dataLoaded: false }
                                                            }}>
                                                                <Button className={classes.profileButton}><b>View Profile</b></Button>
                                                            </Link>
                                                        </GridItem>
                                                    </Card>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )
                                })}
                            </Carousel>
                            {/* </SwipeableViews> */}
                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        user: state.user.user,
        users: state.user.users,
        solicitationDialogOpen: state.navigation.solicitationDialogOpen,
        loading: false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openSolicitationDialog: () => dispatch(openSolicitationDialog(config.solicitationWarningThread)) // TODO: add continue action
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ExploreMap);

const videoClassesStyle = {
    container: {
        color: "#000",
        backgroundColor: "#dbdbdb",
        margin: "-50px 0 0 0",
        height: "82vh"
    },
    optionCard: {
        borderRadius: "5px",
        padding: "10px",
        height: "33vh",
        margin: "30px 0 0 0"
    },
    lockedCard: {
        borderRadius: "5px",
        padding: "10px",
        height: "33vh",
        backgroundColor: "#a7a7a7",
        margin: "30px 0 0 0"

    },
    addOnIcon: {
        background: "linear-gradient(180deg, rgba(97,164,159,1) 0%, rgba(206,138,89,1) 100%)",
        borderRadius: "50%",
        height: "9vh",
        width: "15vw",
        padding: "17px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "12px"
    },
    description: {
        textAlign: "center"
    },
    header: {
        textAlign: "center",
        fontWeight: "600"
    }
}

export default videoClassesStyle;
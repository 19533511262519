import React from "react";
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom'
import moment from 'moment'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";

import NotificationActionDialog from "views/Components/NotificationActionDialog.jsx";
import { openNotificationActionDialog } from "store/actions/navigationActions.jsx";
import { startModifyNotification } from "store/actions/notificationsActions.jsx";

import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";

function compareIgnoreCase(field, criteria) {
  if (!criteria) return true;
  if (!field) return false;
  return field.toLowerCase().includes(criteria.toLowerCase());
}

function filterNotification(notification, criteria) {
  return !criteria || compareIgnoreCase(notification.text, criteria);
}  

function openDialog(props, notification) {
  const { user, openNotificationActionDialog } = props;
  const { from, cid, tid, role, nid } = notification;
  let warningText, continueAction, rejectAction
  if (cid) {
    if (role === 'advisor') {
      warningText = `
        User ${from} has requested you take on investment ${cid}. You have been
        granted read-only access to this investment. If you continue, you will become the investment owner and 
        be granted full write access. You may also reject this request, in which investment your access to
        the investment materials will be removed. If you do not wish to take either action at this time,
        please press 'cancel'. 
      `;
    } else {
      warningText =  `
        User ${from} has asked to collaborate with you on investment ${cid}. 
        You have been granted read-only access to this investment. If you continue, this notification will
        be removed. You may also reject this request, in which investment your access to
        the investment materials will be removed. If you do not wish to take either action at this time,
        please press 'cancel'. 
        `;
    }
    continueAction = dispatch => dispatch(startModifyNotification(user.id, nid, { status: 'accepted'})).then(()=>dispatch(push(`/investment/${cid}`)));
    rejectAction = startModifyNotification(user.id, nid, { status: 'rejected'});
} else {
    warningText =  `
      User ${from} has asked to join chat thread ${tid}. 
      You have been granted full access to this chat. If you continue, this notification will
      be removed. You may also reject this request, in which investment your access to
      the chat thread will be removed. If you do not wish to take either action at this time,
      please press 'cancel'. 
    `;
    continueAction = dispatch => dispatch(startModifyNotification(user.id, nid, { status: 'accepted'})).then(()=>dispatch(push(`/threads/${tid}`)));
    rejectAction = startModifyNotification(user.id, nid, { status: 'rejected'});
  }
  openNotificationActionDialog(warningText, continueAction, rejectAction);
}

function getText(props, notification) {
  const { threads, investments } = props;
  const { from, cid, tid, role, nid } = notification;
  const relatedInvestment = cid && investments.find(row=>row.cid === cid);
  const relatedThread = tid && threads.find(row=>row.tid === tid);
  if (cid) {
    if (role === 'advisor') {
      return `
        User ${from} has requested you take on investment ${cid}: '${relatedInvestment && relatedInvestment.name}'. 
      `;
    } else {
      return `
        User ${from} has invited you to collaborate on investment ${cid}: '${relatedInvestment && relatedInvestment.name}'. 
      `;
    }
  } else {
    return `
      User ${from} has invited you to participate in chat ${tid}: '${relatedThread && relatedThread.name}'. 
    `;
  }
}


function Notifications(props) {

  const [query, setQuery] = React.useState(null);

    const { notifications, classes } = props;

    return (
      <div>
        <NotificationActionDialog />
        <GridContainer justify="center">

          <GridItem xs={11}>
            <Card
              style={{
                margin: "0"
              }}
            >

              <div style={{ width: "95%", margin: "0 auto", marginTop: "10px" }}>
                <Search style={{ color: "#aaa", marginBottom: "-8px", marginRight: "10px" }} className={classes.headerLinksSvg + " " + classes.searchIcon} />

                <CustomInput
                  formControlProps={{
                    className: classes.top + " " + classes.search,
                    style: { width: "88%" }
                  }}
                  id="notificationsQuery"
                  inputProps={{
                    placeholder: "Search...",
                    onChange: (event) => setQuery(event.target.value),
                    inputProps: {
                      "aria-label": "Search",
                      className: classes.searchInput
                    }
                  }}
                />

              </div>
            </Card>
          </GridItem>
          <GridItem xs={11}>
            {notifications.filter(notification => filterNotification(notification, query)).map((notification, index) => {

              return (
                  <Card key={index} onClick={()=>openDialog(props, notification)}>
                    <CardBody>
                      <p>{getText(props, notification)}</p>
                      <p><small>{moment(notification.createdAt.toDate()).format('MM-DD-YYYY HH:SS')}</small></p>
                    </CardBody>
                  </Card>
              )
            })
            }
          </GridItem>
        </GridContainer>

      </div>
    );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    notifications: state.notifications.notifications.dataRows,
    threads: state.threads.threads.dataRows,
    investments: state.investments.investments.dataRows
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openNotificationActionDialog: (warningText, continueAction, rejectAction, cancelAction) => dispatch(openNotificationActionDialog(warningText, continueAction, rejectAction, cancelAction))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Notifications);

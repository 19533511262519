import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db, sendEmail } from "firebase/fbConfig.js"

import SelectSearch from 'react-select-search'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/AssignAdvisorFormStyle.jsx";
import Explore from "../../views/Explore/Explore";
import ExploreAssignAdvisor from "../../views/Explore/ExploreAssignAdvisor";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false,
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      advisorID: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
    console.log(this.state)
  }
  handleSubmit = (e) => {
    console.log("triggered")
    e.preventDefault();
    // update firestore document with PENDING and comment
    db.collection("users").doc(this.props.authUser.uid).collection("investments").doc(this.props.investmentID).set({
      advisorID: this.state.advisorID,
      advisorAssigned: new Date()
    }, { merge: true }).then(() => {
      db.collection("users").doc(this.state.advisorID).collection("investments").doc(this.props.investmentID).set({
        investmentID: this.props.investmentID,
        advisorID: this.state.advisorID,
        investorID: this.props.authUser.uid,
        advisorAssigned: new Date()
      }, { merge: true }).then(async () => {

        const email = this.props.user.email

        let subject = `You have been assigned a new investment! #${this.props.investmentID}`
        let html = `<p>Hello ${this.state.advisorFirstName}</p>`
        html += `<p>This is a notification / reminder for your latest investment assignment with WealthEnV!</p>`
        html += `<p>Your name investment number is: #${this.props.investmentID} by user #${this.props.authUser.uid}</p>`
        html += `<p>Everytime a investment is generated with your name you can expect to hear from us. You can silence these notifications under Settings inside the GoWealthEnV app!</p>`
        html += `<p>To see investment details in WealthEnV click here: https://wealth-env.web.app/investment/${this.props.investmentID}</p>`
        const emails = [this.state.advisorEmail, 'justin@pondata.com']

        const emailReturn = await sendEmail({
          emails,
          subject,
          html
        })
      }).then(() => {
        db.collection("users").doc(this.state.advisorID).collection("notifications").doc().set({
          text: `You have been assigned Investment #${this.props.investmentID}`,
          investmentID: this.props.investmentID,
          type: "assignAdvisor",
          datetime: new Date()
        }, { merge: true })
      }).then(() => {
        window.location.reload();
      })
    }).catch((err) => {
      console.log(`${err}`)
    })
  }

  resetRequest = () => {
    window.location.reload();
  }

  assignAdvisor = (advisorID, firstName, lastName, email) => {
    this.setState({
      advisorID: advisorID,
      advisorFirstName: firstName,
      advisorLastName: lastName,
      advisorEmail: email
    })
  }

  searchAdvisors = () => {
    this.setState({
      advisorID: false,
    })
  }


  render() {
    const { classes, modalClose, authUser, user, blogID, investmentID } = this.props;
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }
    console.log(this.props)

    const options = [
      { name: 'Bob', value: 'bob' },
      { name: 'Steve', value: 'steve' },
    ];

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 style={{ margin: "10px 10px 10px 10px", textAlign: "center" }} className={classes.textPrimary}>Investment Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div style={{ backgroundColor: "rgb(22, 30, 52)" }}>
            <form onSubmit={this.handleSubmit}
            >
              <GridContainer justify="center">
                <GridItem xs={12} style={{ marginTop: "-40px", backgroundColor: "#264653" }}>
                  {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                  {
                    !!this.state.advisorID ?
                      <Button color="primary"
                        onClick={() => this.searchAdvisors()}
                      >
                        SEARCH ADVISORS
                      </Button>
                      :
                      <ExploreAssignAdvisor onAssignAdvisor={this.assignAdvisor} />
                  }


                  <br /><br />
                  <p>Advisor: {this.state.advisorFirstName} {this.state.advisorLastName}</p>
                  <p>Investor: {this.props.authUser ? this.props.authUser.uid : "Loading..."}</p>
                  {/*<SelectSearch options={options} value="sv" id ="advisorID" onChange={this.handleChange} name="language" placeholder="Choose your language" />*/}
                  Investment Number: <b>{investmentID}</b>; Investment type:
                </GridItem>
              </GridContainer>
              <Button
                type="submit"
                color="primary"
                style={{
                  float: "right",
                  backgroundColor: "#0fb3d8",
                  marginBottom: "10px"
                }}
              >
                <b>CONFIRM</b>
              </Button>
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);
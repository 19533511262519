import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import moment from 'moment'
import { Link } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import loader from "assets/img/loader.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import Style from "assets/jss/material-dashboard-pro-react/views/threadsStyle";

// Forms

// Firebase Redux Integration
import DefaultProfile from "assets/img/default-avatar.png"

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

function messageSort(a, b) {
    return a.createdAt - b.createdAt;
}

function ident(user) {
    return (user.firstName && user.firstName.trim().charAt(0)) + (user.lastName && user.lastName.trim().charAt(0));
}

class InvestorThreadsMap extends React.Component {

    render() {
        const { classes, user, users, currentThread } = this.props;

        let profile = DefaultProfile

        return (
            <div>
                {
                    currentThread ? (
                        <div>
                            {currentThread.messages && currentThread.messages.sort(messageSort).map((message, index) => {
                                if (message.uid === user.id) {
                                    return (
                                        <div key={index}>
                                            <GridContainer>
                                                <GridItem xs={9}>
                                                    <Card style={{ marginTop: '0px', marginBottom: '10px' }}>
                                                        <CardBody>
                                                            <p className={classes.cardCategory}>
                                                                {message.message}
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                                <GridItem xs={2} >
                                                    {ident(user)}
                                                    <img src={user.profileURL} style={{ borderRadius: "50%", height: "40px" }} alt="..." />
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )
                                } else {
                                    const sender = users.find(row => row.uid === message.uid);
                                    const senderImage = sender && sender.profileUrl ? sender.profileUrl : DefaultProfile;
                                    return (
                                        <div key={index}>
                                            <GridContainer>
                                                <GridItem xs={2} >
                                                    {ident(sender)}
                                                    <img src={senderImage} style={{ borderRadius: "50%", height: "40px" }} alt="..." />
                                                </GridItem>
                                                <GridItem xs={9}>
                                                    <Card style={{ marginTop: '0px', marginBottom: '10px' }}>
                                                        <CardBody>
                                                            <p className={classes.cardCategory}>
                                                                {message.message}
                                                            </p>
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )
                                }
                            })}



                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.user.users.dataRows,
        user: state.user.user,
        currentThread: state.threads.currentThread
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    console.log(ownProps)
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(InvestorThreadsMap);

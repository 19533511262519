import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/components/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
        values,
        user
    } = props;

    let newValues = values;
    let defaultValue = props.default;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });

    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    let newInputProps = inputProps;

    if (props.name === 'analyst' || props.name === 'loanOfficer') {
        let inputValueNameString = inputProps.value.firstName + " " + inputProps.value.lastName;
        let newInputValueKey;

        Object.entries(values).forEach(([key, value]) => {
            if (value.includes(inputValueNameString)) {
                newInputValueKey = key;
            }
        })

        if (props.name === 'loanOfficer' && props.user.userType === 'LOAN_OFFICER') {
            newValues = values.filter((value) => {
                return value.includes(`${user.firstName} ${user.lastName} `);
            })
        }

        if (!!newInputValueKey) {
            newInputProps = {
                ...inputProps,
                value: newInputValueKey
            }
        }
    }

    if (props.default) {
        delete newInputProps.value;
        newValues.forEach((value, index) => {
            if (value == props.default) {
                defaultValue = index;
            }
        })
    }
    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <Select

                classes={{
                    select: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled
                }}
                id={id}
                defaultValue={ defaultValue }
                {...newInputProps}
            >
                {Object.entries(values).map(([key,value]) =>{
                        if (newValues.includes(value)) {
                            return <MenuItem classes={{ root: classes.selectMenuItem }} key={key} value={key}>{value}</MenuItem>
                        }
                    }
                )}
            </Select>
            {helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CustomSelect.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node,
    values: PropTypes.object.isRequired
};

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink, withRouter } from 'react-router-dom';
import AddToCalendar from 'react-add-to-calendar';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MessageIcon from '@material-ui/icons/Message';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import TodayIcon from '@material-ui/icons/Today';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/conferenceCalendarStyle';



import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import Datetime from 'react-datetime';

import {
  startSetEventsList,
  addNewEvent,
  deleteUserFromEvent,
  setUserRemovedFromEvent,
  updateExistingEvent
  // setEventAttendess,
} from 'store/actions/calendarActions';
import avatar from 'assets/img/default-avatar.png'
import { event } from 'firebase-functions/lib/providers/analytics';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

export function ConferenceCalendar(props) {
  const {
    classes,
    startSetEventsList,
    uid,
    data,
    addNewEvent,
    users,
    history,
    user,
    matches,
    deleteUserFromEvent,
    userRemoved
  } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [newEventFormOpen, setNewEventFormOpen] = useState(false);
  const [eventName, setEventName] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [eventSelected, setEventSelected] = useState(null);
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [usersInEvent, setUsersInEvent] = useState([]);
  const [needsToFillFields, setNeedsToFillFields] = useState(false);
  const [groupChatId, setGroupChatId] = useState(null);
  const [needsToChangeStartTime, setNeedsToChangeStartTime] = useState(false)
  const [newData, setNewData] = useState([])
  const [editEventFormOpen, setEditEventFormOpen] = useState(false)
  const [editFormStartTime, setEditFormStartTime] = useState(false)
  const [editFormEndTime, setEditFormEndTime] = useState(false)
  const [userInAttendeesAlready, setUserInAttendeesAlready] = useState(false)
  const [editFormTitle, setEditFormTitle] = useState(false)
  const [eventUpdated, setEventUpdated] = useState(false)
  const [addToMyCalendarModal, setAddToMyCalendarModal] = useState(false)

  const getData = useCallback(() => {
    var newArray = []
    data.map((d, index) => {
      newArray.push({ start: new Date(Date.parse(d.start)), end: new Date(Date.parse(d.end)), attendees: d.attendees, groupChatId: d.groupChatId, id: d.id, title: d.title, createdByID: d.createdByID })
    })
    setNewData(newArray)
    setDataLoaded(true)
  }, [data]);


  useEffect(() => {
    if (!dataLoaded) {
      getData()
    }
  }, [data])

  useEffect(() => {
    if (uid && !dataLoaded) {
      startSetEventsList(uid);
    }
  }, [uid]);
  console.log('calendar')

  useEffect(() => {
    if (eventSelected) {
      setGroupChatId(eventSelected.groupChatId);
    }
  }, [eventSelected]);

  const valid = (current) => {
    return current.isAfter(Datetime.moment().subtract(1, 'day'));
  };

  const removeUserFromEvent = (id) => {
    var copyOfUsersInEvent = [...usersInEvent];
    for (var x = 0; x < copyOfUsersInEvent.length; x++) {
      var currentUser = copyOfUsersInEvent[x];
      if (currentUser.id === id) {
        copyOfUsersInEvent.splice(x, 1);
      }
    }
    setUsersInEvent(copyOfUsersInEvent);
  };

  const addAttendee = (e, str) => {
    if (str === 'adding') {
      setUsersInEvent([...usersInEvent, e.target.value]);
    } else {
      var userAlreadyInMeeting = false
      for (var x = 0; x < usersInEvent.length; x++) {
        if (e.target.value.id === usersInEvent[x].id) {
          userAlreadyInMeeting = true
          break
        }
      }
      if (userAlreadyInMeeting) {
        setUserInAttendeesAlready(true)
      } else {
        var attendeesInEvent = [...eventSelected.attendees, ...usersInEvent, e.target.value]
        let unique = [...new Set(attendeesInEvent)];
        setUsersInEvent(unique);
      }
    }
  };


  const updateEvent = () => {
    if (!editFormStartTime || !editFormEndTime || !editFormTitle) {
      setNeedsToFillFields(true);
    } else {
      if (editFormStartTime && editFormStartTime > editFormEndTime) {
        setNeedsToChangeStartTime(true)
      } else {
        props.updateExistingEvent({ eventName: editFormTitle, startTime: editFormStartTime, endTime: editFormEndTime, attendees: usersInEvent, groupChatId: eventSelected.groupChatId, groupChatMessages: eventSelected.groupChatMessages ? eventSelected.groupChatMessages : [], createdByID: eventSelected.createdByID, allDay: false }, uid, user);
        setUsersInEvent([]);
        setEditFormTitle('');
        setEditFormStartTime('');
        setEditFormEndTime('');
        setEditEventFormOpen(false);
        setEventUpdated(true)
      }
    }
  }

  const addEvent = () => {
    if (!eventName || !startTime || !endTime) {
      setNeedsToFillFields(true);
    } else {
      if (startTime && startTime > endTime) {
        setNeedsToChangeStartTime(true)
      } else {
        console.log("usersInEventusersInEvent", usersInEvent)
        addNewEvent({ eventName, startTime, endTime, attendees: usersInEvent }, uid);
        setUsersInEvent([]);
        setEventName('');
        setStartTime('');
        setEndTime('');
        setNewEventFormOpen(false);
      }
    }
  };

  const getUsers = () => {
    const { users } = props;
    var userData = users.dataRows;
    let filteredUsers = userData.filter((user) => {
      return user.firstName !== 'N/A';
    });
    return filteredUsers;

  };

  let items = [
    { outlook: 'Outlook' },
    { google: 'Google' },
    { apple: 'Apple Calendar' },
    { yahoo: 'Yahoo!' },
  ]

  let icon = { 'calendar-plus-o': 'left' };

  let event = {
    title: eventName,
    description: "WealthEnV Conference",
    location: "",
    startTime: startTime,
    endTime: endTime,
  }

  return (
    <GridContainer>
      <GridItem className={classes.calendarGridItem} xs={12}>
        <BigCalendar
          // component={components}
          selectable
          localizer={localizer}
          events={newData}
          selected={eventSelected}
          startAccessor="start"
          endAccessor="end"
          onSelectSlot={(event) => {
            setNewEventFormOpen(true)
            setStartTime(event.start)
            setEndTime(event.end)
          }}
          onSelectEvent={(event) => {
            event.start = event.start.toString()
            event.end = event.end.toString()
            setEventSelected(event);
            setIsEventSelected(true);
          }}
        />
        <Fab
          onClick={() => setNewEventFormOpen(true)}
          className={classes.addNewEventFab}
        >
          <AddIcon className={classes.addNewEventIcon} />
        </Fab>
      </GridItem>

      <Snackbar
        style={{ marginBottom: '30px' }}
        open={userRemoved}
        onClose={() => {
          props.setUserRemovedFromEvent(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            props.setUserRemovedFromEvent(false);
          }}
          severity="error"
        >
          User Removed From Event.
        </Alert>
      </Snackbar>


      <Snackbar
        style={{ marginBottom: '30px' }}
        open={userInAttendeesAlready}
        onClose={() => {
          setUserInAttendeesAlready(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            setUserInAttendeesAlready(false);
          }}
          severity="error"
        >
          User Is Already An Attendee.
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginBottom: '30px' }}
        open={eventUpdated}
        onClose={() => {
          setEventUpdated(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            setEventUpdated(false);
          }}
          severity="success"
        >
          The Event Has Been Updated For All Attendees.
        </Alert>
      </Snackbar>


      <Snackbar
        style={{ marginBottom: '30px' }}
        open={needsToChangeStartTime}
        onClose={() => {
          setNeedsToChangeStartTime(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            setNeedsToChangeStartTime(false);
          }}
          severity="error"
        >
          The Start Time Must Be Before The End Time.
        </Alert>
      </Snackbar>




      <Snackbar
        style={{ marginBottom: '30px' }}
        open={needsToFillFields}
        onClose={() => {
          setNeedsToFillFields(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            setNeedsToFillFields(false);
          }}
          severity="error"
        >
          Event Name, Start Time and End Time Are Mandatory Fields.
        </Alert>
      </Snackbar>

      <Dialog
        maxWidth="lg"
        classes={{
          root: classes.modalRoot,
          paper: classes.modalPaper,
        }}
        open={isEventSelected}
        TransitionComponent={Transition}
        onClose={() => {
          setIsEventSelected(false);
        }}
      >
        <Card className={classes.modalCard}>
          <DialogTitle
            className={classes.modalTitle}
            classes={{
              root: classes.modalTitleRoot,
            }}
          >
            <h1>View Event Details</h1>
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            <GridContainer className={classes.navBtns}>
              <GridItem
                onClick={() => history.push(`/group-message/${groupChatId}`)}
                className={classes.navDivItem}
                xs={3}
              >
                <div className={classes.individualNavDiv}>
                  <MessageIcon className={classes.messageIcon} />
                </div>
                <p className={classes.navDescriptor}>Check Messages</p>
              </GridItem>
              <GridItem
                onClick={() => history.push('/video-conference')}
                className={classes.navDivItem}
                xs={3}
              >
                <div className={classes.individualNavDiv}>
                  <VideoCallIcon className={classes.messageIcon} />
                </div>
                <p className={classes.navDescriptor}>Video Conferences</p>
              </GridItem>
              <GridItem
                onClick={() => {
                  setIsEventSelected(false);
                  setEditEventFormOpen(true);
                  setEditFormStartTime(moment(eventSelected.start).format('MM/DD/YYYY HH:mm A'))
                  setEditFormEndTime(moment(eventSelected.end).format('MM/DD/YYYY HH:mm A'))
                  setEditFormTitle(eventSelected.title)
                  setUsersInEvent([...eventSelected.attendees])

                }}
                className={classes.navDivItem}
                xs={3}
              >
                <div className={classes.individualNavDiv}>
                  <TodayIcon className={classes.messageIcon} />
                </div>
                <p className={classes.navDescriptor}>Edit Event</p>
              </GridItem>


              {/* <GridItem
                onClick={() => {
                  setAddToMyCalendarModal(true)
                  setIsEventSelected(false)
                }}
                className={classes.navDivItem}
                xs={3}
              >
                <div className={classes.individualNavDiv}>
                  <AddIcon className={classes.messageIcon} />
                </div>
                <p className={classes.navDescriptor}>Add To My Calendar</p>
              </GridItem> */}


            </GridContainer>

            <h6>Event Title:</h6>
            <p>{eventSelected && eventSelected.title}</p>
            <h6>Event Start:</h6>
            <p>{eventSelected && eventSelected.start.toString()}</p>
            <h6>Event End:</h6>
            <p>{eventSelected && eventSelected.end.toString()}</p>
            <h6>Attendees:</h6>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {eventSelected && eventSelected.attendees.length > 0
                ? eventSelected.attendees.map((user, index) => {
                  if (user.uid !== uid) {
                    if (eventSelected.createdByID && eventSelected.createdByID === uid) {
                      return (
                        <div className={classes.selectedUsersInEvent}>
                          <div className={classes.userInfoDiv}>
                            <img
                              className={classes.recommendationsPic}
                              src={user.profileURL ? user.profileURL : avatar}
                            />
                          </div>
                          <div style={{ flexDirection: "column" }} className={classes.userInfoDiv}>
                            <p>{`${user.firstName} ${user.lastName}`}</p>
                            <p>{user.email}</p>
                          </div>
                          {/* <div className={classes.userInfoDiv}>
                            <p>{user.email}</p>
                          </div> */}
                        </div>
                      );
                    } else {
                      return (
                        <div className={classes.selectedUsersInEvent}>
                          <div className={classes.userInfoDiv}>
                            <img
                              className={classes.recommendationsPic}
                              src={user.profileURL ? user.profileURL : avatar}
                            />
                          </div>
                          <div style={{ flexDirection: "column" }} className={classes.userInfoDiv}>
                            <p>{`${user.firstName} ${user.lastName}`}</p>
                            <p>{user.email}</p>
                          </div>
                          {/* <div className={classes.userInfoDiv}>
                            <p>{user.email}</p>
                          </div> */}
                        </div>
                      );
                    }
                  }
                })
                : 'N/A'}
            </div>

            <Card style={{ backgroundColor: '#61a59f', margin: '10px auto', width: '100%', padding: '10px', textAlign: "center" }}>
              <AddToCalendar
                event={event}
                listItems={items}
                buttonTemplate={icon}
                id='button-id'
              />
            </Card>
          </DialogContent>
        </Card>
      </Dialog>









      <Dialog
        maxWidth="lg"
        classes={{
          root: classes.modalRoot,
          paper: classes.modalPaper,
        }}
        open={addToMyCalendarModal}
        TransitionComponent={Transition}
        onClose={() => {
          setAddToMyCalendarModal(false)
        }}
      >
        <Card className={classes.modalCard}>
          <DialogTitle
            className={classes.modalTitle}
            classes={{
              root: classes.modalTitleRoot,
            }}
          >
            <h1>Add This Event To An External Calendar</h1>
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            <Card style={{ backgroundColor: '#61a59f', margin: '10px auto', width: '75%', padding: '10px' }}>
              <AddToCalendar
                event={event}
                listItems={items}
                buttonTemplate={icon}
                id='button-id'
              />
            </Card>
          </DialogContent>
        </Card>
      </Dialog>







      <Dialog
        maxWidth="lg"
        classes={{
          root: classes.modalRoot,
          paper: classes.modalPaper,
        }}
        open={editEventFormOpen}
        TransitionComponent={Transition}
        onClose={() => {
          setEditEventFormOpen(false);
        }}
      >
        <Card className={classes.modalCard}>
          <DialogTitle
            className={classes.modalTitle}
            classes={{
              root: classes.modalTitleRoot,
            }}
          >
            <h1>Edit This Event</h1>
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            {/* <form className={classes.formDiv}> */}
            <TextField
              disabled={eventSelected && eventSelected.createdByID !== uid}
              defaultValue={eventSelected && eventSelected.title}
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => setEditFormTitle(e.target.value)}
              id="outlined-basic"
              label="Event Name"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
            <Datetime
              isValidDate={valid}
              timeFormat={true}
              onChange={(e) => {
                setEditFormStartTime(e._d);
                var newdate = moment(e._d).add(30, 'm').toDate();
                setEditFormEndTime(newdate);
                // setEndTime(e._d);
              }}
              selected={editFormStartTime}
              defaultValue={editFormStartTime}
              value={editFormStartTime}
              renderInput={(dateInputProps, open, close) => (
                <TextField
                  disabled={eventSelected && eventSelected.createdByID !== uid}
                  style={{ width: '100%' }}
                  autoComplete="off"
                  inputProps={{ ...dateInputProps, required: true }}
                  InputProps={{
                    classes: {
                      input: classes.inputColor,
                    },
                  }}
                  defaultValue={startTime}
                  value={startTime}
                  id="outlined-basic"
                  label="Start Time"
                  variant="standard"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            />
            <Datetime
              isValidDate={valid}
              timeFormat={true}
              onChange={(e) => setEditFormEndTime(e._d)}
              selected={editFormEndTime}
              defaultValue={editFormEndTime}
              value={editFormEndTime}
              renderInput={(dateInputProps, open, close) => (
                <TextField
                  disabled={eventSelected && eventSelected.createdByID !== uid}
                  style={{ width: '100%', marginBottom: '30px' }}
                  label="End Time"
                  value={editFormEndTime}
                  inputProps={{ ...dateInputProps, required: true }}
                  InputProps={{
                    classes: {
                      input: classes.inputColor,
                    },
                  }}
                  variant="standard"
                  // labelText="End Date"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            />
            {/* </FormControl> */}
            {/* <FormControl> */}
            <InputLabel id="Attendees">Attendees</InputLabel>
            <Select
              id="Attendees"
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={''}
              onChange={(e) => addAttendee(e, 'editing')}
              label="Attendees"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
              }}
            >
              {matches !== undefined && matches.map((item, index) => {
                if (item.id && item.id !== uid) {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={`${item.firstName} ${item.lastName}`}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </MenuItem>
                  )
                }
                if (item.uid && item.uid !== uid) {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={`${item.firstName} ${item.lastName}`}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </MenuItem>
                  )
                }
              })}
            </Select>

            {usersInEvent.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {usersInEvent && usersInEvent.map((user, index) => {
                  if (user.uid && user.uid === uid) {
                    return (
                      null
                    )
                  } else if (user.id && user.id === uid) {
                    return (
                      null
                    )
                  } else {
                    return (
                      <div key={index} className={classes.selectedUsersInEvent}>
                        <div className={classes.userInfoDiv}>
                          <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                        <div className={classes.userInfoDiv}>
                          <img
                            src={user.profileURL ? user.profileURL : avatar}
                            className={classes.recommendationsPic}
                          />
                        </div>
                        {eventSelected && eventSelected.createdByID === uid ? (
                          <div key={index} className={classes.userInfoDiv}>
                            <Button
                              className={classes.removeBtn}
                              onClick={() => {
                                var attendeesCopy = [...eventSelected.attendees]
                                for (var x = 0; x < attendeesCopy.length; x++) {
                                  var currentAttendee = attendeesCopy[x]
                                  if (currentAttendee.id === user.id) {
                                    attendeesCopy.splice(x, 1)
                                  }
                                }
                                var updatedEvent = eventSelected
                                updatedEvent.attendees = attendeesCopy
                                var updatedNewData = [...newData]
                                for (var y = 0; y < updatedNewData.length; y++) {
                                  var currentEvent = updatedNewData[y]
                                  if (currentEvent.groupChatId === updatedEvent.groupChatId) {
                                    currentEvent = updatedEvent
                                  }
                                }
                                setUsersInEvent(attendeesCopy)
                                setEventSelected(updatedEvent);
                                setNewData(updatedNewData)
                                deleteUserFromEvent(user.id, updatedEvent, uid)
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        ) : (
                          null
                        )}

                      </div>
                    )
                  }
                }
                )}
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {eventSelected && eventSelected.attendees.map((user, index) => {
                  if (user.uid && user.uid === uid) {
                    return (
                      null
                    )
                  }
                  if (user.id && user.id !== uid) {
                    return (
                      <div className={classes.selectedUsersInEvent}>
                        <div className={classes.userInfoDiv}>
                          <p>{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                        <div className={classes.userInfoDiv}>
                          <img
                            src={user.profileURL ? user.profileURL : avatar}
                            className={classes.recommendationsPic}
                          />
                        </div>
                        <div className={classes.userInfoDiv}>
                          <Button
                            className={classes.removeBtn}
                            onClick={() => {
                              var attendeesCopy = [...eventSelected.attendees]
                              for (var x = 0; x < attendeesCopy.length; x++) {
                                var currentAttendee = attendeesCopy[x]
                                if (currentAttendee.id === user.id) {
                                  attendeesCopy.splice(x, 1)
                                }
                              }
                              var updatedEvent = eventSelected
                              updatedEvent.attendees = attendeesCopy
                              var updatedNewData = [...newData]
                              for (var y = 0; y < updatedNewData.length; y++) {
                                var currentEvent = updatedNewData[y]
                                if (currentEvent.groupChatId === updatedEvent.groupChatId) {
                                  currentEvent = updatedEvent
                                }
                              }
                              setUsersInEvent(attendeesCopy)
                              setEventSelected(updatedEvent);
                              setNewData(updatedNewData)
                              deleteUserFromEvent(user.id, updatedEvent, uid)
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    )
                  }
                }
                )}
              </div>
            )}
          </DialogContent>
          {/* <Card style={{ backgroundColor: '#61a59f', margin: '10px auto', width: '75%', padding: '10px' }} >
            <AddToCalendar
              event={event}
              listItems={items}
              buttonTemplate={icon}
            />
          </Card> */}
          <Button
            style={{ backgroundColor: '#61a59f', margin: '10px auto', width: '75%' }}
            onClick={updateEvent}
          >
            Submit
          </Button>
        </Card>
      </Dialog>














      <Dialog
        maxWidth="lg"
        classes={{
          root: classes.modalRoot,
          paper: classes.modalPaper,
        }}
        open={newEventFormOpen}
        TransitionComponent={Transition}
        onClose={() => {
          setNewEventFormOpen(false);
        }}
      >
        <Card className={classes.modalCard}>
          <DialogTitle
            className={classes.modalTitle}
            classes={{
              root: classes.modalTitleRoot,
            }}
          >
            <h1>Add A New Event</h1>
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            {/* <form className={classes.formDiv}> */}
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => setEventName(e.target.value)}
              id="outlined-basic"
              label="Event Name"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
            <Datetime
              isValidDate={valid}
              timeFormat={true}
              onChange={(e) => {
                setStartTime(e._d);
                var newdate = moment(e._d).add(30, 'm').toDate();
                setEndTime(newdate);
                // setEndTime(e._d);
              }}
              selected={startTime}
              defaultValue={startTime}
              value={startTime}
              renderInput={(dateInputProps, open, close) => (
                <TextField
                  style={{ width: '100%' }}
                  autoComplete="off"
                  inputProps={{ ...dateInputProps, required: true }}
                  InputProps={{
                    classes: {
                      input: classes.inputColor,
                    },
                  }}
                  defaultValue={startTime}
                  value={startTime}
                  id="outlined-basic"
                  label="Start Time"
                  variant="standard"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            />
            <Datetime
              isValidDate={valid}
              timeFormat={true}
              onChange={(e) => setEndTime(e._d)}
              selected={endTime}
              defaultValue={endTime}
              value={endTime}
              renderInput={(dateInputProps, open, close) => (
                <TextField
                  style={{ width: '100%', marginBottom: '30px' }}
                  label="End Time"
                  value={endTime}
                  inputProps={{ ...dateInputProps, required: true }}
                  InputProps={{
                    classes: {
                      input: classes.inputColor,
                    },
                  }}
                  variant="standard"
                  // labelText="End Date"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            />
            {/* </FormControl> */}
            {/* <FormControl> */}
            <InputLabel id="Attendees">Attendees</InputLabel>
            <Select
              id="Attendees"
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={''}
              onChange={(e) => addAttendee(e, 'adding')}
              label="Attendees"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
              }}
            >
              {matches !== undefined && matches.map((item, index) => {
                console.log('itemmmmm', item)
                if (item.id && item.id !== uid) {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={`${item.firstName} ${item.lastName}`}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </MenuItem>
                  )
                }
                if (item.uid && item.uid !== uid) {
                  return (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={`${item.firstName} ${item.lastName}`}
                    >
                      {`${item.firstName} ${item.lastName}`}
                    </MenuItem>
                  )
                }
              })}
            </Select>
            {/* </FormControl> */}
            {usersInEvent.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {usersInEvent.map((user, index) => (
                  <div className={classes.selectedUsersInEvent}>
                    <div className={classes.userInfoDiv}>
                      <p>{`${user.firstName} ${user.lastName}`}</p>
                    </div>
                    <div className={classes.userInfoDiv}>
                      <img
                        src={user.profileURL ? user.profileURL : avatar}
                        className={classes.recommendationsPic}
                      />
                    </div>
                    <div className={classes.userInfoDiv}>
                      <Button
                        className={classes.removeBtn}
                        onClick={() => removeUserFromEvent(user.id)}
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {/* </form> */}
          </DialogContent>
          <Button
            style={{ backgroundColor: '#61a59f', margin: '10px auto', width: '75%' }}
            onClick={addEvent}
          >
            Submit
          </Button>
        </Card>
      </Dialog>
    </GridContainer >
  );
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
  startSetEventsList: (uid) => dispatch(startSetEventsList(uid)),
  addNewEvent: (eventInfo, uid) => dispatch(addNewEvent(eventInfo, uid)),
  deleteUserFromEvent: (id, updatedEvent, uid) => dispatch(deleteUserFromEvent(id, updatedEvent, uid)),
  setUserRemovedFromEvent: (boolean) => dispatch(setUserRemovedFromEvent(boolean)),
  updateExistingEvent: (eventInfo, uid, userInfo) => dispatch(updateExistingEvent(eventInfo, uid, userInfo))
  // setEventAttendess: (attendees) => dispatch(setEventAttendess(attendees)),
});

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    user: state.user.user,
    users: state.user.users,
    uid: state.auth.user.uid,
    data: state.events.events.data,
    userRemoved: state.events.userRemoved,
    matches: state.user.matches
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(ConferenceCalendar)
);


import React, { Component } from 'react';
import FirstQuestion from './Questions/FirstQuestion';
import SecondQuestion from './Questions/SecondQuestion';
import ThirdQuestion from './Questions/ThirdQuestion';
import FourthQuestion from './Questions/FourthQuestion';
import FifthQuestion from './Questions/FifthQuestion';
import SixthQuestion from './Questions/SixthQuestion';
import SeventhQuestion from './Questions/SeventhQuestion';
import EighthQuestion from './Questions/EighthQuestion';
import NinthQuestion from './Questions/NinthQuestion';
import TenthQuestion from './Questions/TenthQuestion';
import Confirm from './Confirm';
import Success from './Success';

import FirstQuestionGlobalResults from './GlobalResults/FirstQuestionGlobalResults'
import SecondQuestionGlobalResults from './GlobalResults/SecondQuestionGlobalResults'
import ThirdQuestionGlobalResults from './GlobalResults/ThirdQuestionGlobalResults'
import FourthQuestionGlobalResults from './GlobalResults/FourthQuestionGlobalResults'
import FifthQuestionGlobalResults from './GlobalResults/FifthQuestionGlobalResults'
import SixthQuestionGlobalResults from './GlobalResults/SixthQuestionGlobalResults'
import SeventhQuestionGlobalResults from './GlobalResults/SeventhQuestionGlobalResults'
import EighthQuestionGlobalResults from './GlobalResults/EighthQuestionGlobalResults'
import NinthQuestionGlobalResults from './GlobalResults/NinthQuestionGlobalResults'
import TenthQuestionGlobalResults from './GlobalResults/TenthQuestionGlobalResults'

import { db } from 'firebase/fbConfig.js';

export class FiveFactorModel extends Component {
    state = {
        step: 1,
        extravertedEnthusiastic: 0,
        criticalQuarralsome: 0,
        dependableSelfDisciplined: 0,
        anxiousEasilyUpset: 0,
        openComplex: 0,
        reservedQuiet: 0,
        sympatheticWarm: 0,
        disorganizedCareless: 0,
        calmEmotionallyStable: 0,
        conventionalUncreative: 0,
        dataLoaded: false,
        globalTestResults: {},
        steps: {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: ''
        }
    };


    componentDidMount() {
        if (!this.state.dataLoaded) {
            return db.collection('global-test-results').doc('fiveFactorQuiz').get().then((snapshot) => {
                const results = snapshot.data()
                console.log('results', results)
                this.setState({
                    dataLoaded: true,
                    globalTestResults: results
                })
            })
        }
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    // Go back to first question/retake quiz
    firstStep = () => {
        const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    // Handle toggle change
    handleToggle = (input, value) => {
        this.setState({
            [input]: value
        })
        // Get second opinion on how long before progressing to next step
        // setTimeout(this.nextStep, 700)
    }

    handleToggleLast = (input, value) => {
        this.setState({
            [input]: value
        })
        // Get second opinion on how long before progressing to next step
        // setTimeout(this.nextStep, 700)
    }

    render() {
        const { classes } = this.props;
        const { step } = this.state;
        const { extravertedEnthusiastic, criticalQuarralsome, dependableSelfDisciplined, anxiousEasilyUpset, openComplex, reservedQuiet, sympatheticWarm, disorganizedCareless, calmEmotionallyStable, conventionalUncreative } = this.state;
        const values = { extravertedEnthusiastic, criticalQuarralsome, dependableSelfDisciplined, anxiousEasilyUpset, openComplex, reservedQuiet, sympatheticWarm, disorganizedCareless, calmEmotionallyStable, conventionalUncreative };
        // const uid = this.state.auth.user.uid

        switch (step) {
            case 1:
                return (
                    <FirstQuestion
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        prevStep={this.prevStep}
                        values={values}
                        classes={classes}
                        compare={'extravertedEnthusiastic'}
                    />
                );
            case 2:
                return (
                    <FirstQuestionGlobalResults
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        prevStep={this.prevStep}
                        globalTestResults={this.state.globalTestResults}
                        compare={'extravertedEnthusiastic'}
                    />
                );
            case 3:
                return (
                    <SecondQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'criticalQuarralsome'}
                    />
                );
            case 4:
                return (
                    <SecondQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'criticalQuarralsome'}
                    />
                );
            case 5:
                return (
                    <ThirdQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'dependableSelfDisciplined'}
                    />
                );
            case 6:
                return (
                    <ThirdQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'dependableSelfDisciplined'}
                    />
                );
            case 7:
                return (
                    <FourthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'anxiousEasilyUpset'}
                    />
                );
            case 8:
                return (
                    <FourthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'anxiousEasilyUpset'}
                    />
                );
            case 9:
                return (
                    <FifthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'openComplex'}
                    />
                );
            case 10:
                return (
                    <FifthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'openComplex'}
                    />
                );
            case 11:
                return (
                    <SixthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'reservedQuiet'}
                    />
                );
            case 12:
                return (
                    <SixthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'reservedQuiet'}
                    />
                );

            case 13:
                return (
                    <SeventhQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'sympatheticWarm'}
                    />
                );
            case 14:
                return (
                    <SeventhQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'sympatheticWarm'}
                    />
                );
            case 15:
                return (
                    <EighthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'disorganizedCareless'}
                    />
                );
            case 16:
                return (
                    <EighthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'disorganizedCareless'}
                    />
                );
            case 17:
                return (
                    <NinthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'calmEmotionallyStable'}
                    />
                );
            case 18:
                return (
                    <NinthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'calmEmotionallyStable'}
                    />
                );
            case 19:
                return (
                    <TenthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'conventionalUncreative'}
                    />
                );
            case 20:
                return (
                    <TenthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'conventionalUncreative'}
                    />
                );
            case 11:
                return (
                    <Confirm
                        nextStep={this.nextStep}
                        firstStep={this.firstStep}
                        prevStep={this.prevStep}
                        values={values}
                    />
                );
            case 12:
                return (
                    <Success
                        firstStep={this.firstStep}
                    />
                )
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default FiveFactorModel;
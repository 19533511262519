import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';



const LandingPage = (props) => {

  const { classes, advisorData, nextStep } = props;

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: "54px" }}>
        <h3 className={classes.landingHeader}>Welcome to the intake form for {advisorData.firstName}  {advisorData.lastName}!</h3>
        <p className={classes.clickButton}>Please Click The Button Below To Get Started</p>
      </div>

      <GridContainer>
        <GridItem xs={12}>
          <Button
            className={classes.startButton}
            onClick={nextStep}
          >Start</Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(LandingPage);
import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from "@material-ui/icons/Add";
import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';

import GoalsForm from '../Form/GoalsForm';



const GoalsQuestion = (props) => {

  const { classes, advisorData, nextStep, addGoal, goalsData, setGoalsData } = props;

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: "25px" }}>
        <h3 className={classes.landingHeader}>Goals</h3>
        <p className={classes.clickButton}>Add desired purchases by clicking below</p>
      </div>

      <GridContainer>
        {goalsData.map((d, index) => {
          return (
            <GoalsForm goalsData={goalsData} index={index} setGoalsData={setGoalsData} name={d.name} timeframe={d.timeframe} cost={d.cost} criticality={d.criticality} priority={d.priority} />
          )
        })}
      </GridContainer>

      <GridContainer className={classes.gridContainer}>
        <GridItem xs={6}>
          <Button
            className={classes.addButton}
            onClick={addGoal}
          ><AddIcon />Add Goal</Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            className={classes.nextButton}
            onClick={nextStep}
          >Next</Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(GoalsQuestion);
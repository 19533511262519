import React from 'react';
import PropTypes from 'prop-types';


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

import avatar from 'assets/img/faces/user.png';

function compareIgnoreCase(field, criteria) {
  if (!criteria) return true;
  if (!field) return false;
  return field.toLowerCase().includes(criteria.toLowerCase());
}

function filterPartyName(party, criteria) {
  return !criteria || compareIgnoreCase(party.firstName, criteria) || compareIgnoreCase(party.lastName, criteria);
}

class PermissionsMap extends React.Component {

  constructor(props) {
    super(props)

    this.emailList = React.createRef();
    this.state = { query: '' };
  }

  parties() {
    return this.props.currentObject && this.props.currentObject.parties ? this.props.currentObject.parties.filter(uid => uid !== this.props.user.uid).map(uid => this.props.users.find(row => row.uid === uid)) : [];
  }

  collaborators() {
    const uids = new Set();
    // TODO: implement this server-side; in fact these should be the only users in this.props.users
    if (this.props.investments) this.props.investments.forEach(row => { if (row.parties) row.parties.forEach(party => uids.add(party)); });
    if (this.props.threads) this.props.threads.forEach(row => { if (row.parties) row.parties.forEach(party => uids.add(party)); });
    // But we'd still have to do this investor side
    if (this.props.currentObject && this.props.currentObject.parties) this.props.currentObject.parties.forEach(party => uids.delete(party));

    return [...uids].map(uid => this.props.users.find(row => row.uid === uid));
  }

  advisors() {
    return this.props.users.filter(row => row.userType === 'advisor');
  }

  invited() {
    return this.props.currentObject && this.props.currentObject.invitations ? Object.values(this.props.currentObject.invitations).filter(invitee => invitee) : []
  }

  sendEmailInvites(e) {
    const { id, warningMap, match, user } = this.props;
    const warning = warningMap(match.params.role, 'email');
    for (let email of this.emailList.current.value.split(',')) {
      this.props.sendInvite(warning, id, email, { from: user.email, role: match.params.role });
    }
    this.emailList.current.value = '';
  }

  sendDirectInvite(email, e) {
    console.log("Invite", email);
    const { id, warningMap, match, user } = this.props;
    const warning = warningMap(match.params.role, 'direct');
    this.props.sendInvite(warning, id, email, { from: user.email, role: match.params.role });
  }

  handleSearchChange(e) {
    this.setState({ query: e.target.value });
  }

  render() {
    const {
      classes,
      match
    } = this.props;

    const { role } = match.params;
    const invited = this.invited();

    const invitable = role === 'advisor'
      ? this.advisors()
      : this.collaborators();

    return (
      <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
        <span style={{ color: '#000' }}>
          <b>Who has access:</b>
        </span>
        {this.parties().map((party, index) => (
          <Card key={index} style={{ marginBottom: '10px' }} className={classes.investmentCards}>
            <CardBody>
              <GridContainer>
                <GridItem xs={3}>
                  <img
                    src={party.profileURL || avatar}
                    style={{ borderRadius: '50%', height: '40px' }}
                    alt="..."
                  />
                </GridItem>
                <GridItem xs={8}>
                  <p style={{ fontSize: '18px', marginBottom: '0px' }}>
                    <b>
                      {' '}
                      {' ' +
                        party.firstName +
                        ' ' +
                        party.lastName +
                        ' '}
                    </b>
                  </p>
                  <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                    {party.email}
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ))}
        {invited.length > 0 ? (
          <div>
            <hr style={{ borderTop: '1px solid #ccc' }} />
            <Card style={{ marginBottom: '10px' }} className={classes.investmentCards}>
              <CardBody>
                <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                  <b>Invited:</b>
                </p>
                {invited.map((invitee, index) =>
                  <GridContainer key={index}>
                    <GridItem xs={3} />
                    <GridItem xs={8}>
                      <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                        {invitee.email}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </div>
        ) : (
          <div />
        )}
        {invitable.length > 0 ?
          <div>
            <hr style={{ borderTop: '1px solid #ccc' }} />
            <span style={{ color: '#000' }}>
              <b>Who you can give access:</b>
            </span>
            {invitable.length > 5 &&
              <CustomInput
                formControlProps={{
                  className: classes.top + " " + classes.search,
                  style: { width: "85%", paddingTop: "10px" }
                }}
                id="assignPermissionsQuery"
                inputProps={{
                  placeholder: "Search...",
                  onChange: (event) => this.handleSearchChange(event),
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.searchInput
                  }
                }}
              />
            }
            {invitable.filter(party => filterPartyName(party, this.state.query)).map((party, index) => (
              <Card key={index} style={{ marginBottom: '10px' }} className={classes.investmentCards}>
                <CardBody onClick={e => this.sendDirectInvite(party.email, e)}>
                  <GridContainer>
                    <GridItem xs={3}>
                      <img
                        src={party.profileURL || avatar}
                        style={{ borderRadius: '50%', height: '40px' }}
                        alt="..."
                      />
                    </GridItem>
                    <GridItem xs={8}>
                      <p style={{ fontSize: '18px', marginBottom: '0px' }}>
                        <b>
                          {' '}
                          {' ' +
                            party.firstName +
                            ' ' +
                            party.lastName +
                            ' '}
                        </b>
                      </p>
                      <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                        {party.email}
                      </p>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ))}
          </div>
          :
          <div />
        }
        <hr style={{ borderTop: '1px solid #ccc' }} />
        <Card style={{ marginBottom: '10px' }} className={classes.investmentCards}>
          <CardBody>
            <p style={{ fontSize: '14px', marginBottom: '0px' }}>
              <b>Invite people</b>
            </p>

            <GridContainer>
              <GridItem xs={8}>
                <CustomInput
                  formControlProps={{
                    className: classes.top + ' ' + classes.search,
                    style: { width: '85%' },
                  }}
                  id="invitePeopleQuery"
                  inputProps={{
                    placeholder: 'Enter email address(es)',
                    inputProps: {
                      'aria-label': 'Search',
                      className: classes.searchInput,
                    },
                    inputRef: this.emailList
                  }}
                />
              </GridItem>
              <GridItem xs={3}>x</GridItem>
              <GridItem xs={12}>
                <Button style={{ backgroundColor: '#0db2d8' }} fullWidth onClick={e => this.sendEmailInvites(e)}>
                  SEND INVITES
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>

        <Card style={{ marginBottom: '10px' }} className={classes.investmentCards}>
          <CardBody>
            <p style={{ fontSize: '14px', marginBottom: '0px' }}>
              <b>Shareable Link</b>
            </p>

            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  formControlProps={{
                    className: classes.top + ' ' + classes.search,
                    style: { width: '85%' },
                  }}
                  id="shareableLink"
                  inputProps={{
                    placeholder:
                      'https://wealthenv.com/investmentfile/c/389029348290',
                    onChange: (event) => this.handleChange(event),
                    inputProps: {
                      'aria-label': 'Search',
                      className: classes.searchInput,
                    },
                  }}
                />{' '}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}


PermissionsMap.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentObject: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  threads: PropTypes.array.isRequired,
  investments: PropTypes.array.isRequired,
  sendInvite: PropTypes.func.isRequired,
  role: PropTypes.string,
  warningMap: PropTypes.func.isRequired
};

export default withStyles(Style)(PermissionsMap);
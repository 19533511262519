import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from 'assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx';

// @material-ui/icons
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { camelToSnakeCase } from '../../util';
import Card from 'components/Card/Card.jsx';

const FormSelect = ({
  classes,
  value,
  data: { field, label, options },
  onSelect,
}) => (
  <Card style={{ padding: '0px 20px 10px 20px', margin: '0' }}>
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor={field} className={classes.selectLabel}>
        {label || camelToSnakeCase(field)}
      </InputLabel>
      <Select
        required
        value={value}
        classes={{ select: classes.select }}
        onChange={({ target: { name, value } }) => onSelect(name, value)}
        inputProps={{ id: field, name: field }}
        MenuProps={{ className: classes.selectMenu }}
      >
        {options.map((item) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
            }}
            value={item}
            key={item}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Card>
);

FormSelect.propTypes = {
  data: PropTypes.shape({
    field: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormSelect);

const quizzesPageStyle = (theme) => ({
    container: {
        color: "#000",
    },
    quizResultsContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '55vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    riskToleranceResults67vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '67vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    fiveFactorQuizResults133vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '133vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    fiveFactorQuizResults133vhPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '133vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: "650px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white",
            transform: "scale(0.9,0.9)"
        }
    },
    fiveFactorQuizResults: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '125vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    iframeQuizButton: {
        display: "block",
        paddingBottom: "130px"
    },
    quizSuccess: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '80vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    demographicsConfirmQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '49vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    financialKnowledgeQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '84vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    FinancialKnowledgeConfirmQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
            marginTop: '75px'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    demographicQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '100vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    demographicQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '100vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "900px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    demographicQuizContainerAdjustedShorter: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '80vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    demographicQuizContainerAdjusted: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '113vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    riskQuizContainerSixthQuestion: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '100vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    riskQuizContainerThirteenthQuestion: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '92vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    riskQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '85vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    riskQuizContainerPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '85vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "400px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    fiveFactorQuizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '116vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    fiveFactorQuizContainerPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '116vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: "650px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "10px auto",
            width: "80%",
            backgroundColor: "white",
            transform: "scale(0.9, 0.9)",
        }
    },
    quizContainer: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '90vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizContainerPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '90vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "400px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white",
            transform: "scale(0.8,0.8)"
        }
    },
    quizGlobalResults: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '100vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults130vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '130vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults130vhPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '130vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "700px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white",
            transform: "scale(0.9, 0.9)"
        }
    },
    quizGlobalResults136vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '136vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults134vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '134vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults127vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '127vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults125vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '126vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults125vhPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '126vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: "650px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white",
            transform: "scale(0.9, 0.9)"
        }
    },
    quizGlobalResults120vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '120vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults117vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '117vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults102vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '103vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults111vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '111vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResults113vh: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '113vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResultsFirstquestionFinancialKnowledge: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '95vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResultsSecondQuestion: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '110vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResultsSecondQuestionPublic: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '110vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "900px !important",
            padding: "35px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    quizGlobalResultsFinancialKnowledge: {
        padding: "15px",
        [theme.breakpoints.down('sm')]: {
            height: '85vh !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "1000px !important",
            padding: "55px",
            color: "white",
            border: "1px solid",
            borderRadius: "15px",
            boxShadow: "0px 4px 9px gray",
            margin: "50px auto",
            width: "80%",
            backgroundColor: "white"
        }
    },
    header: {
        fontWeight: 600,
        margin: "15px 0 15px 20px",
        textAlign: "center"
    },
    quizEnvTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '35px 0px'
    },
    startLearning: {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '24px',
        textAlign: 'center',
        letterSpacing: '0.07em',
        display: 'flex',
        justifyContent: 'center'
    },
    quizCard: {
        width: "90%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "15px",
        [theme.breakpoints.up('md')]: {
            boxShadow: "0px 4px 9px gray",
            borderRadius: "10px"
        }
    },
    cardImage: {
        width: "80%"
    },
    cardImagePublic: {
        width: "60%"
    },
    quizHeader: {
        fontWeight: 600
    },
    startQuiz: {
        [theme.breakpoints.up('md')]: {
            display: "flex",
            justifyContent: "flex-end"
        }
    },
    takeQuizButton: {
        color: "#fff",
        backgroundColor: "#4775FB",
        "&:hover": {
            backgroundColor: "#61a59f"
        }
    },
    questionHeader: {
        color: "#000",
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "30px",
            margin: '5px 0px'
        }
    },
    questionHeaderResults: {
        color: "#000",
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "25px",
            margin: '5px 0px'
        }
    },
    questionHeaderCloser: {
        color: "#000",
        fontWeight: 600,
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "30px",
            margin: '5px 0px',
            marginBottom: "5px"
        }
    },
    checkboxContainer: {
        [theme.breakpoints.up('md')]: {
            minHeight: "500px",
            marginBottom: "20px",
            paddingLeft: "120px",
            justifyContent: "center"
        },
    },
    wealthManagementCheckboxContainer: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: "120px"
        },
    },
    questionDescription: {
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "20px",
            lineHeight: "35px",
            // marginBottom: "40px"
            marginBottom: "5px"
        }
    },
    questionDescriptionResults: {
        color: "#000",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "20px",
            lineHeight: "20px",
            // marginBottom: "40px"
            marginBottom: "5px"
        }
    },
    questionCard: {
        padding: "5px"
    },
    checkboxText: {
        color: "#000",
        margin: "-3px"
    },
    backButton: {
        width: "50vw",
        marginTop: "15px",
        margin: "auto",
        display: "block",
        backgroundColor: "#60D5C4",
        color: "#fff",
    },
    nextButton: {
        width: "50vw",
        marginTop: "15px",
        margin: "auto",
        display: "block",
        backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)",
        color: "#fff",
    },
    submitButton: {
        width: "50vw",
        marginTop: "15px",
        margin: "auto",
        display: "block",
        background: "#436CFC",
        color: "#fff",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around"
    },
    successButton: {
        display: "block",
        marginTop: "-100px",
        color: "#000",

    },
    radar: {
        padding: "50px",
        maxWidth: "500px",
        margin: "-75px auto"
    },
    progressBar: {
        margin: "20px 0 0 0"
    },
    trophyIcon: {
        marginRight: "auto",
        marginLeft: "auto",
        height: "50vh",
        display: "block"
    },
    successDescription: {
        marginRight: "auto",
        marginLeft: "auto",
        display: "block",
        textAlign: "center"
    },
    xpContainer: {
        display: "flex",
        justifyContent: "space-around"
    },
    coins: {
        height: "20vh"
    },
    correctFont: {
        color: "#0DD828",
        fontWeight: 600,
        textAlign: "center"
    },
    incorrectFont: {
        color: "#FF0000",
        fontWeight: 600,
        textAlign: "center"
    },
    addOnIcon: {
        border: "1px solid black"
    },
    quizTotalContainer: {
    },
    quizzesContainer: {
        padding: "20px"
    },
    quizTotalGridItem: {
        height: '200px',
        display: 'flex',
        alignItems: 'center'
    },
    graphDiv: {
        background: '#FFFFFF',
        border: '1px solid #274B73',
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        fontSize: "25px",
        width: '135px',
        margin: '0px auto',
        height: '135px',
        borderRadius: '100%',
        flexDirection: "column"
    },
    completedText: {
        fontSize: '19px',
        marginTop: '10px'
    },
    cardForQuiz: {
        background: '#FFFFFF',
        border: '1px solid #72C5C8',
        borderRadius: '32px',
        width: "100%",
        justifyContent: 'center',
        padding: "17px"
    },
    cardForQuizContainer: {
        maxWidth: '100%',
        margin: '0px auto'
    },
    buttonToQuiz: {
        background: '#436CFC',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '15px',
    },
    learnMore: {
        fontWeight: 600,
        fontSize: '14px',
        color: '#72C5C8',
        display: "flex",
        alignItems: "center"
    },
    arrowButton: {
        height: '50px',
        background: '#436CFC',
        width: '50px',
        borderRadius: '100px',
        padding: '10px'
    },
    arrowButtonsDiv: {
        display: 'flex',
        width: "100%",
        justifyContent: "space-between",
        marginTop: "25px"
    },

})

export default quizzesPageStyle;
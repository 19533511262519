import React from "react";

import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import firebase, { db, storage } from "firebase/fbConfig.js";

import defaultImage from "assets/img/wealth-env/cloud_upload.svg";

class DocumentUpload extends React.Component {
    constructor(props) {
        super(props);
        const currentDoc = props.currentDoc || {};
        this.state = {
            file: null,
            imagePreviewUrl: currentDoc.url || defaultImage,
            uploadProgress: ''
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    imageChanged: true
                });
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({
                file: undefined,
                imagePreviewUrl: undefined,
                imageChanged: true
            });
        }
    }

    handleSubmit() {
        const pic = this.state.file
        // create storage ref
        var storageRef = storage.ref(this.props.storageLocation + "/" + pic.name)
        // upload file
        var uploadTask = storageRef.put(pic)
        // update progress bar
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and 
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    this.setState({ uploadProgress: ' Upload is paused' });
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    this.setState({ uploadProgress: 'Upload is running' });
                    break;
            }
        }, (error) => {
            // Handle unsuccessful uploads
            console.log(error)
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.setState({ uploadProgress: 'Uploaded' });
                this.props.setDocument(pic.name, downloadURL);
            });
        })
    }
    render() {
        return (
            <div className="picture-container">
                <label>
                    <img
                        src={this.state.imagePreviewUrl}
                        className="picture-src"
                        alt="..."
                    />
                    <input style={{ display: "none" }} type="file" onChange={e => this.handleImageChange(e)} />
                </label>

                {
                    this.state.file ? (
                        this.state.uploadProgress ? (
                            <p style={{ color: "grey" }}>{this.state.uploadProgress}</p>
                        ) : (
                                <Button
                                    color="primary"
                                    onClick={() => this.handleSubmit(this.props.id, this.props.fileType)}
                                ><b>Update</b></Button>
                            )
                    ) : (
                            <h6 style={{ color: "white" }} className="description">Upload {this.props.fileType}</h6>
                        )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //console.log(state)
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);

import React from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export const ButtonUpload = ({ onClick }) => (
  <div>
    <label
      htmlFor="file-upload"
      style={{
        border: '1px solid #ccc',
        display: 'inline-block',
        padding: '6px 12px',
        cursor: 'pointer',
        color: 'black',
        fontSize: '17px',
      }}
    >
      <CloudUploadIcon style={{color: 'white'}}/>
      <span
        style={{
          top: '-4px',
          position: 'relative',
          marginLeft: '12px',
          color: '#fff',
        }}
      >
        Upload Documents
      </span>
    </label>
    <input
      id="file-upload"
      type="file"
      style={{ display: 'none' }}
      onChange={onClick}
    />
  </div>
);

ButtonUpload.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ButtonUpload;

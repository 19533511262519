import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { NavLink, withRouter } from 'react-router-dom';

import Slide from "@material-ui/core/Slide";

import loader from "assets/img/loader.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Style from "assets/jss/material-dashboard-pro-react/views/matchesPageStyle";

import avatar from "assets/img/default-avatar.png";

import SolicitationDialog from "../Components/SolicitationDialog.jsx";
import { openSolicitationDialog } from "../../store/actions/navigationActions.jsx";
import config from 'config';
import { sampleInvestors, sampleAdvisors } from "variables/sampleUsers.jsx";
import { db } from '../../firebase/fbConfig.js';
import { setEventAttendessAndTitle } from "../../store/actions/calendarActions.jsx";
import Chartist from "chartist";

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class MatchesMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: '',
            active: props.active,
        };
    }

    componentDidCatch() {
        this.fetchUsersMatches()
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    usersData = () => {
        return this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop }
            const volUID = colObj.volUID
            const username = colObj.username
            // Load Data for Summary Cards and add buttons
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={
                            () => this.handleClickOpen("assignActionModal",
                                volUID
                            )}
                    >ASSIGN ACTION</Button>)
            }
        })
    }

    static compareIgnoreCase(a, b) {
        if (a === b) return true;
        if (!a || !b) return false;
        return (a.toLowerCase().includes(b.toLowerCase()));
    }

    fetchUsersMatches = () => {
        if (this.props.user.id) {
            let matchesArray = []
            db.collection("users").doc(this.props.user.id).collection("matches")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        matchesArray.push(doc.data())
                    });
                    this.setState({
                        matches: matchesArray
                    })
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    // loadMatches = (uid) => {
    //     let data = db.collection("users").doc(uid).collection("matches")
    //         .get().then((snapshot) => {
    //             var docData = snapshot.data()
    //             if (docData) {
    //                 var docObj = {
    //                     id: snapshot.id,
    //                     firstName: docData.firstName ? docData.firstName : "N/A",
    //                     lastName: docData.lastName ? docData.lastName : "N/A",
    //                     investmentType: docData.investmentType ? docData.investmentType : "N/A",
    //                     city: docData.city ? docData.city : "N/A",
    //                     state: docData.state ? docData.state : "N/A",
    //                     zip: docData.zip ? docData.zip : "N/A",
    //                     experience: docData.experience ? docData.experience : "N/A",
    //                     profileURL: docData.profileURL ? docData.profileURL : "N/A",
    //                     firmName: docData.firmName ? docData.firmName : "N/A",
    //                     groups: docData.groups ? docData.groups : "N/A",
    //                     phone: docData.phone ? docData.phone : "N/A",
    //                     address: docData.address ? docData.address : "N/A",
    //                     bio: docData.bio ? docData.bio : "N/A",
    //                     email: docData.email ? docData.email : "N/A",
    //                     areasOfInvestment: docData.areasOfInvestment || [],
    //                 }
    //                 return (docObj)
    //             } else {
    //                 return (null)
    //             }
    //         }, (error) => {
    //             console.log('error fetching data: ', error)
    //         }).then(docObj => {
    //             // this.fetchAdvisorWhiteList(docObj.id)
    //             this.setState({
    //                 data: docObj,
    //                 dataLoaded: true
    //             })
    //         })
    // }

    advisorsData = () => {
        const { query, users } = this.props;
        // let allUsers = users.dataRows.filter((user) => {
        //     return user.userType.toLowerCase() == 'advisor'
        // });
        if (this.state.matches) {
            let filteredUsers = this.state.matches.filter((user) => {
                return user.firstName !== "N/A" && user.uid !== this.props.uid
            })
            if (query) {
                let queryFilteredUsers = filteredUsers.filter((user) => {
                    return user.firstName.toLowerCase().includes(query) || user.lastName.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)
                })
                return queryFilteredUsers
            } else {
                return filteredUsers
            }
        }
    }

    handleChange = (docID) => {
        console.log(docID)
    }

    render() {
        const { AuthBool, classes, query, loading, solicitationDialogOpen, openSolicitationDialog, cancelSolicitationDialog, continueSolicitationDialog, direction, history, tab, userType } = this.props;

        const searchButton =
            classes.top +
            " " +
            classes.searchButton;


        if (!this.state.matches) {
            this.fetchUsersMatches()
        }
        // if(investments) {this.filterData(query, investments.dataRows)}
        //console.log(investments.dataRows)

        return (
            <div>
                <SolicitationDialog />
                {
                    !loading
                        ? (
                            <div>
                                {this.props.matchesArray.length != 0 ? (
                                    <div>
                                        {this.props.matchesArray.map(advisorRow => {
                                            return (
                                                <Card className={classes.matchesCard} key={advisorRow.uid}>
                                                    <GridContainer className={classes.favoritedContainer}>
                                                        <GridItem className={classes.favoritedRow} xs={4}>
                                                            <Link to={{
                                                                pathname: `advisor/${advisorRow.id}`,
                                                                state: { dataLoaded: false }
                                                            }}>
                                                                {advisorRow.profileURL == "N/A" ?
                                                                    <img src={avatar} className={classes.favoritedPic} />
                                                                    :
                                                                    <img src={advisorRow.profileURL} className={classes.favoritedPic} />
                                                                }
                                                            </Link>
                                                        </GridItem>
                                                        <GridItem xs={4} className={classes.favoritedRow}>
                                                            <p className={classes.name} style={{ textAlign: "center" }}><b>{advisorRow.firstName} {advisorRow.lastName}</b></p>
                                                        </GridItem>
                                                        <GridItem className={classes.favoritedRow} xs={4}>
                                                            {/* <Link to={{
                                                    pathname: `chat/${advisorRow.uid}`,
                                                    state: { dataLoaded: false }
                                                }}> */}
                                                            <Button onClick={() => {
                                                                this.props.setEventAttendessAndTitle([advisorRow, this.props.user], 'title')
                                                                history.push(`chat/${advisorRow.id}`)
                                                            }}
                                                                className={classes.chatButton}><b>Chat</b></Button>
                                                            {/* </Link> */}
                                                        </GridItem>
                                                    </GridContainer>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                )
                                    : (
                                        <center>
                                            <h3>You do not have any {tab == 'favorited' ? 'favorited' : 'matched'} {userType == 'advisor' ? 'investors' : 'advisors'} yet</h3>
                                        </center>
                                    )
                                }
                            </div>
                        ) : (
                            <div
                                style={{
                                    backgroundColor: '#f0f1f5',
                                    width: '100%',
                                    height: '100%',
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: "center"
                                }}
                            >
                                <img
                                    style={{ width: '20%' }}
                                    src={loader}
                                    alt="..."
                                />
                            </div>
                        )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        user: state.user.user,
        users: state.user.users,
        solicitationDialogOpen: state.navigation.solicitationDialogOpen,
        loading: false,
        uid: state.auth.user.uid,
        userType: state.user.user.userType
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openSolicitationDialog: () => dispatch(openSolicitationDialog(config.solicitationWarningThread)),
        setEventAttendessAndTitle: (attendees, title) => dispatch(setEventAttendessAndTitle(attendees, title))
    }
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(MatchesMap));

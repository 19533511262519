import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { twilioToken } from 'firebase/fbConfig.js';
import { NavLink, withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { db } from '../../firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Loader from 'components/Loader';
import { useHistory } from 'react-router';
import Hidden from '@material-ui/core/Hidden';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/conferencingPageStyle';
import ConferenceCalendar from '../Conferencing/ConferenceCalendar.jsx';
import JoinRoomModal from '../Conferencing/JoinRoomModal.jsx';
import CreateCallModal from '../Conferencing/CreateCallModal.jsx';
import CreateCallButton from '../../components/CreateCallButton/CreateCallButton.jsx';
import { ReactComponent as ChatEnv } from 'assets/img/chatEnv.svg';
import MatchesMessagesMap from '../Matches/MatchesMessagesMap.jsx';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function ConferencingPage(props) {
  const { classes, startSetEventsList, uid, data, addNewEvent, history, user } = props;
  const [newData, setNewData] = useState([]);
  const [groupChatId, setGroupChatId] = useState();
  const [token, setToken] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [username, setUsername] = useState('');
  const [room, setRoom] = useState('');
  const [modal, setModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false)
  const [allMessagesData, setAllMessagesData] = useState([])
  const [joinModal, setJoinModal] = useState(false);
  console.log('allMessagesData', allMessagesData)

  useEffect(() => {
    if (!username && user.firstName !== 'N/A') {
      setUsername(`${user.firstName} ${user.lastName}`);
    }
    if (!messagesLoaded && user.id) {
      const fetchMessages = async () => {
        return db.collection('users').doc(user.id).collection('one-on-one-chats').get().then((snapshot) => {
          console.log('hereee', snapshot)
          if (!snapshot.empty) {
            let data = []
            snapshot.docs.forEach((d) => {
              d = d.data()
              const from = d.messages[0].messageSentFrom
              data.push({ id: d.id, from, firstMessage: d.messages[0].message })
            })
            setAllMessagesData(data)
            setMessagesLoaded(true)
          } else {
            setMessagesLoaded(true)
          }
        })
      }
      fetchMessages()
    }
  }, [user])

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    console.log('look room', room)
    const response = await twilioToken({ identity: username, roomName: room });
    history.push(`/video-conference/?room=${room}`, { state: { data: response.data } })
  }, [username, room]);

  if (!messagesLoaded)
  return <Loader />

  return (
    <div className={classes.conferencePageDiv}>

      <Hidden smDown implementation="css">
        <GridContainer justify="center" className={classes.gridContainer}>
          <GridItem xs={4} sm={6} md={6} lg={3}>
            <Card className={classes.cardContainer} onClick={() => setCreateModal(true)}>
              <div className={classes.iconContainer}>
                <VideoCallIcon className={classes.icon} />
              </div>
              <h6 className={classes.cardTitle}>Create Call</h6>
            </Card>


          </GridItem>
          <GridItem xs={4} sm={6} md={6} lg={3}>
            {/* <Card className={classes.cardContainer} onClick={handleSubmit}> */}
            <Card className={classes.cardContainer} onClick={()=>setModal(true)}>
              <div className={classes.iconContainer}>
                <VideoCallIcon className={classes.icon} />
              </div>
              <h6 className={classes.cardTitle}>Join Room</h6>
            </Card>
            {/* <Card>
              <CustomInput
                id="room"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    placeholder: "Room ID",
                    value: room,
                    onChange: (event) => setRoom(event.target.value)
                }}
              />
            </Card> */}

            <JoinRoomModal
              open={modal}
              transition={Transition}
              username={username}
              closeButton={() => setModal(false)}
            />

          </GridItem>
          <GridItem xs={4} sm={6} md={6} lg={3}>
            <CreateCallButton />

          </GridItem>
          <GridItem xs={4} sm={6} md={6} lg={3}>
            <NavLink to={"/messages"}>
              <Card className={classes.cardContainer}>
                <div className={classes.iconContainer}>
                  <ChatBubbleIcon className={classes.icon} />
                </div>
                <h6 className={classes.cardTitle}>Messages</h6>
              </Card>
            </NavLink>

          </GridItem>
        </GridContainer>
        <CreateCallModal
              open={createModal}
              transition={Transition}
              closeButton={() => setCreateModal(false)}
            />
      </Hidden>



      {/* mobile */}
      <Hidden mdUp>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "70px" }}>
          <ChatEnv />
        </div>

        <GridContainer className={classes.messagesGrid}>
          <GridItem className={classes.titleAndButton} xs={12}>
            <h1 className={classes.recentMessages}> Messages</h1>
            <Button onClick={() => history.push('/messages')} style={{ width: "95px" }} className={classes.buttonToMessage}>
              <span>View All</span>
              <ArrowRightAltIcon />
            </Button>
          </GridItem>
          <GridItem xs={12} className={classes.messagesMap}>
            <MatchesMessagesMap allMessagesData={allMessagesData.slice(0, 3)} messagesLoaded={messagesLoaded} user={user} />
          </GridItem>

        </GridContainer>
        <div>
          <GridContainer justify="center" className={classes.gridContainerrr}>
              <GridItem xs={4}>
                <NavLink to={"/messages"}>
                  <div className={classes.findMoreButton}>Send Message</div>
                </NavLink>
              </GridItem>
            <GridItem xs={4}>
                <CreateCallButton />
                <JoinRoomModal
                  open={modal}
                  transition={Transition}
                  closeButton={() => setModal(false)}
                />
            </GridItem>
            <GridItem xs={4}>
              <div className={classes.findMoreButton} >Schedule Event</div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <ConferenceCalendar />
            </GridItem>
          </GridContainer>
        </div>

      </Hidden>


      <Hidden smDown implementation="css">
        <Card className={classes.containerCard}>
          <h1 className={classes.header}>Conference Scheduler</h1>
          <p className={classes.description}>
            This page will allow you to send a chat message, begin a video conference,
            and schedule an upcoming event
          </p>
          <ConferenceCalendar />
        </Card>
      </Hidden>


    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    user: state.user.user,
    users: state.user.users,
    uid: state.auth.user.uid,
    data: state.events.events.data,
  };
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(ConferencingPage));

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import loader from 'assets/img/loader.gif';
import miniLoading from 'assets/img/simple_loading.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Search from '@material-ui/icons/Search';
import Accordion from 'components/Accordion/Accordion.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/actionsStyle';
import { Grid } from '@material-ui/core';

import ThreadsMap from './ThreadsMap';
// Forms
import CreateMessageForm from '../../../components/Forms/CreateMessageForm';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const Threads = (props) => {
  const { classes } = props;
  //console.log(users)
  const searchButton = classes.top + ' ' + classes.searchButton;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={11}>
          <ThreadsMap />
          {/* <CreateMessageForm /> */}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Threads);

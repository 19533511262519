import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { startLogout } from "../../store/actions/authActions"
// import { Manager, Target, Popper } from "react-popper";
import { NavLink, withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import DefaultProfile from "assets/img/default-avatar.png"
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import { listenToNotificationsForUser, changeNotifcationStatusToOld, deleteSingleNotifcation } from 'store/actions/notificationsActions';



class HeaderLinks extends React.Component {
  state = {
    open: false,
    openNotification: null,
    setOpenNotification: null,
    notificationsReceived: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  donate = () => {
    window.open('https://secure.actblue.com/donate/khalilpeter?utf8=%E2%9C%93&amount=&commit=Contribute', "_blank")
  }

  handleClickNotification = (e) => {
    e.preventDefault()
    if (this.state.openNotification && this.state.openNotification.contains(e.target)) {
      this.setState({ setOpenNotification: null, openNotification: null });
    } else {
      this.setState({ setOpenNotification: e.currentTarget, openNotification: e.currentTarget });
    }
  }

  handleCloseNotification = () => {
    this.setState({ setOpenNotification: null, openNotification: null, closing: true });
  }

  // componentDidMount = () => {
  //   if (!this.state.notificationsReceived && this.props.uid) {
  //     this.props.listenToNotificationsForUser(this.props.uid)
  //     this.setState({
  //       notificationsReceived: true
  //     })
  //   }
  // }


  // handleClickNotification = (e) => {
  //   if (!this.state.openNotification && this.state.buttonNotif && this.state.closeNotif) {
  //     this.setState({ openNotification: false, buttonNotif: false, closeNotif: false })
  //   } else {
  //     if (this.state.buttonNotif) {
  //       if (this.state.closeNotif) {
  //         this.setState({ openNotification: true, closeNotif: false })
  //       } else {
  //         this.setState({ openNotification: false, buttonNotif: false })
  //       }
  //     } else {
  //       this.setState({ openNotification: true, buttonNotif: true })
  //     }
  //   }
  // }

  // handleCloseNotification = (e) => {
  //   this.setState({ openNotification: false, closeNotif: true })
  // }


  render() {
    const {
      AuthBool,
      authUser,
      classes,
      rtlActive,
      startLogout,
      user,
      uid,
      notificationData,
      history
    } = this.props;

    const { open } = this.state;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton +
      " " +
      classNames({
        [classes.searchRTL]: rtlActive
      });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    let profilePhoto = DefaultProfile
    if (AuthBool) {
      (user.user.profileURL) ? (
        profilePhoto = user.user.profileURL
      ) : (
        // add link to profile photo to firestore
        profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
        // add profile photo to firebase storage
        // update redux with link
      )
    }
    //console.log(this.state)

    return (
      <div className={wrapper}>
        <Hidden smDown implementation="css">
          <div className={managerClasses}>
            <Button
              color="transparent"
              justIcon
              aria-label="Notifications"
              aria-owns={this.state.openNotification ? "notification-menu-list" : null}
              aria-haspopup="true"
              onClick={() => history.push('/notifications')}
              // onClick={this.handleClickNotification}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ""
              }}
            >
              <Notifications
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              />
              {/* <span className={classes.notifications}>5</span> */}
              <Hidden mdUp implementation="css">
                <span
                  onClick={this.handleClickNotification}
                  className={classes.linkText}
                >
                  {rtlActive ? "إعلام" : "Notification"}
                </span>
              </Hidden>
            </Button>
            {/* <Popper
              open={Boolean(this.state.openNotification)}
              anchorEl={this.state.openNotification}
              transition
              disablePortal
              placement="bottom"
              className={classNames({
                [classes.popperClose]: !this.state.openNotification,
                [classes.popperResponsive]: true,
                [classes.popperNav]: true
              })}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={() => setTimeout(this.handleCloseNotification)}>
                  <Grow
                    {...TransitionProps}
                    id="notification-menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={this.handleCloseNotification}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "إجلاء أوزار الأسيوي حين بل, كما"
                            : "Mike John responded to your email"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleCloseNotification}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "شعار إعلان الأرضية قد ذلك"
                            : "You have 5 new tasks"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleCloseNotification}
                          className={dropdownItem}
                        >
                          {rtlActive
                            ? "ثمّة الخاصّة و على. مع جيما"
                            : "You're now friend with Andrew"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleCloseNotification}
                          className={dropdownItem}
                        >
                          {rtlActive ? "قد علاقة" : "Another Notification"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleCloseNotification}
                          className={dropdownItem}
                        >
                          {rtlActive ? "قد فاتّبع" : "Another One"}
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Grow>
                </ClickAwayListener>
              )}
            </Popper> */}
            {AuthBool ? (
              <span>
                <Link to="/profile">
                  <img
                    style={{ borderRadius: "4px" }}
                    className={classes.profilePhoto}
                    src={profilePhoto}
                    alt="User Photo"
                  />
                </Link>
                {/*<Button
              onClick={this.donate}
              className={classes.profilePhoto}
              type="button"
              color="danger"
            >DONATE</Button>
            <Button
              onClick={startLogout}
              type="button"
              style={{
                backgroundColor: "#F5AC70"
              }}
            >LOGOUT</Button>*/}
              </span>
            ) : null}
          </div>
        </Hidden>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
  listenToNotificationsForUser: (uid) => dispatch(listenToNotificationsForUser(uid)),
})

const mapStateToProps = (state) => {
  console.log('headerlinkssstate', state.user)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user,
    notificationData: state.notifications.notifications.dataRows,
    uid: state.auth.user.uid,
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(headerLinksStyle)
)(HeaderLinks));
const conferencingPageStyle = (theme) => ({
  conferencePageDiv: {
    padding: "20px !important"
  },
  container: {
    // border: "1px solid black"
    height: "calc(100vh - 170px)"
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',

    },
  },
  containerCard: {
    boxShadow: '0px 4px 9px grey',
    borderRadius: '15px',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '1000px',
      borderRadius: '27px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0px auto'
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    flexDirection: 'column',

    boxShadow: '0px 4px 9px grey',
    borderRadius: '15px',
    padding: '10px',
    height: 'min-content',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 5px #d3d3d3'
    }
    /* [theme.breakpoints.up('md')]: {

      marginLeft: 'auto',
      marginRight: 'auto',
      height: '250px',
    }, */
  },
  iconContainer: {
    width: "min-content",
    height: "auto",
    backgroundColor: "#72E5E4",
    padding: "0.5rem",
    borderRadius: "10px"
  },
  icon: {
    height: "35px",
    width: "35px",
    color: "white"

  },
  cardTitle: {
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "13px",
    }
  },
  header: {
    color: '#000',
    textAlign: 'center',
  },
  description: {
    color: '#000',
    textAlign: 'center',
  },
  messagesButton: {
    width: '50vw',
    marginTop: '15px',
    margin: 'auto',
    display: 'block',
    backgroundColor: '#61a59f',
    color: '#fff',
  },
  videoButton: {
    width: '50vw',
    marginTop: '15px',
    margin: 'auto',
    display: 'block',
    backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)",
    color: '#fff',
  },
  modalClose: {
    width: '500px',
  },
  addNewEventFab: {
    // position: "fixed",
    backgroundColor: 'rgba(146,205,145,1)',
    [theme.breakpoints.up('md')]: {
      float: 'right',
      marginRight: '100px',
    },
  },
  addNewEventIcon: {
    fontSize: 'xxx-large',
    color: 'white',
  },
  addNewEventModal: {
    [theme.breakpoints.up('md')]: {
      minwidth: '900px !important',
    },
  },
  formDiv: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '500px',
      height: '300px',
      justifyContent: 'space-around',
      marginBottom: '60px',
    },
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalTitle: {
    marginRight: "25px",
    fontSize: "12pt",
    color: "#434343"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: "#4b4b4b"
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    // margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    border: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 #ffffff",
    opacity: ".5"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "1.75rem auto"
    },
    borderRadius: "0",
    overflow: "visible",
    // maxHeight: "unset",
    width: "100%",
    marginTop: "130px !important",
    background: "transparent",
    boxShadow: "none"
  },
  createModal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "1.75rem auto"
    },
    borderRadius: "0",
    overflow: "visible",
    // maxHeight: "unset",
    width: "25vw",
    marginTop: "130px !important",
    background: "transparent",
    boxShadow: "none"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  modalButton: {
    backgroundColor: "#315B7B",
    "&:hover": {
      backgroundColor: "#244157"
    }
  },
  modalBody: {
    marginBottom: "0.75rem",
    width: "25vw"
  },
  messagesGrid: {
    padding: "0px 30px 30px 30px"
  },
  titleAndButton: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between"
  },
  recentMessages: {
    fontFamily: "Nova",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.05em',
    color: '#4672FB',
    margin: "0px",
  },
  messagesMap: {
    color: "black !important"
  },
  buttonToMessage: {
    background: '#436CFC',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
  },
  findMoreButton: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '40px',
    display: 'flex',
    justifyContent: "space-evenly",
    margin: "25px auto",
    color: "black !important",
    padding: "5px"
  },
  gridContainerrr: {
    background: "#72E5E4",
    padding: "5px"
  }

});

export default conferencingPageStyle;

// ##############################
// // // Header styles
// #############################

import {
    containerFluid,
    defaultFont,
    primaryColor,
    whiteColor,
    defaultBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    lightColor,
    lightBlueColor
} from 'assets/jss/material-dashboard-pro-react.jsx';

import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx';
import { Z_FIXED } from 'zlib';

const headerStyle = (theme) => ({
    ...customDropdownStyle(theme),
    appBar: {
        background: "linear-gradient(161.29deg, #4672FB -4.43%, #75E8E2 61.61%);",
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: '#FFFFFF',
        border: '0',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
        boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.75)',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            background: 'none',
            boxShadow: 'none',
        },
    },
    container: {
        ...containerFluid,
        minHeight: '50px',
    },
    flex: {
        flex: 1,
    },
    title: {
        ...defaultFont,
        lineHeight: '30px',
        fontSize: '18px',
        borderRadius: '3px',
        textTransform: 'none',
        color: whiteColor,
        paddingTop: '0.625rem',
        paddingBottom: '0.625rem',
        margin: '0 !important',
        '&:hover,&:focus': {
            background: 'transparent',
        },
    },
    primary: {
        backgroundColor: primaryColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    info: {
        backgroundColor: infoColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    success: {
        backgroundColor: successColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    warning: {
        backgroundColor: warningColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    danger: {
        backgroundColor: dangerColor,
        color: '#FFFFFF',
        ...defaultBoxShadow,
    },
    sidebarMinimize: {
        float: 'left',
        padding: '0 0 0 15px',
        display: 'block',
        color: '#FFFFFF',
    },
    sidebarMinimizeRTL: {
        padding: '0 15px 0 0 !important',
    },
    sidebarMiniIcon: {
        width: '20px',
        height: '17px',
        color: '#999',
    },
    search: {
        margin: '0',
        paddingTop: '7px',
        paddingBottom: '7px',
        [theme.breakpoints.down('sm')]: {
            margin: '10px 15px',
            float: 'none !important',
            paddingTop: '1px',
            paddingBottom: '1px',
            padding: '10px 15px',
            width: 'auto',
        },
    },
    searchInput: {
        paddingTop: '2px',
    },
    searchRTL: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '18px !important',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '12px',
        },
    },
    linkText: {
        zIndex: '4',
        ...defaultFont,
        fontSize: '14px',
        margin: '0!important',
        textTransform: 'none',
    },
    buttonLink: {
        [theme.breakpoints.down('sm')]: {
            //display: "flex",
            //margin: "5px 15px 0",
            //width: "auto",
            //height: "auto",
            '& svg': {
                width: '30px',
                height: '24px',
                marginRight: '5px',
                marginLeft: '3px',
            },
            '& .fab,& .fas,& .far,& .fal,& .material-icons': {
                width: '30px',
                fontSize: '24px',
                lineHeight: '30px',
                marginRight: '19px',
                marginLeft: '3px',
            },
        },
    },
    searchButton: {
        [theme.breakpoints.down('sm')]: {
            top: '-50px !important',
            marginRight: '38px',
            float: 'right',
        },
    },
    top: {
        zIndex: '4',
    },
    searchIcon: {
        width: '17px',
        zIndex: '4',
    },
    links: {
        width: '20px',
        height: '20px',
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '30px',
            height: '30px',
            color: 'inherit',
            opacity: '0.8',
            marginRight: '16px',
            marginLeft: '-5px',
        },
    },
    notifications: {
        zIndex: '4',
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '5px',
            right: '5px',
            border: '1px solid #f9f9f9',
            fontSize: '9px',
            background: '#f44336',
            color: '#f9f9f9',
            minWidth: '16px',
            height: '16px',
            borderRadius: '10px',
            textAlign: 'center',
            lineHeight: '14px',
            verticalAlign: 'middle',
            display: 'block',
        },
        [theme.breakpoints.down('sm')]: {
            ...defaultFont,
            position: 'absolute',
            top: '5px',
            right: '5px',
            border: '1px solid #f9f9f9',
            fontSize: '9px',
            background: '#f44336',
            color: '#f9f9f9',
            minWidth: '16px',
            height: '16px',
            borderRadius: '10px',
            textAlign: 'center',
            lineHeight: '14px',
            verticalAlign: 'middle',
            display: 'block',
        },
    },
    wrapperRTL: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '16px',
        },
    },
    buttonLinkRTL: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '-webkit-fill-available',
            margin: '10px 15px 0',
            padding: '10px 15px',
            display: 'block',
            position: 'relative',
        },
    },
    labelRTL: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row-reverse',
            justifyContent: 'initial',
            display: 'flex',
        },
    },
    linksRTL: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '-5px !important',
            marginLeft: '16px !important',
        },
    },
    managerClasses: {
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
        },
    },
    headerLinksSvg: {
        width: '20px !important',
        height: '20px !important',
        color: '#F9F9F9',
    },
    dropdownItem: {
        color: '#000',
        padding: '8px',
    },
    dropdown: {
        padding: '10px',
    },
    modalHeader: {
        backgroundColor: '#fd6047',
        color: whiteColor,
        fontSize: '.6rem',
        padding: '8px 6px 8px',
    },
    modalTitle: {
        margin: '0',
        textAlign: 'center',
    },
    modalBody: {
        fontSize: '.7rem',
        lineHeight: '.9rem',
    },
});

export default headerStyle;

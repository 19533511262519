import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";


// @material-ui/icons
import Close from "@material-ui/icons/Close";
import SendIcon from '@material-ui/icons/Send';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// import LockOutline from "@material-ui/icons/LockOutline";

// Core Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import MultiFileUploadDialog from 'components/CustomUpload/MultiFileUploadDialog';

import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestorThreadsMessageFormStyle.jsx";

import { startAddMessage, startModifyThread } from 'store/actions/threadsActions';
import { openFileUploadDialog } from 'store/actions/navigationActions'

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}


class Form extends React.Component {

    state = {
        sending: false,
        messageError: false,
        cardAnimaton: "cardHidden"
    }

    constructor(props) {
        super(props);
        this.messageRef = React.createRef();
    }

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { uid, currentThread } = this.props;
        console.log("handleSubmit", this.messageRef.current);
        this.setState({ ...this.state, sending: true });
        this.props.startAddMessage(currentThread.tid, { uid, message: this.messageRef.current.value }).then(
            () => { this.setState(state => ({ ...state, sending: false })); this.messageRef.current.value = '' },
            (messageError) => this.setState(state => ({ ...state, messageError, sending: false }))
        );
    }

    render() {
        const { classes, currentThread, openUploadDialog, startModifyThread } = this.props;

        return (
            <div sm={12} md={12}>
                <Button
                    color="info"
                    onClick={() => openUploadDialog()}
                    style={{
                        marginTop: "20px",
                        marginLeft: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <AddCircleIcon></AddCircleIcon>
                </Button>

                <div className={classes.formContainer} justify="center"
                    style={{

                    }}>


                    <form onSubmit={this.handleSubmit}
                    >
                        <GridContainer style={{
                            marginLeft: "10px"
                        }}>
                            <GridItem xs={8}>
                                {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                                    <CustomInput
                                        labelText="Type Your Message"
                                        id="message"
                                        inputRef={this.messageRef}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            required: true,
                                            type: "text",
                                            defaultValue: this.state.message,
                                            multiline: false,
                                            rows: 1
                                        }}
                                    />
                            </GridItem>

                            <GridItem xs={3}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "10px"
                                    }}>
                                    <SendIcon></SendIcon>
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>


                    {/* MODAL */}
                    <MultiFileUploadDialog
                        storageLocation={`thread_${currentThread.tid}`}
                        documents={currentThread.documents}
                        onUpdate={documents => startModifyThread(currentThread.tid, { documents })}
                    />
                </div>


            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        uid: state.auth.user.uid,
        currentThread: state.threads.currentThread
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startAddMessage: (id, message) => dispatch(startAddMessage(id, message)),
        startModifyThread: (id, data) => dispatch(startModifyThread(id, data)),
        openUploadDialog: () => dispatch(openFileUploadDialog())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Form);
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import moment from 'moment'
import { Link } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";
import CardAvatar from 'components/Card/CardAvatar.jsx';
import Radar from 'react-d3-radar';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/profileStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

import { sampleInvestors, sampleAdvisors } from "variables/sampleUsers.jsx";

import DefaultProfile from 'assets/img/default-avatar.png';

import DeclineLogo from 'assets/img/wealth-env/decline-logo.png'
import AcceptLogo from 'assets/img/wealth-env/accept-logo.png'

import { addMatchNotifications } from '../../store/actions/notificationsActions.jsx'


const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: '',
            refresh: false,
            messageSize: null,
            about: true,
            advisorMatch: false,
            addedToWhiteList: false,
            addedToBlackList: false
            //query: db.collection("TEST-users").doc(this.props.uid).collection("threads").doc(this.props.id).collection("messages")
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    //   loadData = (uid, id) => {
    //     let data = db.collection("TEST-users").doc(uid).collection("investments").doc(id)
    //     .get().then((snapshot) => {
    //         const data = []
    //         // Parse data into array like in firebase.js
    //         snapshot.forEach((doc) => {
    //           var docData = doc.data()
    //           console.log(docData)
    //           var docObj = {
    //             id: doc.id,
    //             message: docData.message ? docData.message : "N/A",
    //             uid: docData.uid ? docData.uid : "N/A",
    //             createdTime: docData.createdTime ? docData.createdTime : "N/A"
    //           }
    //           data.push(docObj);
    //         })
    //         return (data)
    //       }, (error) => {
    //         console.log('error fetching data: ', error)
    //       }).then(data => {
    //         this.setState({
    //           data: data,
    //           dataLoaded: true,
    //           refresh: false
    //         })
    //       })

    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
    //   }

    // loadData = (uid, id) => {
    //     let investmentsData = db.collection("TEST-users").doc(uid).collection("investments").doc(id).get().then(doc => {
    //         if (!doc.exists) {
    //           console.log('No such document!');
    //         } else {
    //           console.log('Document data:', doc.data());
    //           return(doc.data())
    //         }
    //       })
    //       .catch(err => {
    //         console.log('Error getting document', err);
    //       });



    //     console.log(investmentsData)

    //     this.setState({
    //         investmentsData,
    //         dataLoaded: true
    //     })
    // }
    //     loadData = (uid, id) => {
    //         db.collection("TEST-users").doc(uid).collection("investments").doc(id)
    //             .get().then((snapshot) => {
    //              let data = snapshot.data()
    //           }, (error) => {
    //             console.log('error fetching data: ', error)
    //           }).then(data => {
    //               console.log(data)
    //             this.setState({
    //               data: data,
    //               dataLoaded: true,
    //               refresh: false
    //             })
    //           })
    //    }
    loadData = (uid, id) => {
        let data = db.collection("users").doc(id)
            .get().then((snapshot) => {
                var docData = snapshot.data()
                console.log('docDataaaa', docData)
                if (docData) {
                    var docObj = {
                        id: snapshot.id,
                        firstName: docData.firstName ? docData.firstName : "N/A",
                        lastName: docData.lastName ? docData.lastName : "N/A",
                        investmentType: docData.investmentType ? docData.investmentType : "N/A",
                        city: docData.city ? docData.city : "N/A",
                        state: docData.state ? docData.state : "N/A",
                        zip: docData.zip ? docData.zip : "N/A",
                        experience: docData.experience ? docData.experience : "N/A",
                        profileURL: docData.profileURL ? docData.profileURL : "N/A",
                        firmName: docData.firmName ? docData.firmName : "N/A",
                        groups: docData.groups ? docData.groups : "N/A",
                        phone: docData.phone ? docData.phone : "N/A",
                        address: docData.address ? docData.address : "N/A",
                        bio: docData.bio ? docData.bio : "N/A",
                        email: docData.email ? docData.email : "N/A",
                        areasOfInvestment: docData.areasOfInvestment || [],
                        receiveEventNotificaitonEmails: docData.receiveEventNotificaitonEmails,
                        receiveMessageNotificationEmails: docData.receiveMessageNotificationEmails
                    }
                    return (docObj)
                } else {
                    return (null)
                }
            }, (error) => {
                console.log('error fetching data: ', error)
            }).then(docObj => {
                // this.fetchAdvisorWhiteList(docObj.id)
                this.setState({
                    data: docObj,
                    dataLoaded: true
                })
            })
        //console.log(this.state)
        //let relatedConceptsData = this.props.relatedConcepts.dataRows

        // this.setState({
        //   relatedConceptsData,
        //   dataLoaded: true
        // })
    }

    fetchAdvisorWhiteList = async (id) => {
        const data = await db.collection("users").doc(id).collection("whiteList").get()
        const whiteListArray = data.docs.map(doc => doc.data())
        this.setState({
            advisorWhiteList: whiteListArray
        })
    }

    fetchAdvisorFiveFactor = async (id) => {
        const quizRef = db.collection("users").doc(id).collection("quizzes").doc("fiveFactorQuiz")
        const doc = await quizRef.get()
        if (doc.exists) {
            const quizScores = doc.data()
            this.setState({
                agreeablenessScore: quizScores.agreeablenessScore,
                conscientiousnessScore: quizScores.conscientiousnessScore,
                emotionalStabilityScore: quizScores.emotionalStabilityScore,
                extraversionScore: quizScores.extraversionScore,
                opennessExperiencesScore: quizScores.opennessExperiencesScore,
            })
        }
    }



    compareWhiteList = async () => {
        if (this.state.advisorWhiteList) {
            const data = await db.collection("users").doc(this.props.user.id).collection("whiteList").get()
            const userWhiteList = data.docs.map(doc => doc.data())
            const advisorWhiteList = this.state.advisorWhiteList
            const userWhiteIdArray = userWhiteList.map(user => user.id)
            const advisorWhiteIdArray = advisorWhiteList.map(user => user.id)
            let isAMatch;
            console.log('userIduserIduserIduserId', advisorWhiteIdArray.includes(this.props.user.id))
            let matchCheck = advisorWhiteIdArray.includes(this.props.user.id)
            if (matchCheck == true) {
                this.setState({
                    advisorMatch: true
                })
                this.removeFromUserWhiteList()
                this.removeFromAdvisorWhiteList()
                this.addToUserMatches()
                this.addToAdvisorMatches()
                this.props.addMatchNotifications(this.props.user, this.state.data)
            } else {
                this.addToWhiteList()
            }
        }
        else {
            this.addToWhiteList()
        }
    }

    // async getMarker() {
    //     const snapshot = await firebase.firestore().collection('events').get()
    //     return snapshot.docs.map(doc => doc.data());
    // }

    addToBlackList = () => {
        db.collection('users').doc(this.props.user.id).collection('blackList').doc(this.state.data.id).set({
            bio: this.state.data.bio,
            city: this.state.data.city,
            email: this.state.data.email,
            experience: this.state.data.experience,
            firmName: this.state.data.firmName,
            firstName: this.state.data.firstName,
            groups: this.state.data.groups,
            id: this.state.data.id,
            investmentType: this.state.data.investmentType,
            lastName: this.state.data.lastName,
            phone: this.state.data.phone,
            profileURL: this.state.data.profileURL,
            state: this.state.data.state,
            zip: this.state.data.zip,
            receiveMessageNotificationEmails: this.state.data.receiveMessageNotificationEmails,
            receiveEventNotificaitonEmails: this.state.data.receiveEventNotificaitonEmails
        }, { merge: true }).then(() => {
            this.setState({
                addedToBlackList: true
            })
            // window.alert(`You declined ${this.state.data.firstName}, info saved in blackList collection`)
        }).catch((error) => {
            console.error(`Error adding id ${this.props.user.id} to blackList`, error);
        })
    }

    addToWhiteList = () => {
        db.collection('users').doc(this.props.user.id).collection('whiteList').doc(this.state.data.id).set({
            bio: this.state.data.bio,
            city: this.state.data.city,
            email: this.state.data.email,
            experience: this.state.data.experience,
            firmName: this.state.data.firmName,
            firstName: this.state.data.firstName,
            groups: this.state.data.groups,
            id: this.state.data.id,
            investmentType: this.state.data.investmentType,
            lastName: this.state.data.lastName,
            phone: this.state.data.phone,
            profileURL: this.state.data.profileURL,
            state: this.state.data.state,
            zip: this.state.data.zip,
            receiveMessageNotificationEmails: this.state.data.receiveMessageNotificationEmails,
            receiveEventNotificaitonEmails: this.state.data.receiveEventNotificaitonEmails
        }, { merge: true }).then(() => {
            this.setState({
                addedToWhiteList: true
            })
            // Save cache of the entire data set to reference later on the MatchesPage
            // window.alert(`You added ${this.state.data.firstName} to the whiteList collection`)
        })
            .catch((error) => {
                console.error(`Error adding id ${this.props.user.id} to whiteList`, error);
            })
    }

    addToUserMatches = () => {
        db.collection('users').doc(this.props.user.id).collection('matches').doc(this.state.data.id).set({
            bio: this.state.data.bio,
            city: this.state.data.city,
            email: this.state.data.email,
            experience: this.state.data.experience,
            firmName: this.state.data.firmName,
            firstName: this.state.data.firstName,
            groups: this.state.data.groups,
            id: this.state.data.id,
            investmentType: this.state.data.investmentType,
            lastName: this.state.data.lastName,
            phone: this.state.data.phone,
            profileURL: this.state.data.profileURL,
            state: this.state.data.state,
            zip: this.state.data.zip,
            receiveMessageNotificationEmails: this.state.data.receiveMessageNotificationEmails,
            receiveEventNotificaitonEmails: this.state.data.receiveEventNotificaitonEmails
        }, { merge: true }).then(() => {
            // window.alert(`You added ${this.state.data.firstName} to the matches collection`)
        }).catch((error) => {
            console.error(`Error adding id ${this.props.user.id} to matches`, error);
        })
    }

    addToAdvisorMatches = () => {
        console.log('user id, advisor id', this.state.data.id)
        console.log('user id, user id', this.props.user.id)
        db.collection('users').doc(this.state.data.id).collection('matches').doc(this.props.user.id).set({
            bio: this.props.user.bio ? this.props.user.bio : "N/A",
            city: this.props.user.city ? this.props.user.city : "N/A",
            email: this.props.user.email ? this.props.user.email : "N/A",
            experience: this.props.user.experience ? this.props.user.experience : "N/A",
            firmName: this.props.user.firmName ? this.props.user.firmName : "N/A",
            firstName: this.props.user.firstName ? this.props.user.firstName : "N/A",
            groups: this.props.user.groups ? this.props.user.groups : "N/A",
            id: this.props.user.id ? this.props.user.id : "N/A",
            investmentType: this.props.user.investmentType ? this.props.user.investmentType : "N/A",
            lastName: this.props.user.lastName ? this.props.user.lastName : "N/A",
            phone: this.props.user.phone ? this.props.user.phone : "N/A",
            profileURL: this.props.user.profileURL ? this.props.user.profileURL : "N/A",
            state: this.props.user.state ? this.props.user.state : "N/A",
            zip: this.props.user.zip ? this.props.user.zip : "N/A"
        }, { merge: true }).then(() => {
            // window.alert(`You added ${this.state.data.firstName} to the matches collection`)
        }).catch((error) => {
            console.error(`Error adding id ${this.props.user.id} to matches`, error);
        })
    }

    removeFromUserWhiteList = () => {
        db.collection('users').doc(this.props.user.id).collection('whiteList').doc(this.state.data.id).delete()
            .catch((error) => {
                console.error("Error removing document: ", error);
            })
    }

    removeFromAdvisorWhiteList = () => {
        db.collection('users').doc(this.state.data.id).collection('whiteList').doc(this.props.user.id).delete()
            .catch((error) => {
                console.error("Error removing document: ", error);
            })
    }

    refresh = () => {
        this.setState({
            refresh: true
        })
    }

    changeMessageSize = (size) => {
        this.setState({
            messageSize: size
        })
    }

    handleChange = (docID) => {
        console.log(docID)
    }
    componentWillMount() {
    }

    handlePractice = () => {
        this.setState({
            practice: true,
            about: false
        })
    }
    handleAbout = () => {
        this.setState({
            practice: false,
            about: true
        })
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    };

    render() {
        const { AuthBool, classes, currentInvestment, messages, currentThread, uid, id } = this.props;
        const sortedAttributes = [
            this.state.extraversionScore,
            this.state.agreeablenessScore,
            this.state.conscientiousnessScore,
            this.state.emotionalStabilityScore,
            this.state.opennessExperiencesScore
        ].sort((a, b) => b - a)
        const searchButton =
            classes.top +
            " " +
            classes.searchButton;

        if (AuthBool) {
            if (!this.state.dataLoaded) {
                this.loadData(uid, id)
                this.fetchAdvisorWhiteList(id)
                this.fetchAdvisorFiveFactor(id)
            }
        }

        if (this.state.refresh) {
            this.loadData(uid, id)
            this.fetchAdvisorWhiteList(id)
        }

        if (AuthBool) {
            if (this.state.data) {
                if (this.state.data.id !== id) {
                    this.loadData(uid, id)
                    this.fetchAdvisorWhiteList(id)
                }
            }
        }


        // let observer = this.state.query.onSnapshot(querySnapshot => {
        //   console.log(`Received query snapshot of size ${querySnapshot.size}`);
        //   if(this.state.messageSize === null) {
        //   this.changeMessageSize(querySnapshot.size)
        //   } else if (this.state.messageSize !== querySnapshot.size) {
        //     this.changeMessageSize(querySnapshot.size)
        //     this.loadData(uid, id)
        //   }
        //   // ...
        // }, err => {
        //   console.log(`Encountered error: ${err}`);
        // });


        // let profile;

        // if (this.props.users.dataRows !== undefined) {
        //   //let index = props.users.dataRows.indexOf(id);
        //   let obj = this.props.users.dataRows.find(o => o.volUID === id);
        //   console.log(this.props.users.dataRows)
        //   console.log(obj)
        //   profile =  obj.profileURL
        // }

        // console.log(profile)

        // let profilePhoto = DefaultProfile
        // if (AuthBool) {
        //   (user.dataLoaded && user.profileURL) ? (
        //     profilePhoto = user.profileURL
        //   ) : (
        //       // add link to profile photo to firestore
        //       profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
        //       // add profile photo to firebase storage
        //       // update redux with link
        //     )
        // }

        // console.log('state', this.state)
        // console.log('props', this.props)
        return (
            <div>
                {
                    this.state.data ? (
                        <div>
                            {console.log('current state', this.state)}
                            <GridContainer className={classes.detailsMapGridContainer}>
                                <GridItem xs={12}>
                                    <Card profile style={{ marginTop: '30px', paddingBottom: '20px' }}>
                                        <CardAvatar>
                                            {this.state.data.profileURL != "N/A" ?
                                                <img
                                                    className={classes.userPic}
                                                    src={this.state.data.profileURL}
                                                    alt="User Photo"
                                                />
                                                :
                                                <img
                                                    className={classes.userPic}
                                                    src={DefaultProfile}
                                                    alt="User Photo"
                                                />
                                            }
                                        </CardAvatar>
                                        <h3 className={classes.body}>
                                            {this.state.data.firstName} {this.state.data.lastName || null}
                                        </h3>
                                        <GridContainer>
                                            {this.state.data.city == "N/A" ?
                                                <GridItem xs={12}>
                                                    <p>This user has not added their location yet...</p>
                                                </GridItem>
                                                :
                                                <GridItem xs={12}>
                                                    <p>{this.state.data.city}, {this.state.data.state}</p>
                                                </GridItem>
                                            }
                                        </GridContainer>
                                        <GridContainer>
                                            {this.state.data.bio == "N/A" ?
                                                <GridItem xs={12}>
                                                    <p>This user has not added their financial bio yet...</p>
                                                </GridItem>
                                                :
                                                <GridItem xs={12}>
                                                    <p>{this.state.data.bio}</p>
                                                </GridItem>
                                            }
                                            {this.state.extraversionScore ?
                                                <Card className={classes.detailsMapCard}>
                                                    <CardHeader style={{ margin: "auto" }}>
                                                        <h4 className={classes.cardTitle}>Personality Inventory (TIPI)</h4>
                                                    </CardHeader>
                                                    <CardBody className={classes.detailsMapCard}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={6}>
                                                                <Radar
                                                                    width={500}
                                                                    height={500}
                                                                    padding={70}
                                                                    domainMax={10}
                                                                    highlighted={null}
                                                                    onHover={(point) => {
                                                                        if (point) {
                                                                            //console.log('hovered over a data point');
                                                                        } else {
                                                                            //console.log('not over anything');
                                                                        }
                                                                    }}
                                                                    data={{
                                                                        variables: [
                                                                            { key: 'extraversion', label: 'Extraversion' },
                                                                            { key: 'agreeableness', label: 'Agreeableness' },
                                                                            { key: 'conscientiousness', label: 'Conscientiousness' },
                                                                            { key: 'emotionalStability', label: 'Emotional Stability' },
                                                                            { key: 'opennessToExperiences', label: 'Openness to Experiences' },
                                                                        ],
                                                                        sets: [
                                                                            {
                                                                                key: 'everyone',
                                                                                label: 'Everyone',
                                                                                values: {
                                                                                    extraversion: this.state.extraversionScore,
                                                                                    agreeableness: this.state.agreeablenessScore,
                                                                                    conscientiousness: this.state.conscientiousnessScore,
                                                                                    emotionalStability: this.state.emotionalStabilityScore,
                                                                                    opennessToExperiences: this.state.opennessExperiencesScore
                                                                                },
                                                                            },
                                                                        ],
                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={6}>
                                                                <Table
                                                                    desktopProfileTable={true}
                                                                    style={{ height: "100% !important" }}
                                                                    tableHeaderColor="primary"
                                                                    tableHead={["Category", "Score"]}
                                                                    tableData={[
                                                                        ["Openness", this.state.opennessExperiencesScore],
                                                                        ["Conscientiousness", this.state.conscientiousnessScore],
                                                                        ["Extraversion", this.state.extraversionScore],
                                                                        ["Agreeableness", this.state.agreeablenessScore],
                                                                        ["Emotional Stability", this.state.emotionalStabilityScore],
                                                                    ]}
                                                                    coloredColls={[3]}
                                                                    colorsColls={["primary"]}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>

                                                        {/* Top Attribute Description */}
                                                        {sortedAttributes[0] == this.state.extraversionScore ?
                                                            (<div>
                                                                <p className={classes.attributeDescriptionDetail}>
                                                                    Individuals who score high in extroversion are characterized by their sociability and
                                                                    talkativeness. They tend to be outgoing and gain energy in social situations. When speaking with
                                                                    others, they are often optimistic, cheerful, and enthusiastic about whatever topic they are
                                                                    speaking about. Due to this charismatic nature, they feel comfortable talking to anyone -
                                                                    including new people! Their emotional expressiveness means you can easily interpret how an
                                                                    extrovert is feeling by their facial expressions, words, or body language.
                                                                    Extroverts thrive on being involved in discussion and activities with others! However, they tend
                                                                    to get lost in the details. When they are speaking with others they tend to 'think to speak',
                                                                    meaning they talk through their ideas and thoughts.
                                                                </p>
                                                            </div>) : (null)
                                                        }
                                                        {sortedAttributes[0] == this.state.emotionalStabilityScore ?
                                                            (<div>
                                                                <p className={classes.attributeDescriptionDetail}>
                                                                    People who score high in emotional stability can effectively manage negative
                                                                    feelings. They are unlikely to be overtaken by fear and react impulsively to adverse
                                                                    situations. Their ability to calmly react to a crisis or emergency in their job, personal
                                                                    or financial life leads to greater financial stability and happiness.
                                                                </p>
                                                            </div>) : (null)
                                                        }
                                                        {sortedAttributes[0] == this.state.agreeablenessScore ?
                                                            (<div>
                                                                <p className={classes.attributeDescriptionDetail}>
                                                                    Those who are high in agreeableness tend to be focused on social harmony. They find it
                                                                    important to get along with other people and put aside their interests for the greater goal of the
                                                                    people - what we can refer to as 'we'-focused as opposed to 'me'-focused. These individuals
                                                                    exhibit many prosocial behaviors including being cooperative, kind, and friendly. They try their
                                                                    best to help those in need due to their empathetic nature, and try to understand the emotions of
                                                                    others. They are always listening to the opinions of those around them and looking for the
                                                                    common good in others.
                                                                </p>
                                                            </div>) : (null)
                                                        }
                                                        {sortedAttributes[0] == this.state.conscientiousnessScore ?
                                                            (<div>
                                                                <p className={classes.attributeDescriptionDetail}>
                                                                    People who are high in conscientiousness are highly organized individuals. They are focused on
                                                                    and enjoy the details of whatever they are working on or talking through. Their focus is typically
                                                                    one item at a time, and they like to dive into that item fully and exhaust it before moving on to
                                                                    the next topic. Due to this nature, they are highly structured individuals who enjoy following a
                                                                    schedule that aligns with their goals. This also aligns with other personal traits such as their high
                                                                    levels of thoughtfulness for others and strong impulse control.
                                                                    Their focus tends to be on logical and rational thinking, and they desire information to be clear
                                                                    and precise. Due to this focus on detail, they always plan ahead and are mindful of deadlines.
                                                                </p>
                                                            </div>) : (null)
                                                        }
                                                        {sortedAttributes[0] == this.state.opennessExperiencesScore ?
                                                            (<div>
                                                                <p className={classes.attributeDescriptionDetail}>
                                                                    Individuals who score high in openness to experience are those who are imaginative, insightful,
                                                                    and willing to take risks. They have a broad range of interests and are always trying to expand
                                                                    these even more by trying out new experiences and learning new things.
                                                                    Due to their adventurous nature, they tend to be extremely creative, self-reliant, and quick
                                                                    thinking! However, they tend to dislike monotony and can get bored on the same topic quickly,
                                                                    so they are always looking for ways to approach a task in a new way.
                                                                </p>
                                                            </div>) : (null)
                                                        }
                                                    </CardBody>
                                                </Card>
                                                :
                                                <GridItem xs={12}>
                                                    <p>This user has not taken the Five Factor Personality Test yet, when they do scores will be displayed here...</p>
                                                </GridItem>
                                            }
                                        </GridContainer>
                                        <div className={classes.spaceAround}>
                                            {/* <Link to={'/explore'}> */}
                                            <Button className={classes.declineButton} onClick={this.addToBlackList}>
                                                <img src={DeclineLogo} />
                                            </Button>
                                            {/* </Link><Link to={'/explore'}> */}
                                            <Button className={classes.blueButton}
                                                onClick={this.compareWhiteList}
                                            >
                                                <img src={AcceptLogo} />
                                            </Button>
                                            {/* </Link> */}
                                        </div>
                                        {/* It's a Match! Modal */}
                                        <Dialog
                                            open={this.state.advisorMatch}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => this.handleClose("advisorMatch")}
                                            aria-labelledby="shipment-modal-slide-title"
                                            aria-describedby="shipment-modal-slide-description"
                                        >
                                            <div className={classes.matchModal}>
                                                <DialogContent
                                                    id="shipment-modal-slide-description"
                                                    className={classes.modalBody}
                                                >
                                                    <h1><b>It's a Match!</b></h1>
                                                    <h4>You and {this.state.data.firstName} would work well together.</h4>
                                                    <div className={classes.spaceBetween}>
                                                        <img src={this.props.user.profileURL || DefaultProfile} className={classes.matchImage} />
                                                        {this.state.data.profileURL != "N/A" ?
                                                            <img
                                                                className={classes.matchImage}
                                                                src={this.state.data.profileURL}
                                                                alt="User Photo"
                                                            />
                                                            :
                                                            <img
                                                                className={classes.matchImage}
                                                                src={DefaultProfile}
                                                                alt="User Photo"
                                                            />
                                                        }
                                                    </div>
                                                    <Link to={`/chat/${this.state.data.id}}`}>
                                                        <Button className={classes.sendMessageButton}>SEND A MESSAGE</Button>
                                                    </Link>
                                                    <Link to={'/explore'}>
                                                        <Button className={classes.backButton}>GO BACK</Button>
                                                    </Link>
                                                </DialogContent>
                                            </div>
                                        </Dialog>
                                        {/* White list confirmation Modal */}
                                        <Dialog
                                            open={this.state.addedToWhiteList}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => this.handleClose("addedToWhiteList")}
                                            aria-labelledby="shipment-modal-slide-title"
                                            aria-describedby="shipment-modal-slide-description"
                                        >
                                            <div className={classes.matchModal}>
                                                <DialogContent
                                                    id="shipment-modal-slide-description"
                                                    className={classes.modalBody}
                                                >
                                                    <h1><b>We recorded that you would like to work with {this.state.data.firstName}!</b></h1>
                                                    <Link to={'/explore'}>
                                                        <Button className={classes.backButton}>Explore More Options</Button>
                                                    </Link>
                                                </DialogContent>
                                            </div>
                                        </Dialog>
                                        {/* BlackList confirmation Modal */}
                                        <Dialog
                                            open={this.state.addedToBlackList}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={() => this.handleClose("addedToBlackList")}
                                            aria-labelledby="shipment-modal-slide-title"
                                            aria-describedby="shipment-modal-slide-description"
                                        >
                                            <div className={classes.matchModal}>
                                                <DialogContent
                                                    id="shipment-modal-slide-description"
                                                    className={classes.modalBody}
                                                >
                                                    <h1><b>We recorded you would not like to work with {this.state.data.firstName}</b></h1>
                                                    <Link to={'/explore'}>
                                                        <Button className={classes.backButton}>Explore More Options</Button>
                                                    </Link>
                                                </DialogContent>
                                            </div>
                                        </Dialog>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#a8a8a8", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        users: state.user.users,
        // users: sampleAdvisors,
        threads: state.threads.threads,
        messages: state.messages.messages,
        currentAdvisor: state.advisors.currentAdvisor,
        user: state.user.user
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addMatchNotifications: (user, match) => dispatch(addMatchNotifications(user, match))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Users);

export const investmentStatus = Object.freeze({
    NEW: 'new',
    PENDING: 'pending',
    ASSIGNED: 'assigned',
    UNASSIGNED: 'unassigned',
    ARCHIVED: 'archived'
});

export const notificationStatus = Object.freeze({
    NEW: 'new',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected'
});

export const investmentFlow = Object.freeze({
    ADVISOR_CREATED: 'advisorCreated',
    INVESTOR_CREATED: 'investorCreated'
});

export default {
    states: [
        'Alabama (AL)',
        'Alaska (AK)',
        'Arizona (AZ)',
        'Arkansas (AR)',
        'California (CA)',
        'Colorado (CO)',
        'Connecticut (CT)',
        'Delaware (DE)',
        'District Of Columbia (DC)',
        'Florida (FL)',
        'Georgia (GA)',
        'Hawaii (HI)',
        'Idaho (ID)',
        'Illinois (IL)',
        'Indiana (IN)',
        'Iowa (IA)',
        'Kansas (KS)',
        'Kentucky (KY)',
        'Louisiana (LA)',
        'Maine (ME)',
        'Maryland (MD)',
        'Massachusetts (MA)',
        'Michigan (MI)',
        'Minnesota (MN)',
        'Mississippi (MS)',
        'Missouri (MO)',
        'Montana (MT)',
        'Nebraska (NE)',
        'Nevada (NV)',
        'New Hampshire (NH)',
        'New Jersey (NJ)',
        'New Mexico (NM)',
        'New York (NY)',
        'North Carolina (NC)',
        'North Dakota (ND)',
        'Ohio (OH)',
        'Oklahoma (OK)',
        'Oregon (OR)',
        'Pennsylvania (PA)',
        'Rhode Island (RI)',
        'South Carolina (SC)',
        'South Dakota (SD)',
        'Tennessee (TN)',
        'Texas (TX)',
        'Utah (UT)',
        'Vermont (VT)',
        'Virginia (VA)',
        'Washington (WA)',
        'West Virginia (WV)',
        'Wisconsin (WI)',
        'Wyoming (WY)',
    ],

    serviceTypes: [
        'Investment Advice',
        'Funds Management',
        'Financial Strategy',
        'Long Term Investments',
        'High Risk Investments'
    ],

    userTypes: ['advisor', 'investor', 'existing investor'],

    areasOfInvestment: ['Cryptocurrency', 'Foreign Exchange', 'Stock Market', 'Options', 'Futures Contracts', 'Shorting', 'Commodities', 'Equities', 'Fixed Income', 'Cash Management', 'Asset Allocation', 'Real Estate', 'Private Equity', 'Hedge Funds', 'FX', 'Art', 'Insurance', 'Annuities', 'Commodities', 'Retirement', 'Education Savings'],

    solicitationWarningThread: 'Initiating chat with a advisor may be considered a solicitation of professional services which could ultimately become billable.',

    solicitationWarningInvestment: {
        advisor: 'You are about to invite a advisor to an investment. This may be considered a solicitation of professional services which could ultimately become billable.',
        collaborator: 'You are about to invite a collaborator to a investment. The collaborator will have full access to this investment and all related threads.',
        investor: 'You are about to invite a investor to an investment. The investor will have full access to this investment and all related threads',
    },

    solicitationWarningInvestmentByEmail: {
        advisor: 'You are about to invite a advisor to an investment via email. This may be considered a solicitation of professional services which could ultimately become billable.',
        collaborator: 'You are about to invite a collaborator or collaborators to an investment via email. The collaborator(s) will have full access to this investment and all related threads.',
        investor: 'You are about to invite a investor or investors to an investment via email. The investor(s) will have full access to this investment and all related threads',
    },

    investmentStatus,

    investmentFlow
};

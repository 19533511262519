import { db } from "firebase/fbConfig.js"

export const editDialogOpen = () => {
  return {
    type: 'PROFILE_EDIT_DIALOG_OPEN'
  }
};

export const editDialogClose = () => {
  return {
    type: 'PROFILE_EDIT_DIALOG_CLOSE'
  }
};

export const editDialogSave = (data) => {
  return {
    type: 'PROFILE_EDIT_DIALOG_SAVE',
    data: data
  }
};

export const photoDialogOpen = () => {
  return {
    type: 'PROFILE_PHOTO_DIALOG_OPEN'
  }
};

export const photoDialogClose = () => {
  return {
    type: 'PROFILE_PHOTO_DIALOG_CLOSE'
  }
};

export const photoDialogSave = (url) => {
  return {
    type: 'PROFILE_PHOTO_DIALOG_SAVE',
    url: url
  }
};

export const startSetUser = (userObj, user) => {
  return (dispatch) => {
    if (!userObj.email || !userObj.groups || !userObj.termGroups) {
      userObj = {
        ...userObj,
        email: user.email,
        groups: [],
        termGroups: []
      }

      updateUser(userObj, user.uid)
    }
    dispatch(setUser(userObj))
  }
}


export const setUser = (userObj) => ({
  type: 'SET_USER',
  userObj
})

export const updateUser = (userObj, uid) => {
  db.collection("TEST-users").doc(uid).set({
    ...userObj,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    window.location.reload()
  }).catch((err) => {
    return (`Update TEST-users with id: ${uid} with error: ${err}`)
  })
}
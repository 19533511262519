import { LOCATION_CHANGE, push } from 'connected-react-router';
import { pathToRegexp } from 'path-to-regexp'
import { startArchiveThread } from 'store/actions/threadsActions'
import { startArchiveInvestment, startUnarchiveInvestment } from 'store/actions/investmentsActions'

const investorThreadPath = pathToRegexp('/:cid/investorThreads/:tid');
const investmentChatPath = pathToRegexp('/investmentChat/:cid');
const advisorPath = pathToRegexp('/advisor/:cid')
const threadPath = pathToRegexp('/threads/:tid');
const threadPermissionsPath = pathToRegexp('/threadshare/:tid');
const investmentPath = pathToRegexp('/investment/:cid');
const investmentPermissionsPath = pathToRegexp('/investmentshare/:cid/:role');
const archivedInvestments = pathToRegexp('/investments/archived');
const archivedInvestmentsPath = pathToRegexp('/investment/archived/:cid');
const investments = pathToRegexp('/investments');
const quizzes = pathToRegexp('/quizzes');
const conferencing = pathToRegexp('/conferencing');
const matches = pathToRegexp('/matches');
const threads = pathToRegexp('/chat');
const explore = pathToRegexp('/explore');
const settings = pathToRegexp('/settings');
const profile = pathToRegexp('/profile');
const notifications = pathToRegexp('/notifications');
const setup = pathToRegexp('/setup');
const createFirm = pathToRegexp('/create-firm');
const oneVOneChat = pathToRegexp('/chat/:id');
const groupChat = pathToRegexp('/group-message/:id');
const achievements = pathToRegexp('/achievements')
const rewards = pathToRegexp('/rewards')
const addOnPath = pathToRegexp('/add-ons')

function permissionScreenName(role) {
    switch (role) {
        case 'advisor': return 'Assign Advisor';
        case 'investor': return 'Assign Investor';
        default: return 'Permissions';
    }
}

// Be sure to add this for header title to populate!
function parsePath(path) {
    console.log('navigationReducer', path);
    let match = investorThreadPath.exec(path);
    if (match) return { cid: match[1], tid: match[2], screenName: 'Chat', goBackLink: `/investmentChat/${match[1]}`, editPermissionsLink: `/threadshare/${match[2]}`, archiveAction: startArchiveThread(match[2]), showEditMenu: true, showNotifications: false, hideSidebar: false }
    match = threadPath.exec(path);

    match = oneVOneChat.exec(path);
    if (match) return { cid: match[1], tid: match[2], screenName: 'Direct Chat', goBackLink: `/matches`, editPermissionsLink: `/threadshare/${match[2]}`, archiveAction: startArchiveThread(match[2]), showEditMenu: false, showNotifications: false, hideSidebar: false, showChatOptions: true }

    match = groupChat.exec(path);
    if (match) return { cid: match[1], tid: match[2], screenName: 'Group Chat', goBackLink: `/conferencing`, editPermissionsLink: `/threadshare/${match[2]}`, archiveAction: startArchiveThread(match[2]), showEditMenu: true, showNotifications: false, hideSidebar: false }

    match = rewards.exec(path)
    if (match) return { cid: match[1], tid: match[2], screenName: 'Rewards', goBackLink: `/achievements`, editPermissionsLink: `/threadshare/${match[2]}`, archiveAction: startArchiveThread(match[2]), showEditMenu: false, showNotifications: true, hideSidebar: false }

    match = achievements.exec(path)
    if (match) return { cid: match[1], tid: match[2], screenName: 'Achievements', goBackLink: `/conferencing`, editPermissionsLink: `/threadshare/${match[2]}`, archiveAction: startArchiveThread(match[2]), showEditMenu: false, showNotifications: true, hideSidebar: false }

    if (match) return { tid: match[1], screenName: 'Chat', goBackLink: `/chat`, editPermissionsLink: `/threadshare/${match[1]}`, archiveAction: startArchiveThread(match[1]), showEditMenu: true, showNotifications: false, hideSidebar: false };
    match = threadPermissionsPath.exec(path);

    if (match) return { tid: match[1], screenName: 'Permissions', goBackLink: `/threads/${match[1]}`, showEditMenu: false, showNotifications: false, hideSidebar: false };


    match = addOnPath.exec(path);
    if (match) return { tid: match[1], screenName: 'Activities', showEditMenu: false, showNotifications: true, hideSidebar: false };



    match = investmentChatPath.exec(path);
    if (match) return { cid: match[1], screenName: 'Chat', goBackLink: `/investment/${match[1]}`, assignInvestorLink: `/investmentshare/${match[1]}/investor`, assignAdvisorLink: `/investmentshare/${match[1]}/advisor`, editPermissionsLink: `/investmentshare/${match[1]}/collaborator`, archiveAction: startArchiveInvestment(match[1]), showEditMenu: true, showNotifications: false, hideSidebar: false };
    match = investmentPath.exec(path);
    if (match) return { cid: match[1], screenName: 'Investment', goBackLink: `/investments`, assignInvestorLink: `/investmentshare/${match[1]}/investor`, assignAdvisorLink: `/investmentshare/${match[1]}/advisor`, editPermissionsLink: `/investmentshare/${match[1]}/collaborator`, archiveAction: startArchiveInvestment(match[1]), showEditMenu: true, showNotifications: false, hideSidebar: false };
    match = investmentPermissionsPath.exec(path);
    if (match) return { cid: match[1], screenName: permissionScreenName(match[2]), goBackLink: `/investment/${match[1]}`, showEditMenu: false, showNotifications: false, hideSidebar: false };
    match = setup.exec(path);
    if (match) return { screenName: 'Setup', goBackLink: `/setup`, showEditMenu: false, showNotifications: false, hideSidebar: true };
    match = createFirm.exec(path);
    if (match) return { screenName: 'CreateFirm', goBackLink: `/setup`, showEditMenu: false, showNotifications: false, hideSidebar: true };
    match = archivedInvestmentsPath.exec(path);
    if (match) return { cid: match[1], screenName: 'Archived Investment', goBackLink: `/investments/archived`, showEditMenu: true, showNotifications: false, unarchiveAction: startUnarchiveInvestment(match[1]), hideSidebar: false }
    match = archivedInvestments.exec(path);
    if (match) return { screenName: 'Archived Investments', goBackLink: `/settings`, showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = investments.exec(path);
    if (match !== null) return { screenName: 'Investments', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = threads.exec(path);
    if (match !== null) return { screenName: 'Chat', goBackLink: `/investments`, showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = explore.exec(path);
    if (match !== null) return { screenName: 'Explore', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = settings.exec(path);
    if (match !== null) return { screenName: 'Settings', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = profile.exec(path);
    if (match !== null) return { screenName: 'Profile', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = notifications.exec(path);
    if (match !== null) return { screenName: 'Notifications', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = quizzes.exec(path);
    if (match !== null) return { screenName: 'Quizzes', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = matches.exec(path);
    if (match !== null) return { screenName: 'Matches', goBackLink: '/profile', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = conferencing.exec(path);
    if (match !== null) return { screenName: 'Conferencing', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    match = advisorPath.exec(path);
    if (match !== null) return { cid: match[1], screenName: 'Advisors', goBackLink: '/matches', showEditMenu: false, showNotifications: true, hideSidebar: false }
    return { screenName: '', hideSidebar: false }
}

export const initState = {
    loading: true,
    solicitationDialogOpen: false,
    fileUploadDialogOpen: false,
    notificationActionDialogOpen: false,
    header: { screenName: 'WealthEnV' },
    sidebar: {}
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'NAVIGATION_LOADING':
            return {
                ...state,
                loading: true
            };
        case 'NAVIGATION_LOADED':
            return {
                ...state,
                loading: false
            };
        case 'NAVIGATION_OPEN_SOLICITATION_DIALOG':
            return {
                ...state,
                solicitationDialogOpen: true,
                warningText: action.warningText,
                onContinue: action.onContinue,
                onCancel: action.onCancel
            }
        case 'NAVIGATION_CLOSE_SOLICITATION_DIALOG':
            return {
                ...state,
                solicitationDialogOpen: false
            }
        case 'NAVIGATION_OPEN_NOTIFICATION_ACTION_DIALOG':
            return {
                ...state,
                notificationActionDialogOpen: true,
                warningText: action.warningText,
                onContinue: action.onContinue,
                onCancel: action.onCancel,
                onReject: action.onReject
            }
        case 'NAVIGATION_CLOSE_NOTIFICATION_ACTION_DIALOG':
            return {
                ...state,
                notificationActionDialogOpen: false
            }
        case 'NAVIGATION_OPEN_FILE_UPLOAD_DIALOG':
            return {
                ...state,
                fileUploadDialogOpen: true
            }
        case 'NAVIGATION_CLOSE_FILE_UPLOAD_DIALOG':
            return {
                ...state,
                fileUploadDialogOpen: false
            }
        case 'NAVIGATION_SET_HEADER':
            return {
                ...state,
                header: { ...state.header, ...action.data }
            }
        case LOCATION_CHANGE:
            const { screenName, editPermissionsLink, assignAdvisorLink, assignInvestorLink, goBackLink, showEditMenu, showNotifications, archiveAction, unarchiveAction, hideSidebar } = parsePath(action.payload.location.pathname);
            return {
                ...state,
                header: {
                    ...state.header,
                    screenName,
                    editPermissionsLink,
                    assignInvestorLink,
                    assignAdvisorLink,
                    goBackLink,
                    showEditMenu,
                    showNotifications,
                    archiveAction,
                    unarchiveAction
                },
                sidebar: {
                    hideSidebar
                }
            }
        default:
            return state;
    }
}

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Redirect } from 'react-router-dom'
import { createBrowserHistory } from "history";

import PropTypes from "prop-types";

import axios from 'axios'
import firebase, { db, storage } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx";

import { investmentTypes, serviceTypes, states } from 'config';

class Form extends React.Component {
    state = {
        comment: '',
        messageSent: false,
        messageError: false
    }
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            continue2: false,
            continueForm: false
        };
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    handleChange = (e, uid, name) => {
        this.setState({
            [e.target.id]: e.target.value,
            uid: uid,
            name: name
        })
    }
    handleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        console.log(this.state)
    }
    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                imageChanged: true
            });
        };
        reader.readAsDataURL(file);
    }


    handleSubmit(id, fileType) {
        const pic = this.state.file
        // create storage ref
        var storageRef = storage.ref('documents/' + this.props.uid + pic.name)
        // upload file
        var uploadTask = storageRef.put(pic)
        // update progress bar
        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    this.setState({ uploadProgress: ' Upload is paused' });
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    this.setState({ uploadProgress: 'Upload is running' });
                    break;
            }
        }, (error) => {
            // Handle unsuccessful uploads
            console.log(error)
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.setState({ uploadProgress: 'Upload finished. Updating Firestore' });

                db.collection("users").doc(this.props.authUser.uid).collection("investments").doc().set({
                    document: downloadURL,
                    uid: this.props.authUser.uid,
                    investmentType: this.state.investmentType,
                    serviceType: this.state.serviceType,
                    summary: this.state.summary,
                    createdDate: new Date()
                }, { merge: true }).then(() => {
                    this.setState({
                        comment: '',
                        messageSent: true,
                        continue2: true
                    })
                })
            });
        })

        // e.preventDefault();
        // update firestore document with PENDING and comment
        // db.collection("users").doc(this.props.authUser.uid).collection("cases").doc().set({
        //   uid: this.props.authUser.uid,
        //   caseType: this.state.investmentType,
        //   serviceNeeded: this.state.serviceNeeded,
        //   summary: this.state.summary,
        //   createdDate: new Date()
        // }, { merge: true }).then(() => {
        //   console.log(doc.id)
        //   this.setState({
        //     comment: '',
        //     messageSent: true,
        //     continue2: true
        //   })
        // }).catch((err) => {
        //   console.log(`${err}`)
        // })
    }

    resetRequest = () => {
        // window.location.reload();
    }

    continueForm = () => {

        this.setState({
            continueForm: true,
        })

    }



    render() {
        const { classes, modalClose, authUser, blogID } = this.props;
        console.log(window.location.pathname)

        if (this.state.messageSent && (window.location.pathname == "/create-investment")) {
            return <Redirect to='/legal-disclaimer' />
        }
        const hist = createBrowserHistory();

        // reset modal when closed
        // if (this.state.messageSent) { this.resetRequest() }


        return (
            <div style={{ padding: "20px" }}>
                <h5><b>WealthEnV Privacy Policy</b></h5>
                <p>WealthEnV operates the wealthenv.com website, which provides easy ways to connect advisors to investors.</p>
                <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Website Name website.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Website URL, unless otherwise defined in this Privacy Policy.</p>
                <h5><b>Information Collection and Use</b></h5>
                <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</p>
                <h5><b>Log Data</b></h5>
                <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
                <h5><b>Cookies</b></h5>
                <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer's hard drive.</p>
                <p>Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
                <h5><b>Service Providers</b></h5>
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <h5><b>To facilitate our Service</b></h5>
                <p>To provide the Service on our behalf;<br />
                    To perform Service-related services; or<br />
                    To assist us in analyzing how our Service is used. We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                <h5><b>Security</b></h5>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h5><b>Links to Other Sites</b></h5>
                <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <h5><b>Minor Privacy</b></h5>
                <p>Our Services do not address anyone under the age of 18. We do not knowingly collect personal identifiable information from minors. In the case we discover that a person under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <h5><b>Changes to This Privacy Policy</b></h5>
                <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                <h5><b>Contact Us</b></h5>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                <Button
                    onClick={hist.goBack}
                    style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", fontSize: "1rem", textAlign: "center" }}
                >
                    <b>Go Back</b>
                </Button>
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.user.user,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Form);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import loader from 'assets/img/loader.gif';
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SearchBox from 'components/SearchBox';
import MessagesSearchBox from 'components/SearchBox/MessagesSearchBox';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/matchesPageStyle';

import { sampleInvestors, sampleAdvisors } from "variables/sampleUsers.jsx";

import MatchesMap from '../Matches/MatchesMap.jsx';
import MatchesMessagesMap from '../Matches/MatchesMessagesMap.jsx';
import RecommendationsMap from '../Matches/RecommendationsMap.jsx';
import { db } from '../../firebase/fbConfig.js';

import { ReactComponent as MatchesTitle } from 'assets/img/matchesTitle.svg';


export function MatchesPage(props) {

    const [query, setQuery] = useState(null);
    const [matches, setMatches] = useState(false)
    const [whiteListArray, setWhiteListArray] = useState([])
    const [blackListArray, setBlackListArray] = useState([])
    const [filteredWhiteList, setFilteredWhiteList] = useState([])
    const [filteredMatches, setFilteredMatches] = useState([])
    const [filtered, setFiltered] = useState(false)
    const [messagesLoaded, setMessagesLoaded] = useState(false)
    const [allMessagesData, setAllMessagesData] = useState([])
    const [matchesDataLoaded, setMatchesDataLoaded] = useState(false)
    const { classes, user } = props;


    const history = useHistory();

    const onSearchInput = (input, str) => {
        input = input.toLowerCase()
        const term = input.trim();
        if (term) {
            if (str === 'favorited') {
                var result = []
                for (var x = 0; x < whiteListArray.length; x++) {
                    var current = whiteListArray[x]
                    var name = current.firstName.toLowerCase() + ' ' + current.lastName.toLowerCase()
                    if (name.includes(term)) {
                        result.push(current)
                    }
                }
                setFilteredWhiteList(result)
                setFiltered(true)
                setQuery(term);
            } else {
                var result = []
                for (var x = 0; x < matches.length; x++) {
                    var current = matches[x]
                    var name = current.firstName.toLowerCase() + ' ' + current.lastName.toLowerCase()
                    if (name.includes(term)) {
                        result.push(current)
                    }
                }
                setFilteredMatches(result)
                setFiltered(true)
                setQuery(term);
            }
        } else {
            setQuery(null);
            setFilteredWhiteList([])
            setFilteredMatches([])
            setFiltered(false)
        }
    };

    useEffect(() => {
        if (!messagesLoaded && user.uid) {
            const fetchMessages = async () => {
                return db.collection('users').doc(user.uid).collection('one-on-one-chats').get().then((snapshot) => {
                    if (!snapshot.empty) {
                        let data = []
                        snapshot.docs.forEach((d) => {
                            d = d.data()
                            const from = d.messages[0].messageSentFrom
                            data.push({ id: d.id, from, firstMessage: d.messages[0].message })
                        })
                        setAllMessagesData(data)
                        setMessagesLoaded(true)
                    } else {
                        setMessagesLoaded(true)
                    }
                })
            }
            fetchMessages()
        }
    }, [user])

    const fetchMatches = async () => {
        const data = await db.collection("users").doc(props.user.uid).collection("matches").get()
        const matchArray = data.docs.map(doc => doc.data())
        setMatches(matchArray)
    }

    const fetchWhiteList = async () => {
        const whiteListData = await db.collection("users").doc(props.user.uid).collection("whiteList").get()
        const whiteListArray = whiteListData.docs.map(doc => doc.data())
        setWhiteListArray(whiteListArray)
    }


    const fetchUsersBlackList = async () => {
        const blackListData = await db.collection("users").doc(props.user.uid).collection("blackList").get()
        const blackListArray = blackListData.docs.map(doc => doc.data())
        setBlackListArray(blackListArray)
    }

    useEffect(() => {
        // declare the data fetching function
        if (user.uid && !matchesDataLoaded) {
            setMatchesDataLoaded(true)
            const fetchData = async () => {
                fetchMatches().then(() => {
                    fetchWhiteList().then(() => {
                        fetchUsersBlackList().then(() => {
                            console.log('done')
                        })
                    })
                })
            }
            // call the function
            fetchData()
                // make sure to catch any error
                .catch(console.error);
        }
    }, [props])

    const addTestInvestors = () => {
        return sampleInvestors.map((investor) => {
            db.collection('TEST-users').doc().set({
                bio: investor.bio,
                city: investor.city,
                email: investor.email,
                experience: investor.experience,
                firmName: investor.firmName,
                firstName: investor.firstName,
                groups: investor.groups,
                id: investor.id,
                investmentType: investor.investmentType,
                lastName: investor.lastName,
                phone: investor.phone,
                profileURL: investor.profileURL,
                state: investor.state,
                zip: investor.zip,
                userType: investor.userType
            }, { merge: true })
        })
    }

    if (!matches || !whiteListArray || !blackListArray || !messagesLoaded)
        return (
            <div
                style={{
                    width: '100%',
                    minHeight: '90vh',
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center"
                }}
            >
                <img
                    style={{ width: '20%' }}
                    src={loader}
                    alt="..."
                />
            </div>

        )

    return (
        <div className={classes.container}>

            {/* mobile */}
            <Hidden mdUp implementation="css">
                <MatchesTitle className={classes.matchesTitle} />
                <RecommendationsMap whiteListArray={whiteListArray} blackListArray={blackListArray} matchesArray={matches} uid={user.uid}
                // query={this.state.matchesQuery}
                />
                <Button onClick={() => history.push('/explore')} className={classes.findMoreButton}><span className={classes.findMoreText}>Find More {props.userType == 'advisor' ? "Investors" : "Advisors"} Now
                </span>  <ArrowRightAltIcon style={{ color: '#274B73', }} /></Button>

                <GridContainer className={classes.messagesGrid}>
                    <GridItem xs={12}>
                        <h1 className={classes.recentMessages}>Recent Messages</h1>
                    </GridItem>
                    <GridItem xs={12} className={classes.searchBox}>
                        <MessagesSearchBox matchesPage={true} onInput={(e) => onSearchInput(e, 'favorited')} />
                    </GridItem>
                    <GridItem xs={12} className={classes.searchBox}>
                        <MatchesMessagesMap allMessagesData={allMessagesData} messagesLoaded={messagesLoaded} user={user} />
                    </GridItem>

                </GridContainer>
            </Hidden>
            {/* Desktop */}
            <Hidden smDown implementation="css">
                <div className={classes.recommendationsdiv}>
                    <GridContainer className={classes.recommendationsHeader}>
                        <GridItem xs={7}>
                            <h4><b className={classes.recommendations}>Recommendations</b></h4>
                        </GridItem>
                        <GridItem className={classes.meetAdvisors} xs={5}>
                            <NavLink to={'/explore'}>
                                <Button className={classes.meetButton}><b>Meet {props.userType == 'advisor' ? "Investors" : "Advisors"}</b></Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                    <p className={classes.description}>We think you’d be a good match with the following {props.userType == 'advisor' ? 'investors' : 'advisors'}:</p>
                    <RecommendationsMap whiteListArray={whiteListArray} blackListArray={blackListArray} matchesArray={matches} uid={user.uid}
                    // query={this.state.matchesQuery}
                    />
                </div>
                {/* <h4 className={classes.header}><b>Favorited Advisors List</b></h4> */}
                <CustomTabs
                    tabs={[
                        {
                            id: "Favorited",
                            tabName: "Favorited",
                            // tabIcon: () => ,
                            tabContent: (
                                <GridContainer >
                                    <GridContainer className={classes.searchBoxContainer}>
                                        <GridItem xs={10} className={classes.searchBox}>
                                            <SearchBox matchesPage={true} id="investmentsQuery" onInput={(e) => onSearchInput(e, 'favorited')} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridItem xs={12}>
                                        {matches ? (
                                            <MatchesMap
                                                tab='favorited'
                                                uid={user.uid}
                                                query={query}
                                                matchesArray={filtered ? filteredWhiteList : whiteListArray}
                                            />
                                        ) : (
                                            null
                                        )}
                                    </GridItem>
                                </GridContainer>
                            )
                        },
                        {
                            id: "Matched",
                            tabName: "Matched",
                            // tabIcon: () => ,
                            tabContent: (
                                <GridContainer>

                                    <GridContainer className={classes.searchBoxContainer}>
                                        <GridItem xs={10} className={classes.searchBox}>
                                            <SearchBox matchesPage={true} id="investmentsQuery" onInput={(e) => onSearchInput(e, 'matched')} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridItem xs={12}>
                                        {matches ? (
                                            <MatchesMap
                                                tab='matched'
                                                uid={user.uid}
                                                query={query}
                                                matchesArray={filtered ? filteredMatches : matches}
                                            />
                                        ) : (
                                            null
                                        )}
                                    </GridItem>
                                </GridContainer>
                            )
                        },
                    ]}
                />
            </Hidden>
        </div >
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        user: state.auth.user,
        userType: state.user.user.userType
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(MatchesPage);
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { openFileUploadDialog } from '../../store/actions/navigationActions';

export const ButtonUploadBlue = ({openUpload}) => (
    <label
      onClick={()=>openUpload()}
      htmlFor="file-upload"
      style={{
        background: '#0db2d8',
        display: 'inline-block',
        padding: '6px 12px',
        cursor: 'pointer',
        color: 'white',
        fontSize: '17px',
      }}
    >
      <CloudUploadIcon />
      <span
        style={{
          top: '-4px',
          position: 'relative',
          marginLeft: '12px',
        }}
      >
        Add Documents
      </span>
    </label>
);

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openUpload: ()=>dispatch(openFileUploadDialog())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonUploadBlue);

import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/loader.gif";
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import GaugeChart from 'react-gauge-chart'
import { addQuizXpPoints } from "store/actions/eventsActions"



export const Confirm = (props) => {
    const [riskToleranceStatus, setRiskToleranceStatus] = useState('')
    const [riskPercentageForGraph, setRiskPercentageForGraph] = useState('')
    const [loaded, setLoaded] = useState(false)


    useEffect(() => {
        if (!riskToleranceStatus && !loaded) {
            const values = props.location.state.state.values
            const riskToleranceScore = Object.values(values).reduce((a, b) => a + b, 0)
            const percentage = (riskToleranceScore / 47)
            if (riskToleranceScore <= 18) {
                setRiskToleranceStatus('Low Tolerance For Risk')
                setRiskPercentageForGraph(percentage)
                setLoaded(true)
            }
            else if (riskToleranceScore >= 19 && riskToleranceScore <= 22) {
                setRiskToleranceStatus('Below-average tolerance for risk')
                setRiskPercentageForGraph(percentage)
                setLoaded(true)
            }
            else if (riskToleranceScore >= 23 && riskToleranceScore <= 28) {
                setRiskToleranceStatus('Average/moderate tolerance for risk')
                setRiskPercentageForGraph(percentage)
                setLoaded(true)
            }
            else if (riskToleranceScore >= 29 && riskToleranceScore <= 32) {
                setRiskToleranceStatus('Above-average tolerance for risk')
                setRiskPercentageForGraph(percentage)
                setLoaded(true)
            }
            else if (riskToleranceScore >= 33 && riskToleranceScore <= 47) {
                setRiskToleranceStatus('High tolerance for risk')
                setRiskPercentageForGraph(percentage)
                setLoaded(true)
            }
        }
    }, [props])



    const addToFirestore = () => {
        const values = props.location.state.state.values
        console.log('valuesss values', values)
        return db.collection('global-test-results').doc('risk-tolerance').get()
            .then((snapshot) => {
                const results = snapshot.data()
                for (var key in results) {
                    const scoresObj = results[key]
                    scoresObj[values[key]]++
                }
                console.log('valuesss results', results)
                return db.collection('global-test-results').doc('risk-tolerance').update({ ...results }).then(() => {
                    return db.collection("users").doc(props.uid).collection("quizzes").doc("riskTolerance").collection("quizLists").add({
                        quiz: "Risk Tolerance",
                        firstQuestion: props.location.state.state.values.firstQuestion,
                        secondQuestion: props.location.state.state.values.secondQuestion,
                        thirdQuestion: props.location.state.state.values.thirdQuestion,
                        fourthQuestion: props.location.state.state.values.fourthQuestion,
                        fifthQuestion: props.location.state.state.values.fifthQuestion,
                        sixthQuestion: props.location.state.state.values.sixthQuestion,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        quizID: "Placeholder"
                        // riskProfileStatus: state.riskProfileStatus
                    })
                        .then((docRef) => {
                            return db.collection("users").doc(props.uid).collection("quizzes").doc("riskTolerance").collection("quizLists").doc(docRef.id).update({
                                quizID: docRef.id
                            }).then(() => {
                                // props.addQuizXpPoints(props.uid, props.events, 'Risk Profile Quiz')
                                props.history.push('/risk-tolerance-success');
                            })
                        })

                })
            })
    }

    const retakeQuiz = e => {
        e.preventDefault();
        props.history.push('/risk-tolerance');
    }

    const { classes } = props;

    if (!loaded) {
        return (
            <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                <img
                    style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                    src={loader}
                    alt="..."
                />
            </div>
        )
    } else {
        return (
            <div className={classes.riskToleranceResults67vh}>
                <div style={{ marginBottom: "35px" }}>
                    <h3 className={classes.questionHeader}>You have a {riskToleranceStatus}!</h3>
                </div>
                <GaugeChart id="gauge-chart5"
                    arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2,]}
                    colors={['#75e8e2', '#00d3f0', '#00baff', '#009bff', '#4672fb']}
                    percent={riskPercentageForGraph}
                    arcPadding={0.02}
                    textColor='black'
                    animate={false}
                    hideText
                />

                <GridContainer>
                    <GridItem xs={6}>
                        <Button
                            style={{ width: "90%" }}
                            onClick={retakeQuiz}
                            className={classes.backButton}
                            variant="contained"
                        >Retake Quiz</Button>
                    </GridItem>
                    <GridItem xs={6}>
                        <Button
                            style={{ width: "90%" }}
                            className={classes.submitButton}
                            // onClick={assignRiskType}
                            onClick={addToFirestore}
                        >Submit Results</Button>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {}
    return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Confirm);
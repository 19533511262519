import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { addQuizXpPoints } from "store/actions/eventsActions"



export class Confirm extends Component {

    addToFirestore = () => {
        console.log('results pre', this.props.location.state.state.values)
        const values = this.props.location.state.state.values
        return db.collection('global-test-results').doc('wealth-management').get()
            .then((snapshot) => {
                const results = snapshot.data()
                for (var key in results) {
                    const scoresObj = results[key]
                    scoresObj[values[key]]++
                }
                console.log('results post', results)
                return db.collection('global-test-results').doc('wealth-management').update({ ...results }).then(() => {
                    return db.collection("users").doc(this.props.uid).collection("quizzes").doc("wealthManagement").collection("quizLists").add({
                        quiz: "Wealth Management",
                        riskAversion: this.props.location.state.state.values.riskAversion,
                        riskSensationSeeking: this.props.location.state.state.values.riskSensationSeeking,
                        objectivePerceivedControl: this.props.location.state.state.values.objectivePerceivedControl,
                        nonObjectivePerceivedControl: this.props.location.state.state.values.nonObjectivePerceivedControl,
                        contentSpecificRisk: this.props.location.state.state.values.contentSpecificRisk,
                        situationalSpecificRisk: this.props.location.state.state.values.situationalSpecificRisk,
                        relationshipCommitment: this.props.location.state.state.values.relationshipCommitment,
                        trust: this.props.location.state.state.values.trust,
                        communicationEffectiveness: this.props.location.state.state.values.communicationEffectiveness,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        quizID: "Placeholder"
                    })
                        .then((docRef) => {
                            return db.collection("users").doc(this.props.uid).collection("quizzes").doc("wealthManagement").collection("quizLists").doc(docRef.id).update({
                                quizID: docRef.id
                            }).then(() => {
                                this.props.addQuizXpPoints(this.props.uid, this.props.events, 'Wealth Management Quiz')
                                this.props.history.push('/wealth-management-success');
                            })
                        })

                })
            })
    }

    continue = () => {
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    retakeQuiz = e => {
        e.preventDefault();
        this.props.history.push('/wealth-management');
    }

    render() {
        const { classes } = this.props;
        const values = this.props.location.state.state
        const sortedResults = [
            { riskAversion: values.riskAversion },
            { riskSensationSeeking: values.riskSensationSeeking },
            { objectivePerceivedControl: values.objectivePerceivedControl },
            { nonObjectivePerceivedControl: values.nonObjectivePerceivedControl },
            { contentSpecificRisk: values.contentSpecificRisk },
            { situationalSpecificRisk: values.situationalSpecificRisk },
            { relationshipCommitment: values.relationshipCommitment },
            { trust: values.trust },
            { communicationEffectiveness: values.communicationEffectiveness },
        ].sort((a, b) => {
            console.log('aaaaaa', Object.values(a)[0], Object.values(b)[0])
            return Object.values(b)[0] - Object.values(a)[0]
        })

        console.log('scores', Object.keys(sortedResults[0]))

        return (
            <div className={classes.quizResultsContainer}>
                {Object.keys(sortedResults[0])[0] === 'riskAversion' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Risk Aversion!</h3>
                        <p className={classes.questionDescription}>
                            This means you tend to avoid situations that lack certainty or
                            feel like a gamble. You prefer low risk- or risk-free circumstances where you typically know the
                            outcome
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'riskSensationSeeking' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Risk Sensation Seeking!</h3>
                        <p className={classes.questionDescription}>
                            Woo-hoo! You scored high in risk sensation seeking meaning you seek out risks in the hope of
                            higher rewards. The rewards you seek would not have been gained if not for the risk or have
                            taken more a much greater time.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'objectivePerceivedControl' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Objective Perceived Control!</h3>
                        <p className={classes.questionDescription}>
                            This means you prefer to be involved in actions
                            that directly affect you and believe your actions have an ability to impact your future. Others
                            who score low in this have low levels of interest in being involved in decisions surrounding them
                            because they believe they lack control over their fate and future.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'contentSpecificRisk' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Content Specific Risk Perception!</h3>
                        <p className={classes.questionDescription}>
                            Incoming news – you scored high in content specific risk perception. This means you tend to
                            seek out and/or listen to information that comes from unconventional sources including social
                            media, blogs, and other opinion-based resources.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'situationalSpecificRisk' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Situational Specific Risk Perception!</h3>
                        <p className={classes.questionDescription}>
                            News alert – you scored high in situational specific risk perception. This means you make
                            decisions based on information gathered from a wide variety of trusted and credible sources.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'relationshipCommitment' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Relationship Commitment!</h3>
                        <p className={classes.questionDescription}>
                            You scored high in relationship commitment which means you tend to remain loyal to those you
                            have worked with. You enjoy building relationships over time and sticking with people.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'trust' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Trust!</h3>
                        <p className={classes.questionDescription}>
                            People like you who score high in trust believe, in general, that the people they choose to work
                            with are competent and reliable.
                        </p>
                    </div>) : (null)
                }
                {Object.keys(sortedResults[0])[0] === 'communicationEffectivesness' ?
                    (<div>
                        <h3 className={classes.questionHeader}>You scored highest in Communication Effectiveness!</h3>
                        <p className={classes.questionDescription}>
                            A high score in communication effectiveness means you are able to communicate reliably with
                            others and share necessary information in a timely fashion with others.
                        </p>
                    </div>) : (null)
                }
                <GridContainer>
                    <GridItem xs={12}>
                        <Button
                            onClick={this.retakeQuiz}
                            className={classes.backButton}
                            variant="contained"
                        >Retake Quiz</Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <Button
                            className={classes.submitButton}
                            onClick={this.addToFirestore}
                        >Submit My Results</Button>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {}
    return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Confirm);
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

export const CardInfoBox = ({ classes, children }) => (
  <Card style={{ margin: '10px 0' }} className={classes.investmentCards}>
    <CardBody>{children}</CardBody>
  </Card>
);

CardInfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

export default withStyles(Style)(CardInfoBox);

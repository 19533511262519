const stocksStyle = (theme) => ({
  container: {
      color: "#000",
      margin: "-50px 0 0 0",
      height: "120vh"
  },
  gridContainerDiv: {
      [theme.breakpoints.down('sm')]: {
          padding: "1rem 1.5rem",
          paddingBottom: "100px",
          marginTop: "10%"
      }
  },
  optionCard: {
      borderRadius: "10px",
      padding: "10px",
      height: "34vh",
      margin: "30px 0 0 0",
      boxShadow: "0px 4px 9px gray",
      display: "flex",
      alignItems: "center"
  },
  lockedCard: {
      borderRadius: "10px",
      padding: "10px",
      height: "33vh",
      backgroundColor: "#a7a7a7",
      margin: "30px 0 0 0"

  },
  addOnIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(297.41deg, #75E8E2 9.14%, #4672FB 106.58%)",
      borderRadius: "50%",
      width: "150px",
      height: "150px",
      [theme.breakpoints.down('md')]: {
          width: "90px",
          height: "90px",
      }
  },
  additonalQuizIcon: {
      height: "187px",
      marginTop: "22px",
      [theme.breakpoints.down('md')]: {
          height: "140px",
      }
  },
  videoClassIcon: {
      height: "60px",
      [theme.breakpoints.down('md')]: {
          height: "38px",
      }
  },
  accountIcon: {
      height: "80px",
      [theme.breakpoints.down('md')]: {
          height: "50px",
      }
  },
  investmentIcon: {
      height: "130px",
      [theme.breakpoints.down('md')]: {
          height: "83px",
      }
  },
  socialIcon: {
      height: "165px",
      marginTop: "20px",
      [theme.breakpoints.down('md')]: {
          height: "100px",
      }
  },
  headerDescription: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#4F4F4F",
      [theme.breakpoints.up('md')]: {
          fontSize: "30px",
      }
  },
  description: {
      fontSize: "12px",
      [theme.breakpoints.up('md')]: {
          fontSize: "20px",
      }
  },
  investmentDescription: {
    fontSize: "12px",
    margin: 0,
  },
  header: {
      textAlign: "center",
      fontWeight: "600",
      marginTop: "60px"
  },
  cardContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
  },
  gridContainer: {
      padding: "20px",
      justifyContent: "space-around"
  },
  addOnTitle: {
      fontWeight: 700,
      fontSize: '14px',
      color: '#4F4F4F'
  },
  imgGridItem: {
      width: "100%",
      borderRadius: "20px",
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#4775FB"
  },
  infoGridItem: {
      width: "100%",
      background: '#FFFFFF',
      border: '1px solid #72C5C8',
      display: 'flex',
      flexDirection: "column",
      borderRadius: "20px",
      padding: "10px !important"
  },
  header: {
    fontFamily: "NOVA",
    fontWeight: 400,
    fontSize: "32px",
    color: "#4672FB"
  },
  sectionHeader: {
    fontFamily: "NOVA",
    fontWeight: 400,
    fontSize: "25px",
  },
  section: {
    border: "solid 1px rgb(105,189,192)",
    margin: "10px 0",
    borderRadius: "20px",
    background: "white",
    padding: "0 1rem"
  },
  searchBar: {
    width: "100%",
    height: "39px",
    borderRadius: "7px",
    border: "1px solid #72C5C8",
    boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
    background: "white",
    marginTop: "-5%"
  },
  balance: {
    color: "#4775FB",
    fontWeight: 800,
    fontSize: "18px"
  },
  checkingBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginTop: "-0.5rem"
  },
  typeSpan: {
      color: "#4672FB",
      fontSize: "12px",
      fontWeight: 700,
      marginLeft: "1rem"
  },
  priceHeader: {
    fontFamily: "Archivo",
    fontWeight: 700,
    fontSize: "47px",
    color: "white"
  },
  priceChange: {
    fontFamily: "Archivo",
    fontWeight: 700,
    fontSize: "16px",
    color: "#4F4F4F",
    marginLeft: "1rem"
  },
  headerButton: {
    color: "#274B73",
    boxShadow: "0px 4px 4px rgb(0,0,0,0.25)",
    borderRadius: "25px",
    background: "white",
    width: "44%",
    margin: "2rem 5px 0"
  },
  detailsBubble: {
    border: "solid 1px #72C5C8",
    borderRadius: "32px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "12px",
    background: "white"
  },
  aboutHeader: {
    fontFamily: "NOVA",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "25px",
    margin: "4px 2rem 14px"
  },
  newsHeader: {
    fontFamily: "NOVA",
    fontWeight: 400,
    fontSize: "32px",
    color: "#4672FB",
    marginTop: "1.5rem"
  },
  newsTitle: {
    fontFamily: "Archivo",
    fontWeight: 600,
    fontSize: "18px",
    color: "#4F4F4F"
  },
  newsButton: {
    color: "white",
    background: "#436CFC",
    borderRadius: "34px",
    margin: "auto",
    width: "50%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  favoritesButton: {
    marginTop: "12px"
  }
})

export default stocksStyle;
import React, { useState, useEffect, useRef } from 'react';
import AVControls from './AVControls';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Video from 'twilio-video';
import Button from 'components/CustomButtons/Button';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import style from 'assets/jss/material-dashboard-pro-react/components/videoStyle';

const Participant = ({ participant, self, classes, room, handleLogout, dominant, setMainScreenShared, id, setRoomVideoTracks, realSelf, setSelfTracks, selfTracks, localScreenShared }) => {
  if (!setSelfTracks) {
    setSelfTracks = ()=>{};
  }
  if (!selfTracks) {
    selfTracks = null;
  }
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [enlarged, setEnlarged] = useState('');
  const [screenShared, setScreenShared] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();
  const screenRef = useRef();

  const capitalize = (identity) => {
    let name = identity.replace('-', ' ').replace(/[^a-zA-Z\s:]/g, '');
    let nameArr = name.split(' ');
    for (let i = 0; i < nameArr.length; i++) {
      nameArr[i] = nameArr[i][0].toUpperCase() + nameArr[i].substr(1);
    }
    return nameArr.join(' ');
  };

  const handleEnlarge = (module) => {
    setEnlarged((bigModule) => {
      if (bigModule === module) {
        return '';
      }
      return module;
    });
  };

  let mutedShowVideoButtons = [];

  // const shareScreenHandler = (e) => {
  //   // e.preventDefault();
  //   if (!videoTracks[1]) {
  //     navigator.mediaDevices
  //       .getDisplayMedia()
  //       .then((stream) => {
  //         const track = new Video.LocalVideoTrack(stream.getTracks()[0]);
  //         setVideoTracks((videoTracks) => [...videoTracks, track]);
  //         room.localParticipant.publishTrack(track);
  //         setScreenShared(true);
  //         track.mediaStreamTrack.onended = () => {
  //           shareScreenHandler();
  //         };
  //       })
  //       .catch(() => {
  //         alert('Could not share the screen');
  //       });
  //   } else {
  //     room.localParticipant.unpublishTrack(videoTracks[1]);
  //     videoTracks[1].stop();
  //     setScreenShared(false);
  //     setVideoTracks((videoTracks) => {
  //       let newTracks = [...videoTracks];
  //       newTracks.pop();
  //       return newTracks;
  //     });
  //   }
  // };

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setSelfTracks((tracks) => [...tracks.video, track]);
        setVideoTracks((videoTracks) => {
          console.log('look video', videoTracks, track)
          return [...videoTracks, track];
        });
      } else {
        setSelfTracks((tracks) => [...tracks.audio, track]);
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setSelfTracks((tracks) => tracks.video.filter((v) => v !== track));
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setSelfTracks((tracks) => tracks.audio.filter((v) => v !== track));
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    if (setSelfTracks) {
      setSelfTracks({
        video: trackpubsToTracks(participant.videoTracks),
        audio: trackpubsToTracks(participant.audioTracks)
      })
    }

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      setSelfTracks({});
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    let screenTrack = null;
    if (selfTracks && selfTracks.video) {
      screenTrack = selfTracks.video[1]
    } else {
      screenTrack = videoTracks[1];
    }
    if (screenTrack) {
      setScreenShared(true);
      screenTrack.attach(screenRef.current);
      return () => {
        screenTrack.detach();
      };
    } else {
      setScreenShared(false);
    }
  }, [selfTracks, videoTracks]);

  useEffect(() => {
    setRoomVideoTracks((roomVideoTracks) => {
      let newRoomTracks = {...roomVideoTracks};
      newRoomTracks[id] = videoTracks;
      return newRoomTracks;
    });
  }, [videoTracks])

  return (
    <div
      className={self ? classes.participant : classes.smallParticipant}
      style={
        self ? { minWidth: '100%' } : { margin: '0px 30px' },
        dominant ? { border: "3px solid red" } : null
        }
    >
      <h3 className={self ? classes.participantName : classes.smallParticipantName}>
        {realSelf ? 'You' : capitalize(participant.identity)}
      </h3>
      <video
        resizeMode={'contain'}
        style={{
          flex: 1,
        }}
        className={screenShared ? classes.screenSharedVideo : classes.video}
        ref={videoRef}
        autoPlay={true}
        onClick={() => {
          handleEnlarge('video');
        }}
      />
      <audio ref={audioRef} autoPlay={true} />
      {videoTracks[1] ? (
        <TransformWrapper style={{ position: 'fixed', top: 0 }}>
          <TransformComponent>
            <video
              className={classes.screenShare}
              ref={screenRef}
              autoPlay={true}
              onClick={() => {
                handleEnlarge('screen');
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      ) : null}
      {/* {self ? (
        <div style={{ borderRadius: '15px' }} className={classes.controls}>
          <AVControls
            audioTrack={audioTracks[0]}
            videoTrack={videoTracks[0]}
            screenTrack={videoTracks[1]}
            shareScreenHandler
            handleLogout
            classes
          >
            <Button
              style={{
                background: '#72E5E4',
                marginLeft: '2%',
                marginRight: '1%',
                borderRadius: '15px',
                fontSize: '20px',
                fontWeight: 500,
              }}
              onClick={shareScreenHandler}
            >
              {videoTracks[1] ? 'Stop Sharing' : 'Share Screen'}
            </Button>
            <Button
              style={{
                background: 'red',
                marginLeft: '2%',
                borderRadius: '15px',
                fontSize: '20px',
                fontWeight: 500,
              }}
              onClick={handleLogout}
              className={classes.logout}
            >
              Leave Call
            </Button>
          </AVControls>
        </div>
      ) : (
        mutedShowVideoButtons
      )} */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => {
  // nothing yet
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style)
)(Participant);

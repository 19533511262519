const controlBarStyles = (theme) => ({
    container: {
        width: "100%",
        height: "100px",
        position: "absolute",
        bottom: "0px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',

    },
    buttonContainer: {
        backgroundColor: "#434343",
        padding: "20px",
        borderRadius: "50%",
        marginRight: "7px",
        "&:hover": {
            backgroundColor: "#2c2c2c"
        }
    },
    endCallButtonContainer: {
        backgroundColor: "#ec2b2b",
        padding: "20px",
        borderRadius: "50%",
        marginRight: "7px",
        "&:hover": {
            backgroundColor: "#c42020"
        }
    },
    buttonIcon: {
        width: "25px",
        height: "25px",
        color: "#fff"
    }
});

export default controlBarStyles;
import React from 'react';

import {
  getFirestoreData,
  updateFirestoreWithOneObject,
} from 'store/actions/firestoreActions.jsx';
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx';
import moment from 'moment';
import { db, firebase } from 'firebase/fbConfig.js';
import Button from 'components/CustomButtons/Button.jsx';
import { setGroupMessageThread } from './threadsActions';
import { sendAddedUserToEventEmail } from "firebase/fbConfig.js"


export function getAttendessAndMessages(uid, messageId) {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('calendar-events')
      .doc(messageId)
      .onSnapshot((snapshot) => {
        snapshot = snapshot.data();
        console.log('snapshotsnapshotsnapshotx', snapshot);
        dispatch(setEventAttendessAndTitle(snapshot.attendees, snapshot.title));
        dispatch(setGroupMessageThread(snapshot.groupChatMessages));
      });
  };
}

export const deleteUserFromEvent = (id, updatedEvent, uid) => {
  return (dispatch) => {
    updatedEvent.start = new Date(updatedEvent.start)
    updatedEvent.end = new Date(updatedEvent.end)
    console.log('updatedEventupdatedEvent', updatedEvent)
    var batch = db.batch()
    // var attendeesToReturn = []
    for (var x = 0; x < updatedEvent.attendees.length; x++) {
      var currentAttendee = updatedEvent.attendees[x]
      var userId
      if (currentAttendee.uid) {
        userId = currentAttendee.uid
      } else {
        userId = currentAttendee.id
      }

      const userRef = db
        .collection('users')
        .doc(userId)
        .collection('calendar-events');
      var attendeeDocRef = userRef.doc(updatedEvent.groupChatId);
      batch.set(
        attendeeDocRef,
        updatedEvent
        ,
        { merge: true }
      );
    }
    batch.commit()
      .then(() => {
        return db.collection('users').doc(id).collection('calendar-events').doc(updatedEvent.groupChatId).delete()
          .then(() => {
            dispatch(setUserRemovedFromEvent(true))
            // dispatch(startSetEventsList(uid))
          })
      })
  }
}

export const setUserRemovedFromEvent = (boolean) => {
  return {
    type: "SET_USER_REMOVED_FROM_EVENT",
    boolean
  }
}


export const setEventAttendessAndTitle = (attendees, title) => {
  return {
    type: 'SET_EVENT_ATTENDEES',
    attendees,
    title,
  };
};

const getAttendeeAvailability = (currentAttendee, eventInfo, id) => {
  return db.collection('users').doc(id).collection('calendar-events').get().then((snapshot) => {
    console.log('whattttt nsap', snapshot)
    if (snapshot.empty) {
      return true
    } else {
      snapshot = snapshot.docs
      var isAttendeeAvail = 'N/A'
      snapshot.forEach((doc) => {
        doc = doc.data()
        console.log('eventInfo.endTime', eventInfo.endTime, doc.end.toDate(), +eventInfo.endTime === +doc.end.toDate())
        if (eventInfo.startTime > doc.start.toDate() && eventInfo.startTime < doc.end.toDate()) {
          console.log('in between', 'not equal')
          isAttendeeAvail = false
        } else if (+eventInfo.startTime === +doc.start.toDate() || +eventInfo.endTime === +doc.end.toDate()) {
          console.log('in between', 'equal')
          isAttendeeAvail = false
        }
      })
      if (isAttendeeAvail === 'N/A') {
        isAttendeeAvail = true
      }
      console.log('isAttendeeAvailisAttendeeAvail', isAttendeeAvail)
      return isAttendeeAvail
    }
  })
}

export const updateExistingEvent = (eventInfo, uid, userInfo) => {
  var batch = db.batch()
  var notificationBatch = db.batch()


  return (dispatch) => {
    console.log('eventInfo.attendeeseventInfo.attendees', eventInfo)
    if (eventInfo.attendees.length > 0) {
      for (var x = 0; x < eventInfo.attendees.length; x++) {
        var currentAttendee = eventInfo.attendees[x];
        // if (currentAttendee.uid !== uid) {
        var id
        if (currentAttendee.uid) {
          id = currentAttendee.uid
        } else {
          id = currentAttendee.id
        }

        const userRef = db
          .collection('users')
          .doc(id)
          .collection('calendar-events');
        console.log('eventInfo.groupChatId', eventInfo)
        var attendeeDocRef = userRef.doc(eventInfo.groupChatId);
        batch.set(
          attendeeDocRef,
          {
            end: new Date(eventInfo.endTime),
            start: new Date(eventInfo.startTime),
            title: eventInfo.eventName,
            allDay: false,
            attendees: eventInfo.attendees,
            groupChatId: eventInfo.groupChatId,
            groupChatMessages: eventInfo.groupChatMessages,
            createdByID: eventInfo.createdByID
          }, { merge: true }
        );
        if (id !== uid) {
          const userNotificationRef = db
            .collection('users')
            .doc(id)
            .collection('notifications');
          var attendeeNotificationRef = userNotificationRef.doc();
          notificationBatch.set(attendeeNotificationRef, {
            type: "Event Update",
            from: userInfo,
            attendees: eventInfo.attendees,
            end: eventInfo.endTime,
            start: eventInfo.startTime,
            title: eventInfo.eventName,
            notificationId: attendeeNotificationRef.id,
            createdDate: new Date(),
            status: "New",
            createdByID: uid
          })
        }
      }
      batch.commit().then(() => {
        notificationBatch.commit()
          .then(() => {
            dispatch(startSetEventsList(uid));
            // sendAddedUserToEventEmail(eventInfo, uid)
          })
      });


    } else {
      dispatch(startSetEventsList(uid));
      // sendAddedUserToEventEmail(eventInfo, uid)
    }
  }
}

export const addNewEvent = (eventInfo, uid) => {
  console.log('eventInfoeventInfo', eventInfo)
  var batch = db.batch();
  var notificationBatch = db.batch()
  const groupChatId = db
    .collection('users')
    .doc(uid)
    .collection('calendar-events')
    .doc().id;
    console.log('look gcid', groupChatId)
  return (dispatch) => {

    // const availability = async () => {
    //   var unavailGuests = []
    //   if (eventInfo.attendees.length > 0) {
    //     for (var x = 0; x < eventInfo.attendees.length; x++) {
    //       var currentAttendee = eventInfo.attendees[x];
    //       var id
    //       if (currentAttendee.uid) {
    //         id = currentAttendee.uid
    //       } else {
    //         id = currentAttendee.id
    //       }
    //       const checkAvailability = getAttendeeAvailability(currentAttendee, eventInfo, id).then((availability) => {
    //         if (!availability) {
    //           // unavailGuests.push(currentAttendee)
    //           unavailGuests.push(currentAttendee)
    //         }
    //       })
    //     }
    //   }
    //   return unavailGuests
    // }

    // availability().then(function (unavailGuests) {
    //   // this is the only place ydata is full of data
    //   console.log('unavailGuestsunavailGuests', unavailGuests);
    // });





    return db
      .collection('users')
      .doc(uid)
      .get()
      .then((snapshot) => {
        snapshot = snapshot.data();
        snapshot.uid = uid;
        eventInfo.attendees.push(snapshot);
        const user = snapshot
        console.log('look snaop', snapshot)
        return db
          .collection('users')
          .doc(uid)
          .collection('calendar-events')
          .doc(groupChatId)
          .set(
            {
              end: eventInfo.endTime,
              start: eventInfo.startTime,
              title: eventInfo.eventName,
              allDay: false,
              attendees: eventInfo.attendees,
              groupChatId,
              groupChatMessages: [],
              createdByID: uid
            },
            { merge: true }
          )
          .then(() => {
            if (eventInfo.attendees.length > 0) {
              for (var x = 0; x < eventInfo.attendees.length; x++) {
                var currentAttendee = eventInfo.attendees[x];
                // if (currentAttendee.uid !== uid) {
                var id
                if (currentAttendee.uid) {
                  id = currentAttendee.uid
                } else {
                  id = currentAttendee.id
                }

                const userRef = db
                  .collection('users')
                  .doc(id)
                  .collection('calendar-events');
                var attendeeDocRef = userRef.doc(groupChatId);
                batch.set(
                  attendeeDocRef,
                  {
                    end: eventInfo.endTime,
                    start: eventInfo.startTime,
                    title: eventInfo.eventName,
                    allDay: false,
                    attendees: eventInfo.attendees,
                    groupChatId,
                    groupChatMessages: [],
                    createdByID: uid
                  },
                  { merge: true }
                );
                if (id !== uid) {
                  const userNotificationRef = db
                    .collection('users')
                    .doc(id)
                    .collection('notifications');
                  var attendeeNotificationRef = userNotificationRef.doc();
                  notificationBatch.set(attendeeNotificationRef, {
                    type: "New Event Invitation",
                    from: user,
                    attendees: eventInfo.attendees,
                    end: eventInfo.endTime,
                    start: eventInfo.startTime,
                    title: eventInfo.eventName,
                    notificationId: attendeeNotificationRef.id,
                    createdDate: new Date(),
                    status: "New",
                    createdByID: uid
                  })
                }
              }
              batch.commit().then(() => {
                notificationBatch.commit()
                  .then(() => {
                    dispatch(startSetEventsList(uid));
                    sendAddedUserToEventEmail(eventInfo, uid)
                  })
              });






            } else {
              dispatch(startSetEventsList(uid));
              // sendAddedUserToEventEmail(eventInfo, uid)
            }
          });
      });
  };
};

// Get Users for Users Panel
export const startSetEventsList = (uid) => {
  return (dispatch) => {
    var data = [];
    return db
      .collection('users')
      .doc(uid)
      .collection('calendar-events')
      .get()
      .then((snapshot) => {
        console.log('startsetevents', snapshot);
        if (!snapshot.empty) {
          // Parse data into array like in firebase.js
          snapshot = snapshot.docs;
          snapshot.forEach((doc) => {
            var docData = doc.data();
            console.log('docDatadocDatadocData', docData.createdByID)
            var docObj = {
              id: doc.id,
              title: docData.title ? docData.title : 'N/A',
              start: docData.start
                ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm')
                : new Date(),
              end: docData.end
                ? moment(docData.end.toDate()).format('MM-DD-YYYY HH:mm')
                : new Date(),
              attendees: docData.attendees,
              groupChatId: docData.groupChatId,
              createdByID: docData.createdByID
            };
            data.push(docObj);
          });
        }
        console.log('dataaaaaaaax', data);
        dispatch(setEventsList(data));
      });
  };
};

// Get Users for Users Panel
export const startGetCurrentAdvisor = (id) => {
  return (dispatch) => {
    db.collection('users')
      .doc(id)
      .get()
      .then(
        (snapshot) => {
          var docData = snapshot.data();
          if (docData) {
            var docObj = {
              id: snapshot.id,
              firstName: docData.firstName ? docData.firstName : 'N/A',
              lastName: docData.lastName ? docData.lastName : 'N/A',
              investmentType: docData.investmentType
                ? docData.investmentType
                : 'N/A',
              city: docData.city ? docData.city : 'N/A',
              state: docData.state ? docData.state : 'N/A',
              experience: docData.experience ? docData.experience : 'N/A',
              profileURL: docData.profileURL ? docData.profileURL : 'N/A',
            };
            return docObj;
          } else {
            return null;
          }
        },
        (error) => {
          console.log('error fetching data: ', error);
        }
      )
      .then((data) => {
        dispatch(getCurrentAdvisor(data));
      });
  };
};

export const setEventsList = (data) => ({
  type: 'SET_EVENTS_LIST',
  data,
});

export const getCurrentAdvisor = (dataRows) => ({
  type: 'GET_CURRENT_ADVISOR',
  dataRows,
});

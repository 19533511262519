import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import TextField from '@material-ui/core/TextField';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const familySelects = ['Father', 'Mother', 'Child', 'Sister', 'Brother', 'Other']

const contactSelects = ['Lawyer', 'Friend', 'Coworker', 'Other']

const typeSelects = ['Family', 'Contact']

export const FamilyForm = (props) => {

  const { classes, firstName, lastName, type, relation, address, index, familyData, setFamilyData } = props;
  console.log('typeee', type)

  const handleUpdate = (e, value) => {
    let data = familyData
    data[index][value] = e.target.value
    setFamilyData([...data])
  }

  return (
    <GridContainer className={classes.familyFormContainer}>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              First Name
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'firstName')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Last Name
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'lastName')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>


      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Contact Type
            </InputLabel>
            <Select
              className={classes.inputColor}
              disableUnderline
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              required
              onChange={(e) => handleUpdate(e, 'type')}
              inputProps={{
                id: 'investmentType',
                name: 'investmentType',
              }}
              InputProps={{ disableUnderline: true }}
            >
              {typeSelects.map((item) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={item}
                  key={item}
                >{item}</MenuItem>
              ))
              }
            </Select>
          </FormControl>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>

          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Relation
            </InputLabel>
            <Select
              className={classes.inputColor}
              disableUnderline
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              disabled={type ? false : true}
              onChange={(e) => handleUpdate(e, 'relation')}
              inputProps={{
                id: 'investmentType',
                name: 'investmentType',
              }}
            >
              {type === 'Family' ? familySelects.map((item) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={item}
                  key={item}
                >{item}</MenuItem>
              )) : contactSelects.map((item) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={item}
                  key={item}
                >{item}</MenuItem>
              ))
              }


            </Select>
          </FormControl>
        </Card>
      </GridItem>



      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Phone Number
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'phoneNumber')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>


      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Email
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'email')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>



      <GridItem xs={12}>
        <hr style={{ borderTop: '1px solid black', margin: "0px auto", marginTop: "40px", width: "75%" }} />
      </GridItem>
    </GridContainer >
  );
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(FamilyForm);
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";


const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class SixthQuestion extends Component {

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.sixthQuestion !== 0) {
            this.props.nextStep();
        }
    }

    render() {
        const { values, handleToggle, classes } = this.props;
        return (
            <div className={classes.financialKnowledgeQuizContainer}>
                <h4 className={classes.questionHeader}>Financial Knowledge Assessment</h4>
                <p className={classes.questionDescription}>Suppose you owe $1,000 on a loan and the interest rate you are charged is 20% per year compounded annually. If you didn't pay anything off, at this interest rate, how many years would it take for the amount you owe to double?</p>
                <GridContainer className={classes.checkboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={<ThemeProvider theme={theme}>
                                <Checkbox
                                    onClick={() => handleToggle('sixthQuestion', 'incorrect1')}
                                    defaultValue={values.sixthQuestion}
                                    checked={values.sixthQuestion === 'incorrect1'}
                                /></ThemeProvider>}
                            label='Less than 2 years'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('sixthQuestion', 'correct')}
                                    defaultValue={values.sixthQuestion}
                                    checked={values.sixthQuestion === 'correct'}
                                />}
                            label='2 to 4 years'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('sixthQuestion', 'incorrect2')}
                                    defaultValue={values.sixthQuestion}
                                    checked={values.sixthQuestion === 'incorrect2'}
                                />}
                            label='5 to 9 years'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('sixthQuestion', 'incorrect3')}
                                    defaultValue={values.sixthQuestion}
                                    checked={values.sixthQuestion === 'incorrect3'}
                                />}
                            label='10 or more years'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>

                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>5 / 6</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>



                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        );
    }
}

export default withRouter(withStyles(Style)(SixthQuestion));
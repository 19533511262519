import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { useHistory } from 'react-router';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';
import ChartistGraph from "react-chartist";
import { withRouter } from "react-router-dom";


const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        visibility: "hidden !important",
        color: "#74EAE3",
        '&$checked': {
          color: "#74EAE3",
        },
      },
    },
  },
});
export class NinthQuestionGlobalResults extends Component {



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.communicationEffectiveness !== 0) {
      this.props.history.push('/wealth-management-results', {
        state: {
          values,
        }
      });
    }
  }


  render() {
    const { values, handleToggle, classes, globalTestResults, compare } = this.props;

    const usersSelection = values[compare]
    const MaxNumber = Math.max.apply(Math, Object.values(globalTestResults[compare]))
    const choseLikeOthers = globalTestResults[compare][usersSelection] === MaxNumber
    console.log('MaxNumber', choseLikeOthers)
    const totalVotes = Object.values(globalTestResults[compare]).reduce((a, b) => a + b, 0)

    console.log('totalVotes', choseLikeOthers)
    const pieChart = {
      data: {
        labels: [`${Math.floor(globalTestResults[compare][1] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][2] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][3] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][4] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][5] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][6] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][7] / totalVotes * 100)}%`,],


        series: [Math.floor(globalTestResults[compare][1] / totalVotes * 100), Math.floor(globalTestResults[compare][2] / totalVotes * 100), Math.floor(globalTestResults[compare][3] / totalVotes * 100), Math.floor(globalTestResults[compare][4] / totalVotes * 100), Math.floor(globalTestResults[compare][5] / totalVotes * 100), Math.floor(globalTestResults[compare][6] / totalVotes * 100), Math.floor(globalTestResults[compare][7] / totalVotes * 100),]
      },
      options: {
        height: "230px"
      }
    };

    return (
      <div className={classes.quizGlobalResults127vh}>

        <span style={{
          fontFamily: "Nova", fontStyle: 'normal', fontWeight: 400,
          fontSize: '27px', color: '#4F4F4F', width: "100%"
        }}>Did You Know?</span>

        {choseLikeOthers ? (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}>Most investors agree with you about this question!</span>
        ) : (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}>This is how other investors voted.</span>
        )}
        <ChartistGraph
          data={pieChart.data}
          type="Pie"
          options={pieChart.options}
        />
        <h4 className={classes.questionHeader}>My advisor notifies me in a timely manner regarding investment decisions.</h4>
        <GridContainer className={classes.wealthManagementCheckboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    defaultChecked={usersSelection === 1 ? true : false}
                    sx={{
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#74EAE3"
                      }
                    }}
                  />
                </ThemeProvider>}
              label={`Disagree strongly (${Math.floor(globalTestResults[compare][1] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#00bcd4" }}
            />

          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 2 ? true : false}
                />}
              label={`Disagree moderately (${Math.floor(globalTestResults[compare][2] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#f05b4f" }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 3 ? true : false}
                />}
              label={`Disagree a little (${Math.floor(globalTestResults[compare][3] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#f4c63d" }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 4 ? true : false}
                />}
              label={`Neither agree nor disagree (${Math.floor(globalTestResults[compare][4] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: '#d17905' }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 5 ? true : false}
                />}
              label={`Agree a little (${Math.floor(globalTestResults[compare][5] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#453d3f" }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 6 ? true : false}
                />}
              label={`Agree moderately (${Math.floor(globalTestResults[compare][6] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#59922b" }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 7 ? true : false}
                />}
              label={`Agree strongly (${Math.floor(globalTestResults[compare][7] / totalVotes * 100)}%)`}
              className={classes.checkboxText}
              style={{ color: "#0544d3" }}
            />
          </GridItem>
        </GridContainer>


        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft
                  onClick={this.back}
                  className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>9 / 9</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>

        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={33}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(Style)(NinthQuestionGlobalResults));
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

import PropTypes from "prop-types";

import axios from 'axios'
import firebase, { db, storage } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx";

import { investmentTypes } from "variables/general";

class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      continue2: false,
      continueForm: false
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid, name) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid,
      name: name
    })
  }
  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    console.log(this.state)
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        imageChanged: true
      });
    };
    reader.readAsDataURL(file);
  }


  handleSubmit(id, fileType) {
    const pic = this.state.file
    // create storage ref
    var storageRef = storage.ref('documents/' + this.props.uid + pic.name)
    // upload file
    var uploadTask = storageRef.put(pic)
    // update progress bar
    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          this.setState({ uploadProgress: ' Upload is paused' });
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          this.setState({ uploadProgress: 'Upload is running' });
          break;
      }
    }, (error) => {
      // Handle unsuccessful uploads
      console.log(error)
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({ uploadProgress: 'Upload finished. Updating Firestore' });

        db.collection("users").doc(this.props.authUser.uid).collection("investments").doc(this.props.id).set({
          document: downloadURL,
        }, { merge: true }).then(() => {
          window.location.reload()
        })
      });
    })

    // e.preventDefault();
    // update firestore document with PENDING and comment
    // db.collection("users").doc(this.props.authUser.uid).collection("investments").doc().set({
    //   uid: this.props.authUser.uid,
    //   investmentType: this.state.investmentType,
    //   serviceNeeded: this.state.serviceNeeded,
    //   summary: this.state.summary,
    //   createdDate: new Date()
    // }, { merge: true }).then(() => {
    //   console.log(doc.id)
    //   this.setState({
    //     comment: '',
    //     messageSent: true,
    //     continue2: true
    //   })
    // }).catch((err) => {
    //   console.log(`${err}`)
    // })
  }

  resetRequest = () => {
    // window.location.reload();
  }

  continueForm = () => {

    this.setState({
      continueForm: true,
    })

  }



  render() {
    const { classes, modalClose, authUser, user, blogID } = this.props;
    console.log(user)

    if (this.state.messageSent && (window.location.pathname == "/create-investment")) {
      return <Redirect to='/legal-disclaimer' />
    }

    // reset modal when closed
    // if (this.state.messageSent) { this.resetRequest() }
    const states = ["Alabama (AL)", "Alaska (AK)", "Arizona (AZ)", "Arkansas (AR)", "California (CA)", "Colorado (CO)", "Connecticut (CT)", "Delaware (DE)", "District Of Columbia (DC)", "Florida (FL)", "Georgia (GA)", "Hawaii (HI)", "Idaho (ID)", "Illinois (IL)", "Indiana (IN)", "Iowa (IA)", "Kansas (KS)", "Kentucky (KY)", "Louisiana (LA)", "Maine (ME)", "Maryland (MD)", "Massachusetts (MA)", "Michigan (MI)", "Minnesota (MN)", "Mississippi (MS)", "Missouri (MO)", "Montana (MT)", "Nebraska (NE)", "Nevada (NV)", "New Hampshire (NH)", "New Jersey (NJ)", "New Mexico (NM)", "New York (NY)", "North Carolina (NC)", "North Dakota (ND)", "Ohio (OH)", "Oklahoma (OK)", "Oregon (OR)", "Pennsylvania (PA)", "Rhode Island (RI)", "South Carolina (SC)", "South Dakota (SD)", "Tennessee (TN)", "Texas (TX)", "Utah (UT)", "Vermont (VT)", "Virginia (VA)", "Washington (WA)", "West Virginia (WV)", "Wisconsin (WI)", "Wyoming (WY)"]
    const serviceTypes = ['Document Preparation', 'Representation']



    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 style={{ margin: "10px 10px 10px 10px", textAlign: "center" }} className={classes.textPrimary}>Investment Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}
            >

              <GridContainer justify="center">
                <GridItem xs={11}>
                  <h5 className={classes.bodyText} style={{ textAlign: "center", fontSize: "26px", fontFamily: "Raleway" }}><b>Investment Information</b></h5>
                  {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                  {/* <GridContainer style={{ marginTop: "20px" }}>
                      <GridItem xs={5}>
                        <p style={{ marginTop: "25px" }}>Legal Investment State:</p>
                      </GridItem>
                      <GridItem xs={7}>
                        <Card style={{ padding: "0px 20px 10px 20px", margin: "0" }}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="InvestmentState"
                              className={classes.selectLabel}>
                              State
             </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              required
                              value={this.state.InvestmentState}
                              onChange={this.handleSelectChange}
                              inputProps={{
                                id: 'InvestmentState',
                                name: 'InvestmentState',
                              }}
                            >
                              {
                                states.map((item) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                    value={item}
                                    key={item}
                                  >{item}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Card>
                      </GridItem>
                    </GridContainer> */}

                  {/* <CustomInput
                      labelText="Investment Type"
                      id="investmentType"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        type: "text",
                        disableUnderline: true,
                        multiline: false,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                      }}
                    /> */}
                  {/* <GridContainer>
                      <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <Card style={{ padding: "0px 20px 10px 20px", margin: "0" }}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="investmentType"
                              className={classes.selectLabel}>
                              Investment Type
             </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              required
                              value={this.state.investmentType}
                              onChange={this.handleSelectChange}

                              inputProps={{
                                id: 'investmentType',
                                name: 'investmentType',
                              }}
                            >
                              {
                                investmentTypes.map((item) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                    value={item}
                                    key={item}
                                  >{item}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>

                        </Card>
                      </GridItem>
                      <GridItem xs={12} style={{ marginTop: "20px" }}>
                        <Card style={{ padding: "0px 20px 10px 20px", margin: "0" }}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}>
                            <InputLabel
                              htmlFor="serviceType"
                              className={classes.selectLabel}>
                              Service Needed
             </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              required
                              value={this.state.serviceType}
                              onChange={this.handleSelectChange}
                              inputProps={{
                                id: 'serviceType',
                                name: 'serviceType',
                              }}
                            >
                              {
                                serviceTypes.map((item) => (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                    value={item}
                                    key={item}
                                  >{item}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>

                        </Card>
                      </GridItem>

                    </GridContainer>
                    {/* <CustomInput
                      labelText="Service Needed"
                      id="serviceNeeded"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        required: true,
                        type: "text",
                        disableUnderline: true,
                        multiline: false,
                        rows: 1,
                        onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                      }}
                    /> */}
                  {/* <Card style={{ padding: "0px 20px 10px 20px", marginTop: "20px" }}>

                      <CustomInput
                        labelText="Summary"
                        id="summary"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          type: "text",
                          disableUnderline: true,
                          multiline: true,
                          rows: 5,
                          onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                        }}
                      />
                    </Card> */}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 style={{ textAlign: "center" }}><b>Supporting Documents</b></h3>
                  <div className="picture-container">
                    <div className="picture">
                      <img
                        src={this.state.imagePreviewUrl}
                        className="picture-src"
                        alt="..."
                      />
                      <input type="file" onChange={e => this.handleImageChange(e)} />
                    </div>

                    {
                      this.state.file ? (
                        this.state.uploadProgress ? (
                          <p style={{ color: "white" }}>{this.state.uploadProgress}</p>
                        ) : (
                          <div>
                            {window.location.pathname == "/create-investment" ?
                              <Button
                                //type="submit"
                                onClick={() => this.handleSubmit(this.props.id, this.props.fileType)}
                                //color="primary"
                                style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", fontSize: "1rem", float: "right" }}
                              >
                                <b>Continue</b> <ArrowRightAltIcon />
                              </Button>

                              :
                              <Button
                                onClick={() => this.handleSubmit(this.props.id, this.props.fileType)}
                                color="primary"
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "10px"
                                }}
                              ><b>
                                  Submit</b>
                              </Button>
                            }

                          </div>
                          // <Button
                          //   color="primary"
                          //   onClick={() => this.handleSubmit(this.props.id, this.props.fileType)}
                          // >UPDATE</Button>
                        )
                      ) : (
                        <h6 style={{ color: "white" }} className="description">Upload {this.props.fileType}</h6>
                      )}
                  </div>

                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  {window.location.pathname == "/create-investment" && <p style={{ textAlign: "center", textDecoration: "underline" }}><Link to="/legal-disclaimer">Skip for now</Link></p>}
                </GridItem>
              </GridContainer>
              {/* <Button
                  type="submit"
                  color="primary"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px"
                  }}
                >
                  SUBMIT
              </Button> */}
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);
import React from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from '@material-ui/core/FormControl';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Hidden from "@material-ui/core/Hidden";
import Card from 'components/Card/Card.jsx';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import styles from 'assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx';
import server from 'services/ServerService';

// Assets
import precincts from 'variables/precincts.jsx';
import config from 'config';

class Form extends React.Component {
    state = {
        GROUPS: [],
    };

    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            ...this.state,
            ...this.props.user,
            termGroups: this.props.user.termGroups || [],
            county: !!this.props.user.county ? this.props.user.county : 'Other',
            precinct: !!this.props.user.precinct ? this.props.user.precinct : '',
            areasOfInvestment: this.props.user.areasOfInvestment || []
        };
    }

    componentDidMount = () => {
        var inputElement = document.getElementById('phone');
        inputElement.addEventListener('keydown', this.enforceFormat);
        inputElement.addEventListener('keyup', this.formatToPhone);
    };

    componentWillUnmount = () => {
        var inputElement = document.getElementById('phone');
        inputElement.removeEventListener('keydown', this.enforceFormat);
        inputElement.removeEventListener('keyup', this.formatToPhone);
    };

    //Phone number code modified from
    // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript
    isNumericInput = (event) => {
        const key = event.keyCode;
        return (
            (key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (
            event.shiftKey === true ||
            key === 35 ||
            key === 36 || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            // Allow Ctrl/Command + A,C,V,X,Z
            ((event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
        );
    };

    enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
            event.preventDefault();
        }
    };

    formatToPhone = (event) => {
        if (this.isModifierKey(event)) {
            return;
        }

        // I am lazy and don't like to type things more than once
        const target = event.target;
        const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        let targetValue;
        if (input.length > 6) {
            targetValue = `(${zip}) ${middle} - ${last}`;
        } else if (input.length > 3) {
            targetValue = `(${zip}) ${middle}`;
        } else if (input.length > 0) {
            targetValue = `(${zip}`;
        }
        target.value = targetValue;
        this.setState({
            phone: targetValue,
        });
    };

    capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    handleChange = (e) => {
        let value = e.target.value;
        if (e.target.id === 'firstName' || e.target.id === 'lastName') {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }
        console.log(value);
        this.setState({
            [e.target.id]: value,
        });
    };

    handleSelectChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };


    handleSelectChangeMultiple = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        //server.updateUser(this.props.uid, this.state);
        this.props.handleSubmit(this.props.uid, this.state);
    };

    handleToggle(value) {
        const { groups } = this.state;
        const currentIndex = groups.indexOf(value);
        const newGroups = [...groups];

        if (currentIndex === -1) {
            newGroups.push(value);
        } else {
            newGroups.splice(currentIndex, 1);
        }

        this.setState({
            groups: newGroups,
        });
    }

    handleToggleTerms(value) {
        const { termGroups } = this.state;
        const currentIndex = termGroups.indexOf(value);
        const newTermGroups = [...termGroups];

        if (currentIndex === -1) {
            newTermGroups.push(value);
        } else {
            newTermGroups.splice(currentIndex, 1);
        }

        this.setState({
            termGroups: newTermGroups,
        });
    }

    render() {
        const { classes, user } = this.props;

        const counties = [
            'Clark',
            'Cowlitz',
            'Lewis',
            'Pacific',
            'Wahkiakum',
            'Skamania',
            'Klickitat',
            'Other',
        ];

        return (
            <div>
                {/* DESKTOP VIEW */}
                <div className={classes.formContainer}>
                    <form onSubmit={this.handleSubmit}>
                        <GridContainer className={classes.profileFormMainGridContainer} justify="center">
                            <GridItem xs={11}>
                                {this.state.messageError ? (
                                    <h5 className={classes.bodyText}>There Has Been An Error</h5>
                                ) : null}
                                <h5 style={{ marginBottom: "30px" }} className={classes.bodyText}>
                                    <b>{this.props.user.email}</b>
                                </h5>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 0px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="First Name"
                                                id="firstName"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.firstName
                                                        ? this.props.user.firstName
                                                        : '',
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Last Name"
                                                id="lastName"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.lastName
                                                        ? this.props.user.lastName
                                                        : '',
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    {user.userType == 'investor' ? null : (
                                        <GridItem xs={12} sm={6}>
                                            <Card
                                                style={{
                                                    padding: '0px 10px 5px 10px',
                                                    margin: '0px 0px 10px 0px',
                                                }}
                                            >
                                                <CustomInput
                                                    labelText="Firm Name"
                                                    id="firmName"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        required: false,
                                                        type: 'text',
                                                        disableUnderline: true,
                                                        onChange: (event) => this.handleChange(event),
                                                        defaultValue: this.props.user.firmName,
                                                    }}
                                                />
                                            </Card>
                                        </GridItem>
                                    )}

                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Phone Number"
                                                id="phone"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    maxLength: '16',
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.phone,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Title"
                                                id="title"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.title,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    {/* {user.userType == 'investor' ? null : (
                                        <GridItem xs={12} sm={6}>
                                            <Card
                                                style={{
                                                    padding: '0px 10px 5px 10px',
                                                    margin: '0px 0px 10px 0px',
                                                }}
                                            >
                                                <CustomInput
                                                    labelText="Investment Types"
                                                    id="investmentType"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        maxLength: '16',
                                                        type: 'number',
                                                        disableUnderline: true,
                                                        onChange: (event) => this.handleChange(event),
                                                        defaultValue: this.props.user.investmentType,
                                                    }}
                                                />
                                            </Card>
                                        </GridItem>
                                    )} */}
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Firm Address"
                                                id="currentFirm"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.currentFirm,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Address"
                                                id="address"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.address,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="City"
                                                id="city"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.city,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="State"
                                                id="state"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.state,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={3}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Zip Code"
                                                id="zip"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.zip,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    {user.userType == 'investor' ? null : (
                                        <GridItem xs={12} sm={6}>
                                            <Card
                                                style={{
                                                    padding: '0px 10px 5px 10px',
                                                    margin: '0px 0px 10px 0px',
                                                }}
                                            >
                                                <CustomInput
                                                    labelText="Years Experience"
                                                    id="experience"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        type: 'number',
                                                        disableUnderline: true,
                                                        onChange: (event) => this.handleChange(event),
                                                        defaultValue: this.props.user.experience,
                                                        minimumValue: 0
                                                    }}
                                                />
                                            </Card>
                                        </GridItem>
                                    )}
                                    {user.userType == 'investor' ? null : (
                                        <GridItem xs={12} sm={6}>
                                            <Card
                                                style={{
                                                    padding: '0px 10px 5px 10px',
                                                    margin: '0px 0px 10px 0px',
                                                }}
                                            >
                                                <CustomInput
                                                    labelText="CRD Number"
                                                    id="crdNumber"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        type: 'number',
                                                        disableUnderline: true,
                                                        onChange: (event) => this.handleChange(event),
                                                        defaultValue: this.props.user.crdNumber,
                                                    }}
                                                />
                                            </Card>
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} sm={6}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Room ID"
                                                id="roomId"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'text',
                                                    disableUnderline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.roomId,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                    <GridItem xs={12} sm={12}>
                                        <Card
                                            style={{
                                                padding: '0px 10px 5px 10px',
                                                margin: '0px 0px 10px 0px',
                                            }}
                                        >
                                            <CustomInput
                                                labelText="Bio"
                                                id="bio"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    type: 'number',
                                                    disableUnderline: true,
                                                    multiline: true,
                                                    onChange: (event) => this.handleChange(event),
                                                    defaultValue: this.props.user.bio,
                                                }}
                                            />
                                        </Card>
                                    </GridItem>

                                    {/*
                <GridItem xs={12}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="userType"
                      className={classes.selectLabel}>
                      User type:
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      style={{color: "black !important"}}
                      value={this.state.userType}
                      onChange={this.handleSelectChange}
                      inputProps={{
                        id: 'userType',
                        name: 'userType'
                      }}
                    >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem
                            }}
                            style={{color: "black"}}
                            value="advertiser"
                            key="advertiser"
                          >Advertiser</MenuItem>
                          <MenuItem
                          classes={{
                            root: classes.selectMenuItem
                          }}
                          style={{color: "black"}}
                          value="investor"
                          key="investor"
                        >Investor</MenuItem>

                        <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        style={{color: "black"}}
                        value="advisor"
                        key="advisor"
                      >Advisor</MenuItem>

                    </Select>
                  </FormControl>
                    </GridItem> */}
                                </GridContainer>

                                {user.userType == 'investor' ? null : (
                                    <FormControl
                                        style={{ marginTop: '20px', backgroundColor: '#fff' }}
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            shrink={true}
                                            htmlFor="areasOfInvestment"
                                            className={classes.selectLabel}
                                        >
                                            Areas of Investment
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            multiple
                                            value={this.state.areasOfInvestment}
                                            onChange={this.handleSelectChangeMultiple}
                                            inputProps={{
                                                id: 'areasOfInvestment',
                                                name: 'areasOfInvestment',
                                            }}
                                        >
                                            {config.areasOfInvestment.map((item) => (
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                    value={item}
                                                    key={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                {/* {user.userType == 'investor' ? null : (
                                    <h5 className={classes.bodyText}>Bar Associations</h5>
                                )} */}
                                {/* <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleToggle('Denver Bar Association')}
                      checked={this.state.groups.indexOf('Denver Bar Association') !== -1 ? true : false}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      color="primary"
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.bodyText }}
                  label='Denver Bar Association'
                />
              </div>
              <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleToggle('Portland Bar Association')}
                      checked={this.state.groups.indexOf('Portland Bar Association') !== -1 ? true : false}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.bodyText }}
                  label='Portland Bar Association'
                />
              </div>
              <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleToggle('Cheyenne Bar Association')}
                      checked={this.state.groups.indexOf('Cheyenne Bar Association') !== -1 ? true : false}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{ label: classes.bodyText }}
                  label='Cheyenne Bar Association'
                />
              </div> */}

                                {/* <h5 className={classes.bodyText}>DOCUMENTS</h5> */}
                                {/* <div
                className={
                  classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                }
              >
                <br />
                <Button
                  style={{ width: '100%', backgroundColor: 'rgb(133, 0, 85)' }}
                  color="primary"
                  //onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/Pioneer%20Commitment%20Document_April%2015%202019.pdf?alt=media&token=fc0a4231-c63c-4e95-a2be-0bc99bba9909', "_blank")}
                >
                  <b>PIONEER COMMITMENT</b>
                </Button>
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.handleToggleTerms('Pioneer Commitment')}
                      checked={
                        this.state.termGroups.indexOf('Pioneer Commitment') !== -1
                          ? true
                          : false
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      color="primary"
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: classes.bodyText }}
                  label="I agree to the terms."
                />
              </div> */}
                                {/* <Button
                                    style={{ width: '100%', backgroundColor: 'rgb(133, 0, 85)' }}
                                    color="primary"
                                //onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/FFPO%20--%20Membership%20T%26C.MARKUP.%204.8.19.pdf?alt=media&token=277eaf75-d380-4ee6-95ba-8c00b6f2dff7', "_blank")}
                                >
                                    <b>TERMS AND CONDITIONS</b>
                                </Button>
                                <br />
                                <br />
                                <div
                                    className={
                                        classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggleTerms('Terms and Conditions')}
                                                checked={
                                                    this.state.termGroups.indexOf('Terms and Conditions') !== -1
                                                        ? true
                                                        : false
                                                }
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                            />
                                        }
                                        classes={{ label: classes.bodyText }}
                                        label="I agree to the terms."
                                    />
                                </div>
                                <br />
                                <div
                                    className={
                                        classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
                                    }
                                >
                                    <Button
                                        style={{ width: '100%', backgroundColor: 'rgb(133, 0, 85)' }}
                                        color="primary"
                                    //onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/FFPO%20--%20Privacy%20Policy.%204.8.19.pdf?alt=media&token=281e3d62-e97f-43fa-afeb-4a311ba56bd9', "_blank")}
                                    >
                                        <b>PRIVACY POLICY</b>
                                    </Button>
                                    <br />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={-1}
                                                onClick={() => this.handleToggleTerms('Privacy Policy')}
                                                checked={
                                                    this.state.termGroups.indexOf('Privacy Policy') !== -1
                                                        ? true
                                                        : false
                                                }
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                            />
                                        }
                                        classes={{ label: classes.bodyText }}
                                        label="I agree to the terms."
                                    />
                                </div> */}
                                <Button
                                    type="submit"
                                    style={{ width: '100%', backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)" }}

                                >
                                    <b>Save Changes</b>
                                </Button>
                            </GridItem>
                        </GridContainer>

                    </form>
                </div>
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);

const videoLayoutStyle = (theme) => ({
    container: {
        display: 'flex',
        height: 'inherit',

    },
    videoLayoutContainer: {
        display: 'flex',
        flexFlow: 'row',
        flexWrap: 'wrap',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'


    }
})

export default videoLayoutStyle;
import LoginPage from "views/Pages/LoginPage.jsx";
import Usertype from "views/Pages/Usertype.jsx";
import SignupPage from "../views/Pages/SignupPage";
import FinancialAptitude from '../views/Assess/FinancialAptitude.jsx';
import Setup from '../views/Setup/Setup.jsx';
import PublicQuizPage from "../views/Pages/PublicQuizPage";

import FiveFactorModel from '../views/Pages/FiveFactorModel/FiveFactorModel.jsx';
import Results from '../views/Pages/FiveFactorModel/Results.jsx';
import FiveFactorResults from '../views/Pages/FiveFactorModel/Confirm.jsx'
import FiveFactorSuccess from '../views/Pages/FiveFactorModel/Success.jsx'
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";

const pagesRoutes = [
  // {
  //   path: "/pages/signup-page",
  //   name: "Signup",
  //   short: "Signup",
  //   mini: "HM",
  //   icon: PersonAdd,
  //   visible: true,
  //   component: SignupPage
  // },
  {
    path: '/pages/signup-page',
    admin: true,
    name: 'wealth management',
    component: SignupPage,
  },
  {
    path: "/pages/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    visible: true,
    component: LoginPage
  },
  {
    path: "/pages/quizpage",
    name: "Quiz Page",
    short: "Quiz",
    mini: "HM",
    icon: Fingerprint,
    visible: true,
    component: PublicQuizPage
  },

  {
    path: "/pages/usertype",
    name: "UserType",
    short: "UserType",
    mini: "LP",
    icon: Fingerprint,
    component: Usertype
  },
  {
    path: "/pages/setup",
    name: "Setup",
    short: "Setup",
    mini: "LP",
    icon: Fingerprint,
    component: Setup
  },
  {
    path: '/pages/five-factor-success',
    admin: true,
    name: 'wealth management',
    component: FiveFactorSuccess,
  },
  {
    path: '/pages/five-factor-results',
    admin: true,
    name: 'wealth management',
    component: FiveFactorResults,
  },
  {
    path: '/pages/five-factor',
    admin: true,
    name: 'five factor model',
    component: FiveFactorModel,
  },
  {
    path: '/pages/results/five-factor/:docID',
    admin: true,
    // name: 'five factor model',
    component: Results,
  }
  // {
  //   path: "/pages/financial-aptitude",
  //   icon: Fingerprint,
  //   component: FinancialAptitude
  // },
];

export default pagesRoutes;

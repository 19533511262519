import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { db, firebase } from '../../firebase/fbConfig.js';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ChartistGraph from "react-chartist";
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import moment from "moment";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useHistory } from 'react-router';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Check from '@material-ui/icons/Check';
import loader from 'assets/img/loader.gif';

// card images
import fiveFactor from "assets/img/wealth-env/five-factor.png";
import wealthManagement from "assets/img/wealth-env/wealth-management.png";
import demographics from "assets/img/wealth-env/demographics.png";
import financialKnowledge from "assets/img/wealth-env/financial-knowledge.png";
import preferences from "assets/img/wealth-env/preferences.png";
import riskProfiling from "assets/img/wealth-env/risk-profiling.png";

import Slider from "@material-ui/core/Slider";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Tooltip, IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as QuizEnvTitle } from 'assets/img/quizEnvTitle.svg';
import { ReactComponent as QuestionMark } from 'assets/img/questionMark.svg';


const incompleteMuiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#FFFF",
            },
            track: {
                color: "#FFFF",
                height: "15px !important",
            },
            rail: {
                color: "#75E8E2 ",
                height: "15px !important",
            }
        }
    }
});

const completedMuiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#FFFF",
            },
            track: {
                color: "#FFFF",
                height: "15px !important",
            },
            rail: {
                color: "#4672FB",
                height: "5px !important",
            }
        }
    }
});

const quizzesMarks = [
    {
        value: 0,
        label: 'Started',
    },
    {
        value: 100,
        label: 'Complete',
    },
];

export function QuizzesPage(props) {


    //  TODO Conditional button rendering to retake quiz if the user has already taken the quiz, currently overrides firestore collection
    const { classes } = props;
    const [factorQuizList, setFactorQuizList] = useState([])
    const [fianancialQuizList, setFinancialQuizList] = useState([])
    const [demoQuizList, setDemoQuizList] = useState([])
    const [riskQuizList, setRiskQuizList] = useState([])
    const [preferencesQuizList, setPreferencesQuizList] = useState([])
    const [quizzesTotal, setQuizzesTotal] = useState(0)
    const [quizDataLoaded, setQuizDataLoaded] = useState(false)
    const [riskToleranceQuizList, setRiskToleranceQuizList] = useState([])
    const [wealthQuizList, setWealthQuizList] = useState([])


    const history = useHistory();

    const quizNames = [{ name: 'Financial Knowledge', link: 'financial-knowledge', data: [...fianancialQuizList] }, { name: 'Demographics', link: 'demographics', data: [...demoQuizList] }, { name: 'Wealth Management', link: 'wealth-management', data: [...wealthQuizList] }, { name: 'TIPI Five Factor Personality', link: 'five-factor', data: [...factorQuizList] }, { name: 'Risk Tolerance', link: 'risk-tolerance', data: [...riskToleranceQuizList] }]
    // const docRef = db.collection('users').doc(props.user.id)

    function getQuizList() {
        firebase.auth().onAuthStateChanged(function (user) {
            console.log('xxxx', user)
            if (user) {
                let quizTotal = 0
                db.collection('users').doc(user.uid).collection('quizzes').doc('riskTolerance').collection('quizLists')
                    .orderBy('timeStamp', 'desc')
                    .limit(5)
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        })
                        if (items.length > 0) {
                            quizTotal++
                        }
                        console.log("items: ", items)
                        setRiskToleranceQuizList(items);
                        setQuizzesTotal(quizTotal)
                    });
                // 5 Factor Quiz
                db.collection('users').doc(user.uid).collection('quizzes').doc('fiveFactorQuiz').collection('quizLists')
                    .orderBy('timeStamp', 'desc')
                    .limit(5)
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        })
                        if (items.length > 0) {
                            quizTotal++
                        }
                        console.log("items: ", items)
                        setFactorQuizList(items);
                        setQuizzesTotal(quizTotal)
                    });

                // // Wealth Management Style Quiz
                db.collection('users').doc(user.uid).collection('quizzes').doc('wealthManagement').collection('quizLists')
                    .orderBy('timeStamp', 'desc')
                    .limit(5)
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        })
                        if (items.length > 0) {
                            quizTotal++
                        }
                        console.log("items: ", items)
                        setWealthQuizList(items);
                        setQuizzesTotal(quizTotal)
                    });

                // Finanacial Knowledge Quiz
                db.collection('users').doc(user.uid).collection('quizzes').doc('financialKnowledge').collection('quizLists')
                    .orderBy('timeStamp', 'desc')
                    .limit(5)
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        })
                        if (items.length > 0) {
                            quizTotal++
                        }
                        console.log("items: ", items)
                        setFinancialQuizList(items);
                        setQuizzesTotal(quizTotal)
                    });

                // Demographics Quiz
                db.collection('users').doc(user.uid).collection('quizzes').doc('demographics').collection('quizLists')
                    .orderBy('timeStamp', 'desc')
                    .limit(5)
                    .onSnapshot((querySnapshot) => {
                        const items = [];
                        querySnapshot.forEach((doc) => {
                            items.push(doc.data())
                        })
                        if (items.length > 0) {
                            quizTotal++
                        }
                        console.log("items: ", items)
                        setDemoQuizList(items);
                        setQuizzesTotal(quizTotal)
                        setQuizDataLoaded(true)
                    });
                // Risk Profiling Quiz
                // db.collection('users').doc(user.uid).collection('quizzes').doc('riskProfile').collection('quizLists')
                //     .orderBy('timeStamp', 'desc')
                //     .limit(5)
                //     .onSnapshot((querySnapshot) => {
                //         const items = [];
                //         querySnapshot.forEach((doc) => {
                //             items.push(doc.data())
                //         })
                //         if (items.length > 0) {
                //             quizTotal++
                //         }
                //         setRiskQuizList(items);
                //         setQuizzesTotal(quizTotal)
                //         setQuizDataLoaded(true)
                //     });
            }
            else {
                console.log("inside error")
            }
        });
    }

    useEffect(() => {
        getQuizList();
    }, []);

    console.log('quizzesTotal', quizzesTotal)

    if (!quizDataLoaded) {
        return (
            <div
                style={{
                    backgroundColor: '#F4F6F8',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                }}
            >
                <img
                    style={{
                        margin: '20% 0% 0% 38%',
                        position: 'relative',
                        width: '20%',
                    }}
                    src={loader}
                    alt="..."
                />
            </div>
        )
    }


    return (
        <div className={classes.container}>
            {/* Mobile View  */}
            <Hidden mdUp implementation="css">
                <QuizEnvTitle className={classes.quizEnvTitle} />
                <span className={classes.startLearning}>Let's Start Learning</span>
                <GridContainer className={classes.quizTotalContainer}>
                    <GridItem className={classes.quizTotalGridItem} xs={12}>
                        <div className={classes.graphDiv}>
                            <span>{quizzesTotal} / 5</span>
                            <span className={classes.completedText}>Completed</span>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.quizzesContainer}>
                    <GridItem xs={12}>
                        {quizNames.map((d) => {
                            return (
                                <Card className={classes.cardForQuiz}>
                                    <GridContainer className={classes.cardForQuizContainer}>
                                        <GridItem xs={8}>
                                            <div style={{ display: "flex", flexDirection: 'column' }}>
                                                {d.data.length > 0 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: "75%" }}>{d.name}</span>
                                                        <Check />
                                                    </div>
                                                ) : (
                                                    <span style={{ fontSize: "75%" }}>{d.name}</span>
                                                )}
                                                <ThemeProvider theme={completedMuiTheme}>
                                                    <Slider
                                                        className={classes.slider}
                                                        aria-label="Restricted values"
                                                        step={null}
                                                        valueLabelDisplay="auto"
                                                        disabled={true}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Button onClick={() => history.push(`/${d.link}`)} className={classes.buttonToQuiz}>
                                                <ArrowRightAltIcon />
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <span className={classes.learnMore}>Learn More    <QuestionMark /></span>

                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            )
                        })}
                    </GridItem>
                </GridContainer>


                {/* <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={fiveFactor} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>TIPI 5 Factor Personality Quiz</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {factorQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            factorQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/five-factor/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Our proprietary personality inventory will analyze different factors of your personality to help us build your profile and better match you with advisors, content, and more!</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>10 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/five-factor'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={wealthManagement} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Wealth Management Style</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {wealthQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            wealthQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/wealth-management/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Let's take a moment to understand your investment styles with this quiz!</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>10 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/wealth-management'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={financialKnowledge} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Financial Knowledge Quiz</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {fianancialQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            fianancialQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/financial-knowledge/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Whether you are a financial whiz or a beginner, take this quiz to help us understand your current level of financial literacy.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>6 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/financial-knowledge'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={demographics} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Demographics</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {demoQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            demoQuizList.map((quiz, index) => (
                                                <>
                                                    <CardBody style={{ fontSize: "16px" }}>
                                                        {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                    </CardBody>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Take a quiz that helps us understand your demographics to better influence suggestions on potential advisors you can partner with and investments that are popular among your peers.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>20 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/demographics'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card >
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={preferences} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Preferences</h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Let us know what you prefer when it comes to meeting advisors, whether that be in person or online, along with what types of strategies you've experienced with investing in the past.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>20 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/preferences'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={riskProfiling} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Risk Profiling</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {riskQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            riskQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/risk-profile/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Assess and dictate your level of risk adversity when it comes to your investments, helping us to match you with advisors that are aligned with your expectations of how to manage risk.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>6 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/risk-profile'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card> */}
            </Hidden>









            {/* Desktop View  */}
            <Hidden smDown implementation="css">
                <h3 className={classes.header}>All Quizzes</h3>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={fiveFactor} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>TIPI 5 Factor Personality Quiz</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {factorQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            factorQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/five-factor/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Our proprietary personality inventory will analyze different factors of your personality to help us build your profile and better match you with advisors, content, and more!</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>10 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/five-factor'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={wealthManagement} className={classes.cardImage} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Let's take a moment to understand your investment styles with this quiz!</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>10 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/wealth-management'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={financialKnowledge} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Financial Knowledge Quiz</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {fianancialQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            fianancialQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/financial-knowledge/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Whether you are a financial whiz or a beginner, take this quiz to help us understand your current level of financial literacy.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>6 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/financial-knowledge'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={demographics} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Demographics</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {demoQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            demoQuizList.map((quiz, index) => (
                                                <>
                                                    <CardBody style={{ fontSize: "16px" }}>
                                                        {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                    </CardBody>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Take a quiz that helps us understand your demographics to better influence suggestions on potential advisors you can partner with and investments that are popular among your peers.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>20 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/demographics'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card >
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={preferences} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Preferences</h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Let us know what you prefer when it comes to meeting advisors, whether that be in person or online, along with what types of strategies you've experienced with investing in the past.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>20 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/preferences'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
                <Card className={classes.quizCard}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <Tooltip
                                disableFocusListener
                                disableTouchListener
                                id="tooltip-top"
                                title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                placement="top-start"
                                classes={{ tooltip: classes.tooltip }}>
                                <IconButton>
                                    <Info style={{ color: "#60D5C4" }} />
                                </IconButton>
                            </Tooltip>
                        </GridItem>
                        <GridItem xs={6}>
                            <img src={riskProfiling} className={classes.cardImage} />
                        </GridItem>
                        <GridItem xs={6}>
                            <h5 className={classes.quizHeader}>Risk Profiling</h5>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Card>
                                        <CardHeader>
                                            <h5 style={{ fontSize: "20px", fontWeight: "600" }}> Most Recent Quizzes Taken</h5>
                                        </CardHeader>
                                        {riskQuizList.length === 0 ?
                                            <div>
                                                No Quizzes Taken
                                            </div>
                                            :
                                            riskQuizList.map((quiz, index) => (
                                                <>
                                                    <NavLink to={`/results/risk-profile/${quiz.quizID}`} style={{ color: "black" }}>
                                                        <CardBody style={{ fontSize: "16px" }}>
                                                            {index + 1}. {quiz.quizID}: {quiz.dateTaken}
                                                        </CardBody>
                                                    </NavLink>
                                                </>
                                            ))}

                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12}>
                            <p className={classes.quizDescription}>Assess and dictate your level of risk adversity when it comes to your investments, helping us to match you with advisors that are aligned with your expectations of how to manage risk.</p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                            <LiveHelpIcon style={{ color: "#315B7B" }} />
                            <p style={{ fontWeight: 600 }}>6 questions</p>
                        </GridItem>
                        <GridItem className={classes.startQuiz} xs={6}>
                            <NavLink to={'/risk-profile'}>
                                <Button className={classes.takeQuizButton}>
                                    Start Quiz
                                </Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Card>
            </Hidden>

        </div >
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    }
    // nothing yet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(QuizzesPage);

const advisorPageStyle = (theme) => ({
  container: {
      color: "#000 !important",
      padding: "15px",
      [theme.breakpoints.up('md')]: {
          minHeight: "1000px !important",
          padding: "55px",
          color: "white",
          border: "1px solid white",
          borderRadius: "15px",
          boxShadow: "0px 4px 9px grey",
          margin: "50px auto",
          width: "90%",
          background: "white"
      }
  },
  section: {
      padding: "70px 0"
  },
  // container,
  marginAuto: {
      marginLeft: "auto !important",
      marginRight: "auto !important"
  },
  favoritedSection: {
      backgroundColor: "#e5e5e5",
      margin: "0px auto",
      width: "calc(100% + 0px)"

  },
  recommendationsHeader: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0 0 30px -15px"
  },
  recommendations: {
      [theme.breakpoints.up('md')]: {
          fontSize: "25px"
      }
  },
  matchesTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: "20px"
  },
  matchesCard: {
      boxShadow: "0px 1px 6px grey",
      padding: "20px",
      [theme.breakpoints.up('md')]: {
          padding: "0px"
      }
  },
  meetAdvisors: {
      [theme.breakpoints.up('md')]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
      }
  },
  header: {
      margin: "50px 0 30px 14px",
      [theme.breakpoints.up('md')]: {
          fontSize: "25px"
      }
  },
  description: {
      margin: "0 0 30px 0",
      [theme.breakpoints.up('md')]: {
          fontSize: "20px"
      }
  },
  meetButton: {
      color: "#fff",
      backgroundColor: "#4775FB",
      margin: "0 0 0 -20px",
      fontSize: "13px",
      borderRadius: "5px"
  },
  chatButton: {
      color: "#fff",
      backgroundColor: "#4775FB",
      fontSize: "13px",
      borderRadius: "5px",
      margin: "14px 0px 0 0px",
      width: "100px",
      [theme.breakpoints.down('md')]: {
          width: "80px",
      }
  },
  name: {
      fontSize: "30px",
      [theme.breakpoints.down('md')]: {
          fontSize: "15px"
      }
  },
  searchBoxContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up('md')]: {
          padding: "0px 28px",
          display: "block",
      }
  },
  searchBox: {
      margin: "0",
      padding: "0px !important",
      [theme.breakpoints.up('md')]: {
          margin: "0",
          padding: "20px"
      }
  },
  favoritedContainer: {
      marginBottom: "20px",
      [theme.breakpoints.up('md')]: {
          marginBottom: "0px",
          padding: "20px",
      }
  },
  favoritedRow: {
      [theme.breakpoints.up('md')]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
      }
  },
  favoritedRowName: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.up('md')]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
      }
  },
  favoritedPic: {
      height: "64px",
      width: "64px"
  },
  favoritedDescription: {
      display: "block",
      margin: "auto 0px auto -24px"
  },
  recommendationsDescription: {
      margin: "0 0 0 0"
  },
  recommendationsContainer: {
      display: "flex",
      justifyContent: "space-between",
  },
  profileButton: {
      backgroundColor: "#6c6b6b",
      width: "67%",
      height: "54%",
      borderRadius: "7px",
      "&:hover": {
          backgroundColor: "#315B7B"
      },
      [theme.breakpoints.down('sm')]: {
          backgroundColor: "#FFFFFF",
          color: '#274B73',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.15)',
          borderRadius: '40px'
      }
  },
  findMoreButton: {
      width: "75%",
      backgroundColor: "#FFFFFF",
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: '40px',
      display: 'flex',
      justifyContent: "space-evenly",
      margin: "25px auto"
  },
  findMoreText: {
      color: '#274B73',
      fontSize: "16px",
  },
  whiteListButton: {
      backgroundColor: "#6c6b6b",
      width: "67%",
      height: "54%",
      borderRadius: "7px"
  },
  blackListButton: {
      backgroundColor: "#6c6b6b",
      width: "67%",
      height: "54%",
      borderRadius: "7px"
  },
  recommendationsName: {
      margin: "5px 0 0 0"
  },
  matchPercentage: {
      margin: "0 0 0 0"
  },
  recommendationsPic: {
      height: "64px",
      width: "64px",
      borderRadius: "50%",
  },
  messagesPic: {
      height: "64px",
      width: "64px",
      borderRadius: "50%",
  },
  fromName: {
      fontWeight: 700,
      fontSize: '14px'
  },
  messagesContainer: {
      height: '100px',
      display: 'flex',
      alignItems: 'center'
  },
  fromGridItem: {
      display: 'flex',
      flexDirection: 'column'
  },
  messagesGrid: {
      marginTop: "60px",
      padding: "30px"
  },
  recentMessages: {
      fontFamily: "Nova",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '32px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.05em',
      color: '#4672FB',
      marginBottom: "0px"

  },
  buttonToMessage: {
      background: '#436CFC',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: '15px',
      width: "100%"
  },
})

export default advisorPageStyle;
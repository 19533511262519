const notificationsPageStyle = (theme) => {
    return {
        container: {
            border: "1px solid black"
        },
        searchDiv: {
            [theme.breakpoints.up("md")]: {
                width: "100%",
                margin: "0 auto",
                marginTop: "10px",
                display: "flex",
                alignItems: "center"
            }
        },
        closeIcon: {
            "&:hover": {
                cursor: "pointer"

            }
        },
        notificationIcon: {
            fontSize: "40px",
            margin: "0px 20px",
            [theme.breakpoints.up("md")]: {
                fontSize: "50px",
                margin: "0px 40px",
            }
        },
        notificationInfo: {
            display: "flex",
            justifyContent: 'space-between',
            width: "100%",
            alignItems: "center",
            [theme.breakpoints.up("md")]: {
                paddingRight: "30px",
            }
        },
        typeAndFromInfoDiv: {
            display: "flex",
            flexDirection: "column"
        },
        typeAndFromInfo: {
            fontSize: "25px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
            }
        },
        noNotificationsDiv: {
            [theme.breakpoints.up("md")]: {
                height: "300px"
            }
        },
        notificationMessage: {
            color: 'black',
            [theme.breakpoints.up("md")]: {
                fontSize: "25px"
            }
        },
        notificationsGridItem: {
            margin: "100px 30px",
            color: "black !important",
            height: "100vh"
        }
    }
}

export default notificationsPageStyle;
import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { camelToSnakeCase } from '../../util';

export const InputCardField = ({ name, label, onInput }) => (
  <Card style={{ margin: '0px', padding: '5px 20px 10px 20px' }}>
    <CustomInput
      labelText={label || camelToSnakeCase(name)}
      id={name}
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        required: true,
        type: 'text',
        disableUnderline: true,
        onChange: ({ target: { value } }) => onInput(name, value),
      }}
    />
  </Card>
);

InputCardField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onInput: PropTypes.func.isRequired,
};

export default InputCardField;

// ##############################
// // // App styles
// #############################

import {
    drawerWidth,
    drawerMiniWidth,
    transition,
    containerFluid,
    grayColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import profileBackground from 'assets/img/profileBackground.svg'
import addAssetBackground from 'assets/img/addAssetBackground.svg'
import notificationsBackground from 'assets/img/notificationsBackground.svg'
import quizzesBackground from 'assets/img/quizzesBackground.svg'
import shorterQuizzesBackground from 'assets/img/shorterQuizzesBackground.svg'
import quizResultsBackground from 'assets/img/quizResults.svg'
import achievementsBackground from 'assets/img/achievementsBackground.svg'
import matchesBackground from 'assets/img/matchesBackground.svg'
import chatBackground from 'assets/img/chatBackground.svg'
import stocksBackground from 'assets/img/stocksBackground.png'
import IntakeFormBackground from 'assets/img/IntakeForm.svg'
import ConferencingBackground from 'assets/img/conferencingBackground.svg'
import ActivitiesBackground from 'assets/img/activitiesBackground.svg'
console.log('drawerWidth', drawerWidth)
const appStyle = theme => ({
    wrapper: {
        backgroundColor: "#fff !important",
        position: "relative",
        top: "0",
        height: "100vh",
        "&:after": {
            display: "table",
            clear: "both",
            content: '" "'
        }
    },
    intakeFormPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${IntakeFormBackground})`,
            backgroundRepeat: 'no-repeat'
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    activitiesMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${ActivitiesBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    chatMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${chatBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    matchesMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${matchesBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    quizResultsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${quizResultsBackground})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    mainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundSize: 'contain',
            // backgroundImage: `url(${profileBackground})`,
            // backgroundRepeat: 'no-repeat';
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    videoConferenceMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `100%`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundSize: 'contain',
            // backgroundImage: `url(${profileBackground})`,
            // backgroundRepeat: 'no-repeat';
        },
        overflow: 'hidden',
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    profileMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${profileBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    conferenceMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${ConferencingBackground})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    achievementsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${achievementsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    notificationsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${notificationsBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    quizzesMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${quizzesBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    questionsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${shorterQuizzesBackground})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    addGroupMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${addAssetBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    addAssetsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${addAssetBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    assetsMainPanel: {
        background: "#F3F2F0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${profileBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    stocksMainPanel: {
        background: "#F3F2F0",
        padding: "11px",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${stocksBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        },
        overflow: "auto",
        position: "relative",
        float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        margin: "70px 0px 60px 0px",
        // padding: "30px 0px 0px 0px",
        minHeight: "calc(100vh - 123px)"
    },
    container: {
        ...containerFluid,
        overflow: "hidden"
        // [theme.breakpoints.down("md")]: {
        //     overflow: "hidden"
        // }
    },
    map: {
        marginTop: "70px"
    },
    mainPanelSidebarMini: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${drawerMiniWidth}px)`
        }
    },
    mainPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    }
});

export default appStyle;

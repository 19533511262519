import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useParams } from 'react-router-dom'


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/groupMessageThreadStyle';
import ThreadsMap from '../Chat/Threads/ThreadsMap.jsx';
import Threads from '../Chat/Threads/Threads.jsx';

import { addMessageToGroupThread, getMessages, addNewMessageToOneOnOneThread } from 'store/actions/threadsActions';
import { getAttendessAndMessages } from 'store/actions/calendarActions';
import loader from 'assets/img/loader.gif';
import avatar from 'assets/img/default-avatar.png';
import CreateMessageForm from '../Conferencing/CreateMessageForm';

import { ReactComponent as BlueTextBubble } from 'assets/img/blueTextBubble.svg';
import { withRouter } from "react-router-dom";

export function OneOnOneChat(props) {
  const {
    classes,
    addMessageToGroupThread,
    user,
    attendee,
    uid,
    oneOnOneMessages,
    title,
    otherAttendeeInfo,
    history
  } = props;
  const messageId = window.location.pathname.slice(6);
  const [attendeesRetrieved, setAttendeesRetrieved] = useState(false);
  const [textInput, setTextInput] = useState('')
  const [attendees, setAttendees] = useState([])
  const otherUserId = window.location.pathname.slice(6);

  var messagesEnd;

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    if (otherAttendeeInfo) {
      var fullInfo = otherAttendeeInfo
      fullInfo.id = otherUserId
      setAttendees([user, fullInfo])
    }
  }, [otherAttendeeInfo])

  useEffect(() => {
    if (!attendeesRetrieved && uid) {
      props.getMessages(uid, messageId);
      setAttendeesRetrieved(true);
    }
  }, [uid]);


  const handleIntakeFormCardClick = (message) => {
    if (message.message === 'Welcome aboard! You can now fill out the Intake Form by clicking this message')
      history.push(`/intake-form/${message.messageSentFrom.id}`)
  }

  return (
    <div className={classes.container}>
      {/* <Card className={classes.containerCard}>
        {otherAttendeeInfo ? <h1 className={classes.header}>{`Chat With ${otherAttendeeInfo.firstName} ${otherAttendeeInfo.lastName}`}</h1> : null}

        <CardBody className={classes.cardBody}> */}
      {oneOnOneMessages ? (
        oneOnOneMessages.map((message, index) => {
          // sometimes current user id property is under id and sometimes it is under uid
          console.log('checking message', message);
          if (message.intakeForm) {
            return (
              <div onClick={() => handleIntakeFormCardClick(message)} className={classes.messageDiv}>
                <div style={{ background: "#72C5C8", color: "white" }} className={classes.userTextBubble}>{message.message}</div>
              </div>
            );
          }
          if (message.messageSentFrom.id && message.messageSentFrom.id === uid) {
            return (
              <div className={classes.messageDiv}>
                <div className={classes.userTextBubble}>{message.message}</div>
                {/* <div className={classes.userPicAndInfo}>
                  <img
                    className={classes.recommendationsPic}
                    src={message.messageSentFrom.profileURL || avatar}
                  />
                  <p className={classes.nameOfSender}>You</p>
                </div> */}
              </div>
            );
          }
          if (
            message.messageSentFrom.uid &&
            message.messageSentFrom.uid === uid
          ) {
            return (
              <div className={classes.messageDiv}>
                <div className={classes.userTextBubble}>{message.message}</div>
                {/* <div className={classes.userPicAndInfo}>
                  <img
                    className={classes.recommendationsPic}
                    src={message.messageSentFrom.profileURL || avatar}
                  />
                  <p className={classes.nameOfSender}>You</p>
                </div> */}
              </div>
            );
          }
          return (
            <div className={classes.messageDiv}>
              <div className={classes.otherSenderPicAndInfo}>
                <img
                  className={classes.recommendationsPic}
                  src={message.messageSentFrom.profileURL || avatar}
                />
                {/* <p
                  className={classes.nameOfSender}
                >{`${message.messageSentFrom.firstName} ${message.messageSentFrom.lastName}`}</p> */}
              </div>
              <div className={classes.otherSenderTextBubble}>
                {message.message}
              </div>
            </div>
          );
        })
      ) : (
        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
          <img
            style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
            src={loader}
            alt="..."
          />
        </div>
      )}
      <div
        style={{ float: 'left', clear: 'both' }}
        ref={(el) => {
          messagesEnd = el;
        }}
      ></div>
      {/* </CardBody>
      </Card> */}
      <CreateMessageForm oneOnOneChat={true} oneOnOneChatAttendees={attendees} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getMessages: (uid, messageId) => dispatch(getMessages(uid, messageId)),
  addNewMessageToOneOnOneThread: (message, uid, messageId) => dispatch(addNewMessageToOneOnOneThread(message, uid, messageId))
});

const mapStateToProps = (state) => {
  console.log('otherAttendeeInfootherAttendeeInfo', state.threads.otherAttendeeInfo)
  return {
    user: state.user.user,
    attendee: state.events.events.attendees,
    uid: state.auth.user.uid,
    oneOnOneMessages: state.threads.oneOnOneMessages,
    title: state.events.events.title,
    otherAttendeeInfo: state.threads.otherAttendeeInfo
  };
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(OneOnOneChat));

import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from "@material-ui/icons/Add";
import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';

import FamilyForm from '../Form/FamilyForm';



const FamilyQuestion = (props) => {

  const { classes, advisorData, nextStep, familyData, setFamilyData, addContact } = props;

  return (
    <div className={classes.container}>
      <div style={{ marginBottom: "25px" }}>
        <h3 className={classes.landingHeader}>Trusted Contacts</h3>
        <p className={classes.clickButton}>Add trusted contacts by clicking below</p>
      </div>

      <GridContainer>
        {familyData.map((d, index) => {
          return (
            <FamilyForm familyData={familyData} index={index} setFamilyData={setFamilyData} firstName={d.firstName} lastName={d.lastName} type={d.type} relation={d.relation} address={d.address} />
          )
        })}
      </GridContainer>

      <GridContainer className={classes.gridContainer}>
        <GridItem xs={6}>
          <Button
            className={classes.addButton}
            onClick={addContact}
          ><AddIcon />Add Contact</Button>
        </GridItem>
        <GridItem xs={6}>
          <Button
            className={classes.nextButton}
            onClick={nextStep}
          >Next</Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(FamilyQuestion);
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/addOnsStyle';
import videoCallLogo from "assets/img/videoCall.svg";
import { ReactComponent as ActivitiesGraphic } from 'assets/img/activitiesGraphic.svg';

import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';


const addOns = [{ title: "Additional Quiz Sections", description: 'Add more questions to your quizzes to add more points!', img: Question },

{ title: "Take Video Class", description: 'Learn about what WealthEnV has to offer', img: Camera },
{ title: "Account Aggregation", description: 'Link your accounts with our aggregator or by hand', img: Person },

{ title: "Join Investment Club Chat", description: 'Chat with our investors and get firsthand advice', img: Chat },

{ title: "Social Media Integregation", description: `Follow WealthEnV's social accounts and get rewarded`, img: SocialMedia },

]

export function AddOnsPage(props) {

    const { classes, history, user } = props;
    const [username, setUsername] = useState('');

    const handleSubmit = useCallback(async event => {
        event.preventDefault();
        const response = await twilioToken({ identity: username });
        history.push('/video-conference', { state: { data: response.data } })
      }, [username]);

    useEffect(() => {
      if (!username && user.firstName !== 'N/A') {
        setUsername(`${user.firstName} ${user.lastName}`);
      }
    })

    return (
        <div className={classes.container}>

            {/* mobile */}
            <Hidden mdUp>
                <h3 className={classes.header}></h3>
                <div className={classes.gridContainerDiv}>
                    <ActivitiesGraphic style={{ display: 'flex', margin: "105px auto 0px auto", width: "285px" }} />
                    {addOns.map((a, index) => {
                        return (
                            <GridContainer className={classes.gridContainer}>
                                <GridItem className={classes.imgGridItem} xs={2}><img style={a.title === "Take Video Class" || a.title === "Account Aggregation" ? { width: "42px" } : { height: "80px", width: "60px" }} src={a.img} /> </GridItem>
                                <GridItem className={classes.infoGridItem} xs={8}>
                                    <h5 className={classes.addOnTitle}>{a.title}</h5>
                                    <span>{a.description}</span>
                                </GridItem>
                            </GridContainer>
                        )
                    })}
                </div>
            </Hidden>


            {/* desktop */}
            <Hidden smDown>
                <h3 className={classes.header}></h3>
                <div className={classes.gridContainerDiv}>
                    <GridContainer className={classes.cardContainer}>
                        <GridItem xs={5}>
                            <Card className={classes.optionCard}>
                                <div className={classes.addOnIcon}>
                                    <img className={classes.additonalQuizIcon} src={Question} />
                                </div>
                                <h5 className={classes.headerDescription}>Additional Quiz Sections</h5>
                                <p className={classes.description}>Add more questions to your quizzes to add more points!</p>
                            </Card>
                        </GridItem>
                        <GridItem xs={5}>
                            <NavLink to={'/video-classes'}>
                                <Card className={classes.optionCard}>
                                    <div className={classes.addOnIcon}>
                                        <img className={classes.videoClassIcon} src={Camera} />
                                    </div>
                                    <h5 className={classes.headerDescription}>Take Video Class</h5>
                                    <p className={classes.description}>Learn about what WealthEnV has to offer</p>
                                </Card>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.cardContainer}>
                        <GridItem xs={5}>
                            <Card className={classes.optionCard}>
                                <div className={classes.addOnIcon}>
                                    <img className={classes.accountIcon} src={Person} />
                                </div>
                                <h5 className={classes.headerDescription}>Account Aggregation</h5>
                                <p className={classes.description}>Link your accounts with our aggregator or by hand</p>
                            </Card>
                        </GridItem>
                        <GridItem xs={5}>
                            <Card className={classes.optionCard} onClick={handleSubmit}>
                                <div className={classes.addOnIcon}>
                                    <img className={classes.investmentIcon} src={Chat} />
                                </div>
                                <h5 className={classes.headerDescription}>Join Investment Club Chat</h5>
                                <p className={classes.description}>Chat with our investors and get firsthand advice</p>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.cardContainer}>
                        <GridItem xs={5}>
                            <Card className={classes.optionCard}>
                                <div className={classes.addOnIcon}>
                                    <img className={classes.socialIcon} src={SocialMedia} />
                                </div>
                                <h5 className={classes.headerDescription}>Social Media Integregation</h5>
                                <p className={classes.description}>Follow WealthEnV's social accounts and get rewarded</p>
                            </Card>
                        </GridItem>
                        <GridItem xs={5}>
                            <Card className={classes.lockedCard}>
                                <h5 className={classes.headerDescription}>Unlock at Level 4</h5>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </Hidden>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AddOnsPage);
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { useHistory } from 'react-router';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';

import ChartistGraph from "react-chartist";

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        visibility: "hidden !important",
        color: "#74EAE3",
        '&$checked': {
          color: "#74EAE3",
        },
      },
    },
  },
});
export class SecondQuestionGlobalResults extends Component {



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.secondQuestion !== 0) {
      this.props.nextStep();
    }
  }

  render() {
    const { values, handleToggle, classes, globalTestResults, compare } = this.props;


    const usersSelection = values[compare]
    const MaxNumber = Math.max.apply(Math, Object.values(globalTestResults[compare]))
    const choseLikeOthers = globalTestResults[compare][usersSelection] === MaxNumber
    const totalVotes = Object.values(globalTestResults[compare]).reduce((a, b) => a + b, 0)
    const percentageOfPeopleWhoGotItWrong = Math.floor(globalTestResults[compare].incorrect / totalVotes * 100)
    console.log('usersSelection', usersSelection)

    const delays2 = 80,
      durations2 = 500;

    const simpleBarChart = {
      data: {
        labels: [
          "Correct",
          "Incorrect"
        ],
        series: [[globalTestResults[compare].correct, globalTestResults[compare].incorrect]]
      },
      options: {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        },
        stretch: true
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            // axisX: {
            //   labelInterpolationFnc: function(value) {
            //     return value[0];
            //   }
            // }
          }
        ]
      ],
      animation: {
        draw: function (data) {
          if (data.type === "bar") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: "ease"
              }
            });
          }
        }
      }
    };

    return (
      <div className={classes.quizGlobalResults113vh}>

        <span style={{
          fontFamily: "Nova", fontStyle: 'normal', fontWeight: 400,
          fontSize: '27px', color: '#4F4F4F', width: "100%"
        }}>Did You Know?</span>

        {usersSelection === 'correct' ? (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}>You Answered Correctly! This is how others did.</span>
        ) : (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px", color: 'red',
          }}>{percentageOfPeopleWhoGotItWrong}% of voters also got this wrong.</span>
        )}

        <ChartistGraph
          className="ct-chart-blue-colors"
          style={{ width: "365px !important" }}
          data={simpleBarChart.data}
          type="Bar"
          options={simpleBarChart.options}
          responsiveOptions={simpleBarChart.responsiveOptions}
          listener={simpleBarChart.animation}
        />
        <p className={classes.questionDescription}>The reason you have less is inflation. Inflation is the rate at which the price of goods and services rises. If the annual inflation rate is 2 percent but the savings account only earns 1 percent, the cost of goods and services has outpaced the buying power of the money in the savings account that year. Put another way, your buying power has not kept up with inflation.</p>
        <GridContainer className={classes.wealthManagementCheckboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    style={{ display: "hidden" }}
                    defaultChecked={usersSelection === 1 ? true : false}
                    sx={{
                      display: "none",
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#74EAE3"
                      }
                    }}
                  />
                </ThemeProvider>}
              label={`More`}
              className={classes.checkboxText}
            />

          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 2 ? true : false}
                />}
              label={`Same`}
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 2 ? true : false}
                />}
              label={`Less (Correct Answer)`}
              style={{ color: "green" }}
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>


        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft
                  onClick={this.back}
                  className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>2 / 6</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>

        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={33}
        />
      </div>
    );
  }
}

export default withStyles(Style)(SecondQuestionGlobalResults);
import React from "react";

import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
import Button from "components/CustomButtons/Button.jsx";

// Get Users for Users Panel
export const startSetOffersList = () => {
  return (dispatch) => {
    db.collection("boost")
      .get().then((snapshot) => {
        const data = []
        // Parse data into array like in firebase.js
        snapshot.forEach((doc) => {
          var docData = doc.data()
          
          var docObj = {
            id: doc.id,
            image: docData.adimage ? docData.adimage : "N/A",
            title: docData.adtitle ? docData.adtitle : "N/A",
            description: docData.addescription ? docData.addescription : "N/A",
            link: docData.customurl ? docData.customurl : "N/A",
          }
          data.push(docObj);
        })
        return (data)
      }, (error) => {
        console.log('error fetching data: ', error)
      }).then(data => {        
        dispatch(setOffersList(data))
      })
  }
}

export const setOffersList = (dataRows) => ({
  type: 'SET_OFFERS_LIST',
  dataRows
})


import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db, sendEmail } from "firebase/fbConfig.js"

import SelectSearch from 'react-select-search'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import SendIcon from '@material-ui/icons/Send';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/AssignAdvisorFormStyle.jsx";
import Explore from "../../views/Explore/Explore";
import ExploreAssignAdvisor from "../../views/Explore/ExploreAssignAdvisor";
import { inviteNewUser } from "firebase/fbConfig.js"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


class Form extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false,
    email: '',
    succesfullySentEmail: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      advisorID: "Please assign advisor"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    })
    console.log(this.state)
  }
  handleSubmit = (e) => {
    console.log("triggered")
    e.preventDefault();
    inviteNewUser([this.state.email])
      .then(() => {
        this.setState({
          email: '',
          succesfullySentEmail: true
        }, () => {
          console.log('this.state.email', this.state.email)
        })
      })

    // db.collection("users").doc(this.props.authUser.uid).collection("investments").doc(this.props.investmentID).set({
    //   invitedAdvisorEmail: this.state.inviteEmail,
    //   type: "inviteAdvisor",
    //   invitedAdvisor: new Date()
    // }, { merge: true }).then(() => {
    //   // update firestore document with PENDING and comment
    //   db.collection("users").doc(this.props.authUser.uid).collection("invitedAdvisors").doc().set({
    //     email: this.state.inviteEmail,
    //     investmentID: this.props.investmentID ? this.props.investmentID : null,
    //     inviteSent: new Date()
    //   }, { merge: true }).then(async () => {
    //     console.log(this.state.inviteEmail)
    //     const email = this.props.user.email

    //     let subject = `You have been invited to WealthEnV!`
    //     let html = `<p>Hello </p>`
    //     html += `<p>Investment #: ${this.props.investmentID}</p>`
    //     html += `<p>You have been invited to WealthEnV click here: localhost:3000/pages/signup/${this.props.authUser.uid}/investment/${this.props.investmentID}</p>`
    //     const emails = [this.state.inviteEmail]

    //     const emailReturn = await sendEmail({
    //       emails,
    //       subject,
    //       html
    //     })
    //   }).then(() => {
    //     window.location.reload();
    //   })
    // })

  }

  resetRequest = () => {
    window.location.reload();
  }

  assignAdvisor = (advisorID, firstName, lastName, email) => {
    this.setState({
      advisorID: advisorID,
      advisorFirstName: firstName,
      advisorLastName: lastName,
      advisorEmail: email
    })
  }

  render() {
    const { classes, modalClose, authUser, user, blogID, investmentID } = this.props;
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }
    console.log(authUser)

    const options = [
      { name: 'Bob', value: 'bob' },
      { name: 'Steve', value: 'steve' },
    ];

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
          <div>
            <form onSubmit={this.handleSubmit}
            >
              <GridContainer className={classes.inviteAdvisorContainer} style={{ margin: "10px 3px 0px 1px" }} justify="left">
                <GridItem className={classes.adviserFormGridItem} style={{ backgroundColor: "#E9EDF1", borderRadius: "7px", height: "50px" }} xs={6}>
                  <CustomInput
                    advisorForm={true}
                    labelText="Email"
                    id="inviteEmail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.email,
                      required: true,
                      type: "text",
                      disableUnderline: true,
                      multiline: false,
                      rows: 1,
                      onChange: (event) => this.setState({
                        email: event.target.value
                      })
                    }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    className={classes.inviteBtn}
                    type="submit"
                    style={{
                      backgroundColor: '#436CFC',
                    }}
                  >
                    <SendIcon /><b>Invite</b>
                  </Button>
                </GridItem>
                <Snackbar
                  style={{ marginBottom: '30px' }}
                  open={this.state.succesfullySentEmail}
                  onClose={() => {
                    this.setState({
                      succesfullySentEmail: false
                    })
                  }}
                  autoHideDuration={4000}
                >
                  <Alert
                    onClose={() => {
                      this.setState({
                        succesfullySentEmail: false
                      })
                    }}
                    severity="success"
                  >
                    Succesfully sent email.
                  </Alert>
                </Snackbar>
              </GridContainer>
            </form>
          </div>

        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Form);
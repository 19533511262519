import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/ChatInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SendIcon from '@material-ui/icons/Send';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/groupMessageThreadStyle';
import ThreadsMap from '../Chat/Threads/ThreadsMap.jsx';
import Threads from '../Chat/Threads/Threads.jsx';

import { addMessageToGroupThread, addNewMessageToOneOnOneThread } from 'store/actions/threadsActions';
import { ReactComponent as IntakeFormIcon } from 'assets/img/IntakeFormIcon.svg';
import loader from 'assets/img/loader.gif';

const CreateMessageForm = (props) => {
  const {
    classes,
    addMessageToGroupThread,
    user,
    attendees,
    uid,
    groupMessageThread,
    oneOnOneChat,
    addNewMessageToOneOnOneThread,
    oneOnOneChatAttendees
  } = props;
  const [textInput, setTextInput] = useState('');

  const submitMessage = (e) => {
    e.preventDefault();

    if (oneOnOneChat) {
      const messageId = window.location.pathname.slice(6);
      var message = { messageSentFrom: user, message: textInput, messageId, intakeForm: false };
      addNewMessageToOneOnOneThread(message, uid, messageId, oneOnOneChatAttendees)
    } else {
      const messageId = window.location.pathname.slice(15);
      var message = { messageSentFrom: user, message: textInput, messageId, intakeForm: false };
      addMessageToGroupThread(message, attendees, uid);
    }
    setTextInput('');
  };

  const addIntakeFormToThread = () => {
    const messageId = window.location.pathname.split('/')[2]
    let message
    if (user.userType === 'advisor') {
      message = { messageSentFrom: user, message: 'Welcome aboard! You can now fill out the Intake Form by clicking this message', messageId, intakeForm: true };
    } else {
      message = { messageSentFrom: user, message: 'Hey! I am interested in filling out an Intake Form. Please send one over', messageId, intakeForm: true };
    }
    console.log('clciked', messageId, message)
    addNewMessageToOneOnOneThread(message, uid, messageId, oneOnOneChatAttendees)
  }

  return (
    <div>
      <div
        className={classes.formContainer}
        justify="center"
      >
        <form onSubmit={submitMessage}>
          <GridContainer>
            <GridItem className={classes.formGridItem} xs={8}>
              {/* {this.state.messageError ? (
                <h5 className={classes.bodyText}>There Has Been An Error</h5>
              ) : null} */}
              <IntakeFormIcon onClick={addIntakeFormToThread} />
              <br />
              <CustomInput
                createMessageForm={true}
                labelText="Send a message..."
                id="message"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  required: true,
                  type: 'text',
                  multiline: false,
                  rows: 1,
                  onChange: (e) => setTextInput(e.target.value),
                  value: textInput,
                }}
              />
            </GridItem>
            <GridItem className={classes.formGridItem} xs={2}>
              <Button
                type="submit"
                // color="#315B7B"
                className={classes.sendBtn}
              >
                <SendIcon></SendIcon>
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addMessageToGroupThread: (messageInfo, attendees, uid) =>
    dispatch(addMessageToGroupThread(messageInfo, attendees, uid)),
  addNewMessageToOneOnOneThread: (message, uid, messageId, oneOnOneChatAttendees) => dispatch(addNewMessageToOneOnOneThread(message, uid, messageId, oneOnOneChatAttendees))
});

const mapStateToProps = (state) => {
  console.log('stateeeeeeexattendees', state.user.user);
  return {
    user: state.user.user,
    attendees: state.events.events.attendees,
    uid: state.auth.user.uid,
    groupMessageThread: state.threads.groupMessageThread,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(CreateMessageForm);

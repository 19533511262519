export const initState = {

};

export default (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_GROUP':
      console.log('UPDATE_GROUP');
      const newGroups = {...state.groups};
      newGroups.groups.forEach((group, i) => {
        if (group.group_id === action.groupId) {
          newGroups.groups[i].accounts.push(action.accountId);
        }
      })
      return {
        ...state,
        groups: newGroups
      }
    case 'SET_GROUP':
      console.log('SET_GROUP');
      const newGroupSet = [...state.groups.groups];
      const newGroup = {...action.group};
      newGroup.group_id = action.groupId;
      newGroupSet.push(newGroup);
      return {
        ...state,
        groups: {
          ...state.groups,
          groups: newGroupSet
        }
      }
    case 'SET_GROUPS':
      console.log('SET_GROUPS');
      return {
        ...state,
        groups: {
          ...state.groups,
          groups: action.groups
        }
      }
    case 'SET_ACCOUNTS':
      console.log('SET_ACCOUNTS');
      return {
        ...state,
        assets: {
          ...state.accounts,
          accounts: action.accounts
        }
      }
      case 'SET_STORED_ACCOUNTS':
        console.log('SET_STORED_ACCOUNTS');
        return {
          ...state,
          assets: {
            ...state.accounts,
            accounts: action.accounts,
            storedAccountsLoaded: true
          }
        }
    case 'SET_ASSET':
      console.log('SET_ASSET');
      const accounts = {
        ...state.assets.accounts
      };
      accounts[action.id] = action.asset;
      return {
        ...state,
        assets: {
          accounts: accounts
        }
      }
    default:
      return state;
  }
};


const walletPageStyle = (theme) => ({
    container: {
        margin: "0px auto",
        width: "95%",
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            paddingTop: "50px"
        }
    },
    totalPoints: {
        margin: "20px auto",
        [theme.breakpoints.down("md")]: {
            margin: "10px auto",
        }
    },
    totalPointsText: {
        fontSize: "30px",
        [theme.breakpoints.down("md")]: {
            fontSize: "20px",
        }
    },
    topCard: {
        padding: "30px",
        boxShadow: "0px 4px 9px grey",
        borderRadius: "100px",
        [theme.breakpoints.down("md")]: {
            padding: "10px",
        }
    },
    viewActivitiesBtn: {
        width: "200px",
        backgroundColor: "#4775FB",
        color: "white",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
            width: "115px",
        },
        "&:hover": {
            backgroundColor: "#5d7188",
        }
    },
    topCardBody: {
        display: "flex",
        justifyContent: "space-between", alignItems: "center",
        padding: "0px 40px",
        marginBottom: "30px",
        marginTop: "30px",
        [theme.breakpoints.down("md")]: {
            padding: "0px",
            margin: "0px"
        }
    },
    midCard: {
        padding: "30px",
        boxShadow: "0px 4px 9px grey",
        borderRadius: "100px",
        [theme.breakpoints.down("md")]: {
            padding: "10px 30px",
        }
    },
    coinImage: {
        height: "130px",
        [theme.breakpoints.down("md")]: {
            fontSize: "30px",
            height: "60px"
        }
    },
    xp: {
        fontSize: "36px",
        fontWeight: 500,
        marginBottom: "45px",
        [theme.breakpoints.down("md")]: {
            width: "100px",
            fontSize: "30px",
            marginBottom: "5px",
            textAlign: 'center'
        }
    },
    availXp: {
        fontSize: "26px",
        marginBottom: "0px",
        [theme.breakpoints.down("md")]: {
            width: "100px",
            fontSize: "18px",
            marginLeft: "10px"
        }
    },
    learnHow: {
        color: "black",
        padding: "0.75rem 1.25rem",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            fontSize: "12px"
        },
        "&:hover": {
            color: "blue"
        }
    },
    mainAchievementsColumn: {
        display: "flex",
        flexDirection: "column"
    },
    achievementRow: {
        display: "flex",
        marginBottom: "50px",
        alignItems: 'center'
    },
    leftSectionBestInClass: {
        width: "170px",
        height: "170px",
        backgroundImage: "linear-gradient(180deg, #72E5E4 0%, #D7F3F1 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "60px",
        [theme.breakpoints.down("md")]: {
            width: "75px",
            marginRight: "20px",
        }
    },
    trophyImg: {
        width: "90%",
        marginTop: "30px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginTop: "15px",
        }
    },
    trustfulImg: {
        width: "75%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
            marginTop: "10px"
        }
    },
    img: {
        width: "75%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginTop: "10px"
        }
    },
    yieldImg: {
        width: "75%",
        [theme.breakpoints.down("md")]: {
            width: "80%",
            marginTop: "10px"
        }
    },
    leftSectionMarketMaster: {
        width: "170px",
        height: "170px",
        backgroundImage: "linear-gradient(180deg, #72E5E4 0%, #D7F3F1 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "60px",
        [theme.breakpoints.down("md")]: {
            width: "75px",
            marginRight: "20px",
        }
    },
    leftSectionTrustfulTrustee: {
        width: "170px",
        height: "170px",
        backgroundImage: "linear-gradient(180deg, #72E5E4 0%, #D7F3F1 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "60px",
        [theme.breakpoints.down("md")]: {
            width: "100px",
            marginRight: "20px",
        }
    },
    leftSectionYieldInvestment: {
        width: "170px",
        height: "170px",
        backgroundImage: "linear-gradient(180deg, #72E5E4 0%, #D7F3F1 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "60px",
        [theme.breakpoints.down("md")]: {
            width: "87px",
            marginRight: "20px",
        }
    },
    rightSection: {
        display: "flex",
        flexDirection: "column",
        height: "170px",
        justifyContent: "space-between"
    },
    sectionTitle: {
        fontSize: "35px",
        fontWeight: 500,
        marginTop: "0px",
        [theme.breakpoints.down("md")]: {
            fontSize: "27px",
        }
    },
    slider: {
        width: "500px",
        [theme.breakpoints.down("md")]: {
            width: "200px",
        }
    },
    copyCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-evenly",
        alignItems: 'center',
        marginTop: "10px"
    },
    copyBtn: {
        background: '#4775FB',
        borderRadius: '9px',
        width: "10px"
    },
    copyContainer: {
        width: "85%",
        margin: "50px auto 0px auto"
    },
    cardForQuizContainer: {
        alignItems: "center"
    },
    referFriends: {
        color: 'black !important',
        textAlign: 'center'
    },
    cardForReward: {
        background: '#FFFFFF',
        border: '1px solid #72C5C8',
        borderRadius: '32px',
        width: "80%",
        justifyContent: 'center',
        padding: "17px",
        margin: "0px auto 30px auto"
    },
    rewardDescriptor: {
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '11px',
    },
    rewardName: {
        fontSize: "75%",
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px'
    },
    buttonToReward: {
        background: '#436CFC',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '15px',
    },
})

export default walletPageStyle;

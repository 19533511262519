import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import SendIcon from '@material-ui/icons/Send';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/ChatInput.jsx";
import Button from "components/CustomButtons/Button.jsx";


import styles from "assets/jss/material-dashboard-pro-react/components/CreateMessageFormStyle.jsx";

import { startAddMessage } from "store/actions/threadsActions";

class Form extends React.Component {
  state = {
    sending: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
    this.messageRef = React.createRef();
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { uid, currentThread } = this.props;
    console.log("handleSubmit", this.messageRef.current);
    this.setState({...this.state, sending: true});
    this.props.startAddMessage(currentThread.tid, { uid, message: this.messageRef.current.value }).then(
      ()=>{this.setState(state=>({...state, sending: false})); this.messageRef.current.value=''},
      (messageError)=>this.setState(state=>({ ...state, messageError, sending: false }))
    );
  }

  render() {
    const { classes } = this.props;
    //console.log(uid + " " + id)
    // reset modal when closed
    //if (this.state.messageSent) { this.resetRequest() }
    //console.log(this.state)
    return (
      <div>

        <div className={classes.formContainer} justify="center"
          style={{
            padding: "0px 50px 10px 40px",
            marginLeft: "-40px",
            position: "fixed",
            bottom: "0", width: "115%",
            height: "11vh"
          }}>

          <form onSubmit={this.handleSubmit}
          >
            <GridContainer>
              <GridItem xs={9}>
                {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                <br />
                <CustomInput
                  labelText="Type Something..."
                  id="message"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    type: "text",
                    multiline: false,
                    rows: 1,
                  }}
                  inputRef={this.messageRef}
                />

              </GridItem>
              <GridItem xs={1}>
                <Button
                  type="submit"
                  color="primary"
                  style={{
                    marginTop: "20px",
                    marginLeft: "10px",
                    marginBottom: "10px"
                  }}>
                  <SendIcon></SendIcon>
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </div>


      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapDispatchToProps = (dispatch) => {
  return {
    startAddMessage: (id, message) => dispatch(startAddMessage(id, message)),
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    uid: state.auth.user.uid,
    currentThread: state.threads.currentThread
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Form);
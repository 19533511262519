import React, { useState, useEffect, useContext } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { useHistory, useLocation } from 'react-router-dom'
import { twilioToken } from 'firebase/fbConfig.js';

import RoomContext, { withRoomContext } from '../../sfu/RoomContext';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/conferencingPageStyle';



function JoinRoomModal(props) {
    const { user, mediaPerms, username } = props;
    const location = useLocation();

    const sfu = useContext(RoomContext);


    const [roomId, setRoomId] = useState(decodeURIComponent(location.pathname.slice(1)))

    /*    useEffect(() => {
           console.log(decodeURIComponent(location.pathname.slice(1)))
           window.history.replaceState({}, null, `/video-conference/${encodeURIComponent(roomId)}` || "/");
       }, [roomId]); */

    useEffect(() => {
        location.pathname === "/" && history.push(encodeURIComponent(roomId));
    });

    async function _getMediaPermissions() {
        if (mediaPerms.video || mediaPerms.audio) {
            navigator.mediaDevices.getUserMedia(mediaPerms);
        }

    }

    let history = useHistory();
    const { classes } = props;

    async function handleJoinRoom() {
        await _getMediaPermissions();

        const encodedRoomId = encodeURIComponent(roomId);

        const response = await twilioToken({ identity: username, roomName: roomId });

        history.push(`/video-conference/?room=${roomId}`, { state: { data: response.data } });

        sfu.join({ roomId: encodedRoomId, videoPerms: mediaPerms.video, audioPerms: mediaPerms.audio })
    }

    const handleRoomId = (e) => {
        let id = e.target.value;

        setRoomId(id);
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                transition={props.transition}
                keepMounted
                onClose={props.closeButton}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                className={classes.modal}
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    className={classes.modalTitleContainer}
                    disableTypography
                >
                    <h6 className={classes.modalTitle}>Join video room</h6>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={props.closeButton}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}
                >
                    <h5>Enter the room id below.</h5>
                    <CustomInput id="regular"
                        inputProps={{
                            placeholder: "Room id",
                            onChange: handleRoomId
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}

                    />
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}
                >
                    <Button simple onClick={props.closeButton} style={{ color: "#4775FB" }}>Never Mind</Button>
                    <Button onClick={handleJoinRoom} className={classes.modalButton}>
                        Join
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        user: state.user.user,
        users: state.user.users,
        uid: state.auth.user.uid,
        data: state.events.events.data,
        mediaPerms: state.me.mediaPerms
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(JoinRoomModal);

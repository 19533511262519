import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Fab from "@material-ui/core/Fab";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/threadsStyle";
import { Grid } from "@material-ui/core";

import ChatInvestmentsDetailsMap from "./ChatInvestmentsDetailsMap.jsx"
// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import CreateThreadForm from "../../components/Forms/CreateThreadForm";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Users extends React.Component {

  state = {
    modals: { newThreadModal: false, shareInvestmentModal: false  }
  }

  handleClickOpen = (modal) => {
    this.setState(state=>({ ...state, modals: { ...state.modals, [modal] : true }}));
  }

  handleClose = (modal) => {
    this.setState(state=>({ ...state, modals: { ...state.modals, [modal] : false }}));
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { classes } = this.props;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton;

    return (
      <div>
            <div style={{padding: "0px 5%"}}>
              <ChatInvestmentsDetailsMap/>
              
              <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.handleClickOpen("newThreadModal")}>
                <AddIcon />
              </Fab>
              {/* SHARE INVESTMENT MODAL */}
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal + " " + classes.modalLarge
                }}
                open={this.state.modals.shareInvestmentModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("shareInvestmentModal")}
                aria-labelledby="shipment-modal-slide-title"
                aria-describedby="shipment-modal-slide-description"
              >

                <DialogContent
                  id="shipment-modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    style={{
                      margin: "2.5% 1% 0% 0%",
                      color: "white",
                    }}
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("shareInvestmentModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>

                  {/* FORM */}
                  <CreateThreadForm/>
                </DialogContent>
              </Dialog>

              {/* NEW THREAD MODAL */}
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal + " " + classes.modalLarge
                }}
                open={this.state.modals.newThreadModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("newThreadModal")}
                aria-labelledby="shipment-modal-slide-title"
                aria-describedby="shipment-modal-slide-description"
              >

                <DialogContent
                  id="shipment-modal-slide-description"
                  className={classes.modalBody}
                >
                  <Button
                    style={{
                      margin: "2.5% 1% 0% 0%",
                      color: "white",
                    }}
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("newThreadModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>

                  {/* FORM */}
                  <CreateThreadForm/>
                </DialogContent>
              </Dialog>
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Users);

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from 'firebase/fbConfig.js';

// react component used to create a calendar with events on it
import BigCalendar from 'react-big-calendar';
// dependency plugin for react-big-calendar
import moment from 'moment';
// react component used to create alerts
import SweetAlert from 'react-bootstrap-sweetalert';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Heading from 'components/Heading/Heading.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';

// import { startSetEventsList } from 'store/actions/calendarActions';

import { events } from 'variables/general.jsx';

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  selectedEvent(event) {
    alert(event.title);
  }
  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: 'block', marginTop: '-100px' }}
          title='Input something'
          onConfirm={(e) => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.danger
          }
        />
      ),
    });
  }
  addNewEvent(e, slotInfo) {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    this.setState({
      alert: null,
      events: newEvents,
    });
  }
  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = 'event-';
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + 'default');
    return {
      className: backgroundColor,
    };
  }
  loadData = (uid) => {
    // let data = db
    //   .collection('TEST-users')
    //   .doc(uid)
    //   .collection('registeredCourses')
    //   .get()
    //   .then(
    //     (snapshot) => {
    //       const data = [];
    //       // Parse data into array like in firebase.js
    //       snapshot.forEach((doc) => {
    //         var docData = doc.data();
    //         var docObj = {
    //           id: doc.id,
    //           title: docData.title ? docData.title : 'N/A',
    //           start: docData.start
    //             ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm')
    //             : new Date(),
    //           end: docData.end
    //             ? moment(docData.end.toDate()).format('MM-DD-YYYY HH:mm')
    //             : new Date(),
    //         };
    //         data.push(docObj);
    //       });
    //       console.log(data);
    //       return data;
    //     },
    //     (error) => {
    //       console.log('error fetching data: ', error);
    //     }
    //   )
      // .then((data) => {
        const data = []
        this.setState({
          data: data,
          dataLoaded: true,
        });
      // });
    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  };

  render() {
    const { AuthBool, authUser, user, events } = this.props;
    if (AuthBool) {
      if (!this.state.dataLoaded) {
        // this.loadData(authUser.uid);
      }
    }

    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth();
    var d = today.getDate();
    console.log(new Date(y, m, 1));

    console.log(events);
    return (
      <div style={{ width: '90%', margin: '0px 20px !important' }}>
        {this.state.alert}
        <GridContainer justify='center'>
          <GridItem xs={10} sm={10} md={10}>
            <div style={{ height: '250px' }}>
              {events.dataLoaded ? (
                <BigCalendar
                  selectable
                  localizer={localizer}
                  events={events.dataRows}
                  defaultView='month'
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => this.selectedEvent(event)}
                  onSelectSlot={(slotInfo) => this.addNewEventAlert(slotInfo)}
                  eventPropGetter={this.eventColors}
                />
              ) : null}
            </div>
          </GridItem>
        </GridContainer>
        {this.state.data &&
          this.state.data.map((course) => {
            return (
              <p
                style={{
                  fontSize: '2rem',
                  color: 'black',
                  position: 'fixed',
                  top: '0',
                  right: '0',
                  zIndex: '999',
                }}
              >
                {' '}
                hello {course.id}
              </p>
            );
            //console.log(course.id)
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
    events: state.events.events,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  console.log(ownProps);
  return {
    // startSetEventsList: dispatch(startSetEventsList(ownProps.uid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(buttonStyle)
)(Calendar);

import React, { useState, useEffect, useContext } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { useHistory, useLocation } from 'react-router-dom'
import { db } from 'firebase/fbConfig.js';

import Datetime from "react-datetime";
import RoomContext, { withRoomContext } from '../../sfu/RoomContext';
import { addNewEvent } from 'store/actions/calendarActions';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from 'components/CustomInput/CustomSelect.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/conferencingPageStyle';



function CreateRoomModal(props) {
    const { user, mediaPerms, uid, addNewEvent } = props;
    const [date, setDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [users, setUsers] = useState([]);
    const [fullUsersData, setFullUsersData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fullSelectedUsers, setFullSelectedUsers] = useState([]);
    const [showCreated, setShowCreated] = useState(false);
    const location = useLocation();

    const sfu = useContext(RoomContext);

    const [roomId, setRoomId] = useState(decodeURIComponent(location.pathname.slice(1)));

    const valid = (current) => {
      return current.isAfter(Datetime.moment().subtract(1, 'day'));
    };

    const getUserList = () => {
      db.collection('users').get()
        .then((snapshot) => {

          return snapshot.docs.map((doc) => {
            let data = doc.data();
            return {
              arrData: {
                id: doc.id,
                name: `${data.firstName} ${data.lastName}`
              },
              data
            };
          })
        })
        .then((results) => {
          let fullData = [];
          let abbrevData = [];
          results.forEach((result) => {
            fullData.push(result.data);
            abbrevData.push(result.arrData);
          })
          console.log('look res', fullData, abbrevData)
          setFullUsersData(fullData);
          setUsers(abbrevData);
        })
    }

    const handleUserSelect = (i) => {
      setFullSelectedUsers((prevState) => {
        return [...prevState, fullUsersData[i]];
      })
      setSelectedUsers((prevState) => {
        return [...prevState, users[i]];
      })
    }

    const handleDeleteSelected = (i) => {
      setFullSelectedUsers((prevState) => {
        prevState.splice(i, 1);
        return prevState;
      })
      setSelectedUsers((prevState) => {
        prevState.splice(i, 1);
        return prevState;
      })
    }

    /*    useEffect(() => {
           console.log(decodeURIComponent(location.pathname.slice(1)))
           window.history.replaceState({}, null, `/video-conference/${encodeURIComponent(roomId)}` || "/");
       }, [roomId]); */

    useEffect(() => {
        location.pathname === "/" && history.push(encodeURIComponent(roomId));
        getUserList();
    }, []);

    async function _getMediaPermissions() {
        if (mediaPerms.video || mediaPerms.audio) {
            navigator.mediaDevices.getUserMedia(mediaPerms);
        }

    }

    let history = useHistory();
    const { classes } = props;

    async function handleCreateRoom() {
      if (date && roomId) {
        const host = {
          name: `${user.firstName} ${user.lastName}`,
          id: user.id
        };
        addNewEvent({ eventName: roomId, startTime: date, endTime: endDate, attendees: fullSelectedUsers }, uid);
        db.collection('conferences').add({ startDate: date, endDate, roomId, users: selectedUsers, host })
          .then(() => {
            setShowCreated(true);
            setRoomId('');
            setDate('');
            setEndDate('');
            setSelectedUsers([]);
            setFullSelectedUsers([]);
          })
      } else {
        // Handle error here
      }
    }

    const handleRoomId = (e) => {
        let id = e.target.value;

        setRoomId(id);
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                transition={props.transition}
                keepMounted
                onClose={props.closeButton}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                className={classes.createModal}
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    className={classes.modalTitleContainer}
                    disableTypography
                >
                    <h6 className={classes.modalTitle}>Create video room</h6>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={props.closeButton}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                {showCreated ? (
                  <DialogContent
                    id="created-modal"
                    >
                    <h5>Created Room!</h5>
                    <Button simple onClick={() => {
                      props.closeButton();
                      setShowCreated(false);
                    }} style={{ color: "#4775FB" }}>Close</Button>
                  </DialogContent>
                ) :
                <>
                  <DialogContent
                      id="modal-slide-description"
                      className={classes.modalBody}
                  >
                      <h5>Enter the room id below.</h5>
                      <CustomInput id="regular"
                          inputProps={{
                              placeholder: "Room ID",
                              onChange: handleRoomId
                          }}
                          formControlProps={{
                              style: {
                                // marginBottom: "1rem",
                                width: "80%"
                              }
                          }}
                      />
                      <CustomSelect
                        labelText="Customer List"
                        values={users.map((user)=>(user.name))}
                        formControlProps={{
                          style: {
                            marginBottom: "1rem",
                            width: "80%"
                          }
                        }}
                        inputProps={{
                            onChange: (e) => handleUserSelect(e.target.value),
                            style: {
                                height: "2rem"
                            }
                        }}
                      />
                      {selectedUsers ? (
                        selectedUsers.map((user, i) => (
                          <div style={{ width: "80%" }}>
                            <p style={{ fontWeight: 800, display: "inline" }}>{user.name}</p>
                            <button onClick={()=>handleDeleteSelected(i)} style={{ color: "red", fontWeight: 800, display: "inline", float: "right" }}>
                                X
                            </button>
                          </div>
                        ))) : null
                      }
                      <Datetime
                        onChange={(date)=>setDate(date.toDate())}
                        timeFormat={true}
                        isValidDate={valid}
                        renderInput={(dateInputProps, open, close) => (
                          <CustomInput
                              inputProps={{ ...dateInputProps, id: 'start', name: 'start', required: true, placeholder: "Start Time"}}
                              onBlur={close}
                              onFocus={open}
                              formControlProps={{
                                style: {
                                  width: "80%",
                                  marginBottom: "1rem"
                                }
                              }}
                          />
                        )}
                      />
                      <Datetime
                        onChange={(date)=>setEndDate(date.toDate())}
                        timeFormat={true}
                        isValidDate={valid}
                        renderInput={(dateInputProps, open, close) => (
                          <CustomInput
                              inputProps={{ ...dateInputProps, id: 'end', name: 'end', required: true, placeholder: "End Time"}}
                              onBlur={close}
                              onFocus={open}
                              formControlProps={{
                                style: {
                                  width: "80%"
                                }
                              }}
                          />
                        )}
                      />
                  </DialogContent>
                  <DialogActions
                      className={classes.modalFooter + " " + classes.modalFooterCenter}
                  >
                    <Button onClick={handleCreateRoom} className={classes.modalButton}>
                        Create
                    </Button>
                  </DialogActions>
                </>
                }
            </Dialog>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
  addNewEvent: (eventInfo, uid) => dispatch(addNewEvent(eventInfo, uid)),
});

const mapStateToProps = (state) => {
    return {
        authUser: state.auth.user,
        user: state.user.user,
        users: state.user.users,
        uid: state.auth.user.uid,
        data: state.events.events.data,
        mediaPerms: state.me.mediaPerms
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(CreateRoomModal);

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Slide from "@material-ui/core/Slide";


// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";



import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx";

import { startCreateThread } from "store/actions/threadsActions";

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class Form extends React.Component {
    state = {
        comment: '',
        messageSent: false,
        messageError: false
    }
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden"
        };
    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { cid, owner } = this.props.investment;
        const { uid } = this.props;
        const parties = [uid];
        if (owner !== uid) parties.push(owner);
        this.props.createNewThread(cid, parties, this.state.threadName, this.state.explanation, uid).then(
            this.props.onClose,
            (messageError) => {
                console.error(messageError);
                this.setState({ ...this.state, messageError });
            }
        )
    }

    render() {
        const { classes } = this.props;
        console.log(this.state)

        return (
            <div>
                {this.state.messageSent ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            <h4 className={classes.textPrimary}>Thread Submitted!</h4>

                        </GridItem>
                    </GridContainer>
                ) : (
                        <div className={classes.formContainer}>
                            <form onSubmit={this.handleSubmit}
                            >
                                <GridContainer justify="center">
                                    <GridItem xs={11}>
                                        <h5 className={classes.bodyText}><center><b>New Thread</b></center></h5>
                                        {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}

                                        <Card style={{ padding: "5px 10px 0px 10px" }}>
                                            <b>
                                                <CustomInput
                                                    labelText="Title"
                                                    id="threadName"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        required: true,
                                                        type: "text",
                                                        disableUnderline: true,
                                                        multiline: false,
                                                        rows: 1,
                                                        onChange: (event) => this.handleChange(event)
                                                    }}
                                                /></b></Card>
                                        <Card style={{ padding: "5px 10px 0px 10px" }}>
                                            <CustomInput
                                                labelText="Explanation (optional)"
                                                id="explanation"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    required: false,
                                                    type: "text",
                                                    disableUnderline: true,
                                                    multiline: false,
                                                    rows: 1,
                                                    onChange: (event) => this.handleChange(event)
                                                }}
                                            />
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        width: "92%",
                                        marginLeft: "4%",
                                        marginBottom: "10px"
                                    }}
                                ><b>
                                        Submit</b>
                                </Button>

                            </form>
                        </div>

                    )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        uid: state.auth.user.uid,
        investment: state.investments.currentInvestment
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createNewThread: (cid, parties, name, explanation, uid) => dispatch(startCreateThread({ cid, parties, name }, explanation, uid))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Form);
import React, { useEffect, useState } from 'react';
import { createLinkToken, sendPublicToken } from 'firebase/fbConfig.js';
import { usePlaidLink } from 'react-plaid-link';

const style = {
  height: '36px',
  borderRadius: '44px',
  background: "#FFFFFF",
  border: 'none',
  color: '#274B73',
  boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
  fontFamily: 'Archivo',
  fontWeight: 400,
  fontSize: '16px',
  width: '15rem',
  marginTop: "1rem"
}

const PlaidLink = ({ userId }) => {
  const [linkToken, setLinkToken] = useState(null);
  const generateToken = async () => {
    if (!userId) {
      return;
    }
    const response = await createLinkToken(userId);
    const data = await response.data;
    setLinkToken(data.link_token);
  };
  useEffect(() => {
    generateToken();
  }, [userId]);
  return linkToken != null ? <Link userId={userId} linkToken={linkToken} /> : <></>;
};
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
interface LinkProps {
  linkToken: string | null;
}
const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const onSuccess = React.useCallback((public_token, metadata) => {
    // send public_token to server
    const response = sendPublicToken({ public_token: public_token, userId: props.userId });
    // Handle response ...
    console.log('look response: ', response)
  }, []);
  const config = {
    token: props.linkToken,
    // receivedRedirectUri: window.location.href,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  return (
    <button onClick={() => open()} disabled={!ready} style={style}>
      Integrate with Plaid
    </button>
  );
};
export default PlaidLink;
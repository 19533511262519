import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import SearchIcon from '@material-ui/icons/Search';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import PlaidModal from './PlaidModal.jsx';
import { getAccounts, getStoredAccounts } from 'store/actions/assetsActions.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/assetsStyle';

import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/yourAssetsTitle.png';
import AddButtonContainerDropdown from './AddButtonContainerDropdown'
import { ReactComponent as YourDashboard } from 'assets/img/yourDashboard.svg';

const assetGroups = [
  {
    title: "Longevity",
    type: "Assets",
    includes: ["Disney Stock", "P&G Stock", "Tesla Stock"]
  },
  {
    title: "Liquidity",
    type: "Income",
    includes: ["Private Equity Fund Income"]
  },
];

const allAssets = [
  {
    title: "Tesla Stock",
    amount: 224.64,
    assetChange: 2.11,
    lastUpdated: new Date(2022, 8, 9, 11, 4)
  },
  {
    title: "Amazon Stock",
    amount: 194.64,
    assetChange: 2.11,
    lastUpdated: new Date(2022, 8, 9, 11, 4)
  }
];

const netWorth = "$55,400";
const netWorthChange = "2.33%";

export function Assets(props) {

  const { classes, history, user, getAccounts, assets, getStoredAccounts } = props;
  const [username, setUsername] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [openAddContainer, setOpenAddContainer] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('assets');

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    const response = await twilioToken({ identity: username });
    history.push('/video-conference', { state: { data: response.data } })
  }, [username]);

  const handleClose = () => {
    console.log('closed')
    setModalOpen(false);
  }

  const handleOpen = () => {
    setModalOpen(true);
  }

  const handleEdit = (account) => {
    history.push({
      pathname: '/add-asset-form',
      state: { toEdit: account },
    })
  }

  useEffect(() => {
    if (!username && user.firstName !== 'N/A') {
      setUsername(`${user.firstName} ${user.lastName}`);
    }
  }, [])

  useEffect(() => {
    if (user && user.id && assets && !assets.storedAccountsLoaded) {
      getStoredAccounts(user.id)
    }
  }, [user, assets])

  useEffect(() => {
    if (user && user.id && !user.plaidConnected) {
      setModalOpen(true);
    }
  }, [user.plaidConnected])

  return (
    <div className={classes.container}>
      {/* mobile */}
      <Hidden mdUp>
        <PlaidModal user={user} handleClose={handleClose} open={modalOpen} />
        <div style={{ textAlign: "center" }}>
          <YourDashboard style={{ height: "210px" }} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ fontWeight: 500, color: "#4F4F4F", fontSize: "1rem" }}>Your Net Worth:</p>
            <p style={{ fontWeight: 800, color: "white", fontSize: "21px" }}>{netWorth}</p>
            <p style={{ fontWeight: 500, color: "#4F4F4F", fontSize: "1rem" }}>{netWorthChange}</p>
          </div>
          <Button style={{ background: "white", color: "#274B73", borderRadius: "39px", width: "70%", fontWeight: 600, fontSize: "0.75rem", marginTop: "30px" }}>Set Net Worth Goal      ⟶</Button>
        </div>
        <div className={classes.gridContainerDiv}>
          <div className={classes.groupSelect}>
            <div onClick={()=>setSelectedGroup('all')} className={selectedGroup === 'all' ? classes.selected : classes.notSelected}>ALL</div>
            <div onClick={()=>setSelectedGroup('assets')} className={selectedGroup === 'assets' ? classes.selected : classes.notSelected}>ASSETS</div>
            <div onClick={()=>setSelectedGroup('expenses')} className={selectedGroup === 'expenses' ? classes.selected : classes.notSelected}>EXPENSES</div>
          </div>
          <div className={classes.searchBar}>
            <SearchIcon style={{ margin: "0 2%" }}/>
            <input type="text" placeholder="Search..." style={{ border: "none" }}/>
          </div>
          <div className={classes.section}>
            <h4 className={classes.sectionHeader}>ASSET GROUPS</h4>
            {assetGroups.map((group, i) => {
              let toReturn = (
                <div>
                  <hr style={{ border: "1px solid rgb(229,229,229)" }} />
                  <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{group.title}</h5>
                    <h4 className={classes.description}>Group Type: <b>{group.type}</b></h4>
                  </div>
                  <p className={classes.description}>Includes:
                    <span className={classes.typeSpan}>{group.includes.join(", ")}
                    </span>
                  </p>
                </div>
              );
              if (i < assetGroups.length - 1) {
                toReturn = (
                  <div>
                    <div className={classes.checkingBody}>
                      <h5 className={classes.headerDescription}>{group.title}</h5>
                      <h4 className={classes.description}>Group Type: <b>{group.type}</b></h4>
                    </div>
                    <p className={classes.description}>Includes:
                      <span className={classes.typeSpan}>{group.includes.join(", ")}
                      </span>
                    </p>
                  </div>
                );
              }
              return toReturn;
            })}
          </div>
          <div className={classes.section}>
            <h4 className={classes.sectionHeader}>ALL ASSETS</h4>
            {assets ? Object.values(assets.accounts).map((asset, i) => {
              let toReturn = (
                <div>
                  <hr style={{ border: "1px solid rgb(229,229,229)" }} />
                  <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{asset.name} {asset.mask ? `***${asset.mask}` : null}</h5>
                    <h4 className={classes.balance}>${asset.value || asset.balances.available || asset.balances.current}</h4>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <div>
                      {/* <p style={{ color: "#55DA53", fontWeight: 900, fontSize: "14px" }} className={classes.investmentDescription}>⬆︎{asset.assetChange}%</p> */}
                      <button
                          onClick={()=>handleEdit(asset)}
                          style={{
                            height: "30px",
                            width: "63px",
                            background: "#4F4F4F",
                            borderRadius: "15px",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                            color: "white",
                            border: "none"
                          }}>
                            Edit
                        </button>
                    </div>
                  </div>
                </div>
              );
              if (i < allAssets.length - 1) {
                toReturn = (
                  <div>
                    <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{asset.name} {asset.mask ? `***${asset.mask}` : null}</h5>
                    <h4 className={classes.balance}>${asset.value || asset.balances.available || asset.balances.current}</h4>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                      <div>
                        {/* <p style={{ color: "#55DA53", fontWeight: 900, fontSize: "14px" }} className={classes.investmentDescription}>⬆︎{asset.assetChange}%</p> */}
                        <button
                          onClick={()=>handleEdit(asset)}
                          style={{
                            height: "30px",
                            width: "63px",
                            background: "#4F4F4F",
                            borderRadius: "15px",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                            color: "white",
                            border: "none"
                          }}>
                            Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              return toReturn;
            }) : <p>Loading...</p>}
          </div>
          <div style={{
            textAlign: "center",
            marginTop: "10px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-evenly"
          }}>
            {/* <div style={{ width: "50%", display: 'flex', justifyContent: "space-between", alignItems: 'center' }} className={classes.section}> */}
            {/* <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Asset</span>
                </div>
                <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Expense</span>
                </div>
                <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Group</span>
                </div> */}
            <AddButtonContainerDropdown
              hoverColor="info"
              buttonText="Add"
              history={history}
              buttonProps={{
                round: true,
                fullWidth: false,
                style: { marginBottom: "0" },
                color: "info"
              }}
              openPlaid={()=>setModalOpen(true)}
              dropPlacement='top'
              dropdownList={[
                'Asset',
                'Expense',
                'Group',
                'Plaid'
              ]}
            />
            {/* <Button onClick={() => setOpenAddContainer(true)} style={{ background: "rgb(61,97,248)", borderRadius: "20px" }}>Add</Button> */}
            <Button style={{ background: "rgb(44,79,109)", borderRadius: "20px", marginLeft: "23px" }}>Load More</Button>
          </div>

          <h3 className={classes.header}>STOCKS OWNED</h3>
        </div>
      </Hidden>


      {/* desktop */}
      <Hidden smDown>
      <PlaidModal user={user} handleClose={handleClose} open={modalOpen} desktop/>
        <div style={{ textAlign: "center", marginLeft: "3rem", marginRight: "3rem" }} >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <p style={{ fontWeight: 500, color: "#4F4F4F", fontSize: "1rem" }}>Your Net Worth:</p>
            <p style={{ fontWeight: 800, color: "white", fontSize: "21px" }}>{netWorth}</p>
            <p style={{ fontWeight: 500, color: "#4F4F4F", fontSize: "1rem" }}>{netWorthChange}</p>
          </div>
          <Button style={{ background: "white", color: "#274B73", borderRadius: "39px", width: "70%", fontWeight: 600, fontSize: "0.75rem", marginTop: "30px" }}>Set Net Worth Goal      ⟶</Button>
        </div>
        <div className={classes.gridContainerDiv} style={{ marginLeft: "3rem", marginRight: "3rem" }}>
          <div className={classes.groupSelectDesk}>
            <div onClick={()=>setSelectedGroup('all')} className={selectedGroup === 'all' ? classes.selectedDesk : classes.notSelected}>ALL</div>
            <div onClick={()=>setSelectedGroup('assets')} className={selectedGroup === 'assets' ? classes.selectedDesk : classes.notSelected}>ASSETS</div>
            <div onClick={()=>setSelectedGroup('expenses')} className={selectedGroup === 'expenses' ? classes.selectedDesk : classes.notSelected}>EXPENSES</div>
          </div>
          <div className={classes.searchBar}>
            <SearchIcon style={{ margin: "0 2%" }}/>
            <input type="text" placeholder="Search..." style={{ border: "none" }}/>
          </div>
          <div className={classes.section}>
            <h4 className={classes.sectionHeader}>ASSET GROUPS</h4>
            {assetGroups.map((group, i) => {
              let toReturn = (
                <div>
                  <hr style={{ border: "1px solid rgb(229,229,229)" }} />
                  <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{group.title}</h5>
                    <h4 className={classes.description}>Group Type: <b>{group.type}</b></h4>
                  </div>
                  <p className={classes.description}>Includes:
                    <span className={classes.typeSpan}>{group.includes.join(", ")}
                    </span>
                  </p>
                </div>
              );
              if (i < assetGroups.length - 1) {
                toReturn = (
                  <div>
                    <div className={classes.checkingBody}>
                      <h5 className={classes.headerDescription}>{group.title}</h5>
                      <h4 className={classes.description}>Group Type: <b>{group.type}</b></h4>
                    </div>
                    <p className={classes.description}>Includes:
                      <span className={classes.typeSpan}>{group.includes.join(", ")}
                      </span>
                    </p>
                  </div>
                );
              }
              return toReturn;
            })}
          </div>
          <div className={classes.section}>
            <h4 className={classes.sectionHeader}>ALL ASSETS</h4>
            {assets ? Object.values(assets.accounts).map((asset, i) => {
              let toReturn = (
                <div>
                  <hr style={{ border: "1px solid rgb(229,229,229)" }} />
                  <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{asset.name} {asset.mask ? `***${asset.mask}` : null}</h5>
                    <h4 className={classes.balance}>${asset.value || asset.balances.available}</h4>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <div>
                      {/* <p style={{ color: "#55DA53", fontWeight: 900, fontSize: "14px" }} className={classes.investmentDescription}>⬆︎{asset.assetChange}%</p> */}
                      <button
                          onClick={()=>handleEdit(asset)}
                          style={{
                            height: "30px",
                            width: "63px",
                            background: "#4F4F4F",
                            borderRadius: "15px",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                            color: "white",
                            border: "none"
                          }}>
                            Edit
                        </button>
                    </div>
                  </div>
                </div>
              );
              if (i < allAssets.length - 1) {
                toReturn = (
                  <div>
                    <div className={classes.checkingBody}>
                    <h5 className={classes.headerDescription}>{asset.name} {asset.mask ? `***${asset.mask}` : null}</h5>
                    <h4 className={classes.balance}>${asset.value || asset.balances.available}</h4>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                      <div>
                        {/* <p style={{ color: "#55DA53", fontWeight: 900, fontSize: "14px" }} className={classes.investmentDescription}>⬆︎{asset.assetChange}%</p> */}
                        <button
                          onClick={()=>handleEdit(asset)}
                          style={{
                            height: "30px",
                            width: "63px",
                            background: "#4F4F4F",
                            borderRadius: "15px",
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                            color: "white",
                            border: "none"
                          }}>
                            Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              return toReturn;
            }) : <p>Loading...</p>}
          </div>
          <div style={{
            textAlign: "center",
            marginTop: "10px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-evenly"
          }}>
            {/* <div style={{ width: "50%", display: 'flex', justifyContent: "space-between", alignItems: 'center' }} className={classes.section}> */}
            {/* <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Asset</span>
                </div>
                <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Expense</span>
                </div>
                <div style={{ display: 'flex', flexDirection: "column", width: "33%", alignItems: 'center' }}>
                  <DiamondIcon />
                  <span>Group</span>
                </div> */}
            <AddButtonContainerDropdown
              hoverColor="info"
              buttonText="Add"
              history={history}
              buttonProps={{
                round: true,
                fullWidth: false,
                style: { marginBottom: "0" },
                color: "info"
              }}
              openPlaid={()=>setModalOpen(true)}
              dropPlacement='top'
              dropdownList={[
                'Asset',
                'Expense',
                'Group',
                'Plaid'
              ]}
            />
            {/* <Button onClick={() => setOpenAddContainer(true)} style={{ background: "rgb(61,97,248)", borderRadius: "20px" }}>Add</Button> */}
            <Button style={{ background: "rgb(44,79,109)", borderRadius: "20px", marginLeft: "23px" }}>Load More</Button>
          </div>
        </div>
      </Hidden>

    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getAccounts: (uid) => dispatch(getAccounts(uid)),
  getStoredAccounts: (uid) => dispatch(getStoredAccounts(uid))
});

const mapStateToProps = (state) => ({
  user: state.user.user,
  assets: state.assets.assets,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Assets);
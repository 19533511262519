import React from 'react';

import {
  startSetUser,
  updateUser,
  addUserListener,
} from 'store/actions/userActions.js';
import { db, firebase } from 'firebase/fbConfig.js';
import { sendMessageNotificationEmail } from "firebase/fbConfig.js"

export function setGroupMessageThread(messageArray) {
  return {
    type: 'SET_GROUP_MESSAGE_THREAD',
    groupMessages: messageArray,
  };
}

export function setOneOnOneThread(messageArray, otherAttendeeInfo) {
  return {
    type: 'SET_ONE_ON_ONE_MESSAGE_THREAD',
    oneOnOneMessages: messageArray,
    otherAttendeeInfo
  };
}

export function getMessages(uid, messageId) {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection('one-on-one-chats').doc(messageId).onSnapshot((snapshot) => {
      if (snapshot.exists) {
        snapshot = snapshot.data()
        const messages = snapshot.messages
        return db.collection('users').doc(messageId).get()
          .then((snapshot) => {
            snapshot = snapshot.data()
            console.log('checking other user info', snapshot)
            dispatch(setOneOnOneThread(messages, snapshot))
          })
      } else {
        return db.collection('users').doc(messageId).get()
          .then((snapshot) => {
            snapshot = snapshot.data()
            console.log('checking other user info', snapshot)
            dispatch(setOneOnOneThread([], snapshot))
          })
      }
    })
  }
}



export function addNewMessageToOneOnOneThread(message, uid, otherAttendeesId, oneOnOneChatAttendees) {
  console.log('message, uid, otherAttendeesId, oneOnOneChatAttendees', message, uid, otherAttendeesId, oneOnOneChatAttendees)
  return (dispatch) => {
    const batch = db.batch()
    console.log('otherAttendeesId', otherAttendeesId)
    return db.collection('users').doc(uid).collection('one-on-one-chats').doc(otherAttendeesId).get()
      .then((snapshot) => {
        if (snapshot.exists) {
          snapshot = snapshot.data()
          snapshot.messages = [
            ...snapshot.messages,
            {
              messageSentFrom: message.messageSentFrom,
              message: message.message,
              intakeForm: message.intakeForm ? message.intakeForm : false
            },
          ];
          const userDocRef = db.collection('users').doc(uid).collection('one-on-one-chats').doc(otherAttendeesId)
          const otherAttendeesDocRef = db.collection('users').doc(otherAttendeesId).collection('one-on-one-chats').doc(uid)
          snapshot.id = otherAttendeesId
          batch.set(userDocRef, snapshot);
          snapshot.id = uid
          batch.set(otherAttendeesDocRef, snapshot);
          batch.commit().then(() => {
            return db.collection('users').doc(otherAttendeesId).collection('notifications').doc().set({
              type: "New Chat Message",
              from: message.messageSentFrom,
              createdDate: new Date(),
              message: message.message,
              intakeForm: message.intakeForm ? message.intakeForm : false,
              threadId: uid,
              status: "New"
            })
              .then(() => {
                console.log('succesfully added message to all user accounts');
                var toSendNotificationTo = []
                for (var x = 0; x < oneOnOneChatAttendees.length; x++) {
                  var currentAttendee = oneOnOneChatAttendees[x]
                  if (currentAttendee.id && currentAttendee.id !== uid && currentAttendee.receiveMessageNotificationEmails) {
                    toSendNotificationTo.push(currentAttendee)
                  }
                }
                console.log('toSendNotificationTo', toSendNotificationTo)
                sendMessageNotificationEmail(toSendNotificationTo, message)
              })
          });
        } else {
          const userDocRef = db.collection('users').doc(uid).collection('one-on-one-chats').doc(otherAttendeesId)
          const otherAttendeesDocRef = db.collection('users').doc(otherAttendeesId).collection('one-on-one-chats').doc(uid)
          batch.set(userDocRef, {
            messages: [{ message: message.message, messageSentFrom: message.messageSentFrom, intakeForm: message.intakeForm ? message.intakeForm : false }],
            id: otherAttendeesId
          });
          batch.set(otherAttendeesDocRef, {
            messages: [{ message: message.message, messageSentFrom: message.messageSentFrom, intakeForm: message.intakeForm ? message.intakeForm : false }],
            id: uid
          });
          return batch.commit().then(() => {
            return db.collection('users').doc(otherAttendeesId).collection('notifications').doc().set({
              type: "New Chat Message",
              from: message.messageSentFrom,
              createdDate: new Date(),
              message: message.message,
              intakeForm: message.intakeForm ? message.intakeForm : false,
              threadId: uid,
              status: "New"
            })
              .then(() => {
                console.log('succesfully added message to all user accounts');
              })
          });
        }
      })
  }
}

export function addMessageToGroupThread(messageInfo, attendees, userUid) {
  const batch = db.batch();
  const notificationBatch = db.batch()
  var attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails = []
  return (dispatch) => {
    // return db.collection
    return db
      .collection('users')
      .doc(messageInfo.messageSentFrom.id)
      .collection('calendar-events')
      .doc(messageInfo.messageId)
      .get()
      .then((snapshot) => {
        snapshot = snapshot.data();
        snapshot.groupChatMessages = [
          ...snapshot.groupChatMessages,
          {
            messageSentFrom: messageInfo.messageSentFrom,
            message: messageInfo.message,
          },
        ];
        for (var x = 0; x < attendees.length; x++) {
          var currentAttendee = attendees[x];
          let conditionalId
          if (currentAttendee.id) {
            conditionalId = currentAttendee.id
          } else {
            conditionalId = currentAttendee.uid
          }

          const attendeeDocRef = db
            .collection('users')
            .doc(conditionalId)
            .collection('calendar-events')
            .doc(messageInfo.messageId);
          batch.set(attendeeDocRef, snapshot);
          if (conditionalId !== userUid) {
            console.log('conditionalId', currentAttendee)
            // adding users to array for send grid email who have this feature enabled
            if (currentAttendee.receiveMessageNotificationEmails) {
              attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails.push(currentAttendee)
            }
            const attendeeNotificationDocRef = db
              .collection('users')
              .doc(conditionalId)
              .collection('notifications')
              .doc();
            notificationBatch.set(attendeeNotificationDocRef, {
              type: "New Group Chat Message",
              from: messageInfo.messageSentFrom,
              createdDate: new Date(),
              message: messageInfo.message,
              threadId: messageInfo.messageId,
              status: "New"
            }, { merge: true })
          }
        }
        return batch.commit().then(() => {
          return notificationBatch.commit().then(() => {
            console.log('succesfully added message to all user accounts',
              attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails, messageInfo);

            if (attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails.length > 0) {
              console.log('messageInfomessageInfo', attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails, messageInfo)
              sendMessageNotificationEmail(attendeesWithoutCurrentUserAndReceiveMessageNotificationEmails, messageInfo)
            }
          })
        });
        // });
      });
  };
}

// Get Users for Users Panel
export function listenToThreadsForUser(store, uid) {
  return db
    .collection('threads')
    .where('parties', 'array-contains', uid)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const id = change.doc.id;
        switch (change.type) {
          case 'added':
            store.dispatch(addThread(id, data));
            store.dispatch(
              addUserListener(listenToMessagesForThread(store, uid, id))
            );
            store.dispatch(
              addUserListener(listenToInvitationsForThread(store, uid, id))
            );
            break;
          case 'modified':
            store.dispatch(modifyThread(id, data));
            break;
          case 'removed':
            store.dispatch(removeThread(id));
            break;
        }
      });
    });
}

export function listenToMessagesForThread(store, uid, tid) {
  return db
    .collection('threads')
    .doc(tid)
    .collection('messages')
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const id = change.doc.id;
        switch (change.type) {
          case 'added':
            store.dispatch(addMessage(tid, data));
            break;
          default:
            console.warn('Unsupported message operation:', change.type);
        }
      });
    });
}

export function listenToInvitationsForThread(store, uid, tid) {
  return db
    .collection('threads')
    .doc(tid)
    .collection('invitations')
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const id = change.doc.id;
        switch (change.type) {
          case 'added':
          case 'modified':
            store.dispatch(setInvitation(tid, id, data));
            break;
          case 'removed':
            store.dispatch(removeInvitation(tid, id));
            break;
          default:
            console.warn('Unsupported message operation:', change.type);
        }
      });
    });
}

export function startCreateThread(thread, message, uid) {
  console.log('startCreateThread', thread, message);
  return (dispatch) => {
    thread = { createdAt: new Date(), ...thread };
    return db
      .collection('threads')
      .add(thread)
      .then((doc) => {
        console.log('startCreateThread', doc.id);
        dispatch(
          updateCurrentThread({ ...thread, tid: doc.id, documents: [] }, true)
        );
        if (message) {
          let messageDoc = { createdAt: new Date(), message, uid };
          return db
            .collection('threads')
            .doc(doc.id)
            .collection('messages')
            .add(messageDoc)
            .then(() => doc.id);
        } else {
          return Promise.resolve(doc.id);
        }
      });
  };
}

export function startArchiveThread(tid) {
  return startModifyThread(tid, { archived: true });
}

export function startArchiveThreadsForInvestment(cid) {
  return (dispatch) => {
    const results = [];
    db.collection('threads')
      .where('cid', '==', cid)
      .get()
      .then((snapshot) =>
        snapshot.forEach((thread) => {
          results.push(thread.ref.update({ archived: true }));
        })
      )
      .then(() => Promise.all(results));
  };
}

export function startModifyThread(tid, data) {
  return (dispatch) => {
    return db.collection('threads').doc(tid).update(data);
  };
}

export function startAddMessage(tid, data) {
  return (dispatch) => {
    let messageDoc = { createdAt: new Date(), ...data };
    return db.collection('threads').doc(tid).collection('messages').add(messageDoc);
  };
}

export function startInvitation(tid, email, data) {
  return (dispatch) => {
    let invitationDoc = { tid, email, createdAt: new Date(), ...data };
    return db
      .collection('threads')
      .doc(tid)
      .collection('invitations')
      .doc(email)
      .set(invitationDoc);
  };
}

export function updateCurrentThread(data, replace) {
  return {
    type: 'THREADS_UPDATE_CURRENT',
    data,
    replace,
  };
}

export function addThread(id, data) {
  return {
    type: 'THREADS_ADD',
    data,
    id,
  };
}

export function modifyThread(id, data) {
  return {
    type: 'THREADS_MODIFY',
    data,
    id,
  };
}

export function removeThread(id) {
  return {
    type: 'THREADS_REMOVE',
    id,
  };
}

export function setCurrentThread(id) {
  return {
    type: 'THREADS_SET_CURRENT',
    id,
  };
}

export function addMessage(id, data) {
  return {
    type: 'THREADS_ADD_MESSAGE',
    id,
    data,
  };
}

export function setInvitation(id, email, data) {
  return {
    type: 'THREADS_SET_INVITATION',
    id,
    email,
    data,
  };
}

export function removeInvitation(id, email) {
  return {
    type: 'THREADS_REMOVE_INVITATION',
    id,
    email,
  };
}

export function setThreadDocument(id, fileNo, data) {
  return {
    type: 'THREADS_SET_DOCUMENT',
    id,
    fileNo,
    data,
  };
}

// ##############################
// // // Dashboard View styles
// #############################

import {
  bigNumbers,
  primaryColor,
  dangerColor,
  successColor,
  tooltip,
  bodyText,
  card,
  cardTitle,
  cardSubtitle,
  title
} from "assets/jss/material-dashboard-pro-react.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import cardImagesStyles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const dashboardStyle = theme => ({
  ...modalStyle(theme),
  ...cardImagesStyles,
  // Profile Pic
  profilePic: {
    borderRadius: "50%",
    display: "block",
    width: "120px",
    height: "120px",
    margin: "-20px auto 10px auto",
    boxShadow: "0px 0px 9px 1px #000",
    [theme.breakpoints.up('md')]: {
      margin: '0px'
    }
  },
  userPic: {
    borderRadius: "50%",
    display: "block",
    width: "300px",
    height: "300px",
    margin: "-20px auto 10px auto",
    boxShadow: "0px 0px 9px 1px #000",
    // [theme.breakpoints.up('md')]: {
    //   margin: '0px'
    // }
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
  },
  dangerIcon: {
    color: dangerColor
  },
  successIcon: {
    color: successColor
  },
  bigNumbers: {
    ...bigNumbers
  },
  ...card,
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    color: "#000",
    paddingTop: "40px"
  },
  title,
  modalTitle: {
    ...title,
    color: "#fff",
    marginBottom: '0',
    marginTop: '0'
  },
  subTitle: {
    ...title,
    color: primaryColor,
    marginTop: "0",
    marginBottom: "0"
  },
  body: {
    color: "#000",
  },
  helloText: {
    fontFamily: "Billion Dreams",
    color: "#FFF",
    fontSize: "40px !important",
    letterSpacing: "4px"
  },
  nameText: {
    fontFamily: "Nova",
    color: "#474747",
    fontSize: "54% !important",
    letterSpacing: "2px"
  },
  cardSubtitle: {
    ...title,
    marginTop: "0",
    marginBottom: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  tipiGraphAndTable: {
    margin: "0px auto",
    display: "flex",
    maxHeight: "500px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#999999",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  editIcon: {
    color: "#FFF",
    backgroundColor: "#4F4F4F",
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    padding: "10px",
    zIndex: "500",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "30px"
    }
  },
  imageContainer: {
    margin: "10px 0 0 0"
  },
  progressBar: {
    margin: "25px 0 0 0"
  },
  attributeDescription: {
    margin: "10px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      textAlign: "center"
    }
  },
  attributeDescriptionDetail: {
    margin: "10px 0 0 0",
    lineHeight: "45px",
    [theme.breakpoints.down("md")]: {
      lineHeight: "30px",
    }
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    margin: "30px",
    [theme.breakpoints.down("md")]: {
      margin: "30px 0px",
    }
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  blueButton: {
    color: "#fff",
    backgroundColor: "#4775FB",
    borderRadius: "50%",
    height: "80px",
    padding: "20px",
    "&:hover": {
      backgroundColor: "#92CD91",
    }
  },
  declineButton: {
    color: "#fff",
    backgroundColor: "#4775FB",
    borderRadius: "50%",
    height: "80px",
    padding: "20px",
    "&:hover": {
      backgroundColor: "red",
    }
  },
  profileButton: {
    color: "#fff",
    background: "linear-gradient(270deg, rgba(39,75,115,1) 0%, rgba(97,164,159,1) 50%, rgba(0,212,255,1) 100%)",
    fontSize: "13px",
    borderRadius: "5px",
    margin: "14px 0px 0 0px",
    width: "100%"
  },
  matchModal: {
    background: "linear-gradient(180deg, rgba(39,75,115,1) 0%, rgba(97,164,159,1) 100%)",
    height: "100vh",
    margin: "auto 0 auto 0"
  },
  modalBody: {
    color: "#fff",
    textAlign: "center",
    marginTop: "50px"
  },
  matchImage: {
    height: "20vh",
    width: "34vw",
    borderRadius: "50%",
    border: "3px solid #fff"
  },
  sendMessageButton: {
    background: "linear-gradient(270deg, rgba(146,205,145,1) 0%, rgba(206,138,89,1) 100%)",
    width: "100%",
    marginTop: "20px"
  },
  backButton: {
    background: "linear-gradient(90deg, rgba(97,164,159,1) 0%, rgba(146,205,145,1) 100%)",
    width: "100%",
    marginTop: "20px"
  },
  exploreCard: {
    // zIndex: "10",
    // color: "black"
  },
  fiveFactorButton: {
    margin: "0 auto 0 auto",
    display: "block"
  },
  detailsMapGridContainer: {
    width: "93%",
    margin: "0px auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    }
  },
  detailsMapCard: {
    width: "98%",
    margin: "0px auto",
    padding: "70px",
    [theme.breakpoints.down("md")]: {
      width: "93%",
      padding: "0.9375rem 20px",
    }
  },
  detailsMapCardBody: {
    padding: "100px",
    [theme.breakpoints.down("md")]: {
      padding: "0.9375rem 20px",
    }
  },
  picAndNameGridItem: {
    display: 'flex',
    paddingTop: "20px !important",
  },
  centerGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center"
  },
  paddingTopGridItem: {
    paddingTop: '50px !important'
  },
  value: {
    fontSize: '30px',
    color: "#474747",
    textAlign: "center",
    fontWeight: 500
  },
  descriptor: {
    fontSize: '17px',
    color: "#474747",
    textAlign: "center",
    fontWeight: 500
  },
  tableCard: {
    background: '#FFFFFF',
    border: '1px solid #72C5C8',
    borderRadius: '21px'
  },
  chatMessenger: {
    bottom: "50px",
    position: "absolute"
  }
});

export default dashboardStyle;

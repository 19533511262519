import { db, firebase, getAccountBalances } from 'firebase/fbConfig.js';

export const getAccounts = (uid) => {
  return (dispatch) => {
    let accountsToReturn = {};
    db.collection('users').doc(uid).collection('plaid').get()
    .then((snapshot) => {
      let i = 0;
      snapshot.docs.map((doc) => {
        getAccountBalances({ accessToken: doc.data().access_token, userId: uid })
          .then((accountsArray) => {
            if (accountsArray.data) {
              accountsArray.data.forEach((account) => {
                if (account.account_id && !accountsToReturn[account.account_id]) {
                  accountsToReturn[account.account_id] = account;
                }
              })
            }
          })
          .then(() => {
            i += 1;
            if (i === snapshot.docs.length) {
              dispatch(setAccounts(accountsToReturn));
            }
          })
      })
    })
  }
};

export const getStoredAccounts = (uid) => {
  return (dispatch) => {
    db.collection('users').doc(uid).collection('accounts').get()
      .then((snapshot) => {
        dispatch(setStoredAccounts(snapshot.docs.map((doc) => {
          return doc.data();
        })));
      })
  }
}

export const submitAsset = (asset, uid) => {
  return (dispatch) => {
    db.collection('users').doc(uid).collection('accounts').add(asset)
      .then(async (doc) => {
        const docRef = db.collection('users').doc(uid).collection('groups').doc(asset.parent_group_id);
        const document = await docRef.get();
        const accounts = document.data().accounts;
        accounts.push(doc.id);
        docRef.update({ accounts: accounts });
        dispatch(setAsset(asset, doc.id));
      })
      .catch((err) => {
        console.log('Error with adding to Firestore', err);
      })
  }
}

export const updateAsset = (asset, uid) => {
  return (dispatch) => {
    db.collection('users').doc(uid)
  }
}

export const submitGroup = (groupName, uid, callback) => {
  return async (dispatch) => {
    const group = {
      title: groupName,
      accounts: [],
      children: [],
      parent_group_id: ""
    };
    await db.collection('users').doc(uid).collection('groups').add(group)
      .then((doc) => {
        callback(doc.id);
        dispatch(setGroup(group, doc.id));
      })
  }
}

export const getGroups = (uid) => {
  return (dispatch) => {
    db.collection('users').doc(uid).collection('groups').get()
      .then((snapshot) => {
        dispatch(setGroups(snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            group_id: doc.id
          };
        })));
      })
  }
}

export const addToGroup = (uid, accountId, groupId) => {
  return (dispatch) => {
    const userRef = db.collection('users').doc(uid);
    const accountRef = userRef.collection('accounts').doc(accountId);
    const groupRef = userRef.collection('groups').doc(groupId);

    accountRef.set({ parent_group_id: groupId }, { merge: true })
      .then(async () => {
        const group = await groupRef.get();
        const accounts = group.data().accounts;
        if (!accounts.includes(accountId)) {
          accounts.push(accountId);
        }
        groupRef.set({ accounts: accounts }, { merge: true })
          .then(() => {
            dispatch(updateGroup(accountId, groupId));
          })
          .catch((err) => { console.log('Error with setting group: ', err) });
      })
      .catch((err) => { console.log('Error with setting account: ', err) });
  }
}

export const updateGroup = (accountId, groupId) => {
  return {
    type: "UPDATE_GROUP",
    accountId,
    groupId
  }
}

export const setGroup = (group, groupId) => {
  return {
    type: "SET_GROUP",
    group,
    groupId
  }
}

export const setGroups = (groups) => {
  return {
    type: "SET_GROUPS",
    groups
  }
}

export const setStoredAccounts = (accounts) => {
  return {
    type: "SET_STORED_ACCOUNTS",
    accounts
  }
}

export const setAccounts = (accounts) => {
  return {
    type: "SET_ACCOUNTS",
    accounts
  }
}

export const setAsset = (asset, id) => {
  return {
    type: "SET_ASSET",
    asset,
    id
  }
}
import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import Video from './Video';

export const MeVideo = (props) => {
  //Get state from redux store
  const producers = useSelector((state) => state.producers);
  const me = useSelector((state) => state.me);
  const webcamInProgress = useSelector((state) => state.me.webcamInProgress);
  const shareInProgess = useSelector((state) => state.me.shareInProgess);

  const connected = useSelector((state) => state.room.connected);

  //Process data
  const producersArray = Object.values(producers);
  const audioProducer = producersArray.find(
    (producer) => producer.track.kind === 'audio'
  );
  const videoProducer = producersArray.find(
    (producer) => producer.track.kind === 'video'
  );

  return (
    <div>
      {webcamInProgress == true || shareInProgess == true ? (
        <Video
          videoTrack={videoProducer ? videoProducer.track : null}
          audioTrack={audioProducer ? audioProducer.track : null}
          id={me.id}
          isMe
        />
      ) : (
        <h1>no video</h1>
      )}

      {connected && <p>connected</p>}
    </div>
  );
};

export default MeVideo;

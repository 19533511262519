import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { useHistory } from 'react-router';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import ChartistGraph from "react-chartist";

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        visibility: "hidden !important",
        color: "#74EAE3",
        '&$checked': {
          color: "#74EAE3",
        },
      },
    },
  },
});
export class SixthQuestionGlobalResults extends Component {



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.sixthQuestion) {
      this.props.history.push('/financial-knowledge-results', {
        state: {
          values,
        }
      });
    }
  }

  render() {
    const { values, handleToggle, classes, globalTestResults, compare } = this.props;


    const usersSelection = values[compare]
    const MaxNumber = Math.max.apply(Math, Object.values(globalTestResults[compare]))
    const totalVotes = Object.values(globalTestResults[compare]).reduce((a, b) => a + b, 0)

    console.log('usersSelection', Math.floor(globalTestResults[compare].incorrect / totalVotes * 100))
    const percentageOfPeopleWhoGotItWrong = Math.floor(globalTestResults[compare].incorrect / totalVotes * 100)

    const delays2 = 80,
      durations2 = 500;

    const simpleBarChart = {
      data: {
        labels: [
          "Correct",
          "Incorrect"
        ],
        series: [[globalTestResults[compare].correct, globalTestResults[compare].incorrect]]
      },
      options: {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        },
        stretch: true
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            // axisX: {
            //   labelInterpolationFnc: function(value) {
            //     return value[0];
            //   }
            // }
          }
        ]
      ],
      animation: {
        draw: function (data) {
          if (data.type === "bar") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: "ease"
              }
            });
          }
        }
      }
    };

    return (
      <div className={classes.quizGlobalResults134vh}>

        <span style={{
          fontFamily: "Nova", fontStyle: 'normal', fontWeight: 400,
          fontSize: '27px', color: '#4F4F4F', width: "100%"
        }}>Did You Know?</span>

        {usersSelection === 'correct' ? (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}>You Answered Correctly! This is how others did.</span>
        ) : (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: 'red', width: "100%", margin: "15px 0px"
          }}>{percentageOfPeopleWhoGotItWrong}% of voters also got this wrong.</span>
        )}

        <ChartistGraph
          className="ct-chart-blue-colors"
          style={{ width: "365px !important" }}
          data={simpleBarChart.data}
          type="Bar"
          options={simpleBarChart.options}
          responsiveOptions={simpleBarChart.responsiveOptions}
          listener={simpleBarChart.animation}
        />
        <p className={classes.questionDescription}>If someone did not understand how compound interest operates, they might conclude that borrowing at 20 percent per year would lead to doubling in five years. However, someone who knew about interest on interest would understand that the answer would be somewhat less than five years. Further, someone who knows the 'rule of 72' heuristic would know that it would be about 3.6 years, which makes the correct answer "2 to 4 years." In finance, the rule of 72 is a method for estimating an investment's doubling time. The rule number (i.e., 72) is divided by the interest percentage per period to obtain the approximate number of periods (usually years) required for doubling. The other responses reflect a misunderstanding of the concept of interest accrual.</p>
        <GridContainer className={classes.wealthManagementCheckboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    style={{ display: "hidden" }}
                    defaultChecked={usersSelection === 1 ? true : false}
                    sx={{
                      display: "none",
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#74EAE3"
                      }
                    }}
                  />
                </ThemeProvider>}
              label={`Less than 2 years`}
              className={classes.checkboxText}
            />

          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={usersSelection === 2 ? true : false}
                />}
              label={`2 to 4 years (Correct Answer)`}
              style={{ color: "green" }}
              className={classes.checkboxText}
            />
          </GridItem>

          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    style={{ display: "hidden" }}
                    defaultChecked={usersSelection === 1 ? true : false}
                    sx={{
                      display: "none",
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#74EAE3"
                      }
                    }}
                  />
                </ThemeProvider>}
              label={`5 to 9 years`}
              className={classes.checkboxText}
            />
          </GridItem>

          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    style={{ display: "hidden" }}
                    defaultChecked={usersSelection === 1 ? true : false}
                    sx={{
                      display: "none",
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#74EAE3"
                      }
                    }}
                  />
                </ThemeProvider>}
              label={`10 or more years`}
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>


        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft
                  onClick={this.back}
                  className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>6 / 6</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>

        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={33}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(Style)(SixthQuestionGlobalResults));
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import { saveStocks, sendSMS, createLinkToken, getAccountBalances } from 'firebase/fbConfig.js';
import PlaidLink from 'components/PlaidLink/PlaidLink.jsx';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Hidden from '@material-ui/core/Hidden';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/stocksStyle';

import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/stocksTitle.png';

import Loader from 'components/Loader';

import { getStocks, saveFavorite } from 'store/actions/stocksActions.jsx';

export function StocksPage(props) {

    const { classes, history, user, getStocks, stocks, dataLoaded, uid, saveFavorite } = props;

    const [favorites, setFavorites] = useState([]);
    const [sorted, setSorted] = useState(false);
    const [localStocks, setLocalStocks] = useState([]);

    const sortFavorites = () => {
      if (sorted) {
        setLocalStocks(stocks);
        setSorted(false);
      } else {
        setLocalStocks(stocks.map((stock) => {
          if (favorites.includes(stock.symbol)) {
            return stock;
          }
        }).filter(Boolean));
        setSorted(true);
      }
    }

    const sendText = async () => {
      const body = 'testMessage';
      const from = '+13146972776';
      let to = `+12137033940`;
      const returnSMS = await sendSMS({
          body, from, to
      })
    }

    const sliceName = (display, symbol) => {
      if (display && display.length < 20) {
        return display;
      }
      return symbol;
    }

    const renderFavoritesButton = (stock) => {
      if (favorites.includes(stock.symbol)) {
        return <Favorite onClick={() => saveFavorite(user.id, stock)} className={classes.favoritesButton}/>;
      } else {
        return <FavoriteBorder onClick={() => saveFavorite(user.id, stock)} className={classes.favoritesButton} style={{ color: "#4F4F4F" }} />;
      }
    }

    const showAll = () => {
      setLocalStocks(stocks);
      setSorted(false);
    }

    useEffect(() => {
      getStocks();
    }, [])

    useEffect(() => {
      setLocalStocks(stocks);
    }, [dataLoaded])

    useEffect(() => {
      if (user.stocks) {
        setFavorites(user.stocks.map((stock) => stock.symbol));
      }
    }, [user])

    if (!localStocks) {
      return <Loader customStyle={{ width: "107%", marginLeft: "-1rem" }}/>
    }

    return (
        <div className={classes.container}>

            {/* mobile */}
            <Hidden mdUp>
                <div style={{ textAlign: "center" }}>
                  <img src={AssetTitle} style={{ margin: "50px 15px", maxHeight: "6rem" }} />
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <input style={{ width: "70%" }} className={classes.searchBar} type="text" placeholder="Search Investments..." />
                  </div>
                  {/* <Button onClick={()=>getAccountBalances({ accessToken: 'access-sandbox-b1a8d720-de7d-45f5-a27d-18d6a0c9af04' }).then((res) => console.log('look res', res))}>GET ACCOUNT BALANCES</Button>
                  <PlaidLink userId={user.id} /> */}
                </div>
                <div className={classes.gridContainerDiv}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Button onClick={showAll} style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        All
                      </Button>
                      <Button style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Type
                      </Button>
                      <Button onClick={sortFavorites} style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Favorites
                      </Button>
                      <Button style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Popular
                      </Button>
                    </div>
                    <GridContainer style={{
                      textAlign: "center",
                      marginTop: "10px",
                    }}>
                      {dataLoaded && localStocks ? localStocks.map((stock) => {
                        return (
                          <GridItem  xs={5} style= {{ border: "1.5px solid #72C5C8", borderRadius: "32px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", padding: "0 8px !important", margin: "8px auto", maxHeight: "15vh", background: "white", minWidth: "45%", minHeight: "100%" }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Link to={`/stock-details/?symbol=${stock.symbol}`}>
                                  <p style={{ color: "#4F4F4F", fontWeight: 800, fontSize: "100%", maxHeight: "100%", height: "1rem", minHeight: "100%", margin: "10px 5px 30px" }}>{sliceName(stock.displayName, stock.symbol)}</p>
                              </Link>
                              {renderFavoritesButton(stock)}
                            </div>
                            <div>
                              <h3 style={{ margin: 0, fontWeight: 900, fontSize: "22px", color: "#4F4F4F" }}>${stock.regularMarketPrice}</h3>
                              <p style={stock.regularMarketChangePercent > 0 ? { color: "#55DA53", fontWeight: 900, fontSize: "14px" } : { color: "red", fontWeight: 900, fontSize: "14px" }}>{stock.regularMarketChangePercent > 0 ? '⬆︎' : '⬇︎'}{stock.regularMarketChangePercent}%</p>
                            </div>
                          </GridItem>
                        )
                      }) : null}
                    </GridContainer>
                </div>
            </Hidden>


            {/* desktop */}
            <Hidden smDown>
            <div style={{ textAlign: "center" }}>
                  <div style={{ display: "flex", justifyContent: "space-around", marginTop: "8rem" }}>
                    <input style={{ width: "70%" }} className={classes.searchBar} type="text" placeholder="Search Investments..." />
                  </div>
                  {/* <Button onClick={()=>getAccountBalances({ accessToken: 'access-sandbox-b1a8d720-de7d-45f5-a27d-18d6a0c9af04' }).then((res) => console.log('look res', res))}>GET ACCOUNT BALANCES</Button>
                  <PlaidLink userId={user.id} /> */}
            </div>
                <div className={classes.gridContainerDiv}>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "0 4rem" }}>
                      <Button onClick={showAll} style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        All
                      </Button>
                      <Button style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Type
                      </Button>
                      <Button onClick={sortFavorites} style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Favorites
                      </Button>
                      <Button style={{ background: "#315A7A", width: "25%", borderRadius: "15px" }}>
                        Popular
                      </Button>
                    </div>
                    <GridContainer style={{
                      textAlign: "center",
                      marginTop: "10px",
                      padding: "3rem"
                    }}>
                      {dataLoaded && localStocks ? localStocks.map((stock) => {
                        return (
                          <GridItem  xs={5} style= {{ border: "1.5px solid #72C5C8", borderRadius: "32px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", padding: "0 8px !important", margin: "8px auto", maxHeight: "15vh", background: "white", minWidth: "45%", minHeight: "100%" }}>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Link to={`/stock-details/?symbol=${stock.symbol}`}>
                                  <p style={{ color: "#4F4F4F", fontWeight: 800, fontSize: "100%", maxHeight: "100%", height: "1rem", minHeight: "100%", margin: "10px 5px 30px" }}>{sliceName(stock.displayName, stock.symbol)}</p>
                              </Link>
                              {renderFavoritesButton(stock)}
                            </div>
                            <div>
                              <h3 style={{ margin: 0, fontWeight: 900, fontSize: "22px", color: "#4F4F4F" }}>${stock.regularMarketPrice}</h3>
                              <p style={stock.regularMarketChangePercent > 0 ? { color: "#55DA53", fontWeight: 900, fontSize: "14px" } : { color: "red", fontWeight: 900, fontSize: "14px" }}>{stock.regularMarketChangePercent > 0 ? '⬆︎' : '⬇︎'}{stock.regularMarketChangePercent}%</p>
                            </div>
                          </GridItem>
                        )
                      }) : null}
                    </GridContainer>
                </div>
            </Hidden>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getStocks: () => dispatch(getStocks()),
    saveFavorite: (uid, stock) => dispatch(saveFavorite(uid, stock))
});

const mapStateToProps = (state) => ({
    user: state.user.user,
    stocks: state.stocks.stocks.data.quotes,
    dataLoaded: state.stocks.stocks.dataLoaded
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(StocksPage);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { db, firebase } from '../../firebase/fbConfig.js';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ChartistGraph from "react-chartist";
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import moment from "moment";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { useHistory } from 'react-router';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Check from '@material-ui/icons/Check';
import loader from 'assets/img/loader.gif';

// card images
import fiveFactor from "assets/img/wealth-env/five-factor.png";
import wealthManagement from "assets/img/wealth-env/wealth-management.png";
import demographics from "assets/img/wealth-env/demographics.png";
import financialKnowledge from "assets/img/wealth-env/financial-knowledge.png";
import preferences from "assets/img/wealth-env/preferences.png";
import riskProfiling from "assets/img/wealth-env/risk-profiling.png";

import Slider from "@material-ui/core/Slider";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Tooltip, IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';
import { ReactComponent as QuizEnvTitle } from 'assets/img/quizEnvTitle.svg';
import { ReactComponent as QuestionMark } from 'assets/img/questionMark.svg';


const incompleteMuiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#FFFF",
            },
            track: {
                color: "#FFFF",
                height: "15px !important",
            },
            rail: {
                color: "#75E8E2 ",
                height: "15px !important",
            }
        }
    }
});

const completedMuiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#FFFF",
            },
            track: {
                color: "#FFFF",
                height: "15px !important",
            },
            rail: {
                color: "#4672FB",
                height: "5px !important",
            }
        }
    }
});

const quizzesMarks = [
    {
        value: 0,
        label: 'Started',
    },
    {
        value: 100,
        label: 'Complete',
    },
];

export function QuizzesPage(props) {


    //  TODO Conditional button rendering to retake quiz if the user has already taken the quiz, currently overrides firestore collection
    const { classes } = props;
    const [factorQuizList, setFactorQuizList] = useState([])
    const [fianancialQuizList, setFinancialQuizList] = useState([])
    const [demoQuizList, setDemoQuizList] = useState([])
    const [riskQuizList, setRiskQuizList] = useState([])
    const [preferencesQuizList, setPreferencesQuizList] = useState([])
    const [quizzesTotal, setQuizzesTotal] = useState(0)
    const [quizDataLoaded, setQuizDataLoaded] = useState(false)
    const [riskToleranceQuizList, setRiskToleranceQuizList] = useState([])
    const [wealthQuizList, setWealthQuizList] = useState([])


    const history = useHistory();

    const quizNames = [{ name: 'Financial Knowledge', link: 'financial-knowledge', data: [...fianancialQuizList] }, { name: 'Demographics', link: 'demographics', data: [...demoQuizList] }, { name: 'Wealth Management', link: 'wealth-management', data: [...wealthQuizList] }, { name: 'TIPI Five Factor Personality', link: 'five-factor', data: [...factorQuizList] }, { name: 'Risk Tolerance', link: 'risk-tolerance', data: [...riskToleranceQuizList] }]


    return (
        <div className={classes.container}>
            {/* Mobile View  */}
            <Hidden mdUp implementation="css">
                <QuizEnvTitle className={classes.quizEnvTitle} />
                <span className={classes.startLearning}>Let's Start Learning</span>
                <GridContainer className={classes.quizTotalContainer}>
                    <GridItem className={classes.quizTotalGridItem} xs={12}>
                        <div className={classes.graphDiv}>
                            <span>{quizzesTotal} / 5</span>
                            <span className={classes.completedText}>Completed</span>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer className={classes.quizzesContainer}>
                    <GridItem xs={12}>
                        {quizNames.map((d) => {
                            return (
                                <Card className={classes.cardForQuiz}>
                                    <GridContainer className={classes.cardForQuizContainer}>
                                        <GridItem xs={8}>
                                            <div style={{ display: "flex", flexDirection: 'column' }}>
                                                {d.data.length > 0 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ fontSize: "75%" }}>{d.name}</span>
                                                        <Check />
                                                    </div>
                                                ) : (
                                                    <span style={{ fontSize: "75%" }}>{d.name}</span>
                                                )}
                                                <ThemeProvider theme={completedMuiTheme}>
                                                    <Slider
                                                        className={classes.slider}
                                                        aria-label="Restricted values"
                                                        step={null}
                                                        valueLabelDisplay="auto"
                                                        disabled={true}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <Button onClick={() => history.push(`/${d.link}`)} className={classes.buttonToQuiz}>
                                                <ArrowRightAltIcon />
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <span className={classes.learnMore}>Learn More    <QuestionMark /></span>

                                        </GridItem>
                                    </GridContainer>
                                </Card>
                            )
                        })}
                    </GridItem>
                </GridContainer>
            </Hidden>









            {/* Desktop View  */}
            <Hidden smDown implementation="css">
                {/* <h3 className={classes.header}>TIPI 5 Factor Personality Quiz</h3> */}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card className={classes.quizCard}>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <Tooltip
                                        disableFocusListener
                                        disableTouchListener
                                        id="tooltip-top"
                                        title="Tagline: Short phrase that reflects your Desired Underlying Condition or Purpose Declaration. (Max of 10 words/1 line)"
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltip }}>
                                        <IconButton>
                                            <Info style={{ color: "#60D5C4" }} />
                                        </IconButton>
                                    </Tooltip>
                                </GridItem>
                                <GridItem xs={6}>
                                    <img src={fiveFactor} className={classes.cardImagePublic} />
                                </GridItem>
                                <GridItem xs={6}>
                                    <h5 className={classes.quizHeader}>TIPI 5 Factor Personality Quiz</h5>
                                    <p className={classes.quizDescription}>Our proprietary personality inventory will analyze different factors of your personality to help us build your profile and better match you with advisors, content, and more!</p>

                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6} style={{ display: "flex", margin: "auto" }}>
                                    <LiveHelpIcon style={{ color: "#315B7B" }} />
                                    <p style={{ fontWeight: 600 }}>10 questions</p>
                                </GridItem>
                                <GridItem className={classes.startQuiz} xs={6}>
                                    <NavLink to={'/pages/five-factor'}>
                                        <Button className={classes.takeQuizButton}>
                                            Start Quiz
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Hidden>

        </div >
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    }
    // nothing yet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(QuizzesPage);
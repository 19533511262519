import Profile from 'views/Profile/Profile.jsx';

// @material-ui/icons
import PersonIcon from '@material-ui/icons/Person';
import Contacts from '@material-ui/icons/Contacts';
import Work from '@material-ui/icons/Work';
import CalendarToday from '@material-ui/icons/CalendarToday';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Chat from '@material-ui/icons/Chat';
import LocalOffer from '@material-ui/icons/LocalOffer';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ChatIcon from '@material-ui/icons/Chat';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TrendingUp from '@material-ui/icons/TrendingUp';
import AddCircle from '@material-ui/icons/AddCircle';

// Page component imports
import Investments from '../views/Investments/Investments.jsx';
import InvestmentDetails from '../views/Investments/InvestmentDetails.jsx';
import OneOnOneChat from '../views/Conferencing/OneOnOneChat.jsx'
import Explore from '../views/Explore/Explore.jsx';
import AdvisorDetails from '../views/Explore/AdvisorDetails.jsx';
import Calendar from '../views/Calendar/CalendarTest.jsx';
import Notifications from '../views/Notifications/Notifications.jsx';
import ChatPage from '../views/Chat/Chat.jsx';
import Offers from '../views/Offers/Offers.jsx';
import Settings from '../views/Settings/Settings.jsx';
import Threads from '../views/Chat/Threads/Threads.jsx';
import ChatInvestmentsDetails from '../views/Chat/ChatInvestmentsDetails.jsx';
import InvestorThreads from '../views/Chat/InvestorThreads.jsx';
import EventsDetails from '../views/Calendar/EventsDetails.jsx';
import ThreadPermissions from '../views/Chat/Permissions/ThreadPermissions.jsx';
import InvestmentPermissions from '../views/Investments/Permissions/InvestmentPermissions.jsx';
import Setup from '../views/Setup/Setup.jsx';
import Legal from '../views/Setup/Legal.jsx';
import LegalDisclaimer from '../views/Setup/LegalDisclaimer.jsx';
import CreateInvestmentForm from '../components/Forms/CreateInvestmentForm.jsx';
import CreateFirmForm from '../components/Forms/CreateFirmForm.jsx';
import PrivacyPolicy from '../views/Setup/PrivacyPolicy.jsx';
import PendingInvestmentsDetails from '../views/Investments/PendingInvestmentsDetails.jsx';
import PersonalAssessmentsPage from '../views/Pages/PersonalAssessmentsPage.jsx';
import QuizzesPage from '../views/Pages/QuizzesPage.jsx';
import MatchesPage from '../views/Pages/MatchesPage.jsx';
import ConferencingPage from '../views/Pages/ConferencingPage.jsx';
import AddOnsPage from '../views/Pages/AddOnsPage.jsx';
import StocksPage from '../views/Pages/StocksPage.jsx';
import StockDetails from '../views/Stocks/StockDetails.jsx';
import Messages from '../views/Conferencing/Messages.jsx';
import GroupMessageThread from '../views/Conferencing/GroupMessageThread.jsx';
import VideoConference from '../views/Conferencing/VideoConference.jsx';
import VideoClasses from '../views/VideoClasses/VideoClasses.jsx';
import IntegrationsPage from '../views/Pages/IntegrationsPage.jsx';
import WalletPage from '../views/Pages/WalletPage.jsx';
import RewardsFeesPage from '../views/Pages/RewardsFeesPage.jsx';
import NotificationsPage from '../views/Pages/NotificationsPage.jsx';
import AdvisorPage from '../views/Pages/AdvisorPage.jsx';
import AssetsPage from '../views/Assets/Assets.jsx';
import AssetsForm from '../views/Assets/AssetsForm.jsx';
import ExpenseForm from '../views/Assets/ExpenseForm.jsx';
import GroupForm from '../views/Assets/GroupForm.jsx';
import WealthBucket from '../views/Assets/WealthBucket.jsx';
import CustomGroup from '../views/Assets/CustomGroup.jsx';
import FinancialKnowledge from '../views/Quizzes/FinancialKnowledge/FinancialKnowledge.jsx';
import FinancialWellness from '../views/Quizzes/FinancialWellness/FinancialWellness.jsx';
import FiveFactorModel from '../views/Quizzes/FiveFactorModel/FiveFactorModel.jsx';
import RiskProfile from '../views/Quizzes/RiskProfile/RiskProfile.jsx';
import WealthManagement from '../views/Quizzes/WealthManagement/WealthManagement.jsx';
import Demographics from '../views/Quizzes/Demographics/Demographics.jsx';

import Preferences from '../views/Quizzes/Preferences/Preferences.jsx';
import Results from '../views/Quizzes/FiveFactorModel/Results.jsx';
import Results2 from '../views/Quizzes/WealthManagement/Results.jsx';
import Results3 from '../views/Quizzes/FinancialKnowledge/Results.jsx';
import Results4 from '../views/Quizzes/RiskProfile/Results.jsx';

import WealthManagementResults from '../views/Quizzes/WealthManagement/Confirm.jsx'
import WealthManagementSuccess from '../views/Quizzes/WealthManagement/Success.jsx'

import FinancialKnowledgeResults from '../views/Quizzes/FinancialKnowledge/Confirm.jsx'
import FinancialKnowledgeSuccess from '../views/Quizzes/FinancialKnowledge/Success.jsx'

import DemographicsResults from '../views/Quizzes/Demographics/Confirm.jsx'
import DemographicsSuccess from '../views/Quizzes/Demographics/Success.jsx'

import RiskProfileResults from '../views/Quizzes/RiskProfile/Confirm.jsx'
import RiskProfileSuccess from '../views/Quizzes/RiskProfile/Success.jsx'


import FiveFactorResults from '../views/Quizzes/FiveFactorModel/Confirm.jsx'
import FiveFactorSuccess from '../views/Quizzes/FiveFactorModel/Success.jsx'


import RiskTolerance from '../views/Quizzes/RiskTolerance/RiskTolerance.jsx';
import RiskToleranceResults from '../views/Quizzes/RiskTolerance/Confirm.jsx'
import RiskToleranceSuccess from '../views/Quizzes/RiskTolerance/Success.jsx'

import IntakeForm from '../views/IntakeForm/IntakeForm'
// Import lobby and conferencing video views
// End call file view, batching data of h5 video player
// 2 types of video conferences, larger scale (meet route), small scale (max @ 4, call route).
import { ReactComponent as WhiteDiamondIcon } from 'assets/img/whiteDiamond.svg';

export default [
  {
    path: '/profile',
    name: 'Profile',
    icon: PersonIcon,
    component: Profile,
  },
  {
    path: '/quizzes',
    name: 'Quizzes',
    icon: HowToRegIcon,
    component: QuizzesPage,
  },
  {
    path: '/risk-tolerance-success',
    name: 'Quizzes',
    admin: true,
    component: RiskToleranceSuccess,
  },
  {
    path: '/intake-form/:id',
    name: 'Quizzes',
    admin: true,
    component: IntakeForm,
  },
  {
    path: '/risk-tolerance-results',
    name: 'Quizzes',
    admin: true,
    component: RiskToleranceResults,
  },
  {
    path: '/risk-tolerance',
    name: 'Quizzes',
    admin: true,
    component: RiskTolerance,
  },
  // TODO fix routing for quizzes to have /quizzes/quiz-name. Currently only showing Quizzes Page
  {
    path: '/financial-knowledge-success',
    admin: true,
    name: 'financial knowledge',
    component: FinancialKnowledgeSuccess,
  },
  {
    path: '/financial-knowledge-results',
    admin: true,
    name: 'financial knowledge',
    component: FinancialKnowledgeResults,
  },
  {
    path: '/financial-knowledge',
    admin: true,
    name: 'financial knowledge',
    component: FinancialKnowledge,
  },
  {
    path: '/results/financial-knowledge/:docID',
    admin: true,
    component: Results3,
  },
  {
    path: '/financial-wellness',
    admin: true,
    name: 'financial wellness',
    component: FinancialWellness,
  },
  {
    path: '/five-factor-success',
    admin: true,
    name: 'wealth management',
    component: FiveFactorSuccess,
  },
  {
    path: '/five-factor-results',
    admin: true,
    name: 'wealth management',
    component: FiveFactorResults,
  },
  {
    path: '/five-factor',
    admin: true,
    name: 'five factor model',
    component: FiveFactorModel,
  },
  {
    path: '/results/five-factor/:docID',
    admin: true,
    // name: 'five factor model',
    component: Results,
  },
  {
    path: '/risk-profile-success',
    admin: true,
    name: 'wealth management',
    component: RiskProfileSuccess,
  },
  {
    path: '/risk-profile-results',
    admin: true,
    name: 'wealth management',
    component: RiskProfileResults,
  },
  {
    path: '/risk-profile',
    admin: true,
    name: 'risk profile',
    component: RiskProfile,
  },
  {
    path: '/results/risk-profile/:docID',
    admin: true,
    component: Results4,
  },
  {
    path: '/wealth-management-success',
    admin: true,
    name: 'wealth management',
    component: WealthManagementSuccess,
  },
  {
    path: '/wealth-management-results',
    admin: true,
    name: 'wealth management',
    component: WealthManagementResults,
  },
  {
    path: '/wealth-management',
    admin: true,
    name: 'wealth management',
    component: WealthManagement,
  },
  {
    path: '/results/wealth-management/:docID',
    admin: true,
    component: Results2,
  },
  {
    path: '/demographics-results',
    admin: true,
    name: 'demographics',
    component: DemographicsResults,
  },
  {
    path: '/demographics-success',
    admin: true,
    name: 'demographics',
    component: DemographicsSuccess,
  },
  {
    path: '/demographics',
    admin: true,
    name: 'demographics',
    component: Demographics,
  },
  {
    path: '/preferences',
    admin: true,
    name: 'preferences',
    component: Preferences,
  },
  {
    path: '/matches',
    name: 'Matches',
    icon: EmojiPeopleIcon,
    component: MatchesPage,
  },
  {
    path: '/conferencing',
    name: 'Conferencing',
    icon: ChatIcon,
    // component: ChatPage,
    component: ConferencingPage,
  },
  {
    // path: '/conferencing/messages',
    path: '/messages',
    admin: true,
    name: 'Messages',
    component: Messages,
  },
  {
    // path: '/conferencing/messages',
    path: '/group-message/:id',
    admin: true,
    name: 'Messages',
    component: GroupMessageThread,
  },
  {
    // path: '/conferencing/video-conference',
    path: '/video-conference',
    name: 'Video Conference',
    icon: NotificationImportant,
    component: VideoConference,
  },
  {
    path: '/video-classes',
    admin: true,
    name: 'Video Classes',
    component: VideoClasses,
  },
  {
    path: '/add-group-form/custom-group',
    name: 'Assets',
    admin: true,
    icon: WhiteDiamondIcon, //Temp
    component: WealthBucket
  },
  {
    path: '/add-group-form/wealth-bucket',
    name: 'Assets',
    admin: true,
    icon: WhiteDiamondIcon, //Temp
    component: WealthBucket
  },
  {
    path: '/add-group-form',
    name: 'Assets',
    admin: true,
    icon: WhiteDiamondIcon, //Temp
    component: GroupForm
  },
  {
    path: '/add-expense-form',
    name: 'Assets',
    admin: true,
    icon: WhiteDiamondIcon, //Temp
    component: ExpenseForm
  },
  {
    path: '/add-asset-form',
    name: 'Assets',
    admin: true,
    icon: WhiteDiamondIcon, //Temp
    component: AssetsForm
  },
  {
    path: '/assets',
    name: 'Assets',
    icon: WhiteDiamondIcon, //Temp
    component: AssetsPage
  },
  {
    path: '/stocks',
    name: 'Stocks',
    icon: TrendingUp, //Temp
    component: StocksPage
  },
  {
    path: '/advisors',
    name: 'Advisors',
    component: AdvisorPage,
    admin: true
  },
  {
    path: '/stock-details/',
    name: 'Stock Details',
    admin: true, //Temp
    component: StockDetails
  },
  {
    path: '/add-ons',
    name: 'Add Ons',
    icon: AddCircle,
    component: AddOnsPage,
  },
  {
    path: '/achievements',
    name: 'Achievements',
    icon: AccountBalanceWalletIcon,
    component: WalletPage,
  },
  {
    path: '/rewards',
    admin: true,
    name: 'Rewards & Fees',
    component: RewardsFeesPage,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    icon: NotificationsActiveIcon,
    component: NotificationsPage,
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: SettingsApplications,
    component: Settings,
  },
  {
    path: '/investments/:type',
    admin: true,
    name: 'Investments',
    icon: Work,
    component: Investments,
  },
  {
    path: '/investment/:type/:id',
    admin: true,
    name: 'Investments',
    icon: Work,
    component: InvestmentDetails,
  },
  {
    path: '/investments',
    name: 'Investments',
    admin: true,
    component: Investments,
  },
  {
    path: '/explore',
    name: 'Explore',
    admin: true,
    component: Explore,
  },
  {
    path: '/chat',
    name: 'Chat',
    admin: true,
    component: ChatPage,
  },
  {
    path: '/integrations',
    admin: true,
    name: 'Integrations',
    component: IntegrationsPage,
  },
  // { path: '/calendar', name: 'Calendar', icon: CalendarToday, component: Calendar },
  // { path: "/retainers", name: "Retainers", icon: Assessment, component: Analytics },
  // { path: '/offers', name: 'Offers', icon: LocalOffer, component: Offers },
  // {
  //     path: '/notifications',
  //     name: 'Notifications',
  //     icon: NotificationImportant,
  //     component: Notifications,
  // },

  {
    path: '/threads/:id',
    admin: true,
    name: 'threads',
    component: Threads,
  },
  {
    path: '/:investmentid/investorthreads/:threadid',
    admin: true,
    name: 'threads',
    component: InvestorThreads,
  },
  {
    path: '/chat/:uid',
    admin: true,
    name: 'threads',
    component: OneOnOneChat,
  },
  {
    path: '/investment/:id',
    admin: true,
    name: 'threads',
    component: InvestmentDetails,
  },
  {
    path: '/pending-investment/:id',
    admin: true,
    name: 'threads',
    component: PendingInvestmentsDetails,
  },
  {
    path: '/event/:id',
    admin: true,
    name: 'threads',
    component: EventsDetails,
  },
  {
    path: '/investmentchat/:id',
    admin: true,
    name: 'threads',
    component: ChatInvestmentsDetails,
  },
  {
    path: '/threadshare/:id',
    admin: true,
    name: 'permissions',
    component: ThreadPermissions,
  },
  {
    path: '/investmentshare/:id/:role',
    admin: true,
    name: 'permissions',
    component: InvestmentPermissions,
  },
  {
    path: '/advisor/:id',
    admin: true,
    name: 'permissions',
    component: AdvisorDetails,
  },
  {
    path: '/setup',
    admin: true,
    name: 'Setup',
    component: Setup,
  },
  {
    path: '/setup/:investmentid',
    admin: true,
    name: 'Setup',
    component: Setup,
  },
  {
    path: '/legal',
    admin: true,
    name: 'Legal',
    component: Legal,
  },
  {
    path: '/legal-disclaimer',
    admin: true,
    name: 'Legal Disclaimer',
    component: LegalDisclaimer,
  },
  {
    path: '/create-investment',
    admin: true,
    name: 'Create Investment',
    component: CreateInvestmentForm,
  },
  {
    path: '/create-firm',
    admin: true,
    name: 'Create Firm',
    component: CreateFirmForm,
  },
  {
    path: '/privacy-policy',
    admin: true,
    name: 'Privacy Policy',
    component: PrivacyPolicy,
  },
  // { path: "/archived", admin: true, name: "Archived Investments", component: Archived },

  // {
  //   collapse: true,
  //   path: "/learn",
  //   name: "Learn",
  //   icon: DashboardIcon,
  //   state: "openLearn",
  //   views: [
  //     { path: "/learn/core-beliefs", name: "Core Beliefs", component: Profile },
  //     { path: "/learn/principles", name: "Principles", component: Profile },
  //     { path: "/learn/practices", name: "Practices", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/assess",
  //   name: "Assess",
  //   admin: true,
  //   icon: HowToRegIcon,
  //   state: "openAssess",
  //   views: [
  //     { path: "/assess/assessment", name: "Take the Assessment", component: Profile },
  //     { path: "/assess/path", name: "Path to Purposeful Leadership", component: Profile },
  //     { path: "/assess/purpose", name: "Purpose Embraced Path", component: Profile },
  //     { path: "/assess/stakeholders", name: "Stakeholders Engaged Path", component: Profile },
  //     { path: "/assess/strategy", name: "Strategy Aligned Path", component: Profile },
  //     { path: "/assess/actions", name: "Actions Aligned Path", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/grow",
  //   name: "Grow",
  //   icon: DashboardIcon,
  //   state: "openGrow",
  //   views: [
  //     { path: "/grow/articles", name: "Articles Library", component: Profile },
  //     { path: "/grow/tudies", name: "Research Studies Library", component: Profile },
  //     { path: "/grow/books", name: "Books Library", component: Profile },
  //     { path: "/grow/share", name: "Share a Resource", component: Profile },
  //     { path: "/grow/consultants", name: "Directory of Consultants", component: Profile },
  //     { path: "/grow/professionals", name: "Directory of Consultants", component: Profile },
  //     { path: "/grow/courses", name: "Upcoming Courses", component: Profile },
  //     { path: "/grow/archive", name: "Course Archive", component: Profile },
  //     { path: "/grow/suggest", name: "Suggest a Course or Webinar", component: Profile }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/connect",
  //   name: "Connect",
  //   icon: DashboardIcon,
  //   state: "openConnect",
  //   views: [
  //     { path: "/connect/communities", name: "Communities of Interest", component: Profile },
  //     { path: "/connect/blog", name: "Blog", component: Profile },
  //     { path: "/connect/newsletter", name: "Newsletter", component: Profile },
  //     { path: "/connect/radio", name: "Radio", component: Profile },
  //     { path: "/connect/group-calls", name: "Group Calls", component: Profile },
  //     { path: "/connect/group-calls", name: "Affliates", component: Profile },
  //     { path: "/connect/member", name: "Become a Member", component: Profile },
  //     { path: "/connect/partner", name: "Become a Partner", component: Profile },
  //     { path: "/connect/champion", name: "Become a Champion", component: Profile },
  //     { path: "/connect/sponsor", name: "Become a Sponsor or Donor", component: Profile },
  //     { path: "/connect/advocate", name: "Become an Advocate", component: Profile },
  //     { path: "/connect/marketplace", name: "Talent Marketplace", component: Profile }

  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/about",
  //   name: "About",
  //   icon: DashboardIcon,
  //   state: "openAbout",
  //   views: [
  //     { path: "/about/who-we-are", name: "Who we Are", component: Profile },
  //     { path: "/about/champions", name: "Our Champions", component: Profile },
  //     { path: "/about/bbard", name: "Our Board Members", component: Profile },
  //     { path: "/about/sponsors", name: "Our Sponsors", component: Profile },
  //     { path: "/about/memmbers", name: "Member Directory", component: Users }
  //   ]
  // },
  // { path: "/contact", name: "Contact", component: Profile },

  // { path: "/dashboard/apply", name: "Job Application", component: JobApplication },
  // {
  //   collapse: true,
  //   path: "/admin",
  //   name: "Admin",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/field",
  //   name: "Field",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/communications",
  //   name: "Communications",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/volunteers",
  //   name: "Volunteers",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/political",
  //   name: "Political",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/fundraising",
  //   name: "Fundraising",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/treasurer",
  //   name: "Treasurer",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/legal",
  //   name: "Legal",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/technology",
  //   name: "Technology",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // },
  // {
  //   collapse: true,
  //   path: "/scheduling",
  //   name: "Scheduling",
  //   icon: DashboardIcon,
  //   views: [
  //     { path: "/dashboard/brokering/orders", name: "Brokering: Orders", component: Orders },
  //     { path: "/dashboard/brokering/brokers", name: "Brokering: Brokers", component: Brokers }
  //   ]
  // }
];

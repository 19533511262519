import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { twilioToken } from 'firebase/fbConfig.js';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Lobby from './Lobby';
import Room from './Room';

import style from 'assets/jss/material-dashboard-pro-react/components/videoStyle';

const VideoChat = (props) => {
  const { classes, user } = props;
  const [username, setUsername] = useState('');
  const [token, setToken] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    const response = await twilioToken({ identity: username });
    setToken(response.data.token);
  }, [username]);

  const handleLogout = useCallback(event => {
    setToken(null);
  }, []);

  useEffect(() => {
    if (!user.firstName.includes('N/A')) {
      setLoaded(true);
    }
    setUsername(`${user.firstName} ${user.lastName}`);
  }, [user])

  let render;
  if (token) {
    render = (
      <Room token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
         handleSubmit={handleSubmit}
         loaded={loaded}
      />
    );
  }
  return render;
};

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = ({ auth, user, profile }) => {
  return {
    user: user.user
}};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(style)
)(VideoChat);
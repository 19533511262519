import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

// @material-ui/icons
import Close from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import loader from 'assets/img/loader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import Hidden from "@material-ui/core/Hidden";
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import ProfilePicUpload from 'components/CustomUpload/profilePicUpload';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import Transition from 'components/Transition';
import Style from 'assets/jss/material-dashboard-pro-react/views/profileStyle';
import { capitalize } from '../../util';
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";
import { NavLink } from "react-router-dom";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";


import Slider from "@material-ui/core/Slider";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Forms
import ProfileForm from 'components/Forms/profileForm.jsx';

// Assets
import DefaultProfile from 'assets/img/default-avatar.png';

import server from 'services/ServerService';

import * as Actions from 'store/actions/profileActions';


const muiTheme = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        padding: "13px 0",
      },
      thumb: {
        color: "#FFFF",
      },
      track: {
        color: "#FFFF",
        height: "15px !important",
      },
      rail: {
        color: "#75E8E2 ",
        height: "15px !important",
      }
    }
  }
});

const bestInClassMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '2.5K',
  },
  {
    value: 50,
    label: '5k',
  },
  {
    value: 75,
    label: '7.5K',
  },
  {
    value: 100,
    label: '10K',
  },
];


function valueLabelFormat(value) {
  return bestInClassMarks.findIndex((mark) => mark.value === value) + 1;
}

const Iframe = (classes) => {
  return (
    <div style={{ bottom: "50px", position: "absolute", zIndex:"10000", left: "30px" }}>
      <df-messenger
        chat-icon="https:&#x2F;&#x2F;storage.googleapis.com&#x2F;wealthenv-stateless&#x2F;2022&#x2F;10&#x2F;364396.png"
        intent="WELCOME"
        chat-title="Sally"
        agent-id="e9e07e7f-0e48-4072-934b-cfad61620f87"
        language-code="en"
        className={classes.chatMessenger}
      ></df-messenger>
    </div>
  )
}

const Profile = (props) => {

  const { classes, user, profileURL, uid } = props;
  const { userType } = user;
  const [quizDataLoaded, setQuizDataLoaded] = useState(false)
  const [updatePhotoModal, setUpdatePhotoModal] = useState(false)
  const [updateProfileModal, setUpdateProfileModal] = useState(false)
  const [sortedAttributes, setSortedAttributes] = useState([])
  const [highestScore, setHighestScore] = useState('')
  console.log('sortedAttributes', sortedAttributes)

  let profilePhoto = user.avatar || props.profileURL || DefaultProfile;

  useEffect(() => {
    if (!quizDataLoaded && uid) {
      const fetchAdvisorFiveFactor = async () => {
        const quizRef = db.collection("users").doc(uid).collection("quizzes").doc("fiveFactorQuiz").collection('quizLists').orderBy('timeStamp', 'desc')
        const docData = await quizRef.get()
        if (!docData.empty) {
          const mostRecentQuizScores = docData.docs[0].data()
          let highestNumber = 0
          let highestScoreTerm
          for (var key in mostRecentQuizScores) {
            if (typeof mostRecentQuizScores[key] === 'number' && mostRecentQuizScores[key] > highestNumber) {
              highestNumber = mostRecentQuizScores[key]
              highestScoreTerm = key
            }
          }
          console.log('highestScoreTerm', highestScoreTerm)
          setSortedAttributes(mostRecentQuizScores)
          setQuizDataLoaded(true)
          setHighestScore(highestScoreTerm)
        } else {
          setQuizDataLoaded(true)
        }
      }
      fetchAdvisorFiveFactor()
    }
  }, [uid])

  return (
    <GridContainer justify="center">
      {/* <Iframe /> */}
      <GridItem xs={11}>
        {uid && quizDataLoaded ? (
          <div>
            {/* MOBILE VIEW */}
            <Hidden mdUp implementation="css">
              <GridContainer>
                <GridItem className={classes.picAndNameGridItem} xs={12} sm={12} md={11}>
                  <GridContainer>
                    <GridItem className={classes.centerGridItem} xs={6}>
                      <EditOutlinedIcon className={classes.editIcon} onClick={props.handleOpenPhotoDialog} />
                      <img
                        className={classes.profilePic}
                        src={profilePhoto || DefaultProfile}
                        alt="User Photo"
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <span className={classes.helloText}>
                        Hello, <br /> <span className={classes.nameText}> {user.firstName} {user.lastName || null}!</span>
                      </span>
                      <hr style={{ borderTop: '1px solid #FFFFFF' }} />
                      <p style={{
                        fontWeight: 600,
                        fontSize: '16px'
                      }}>Risky Investor, Level 2</p>
                      <ThemeProvider theme={muiTheme}>
                        <Slider
                          className={classes.slider}
                          aria-label="Restricted values"
                          defaultValue={25}
                          valueLabelFormat={valueLabelFormat}
                          // getAriaValueText={valuetext}
                          step={null}
                          valueLabelDisplay="auto"
                          // marks={bestInClassMarks}
                          disabled={true}
                        />
                      </ThemeProvider>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={11}>
                  <GridContainer>
                    <GridItem xs={4}><h5 className={classes.value}>37</h5><p className={classes.descriptor}>Matches</p>
                    </GridItem>
                    <GridItem xs={4}><h5 className={classes.value}>5</h5><p className={classes.descriptor}>Activities Completed</p>
                    </GridItem>
                    <GridItem xs={4}><h5 className={classes.value}>2000</h5><p className={classes.descriptor}>Total XP</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem className={classes.picAndNameGridItem} xs={12} sm={12} md={11}>
                  <GridContainer className={classes.paddingTopGridItem}>
                    <GridItem xs={12}>
                      {Object.keys(sortedAttributes).length > 0 ? (
                        <Card className={classes.tableCard}>
                          <CardHeader style={{ margin: "auto" }}>
                            <h4 className={classes.cardTitle}>Personality Inventory (TIPI)</h4>
                          </CardHeader>
                          <CardBody>
                            <Radar
                              width={450}
                              height={450}
                              padding={70}
                              domainMax={10}
                              highlighted={null}
                              onHover={(point) => {
                                if (point) {
                                  //console.log('hovered over a data point');
                                } else {
                                  //console.log('not over anything');
                                }
                              }}
                              data={{
                                variables: [
                                  { key: 'extraversion', label: 'Extraversion' },
                                  { key: 'agreeableness', label: 'Agreeableness' },
                                  { key: 'conscientiousness', label: 'Conscientiousness' },
                                  { key: 'emotionalStability', label: 'Emotional Stability' },
                                  { key: 'opennessToExperiences', label: 'Openness to Experiences' },
                                ],
                                sets: [
                                  {
                                    key: 'everyone',
                                    label: 'Everyone',
                                    values: {
                                      extraversion: sortedAttributes.extraversionScore,
                                      agreeableness: sortedAttributes.agreeablenessScore,
                                      conscientiousness: sortedAttributes.conscientiousnessScore,
                                      emotionalStability: sortedAttributes.emotionalStabilityScore,
                                      opennessToExperiences: sortedAttributes.opennessExperiencesScore
                                    },
                                  },
                                ],
                              }}
                            />
                            <Table
                              tableHeaderColor="primary"
                              // tableHead={["Category", "Score"]}
                              tableData={[
                                ["Openness", sortedAttributes.opennessExperiencesScore],
                                ["Conscientiousness", sortedAttributes.conscientiousnessScore],
                                ["Extraversion", sortedAttributes.extraversionScore],
                                ["Agreeableness", sortedAttributes.agreeablenessScore],
                                ["Emotional Stability", sortedAttributes.emotionalStabilityScore],
                              ]}
                              coloredColls={[3]}
                              colorsColls={["primary"]}
                            />
                            {/* Top Attribute Description */}
                            {highestScore === 'extraversionScore' ?
                              (<div>
                                <p className={classes.attributeDescription}>
                                  Individuals who score high in extroversion are characterized by their sociability and
                                  talkativeness. They tend to be outgoing and gain energy in social situations. When speaking with
                                  others, they are often optimistic, cheerful, and enthusiastic about whatever topic they are
                                  speaking about. Due to this charismatic nature, they feel comfortable talking to anyone -
                                  including new people! Their emotional expressiveness means you can easily interpret how an
                                  extrovert is feeling by their facial expressions, words, or body language.
                                  Extroverts thrive on being involved in discussion and activities with others! However, they tend
                                  to get lost in the details. When they are speaking with others they tend to 'think to speak',
                                  meaning they talk through their ideas and thoughts.
                                </p>
                              </div>) : (null)
                            }
                            {highestScore === 'emotionalStabilityScore' ?
                              (<div>
                                <p className={classes.attributeDescription}>
                                  People who score high in emotional stability can effectively manage negative
                                  feelings. They are unlikely to be overtaken by fear and react impulsively to adverse
                                  situations. Their ability to calmly react to a crisis or emergency in their job, personal
                                  or financial life leads to greater financial stability and happiness.
                                </p>
                              </div>) : (null)
                            }
                            {highestScore === 'agreeablenessScore' ?
                              (<div>
                                <p className={classes.attributeDescription}>
                                  Those who are high in agreeableness tend to be focused on social harmony. They find it
                                  important to get along with other people and put aside their interests for the greater goal of the
                                  people - what we can refer to as 'we'-focused as opposed to 'me'-focused. These individuals
                                  exhibit many prosocial behaviors including being cooperative, kind, and friendly. They try their
                                  best to help those in need due to their empathetic nature, and try to understand the emotions of
                                  others. They are always listening to the opinions of those around them and looking for the
                                  common good in others.
                                </p>
                              </div>) : (null)
                            }
                            {highestScore === 'conscientiousnessScore' ?
                              (<div>
                                <p className={classes.attributeDescription}>
                                  People who are high in conscientiousness are highly organized individuals. They are focused on
                                  and enjoy the details of whatever they are working on or talking through. Their focus is typically
                                  one item at a time, and they like to dive into that item fully and exhaust it before moving on to
                                  the next topic. Due to this nature, they are highly structured individuals who enjoy following a
                                  schedule that aligns with their goals. This also aligns with other personal traits such as their high
                                  levels of thoughtfulness for others and strong impulse control.
                                  Their focus tends to be on logical and rational thinking, and they desire information to be clear
                                  and precise. Due to this focus on detail, they always plan ahead and are mindful of deadlines.
                                </p>
                              </div>) : (null)
                            }
                            {highestScore === 'opennessExperiencesScore' ?

                              (<div>
                                <p className={classes.attributeDescription}>
                                  Individuals who score high in openness to experience are those who are imaginative, insightful,
                                  and willing to take risks. They have a broad range of interests and are always trying to expand
                                  these even more by trying out new experiences and learning new things.
                                  Due to their adventurous nature, they tend to be extremely creative, self-reliant, and quick
                                  thinking! However, they tend to dislike monotony and can get bored on the same topic quickly,
                                  so they are always looking for ways to approach a task in a new way.
                                </p>
                              </div>) : (null)
                            }
                          </CardBody>
                        </Card>
                      ) :
                        <Card>
                          <CardHeader>
                            <h5>The first step on your WealthEnV journey is to take our WenV Personality Inventory! Once you do, your results will be displayed here.</h5>
                          </CardHeader>
                          <CardBody>
                            <NavLink to={'/five-factor'}>
                              <Button className={classes.fiveFactorButton} color="info"><b>Click here to take the Quiz!</b></Button>
                            </NavLink>
                          </CardBody>
                        </Card>

                      }
                    </GridItem>
                    <GridItem xs={12}>
                      <Card className={classes.tableCard}>
                        <CardHeader style={{ display: "flex" }}>
                          <h4 style={{ margin: "auto" }} className={classes.cardTitle}>Contact Information</h4>
                          <EditOutlinedIcon className={classes.editIcon} onClick={props.handleOpenEditDialog} />
                        </CardHeader>
                        <CardBody>
                          <div className={classes.description}>
                            <div className={classes.description}>
                              <div>
                                <p>
                                  <b>Email: </b>
                                  {user.email}
                                </p>
                              </div>
                            </div>
                            <div className={classes.description}>
                              <div>
                                <p>
                                  <b>Phone: </b>
                                  {user.phone ? user.phone : 'N/A'}
                                </p>
                              </div>
                            </div>
                            {user.address ? (
                              <div>
                                <p>
                                  <b>Address: </b>
                                  {user.address}, {user.city || null} {user.state || null} {user.zip || null}
                                </p>
                              </div>
                            ) : (
                              <div>
                                <p>
                                  <b>Address: </b>
                                  N/A
                                </p>
                              </div>
                            )}
                            <br />
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                {/* UPDATE PROFILE MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + ' ' + classes.modalLarge,
                  }}
                  open={props.editDialogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={props.dialogClose}
                  aria-labelledby="profile-edit-modal-title"
                  aria-describedby="profile-edit-modal-description">
                  {/* <DialogTitle
                  id="profile-edit-modal-title"
                  disableTypography
                  className={classes.modalHeader}>
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={this.props.handleCloseEditDialog}>
                    {' '}
                    <Close className={classes.modalClose} />
                  </Button>
                </DialogTitle> */}
                  <DialogContent
                    id="profile-edit-modal-description"
                    className={classes.modalBody}>
                    {/* MOBILE VIEW */}
                    <Button
                      className={classes.ltrButtonWhite}
                      style={{
                        backgroundColor: 'rgba(255,255,255,0.2)',
                        color: 'white',
                        boxShadow: 'none',
                        position: 'relative',
                        zIndex: '9999',
                        fontSize: '16px',
                        float: 'right',
                        margin: '3% -5% 0% 0%',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50px'
                      }}
                      key="close"
                      aria-label="Close"
                      onClick={props.handleCloseEditDialog}
                    >
                      X
                    </Button>

                    {/* FORM */}
                    {/* {uid ? (
                        <ProfileForm
                          user={user}
                          uid={uid}
                          key={user.id}
                          modalOpen={this.props.editDialogOpen}
                          handleSubmit={this.props.handleSubmitEditDialog}
                        />
                      ) : null} */}
                    {/* MOBILE VIEW */}
                    <Button
                      className={classes.ltrButtonWhite}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'white',
                        width: '100%',
                        boxShadow: 'none',
                        zIndex: '9999',
                        position: 'relative',
                        fontWeight: '600',
                        marginTop: "-50px",
                        marginBottom: '50px'
                      }}
                      key="close"
                      aria-label="Close"
                      onClick={props.handleCloseEditDialog}
                    >
                      CLOSE
                    </Button>
                  </DialogContent>
                </Dialog>

                {/* UPDATE PROFILE PHOTO MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + ' ' + classes.modalSmall,
                  }}
                  open={props.photoDialogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={props.handleClosePhotoDialog}
                  aria-labelledby="profile-photo-edit-modal-title"
                  aria-describedby="profile-photo-edit-modal-description"
                >
                  <Card
                    style={{
                      margin: '0px 0% 0px 5%',
                      width: '90%',
                      // backgroundColor: '#264653',
                      borderRadius: '10px',
                    }}
                  >
                    <DialogTitle
                      id="profile-photo-edit-modal-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={props.handleClosePhotoDialog}
                      >
                        {' '}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.modalTitle}>
                        <span style={{ color: 'white' }}>
                          <center>Update Profile Photo</center>
                        </span>
                      </h2>
                    </DialogTitle>
                    <DialogContent
                      id="profile-photo-edit-modal-description"
                      className={classes.modalBody}
                    >
                      {/* FORM */}
                      {uid ? (
                        <ProfilePicUpload
                          currentPic={profilePhoto}
                          username={user.username}
                          uid={uid}
                          user={user}
                          key={user.id}
                          handleSubmit={props.handleSubmitPhotoDialog}
                        />
                      ) : null}
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          className={classes.ltrButtonWhite}
                          style={{ backgroundColor: "#4775FB", width: '90%' }}
                          key="close"
                          aria-label="Close"
                          onClick={props.handleClosePhotoDialog}
                        >
                          <b>CANCEL</b>
                        </Button>
                      </div>
                    </DialogContent>
                  </Card>
                </Dialog>
                <div style={{ margin: '45px' }} />
              </GridContainer>
            </Hidden>
            {/* DESKTOP VIEW */}
            <Hidden smDown implementation="css">
              <GridContainer style={{ margin: '3% 0% 6% -1%' }}>
                <GridItem xs={12} sm={12} md={12}>
                  <Card profile style={{ marginTop: '30px', paddingBottom: '20px' }}>
                    <div onClick={props.handleOpenPhotoDialog}>
                      <CardAvatar profile>
                        <img
                          className={classes.profilePic}
                          src={profilePhoto}
                          alt="User Photo"
                        />
                      </CardAvatar>
                    </div>
                    <CardBody profile>
                      {/*<h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>*/}
                      {user.firstName ? (
                        <h3 className={classes.body} style={{ marginTop: '-10px' }}>
                          {user.firstName} {user.lastName || null}
                        </h3>
                      ) : (
                        <div
                          style={{
                            padding: '0px 0px !important',
                            width: '100%',
                            marginTop: '-50px',
                          }}
                        >
                          <SnackbarContent
                            style={{ backgroundColor: "#60D5C4" }}
                            message={<b>Tap "Edit Profile" To Change Your City.</b>}
                            color="info"
                          />
                        </div>
                      )}
                      {userType ? (
                        <h5 className={classes.body}>{capitalize(userType)}</h5>
                      ) : (
                        <div
                          style={{
                            padding: '0px 0px !important',
                            width: '100%',
                            marginTop: '-50px',
                          }}
                        >
                          <SnackbarContent
                            style={{ backgroundColor: "#60D5C4" }}
                            message={
                              <b>Add More Information By Clicking "Edit Profile".</b>
                            }
                            color="info"
                          />
                        </div>
                      )}
                      {user.city ? (
                        user.city
                      ) : (
                        <div
                          style={{
                            padding: '0px 0px !important',
                            width: '100%',
                            marginTop: '-30px',
                            marginBottom: '-30px',
                          }}
                        >
                          <SnackbarContent
                            style={{ backgroundColor: "#60D5C4" }}
                            message={
                              <b>
                                Use The "Edit Profile" Button Below To Add A City...
                              </b>
                            }
                            color="info"
                          />
                        </div>
                      )}
                      , {user.state ? user.state : null}
                      {user.userType == 'investor' ? null : (
                        <div className={classes.description}>
                          {user.areasOfInvestment ? (
                            'Investment Types: ' + user.areasOfInvestment.map((investment) => ` ${investment}`)
                          ) : (
                            <div className={classes.body}>
                              Click Edit Profile to add your Investment Types
                            </div>
                          )}
                          <br />
                          Experience Level:{' '}
                          <b>
                            {user.experience ? (
                              user.experience + ' years'
                            ) : (
                              <div
                                style={{
                                  padding: '0px 0px !important',
                                  width: '100%',
                                  marginTop: '-20px',
                                }}
                              >
                                <SnackbarContent
                                  style={{ backgroundColor: "#60D5C4" }}
                                  message={
                                    <b>
                                      Tap "Edit Profile" To Update Your Experience.
                                    </b>
                                  }
                                  color="info"
                                />
                              </div>
                            )}
                          </b>
                        </div>
                      )}
                      {/*<Button color="rose" round>
                    Follow
                </Button>*/}
                    </CardBody>
                    <span style={{ padding: '0px 5%' }}>
                      <Button
                        style={{ width: '106%', margin: '2% 3% 0.5% -3%', backgroundColor: "#4775FB", }}
                        onClick={props.handleOpenEditDialog}
                      >
                        <b>Edit Profile</b>
                      </Button>
                    </span>
                  </Card>

                  {user.extraversionScore ?
                    <Card>
                      <CardHeader style={{ margin: "auto" }}>
                        <h4 className={classes.cardTitle}>Personality Inventory (TIPI)</h4>
                      </CardHeader>
                      <CardBody>
                        <div className={classes.tipiGraphAndTable}>
                          <div style={{ width: "60%" }}>
                            <Radar
                              width={500}
                              height={500}
                              padding={70}
                              domainMax={10}
                              highlighted={null}
                              onHover={(point) => {
                                if (point) {
                                  //console.log('hovered over a data point');
                                } else {
                                  //console.log('not over anything');
                                }
                              }}
                              data={{
                                variables: [
                                  { key: 'extraversion', label: 'Extraversion' },
                                  { key: 'agreeableness', label: 'Agreeableness' },
                                  { key: 'conscientiousness', label: 'Conscientiousness' },
                                  { key: 'emotionalStability', label: 'Emotional Stability' },
                                  { key: 'opennessToExperiences', label: 'Openness to Experiences' },
                                ],
                                sets: [
                                  {
                                    key: 'everyone',
                                    label: 'Everyone',
                                    values: {
                                      extraversion: user.extraversionScore,
                                      agreeableness: user.agreeablenessScore,
                                      conscientiousness: user.conscientiousnessScore,
                                      emotionalStability: user.emotionalStabilityScore,
                                      opennessToExperiences: user.opennessExperiencesScore
                                    },
                                  },
                                ],
                              }}
                            />
                          </div>
                          <div style={{ width: "25%" }}>
                            <Table
                              desktopProfileTable={true}
                              style={{ height: "100% !important" }}
                              tableHeaderColor="primary"
                              tableHead={["Category", "Score"]}
                              tableData={[
                                ["Openness", user.opennessExperiencesScore],
                                ["Conscientiousness", user.conscientiousnessScore],
                                ["Extraversion", user.extraversionScore],
                                ["Agreeableness", user.agreeablenessScore],
                                ["Emotional Stability", user.emotionalStabilityScore],
                              ]}
                              coloredColls={[3]}
                              colorsColls={["primary"]}
                            />
                          </div>
                        </div>

                        {/* Top Attribute Description */}
                        {sortedAttributes[0] == user.extraversionScore ?
                          (<div>
                            <p className={classes.attributeDescription}>
                              Individuals who score high in extroversion are characterized by their sociability and
                              talkativeness. They tend to be outgoing and gain energy in social situations. When speaking with
                              others, they are often optimistic, cheerful, and enthusiastic about whatever topic they are
                              speaking about. Due to this charismatic nature, they feel comfortable talking to anyone -
                              including new people! Their emotional expressiveness means you can easily interpret how an
                              extrovert is feeling by their facial expressions, words, or body language.
                              Extroverts thrive on being involved in discussion and activities with others! However, they tend
                              to get lost in the details. When they are speaking with others they tend to 'think to speak',
                              meaning they talk through their ideas and thoughts.
                            </p>
                          </div>) : (null)
                        }
                        {sortedAttributes[0] == user.emotionalStabilityScore ?
                          (<div>
                            <p className={classes.attributeDescription}>
                              People who score high in emotional stability can effectively manage negative
                              feelings. They are unlikely to be overtaken by fear and react impulsively to adverse
                              situations. Their ability to calmly react to a crisis or emergency in their job, personal
                              or financial life leads to greater financial stability and happiness.
                            </p>
                          </div>) : (null)
                        }
                        {sortedAttributes[0] == user.agreeablenessScore ?
                          (<div>
                            <p className={classes.attributeDescription}>
                              Those who are high in agreeableness tend to be focused on social harmony. They find it
                              important to get along with other people and put aside their interests for the greater goal of the
                              people - what we can refer to as 'we'-focused as opposed to 'me'-focused. These individuals
                              exhibit many prosocial behaviors including being cooperative, kind, and friendly. They try their
                              best to help those in need due to their empathetic nature, and try to understand the emotions of
                              others. They are always listening to the opinions of those around them and looking for the
                              common good in others.
                            </p>
                          </div>) : (null)
                        }
                        {sortedAttributes[0] == user.conscientiousnessScore ?
                          (<div>
                            <p className={classes.attributeDescription}>
                              People who are high in conscientiousness are highly organized individuals. They are focused on
                              and enjoy the details of whatever they are working on or talking through. Their focus is typically
                              one item at a time, and they like to dive into that item fully and exhaust it before moving on to
                              the next topic. Due to this nature, they are highly structured individuals who enjoy following a
                              schedule that aligns with their goals. This also aligns with other personal traits such as their high
                              levels of thoughtfulness for others and strong impulse control.
                              Their focus tends to be on logical and rational thinking, and they desire information to be clear
                              and precise. Due to this focus on detail, they always plan ahead and are mindful of deadlines.
                            </p>
                          </div>) : (null)
                        }
                        {sortedAttributes[0] == user.opennessExperiencesScore ?
                          (<div>
                            <p className={classes.attributeDescription}>
                              Individuals who score high in openness to experience are those who are imaginative, insightful,
                              and willing to take risks. They have a broad range of interests and are always trying to expand
                              these even more by trying out new experiences and learning new things.
                              Due to their adventurous nature, they tend to be extremely creative, self-reliant, and quick
                              thinking! However, they tend to dislike monotony and can get bored on the same topic quickly,
                              so they are always looking for ways to approach a task in a new way.
                            </p>
                          </div>) : (null)
                        }
                      </CardBody>
                    </Card>
                    :
                    <Card>
                      <CardHeader>
                        <h5>The first step on your WealthEnV journey is to take our WenV Personality Inventory! Once you do, your results will be displayed here.</h5>
                      </CardHeader>
                      <CardBody>
                        <NavLink to={'/five-factor'}>
                          <Button className={classes.fiveFactorButton} color="info"><b>Click here to take the Quiz!</b></Button>
                        </NavLink>
                      </CardBody>
                    </Card>
                  }
                  <Card>
                    <CardHeader style={{ display: "flex" }}>
                      <h4 style={{ margin: "auto" }} className={classes.cardTitle}>Contact Information</h4>
                      {/* <EditOutlinedIcon className={classes.editIcon} onClick={this.props.handleOpenEditDialog} /> */}
                    </CardHeader>
                    <CardBody>
                      <div className={classes.description}>
                        <div className={classes.description}>
                          {user.email ? (
                            <div>
                              <p>
                                <b>Email: </b>
                                {user.email}
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                padding: '0px 0px !important',
                                width: '100%',
                                marginTop: '-50px',
                              }}
                            >
                              <SnackbarContent
                                style={{ backgroundColor: "#60D5C4" }}
                                message={
                                  <b>Tap "Edit Profile" To Change Your Email...</b>
                                }
                                color="info"
                              />
                            </div>
                          )}
                        </div>
                        <div className={classes.description}>
                          {user.phone ? (
                            <div>
                              <p>
                                <b>Phone: </b>
                                {user.phone}
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                padding: '0px 0px !important',
                                width: '100%',
                                marginTop: '-40px',
                              }}
                            >
                              <SnackbarContent
                                style={{ backgroundColor: "#60D5C4" }}
                                message={
                                  <b>
                                    Tap "Edit Profile" To Update Your Phone Number...
                                  </b>
                                }
                                color="info"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {/*<Button color="rose" round>
                    Follow
                </Button>*/}
                    </CardBody>
                  </Card>







                  <Card>
                    <CardHeader>
                      <h4 className={classes.cardTitle}>Profile</h4>
                    </CardHeader>
                    <CardBody>
                      {/*<h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>*/}
                      <div className={classes.description}>
                        {user.bio ? (
                          user.bio
                        ) : (
                          <div
                            style={{
                              padding: '0px 0px !important',
                              width: '100%',
                              marginTop: '-50px',
                              backgroundColor: "#60D5C4",
                            }}
                          >
                            <SnackbarContent
                              style={{ backgroundColor: "#60D5C4" }}
                              message={
                                <b>
                                  You Don't Have A Bio Yet. Tap "Edit Profile" To Add
                                  One...
                                </b>
                              }
                            />
                          </div>
                        )}
                      </div>

                      {/*<Button color="rose" round>
                  Follow
              </Button>*/}
                    </CardBody>
                  </Card>
                </GridItem>

                {/* UPDATE PROFILE MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + ' ' + classes.modalLarge,
                  }}
                  open={props.editDialogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={props.dialogClose}
                  aria-labelledby="profile-edit-modal-title"
                  aria-describedby="profile-edit-modal-description">
                  {/* <DialogTitle
                  id="profile-edit-modal-title"
                  disableTypography
                  className={classes.modalHeader}>
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={this.props.handleCloseEditDialog}>
                    {' '}
                    <Close className={classes.modalClose} />
                  </Button>
                </DialogTitle> */}
                  <DialogContent
                    id="profile-edit-modal-description"
                    className={classes.modalBody}>
                    {/* MOBILE VIEW */}
                    {/* <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          backgroundColor: 'rgba(255,255,255,0.2)',
                          color: 'white',
                          boxShadow: 'none',
                          position: 'relative',
                          zIndex: '9999',
                          fontSize: '16px',
                          float: 'right',
                          margin: '3% -5% 0% 0%',
                          width: '50px',
                          height: '50px',
                          borderRadius: '50px'
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={this.props.handleCloseEditDialog}
                      >
                        X
                      </Button> */}

                    {/* FORM */}
                    {uid ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          className={classes.ltrButtonWhite}
                          style={{
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            color: 'white',
                            boxShadow: 'none',
                            position: 'relative',
                            zIndex: '9999',
                            fontSize: '16px',
                            float: 'right',
                            margin: '3% -5% 0% 0%',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50px'
                          }}
                          key="close"
                          aria-label="Close"
                          onClick={props.handleCloseEditDialog}
                        >
                          X
                        </Button>
                        <ProfileForm
                          user={user}
                          uid={uid}
                          key={user.id}
                          modalOpen={props.editDialogOpen}
                          handleSubmit={props.handleSubmitEditDialog}
                        />
                      </div>
                    ) : null}
                    {/* MOBILE VIEW */}
                    {/* <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          backgroundColor: 'transparent',
                          color: 'white',
                          width: '100%',
                          boxShadow: 'none',
                          zIndex: '9999',
                          position: 'relative',
                          fontWeight: '600',
                          marginTop: "-50px",
                          marginBottom: '50px'
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={this.props.handleCloseEditDialog}
                      >
                        CLOSE
                      </Button> */}
                  </DialogContent>
                </Dialog>

                {/* UPDATE PROFILE PHOTO MODAL */}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + ' ' + classes.modalSmall,
                  }}
                  open={props.photoDialogOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={props.handleClosePhotoDialog}
                  aria-labelledby="profile-photo-edit-modal-title"
                  aria-describedby="profile-photo-edit-modal-description"
                >
                  <Card
                    style={{
                      margin: '0px 0% 0px 5%',
                      width: '90%',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '10px',
                    }}
                  >
                    <DialogTitle
                      id="profile-photo-edit-modal-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={props.handleClosePhotoDialog}
                      >
                        {' '}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 style={{ color: "black" }} className={classes.modalTitle}>
                        <span style={{ color: "black" }}>
                          <center>Update Profile Photo</center>
                        </span>
                      </h2>
                    </DialogTitle>
                    <DialogContent
                      id="profile-photo-edit-modal-description"
                      className={classes.modalBody}
                    >
                      {/* FORM */}
                      {uid ? (
                        <ProfilePicUpload
                          currentPic={profilePhoto}
                          username={user.username}
                          uid={uid}
                          user={user}
                          key={user.id}
                          handleSubmit={props.handleSubmitPhotoDialog}
                        />
                      ) : null}
                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          className={classes.ltrButtonWhite}
                          style={{ backgroundColor: "#4775FB", width: '90%' }}
                          key="close"
                          aria-label="Close"
                          onClick={props.handleClosePhotoDialog}
                        >
                          <b>CANCEL</b>
                        </Button>
                      </div>
                    </DialogContent>
                  </Card>
                </Dialog>
                <div style={{ margin: '45px' }} />
              </GridContainer>
            </Hidden>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#F4F6F8',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              style={{
                margin: '20% 0% 0% 38%',
                position: 'relative',
                width: '20%',
              }}
              src={loader}
              alt="..."
            />
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
}

Profile.propTypes = {
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  photoURL: PropTypes.string,
  user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth, user, profile }) => {
  const { uid } = auth.user || {};
  const { editDialogOpen, photoDialogOpen } = profile;
  console.log("useruser: ", user.user)
  return {
    uid,
    user: user.user,
    profileURL: user.user.profileURL,
    editDialogOpen,
    photoDialogOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmitEditDialog: (uid, data) => {
      server.updateUser(uid, data);
      dispatch(Actions.editDialogSave(data));
    },
    handleOpenEditDialog: () => {
      dispatch(Actions.editDialogOpen())
    },
    handleCloseEditDialog: () => {
      dispatch(Actions.editDialogClose())
    },
    handleSubmitPhotoDialog: (uid, uri) => {
      server.updateUser(uid, { profileURL: uri });
      dispatch(Actions.photoDialogSave(uri));
    },
    handleOpenPhotoDialog: () => {
      dispatch(Actions.photoDialogOpen())
    },
    handleClosePhotoDialog: () => {
      dispatch(Actions.photoDialogClose())
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Profile);

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

//@material-ui
import withStyles from '@material-ui/core/styles/withStyles';

import MeVideo from '../MeVideo';
import Peers from '../Peers/Peers';

import Style from 'assets/jss/material-dashboard-pro-react/components/videoLayoutStyle';

export function Layout(props) {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <MeVideo />
      <Peers />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => {
  // nothing yet
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Layout);

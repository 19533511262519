import { db } from "firebase/fbConfig.js"



export function addMatchNotifications(user, match) {
  return (dispatch) => {
    console.log('user, match', user, match)
    return db.collection('users').doc(user.id).collection("notifications").doc().set({
      type: "New Match",
      createdDate: new Date(),
      from: match,
      status: "New"
    }, { merge: true })
      .then(() => {
        return db.collection('users').doc(match.id).collection("notifications").doc().set({
          type: "New Match",
          createdDate: new Date(),
          from: user,
          status: "New"
        }, { merge: true })
          .then(() => {
            console.log('set notification')
          })
      })
  }
}

// Get Users for Users Panel
export function listenToNotificationsForUser(uid) {
  console.log('uidddd', uid)
  return (dispatch) => {
    return db.collection("users").doc(uid).collection("notifications").orderBy('createdDate', 'desc').onSnapshot(
      (snapshot) => {
        console.log('snapshotnotifications', snapshot)
        if (!snapshot.empty) {
          snapshot = snapshot.docs
          var data = []
          snapshot.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            doc.notificationId = docId
            data.push(doc)
          })
          dispatch(setNotifications(data))
        } else {
          dispatch(setNotifications([]))
        }
      }
    )
  }
}

export function changeNotifcationStatusToOld(notificationId, uid) {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection("notifications").doc(notificationId).update({
      status: "Old"
    })
      .then(() => {
        dispatch(setNotifcationStatusToOld())
      })
  }
}

export function deleteSingleNotifcation(notificationId, uid, newNotifications) {
  return (dispatch) => {
    return db.collection('users').doc(uid).collection("notifications").doc(notificationId).delete()
      .then(() => {
        dispatch(setDeleteSingleNotification(newNotifications))
      })
  }
}

export function setDeleteSingleNotification(data) {
  return {
    type: "DELETE_SINGLE_NOTIFICATION",
    data
  }
}

export function setNotifcationStatusToOld() {
  return {
    type: "SET_NOTIFICATIONS_STATUS_TO_OLD",
  }
}

export function setNotifications(notifications) {
  return {
    type: "SET_NOTIFICATIONS",
    notifications
  }
}

export function startCreateNotification(uid, notification) {
  console.log("startCreateNotification", uid, notification)
  return dispatch => {
    notification = { createdAt: new Date(), ...notification };
    return db.collection("users").doc(uid).collection("notifications").add(notification)
      .then(doc => {
        console.log("startCreateNotification", doc.id);
        return doc.id;
      });
  }
}

export function startModifyNotification(uid, nid, notification) {
  return dispatch => {
    return db.collection("users").doc(uid).collection("notifications").doc(nid).update(notification);
  }
}

export function addNotification(nid, data) {
  return {
    type: 'NOTIFICATIONS_ADD',
    data,
    nid
  }
}

export function modifyNotification(nid, data) {
  return {
    type: 'NOTIFICATIONS_MODIFY',
    data,
    nid
  }
}

export function removeNotification(nid) {
  return {
    type: 'NOTIFICATIONS_REMOVE',
    nid
  }
}








export function startCloseFileUploadDialog() {
    return (dispatch) => {
        dispatch(closeFileUploadDialog());
        return Promise.resolve(); 
    }
}

export function loading() {
    return {
        type: 'NAVIGATION_LOADING'
    }
}

export function loaded() {
    return {
        type: 'NAVIGATION_LOADED'
    }
}

export function openSolicitationDialog(warningText, onContinue, onCancel) {
    return {
        type: 'NAVIGATION_OPEN_SOLICITATION_DIALOG',
        warningText,
        onContinue,
        onCancel
    }
}

export function closeSolicitationDialog() {
    return {
        type: 'NAVIGATION_CLOSE_SOLICITATION_DIALOG'
    }
}

export function openNotificationActionDialog(warningText, onContinue, onReject, onCancel) {
    return {
        type: 'NAVIGATION_OPEN_NOTIFICATION_ACTION_DIALOG',
        warningText,
        onContinue,
        onCancel,
        onReject
    }
}

export function closeNotificationActionDialog() {
    return {
        type: 'NAVIGATION_CLOSE_NOTIFICATION_ACTION_DIALOG'
    }
}

export function openFileUploadDialog() {
    return {
        type: 'NAVIGATION_OPEN_FILE_UPLOAD_DIALOG',
    }
}

export function closeFileUploadDialog() {
    return {
        type: 'NAVIGATION_CLOSE_FILE_UPLOAD_DIALOG'
    }
}

export function setHeader(data) {
    return {
        type: 'NAVIGATION_SET_SCREEN_NAME',
        data
    }
}
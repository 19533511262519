import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"

// Get User
export const startSetUser = (userObj, user) => {   
  return (dispatch) => {
    if (!userObj.email || !userObj.groups || !userObj.termGroups){
      userObj = {
        ...userObj,
        email: user.email,
        groups: [],
        termGroups: []
      }   
      updateUser(userObj, user.uid)
    }  
    dispatch(setUser(userObj))
  }
}

export const setUser = (userObj) => ({
  type: 'SET_ASSESSMENT',
  userObj
})

export const updateUser = (userObj, uid) => { 
  db.collection("users").doc(uid).set({
    ...userObj,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    window.location.reload() 
  }).catch((err) => {
    return (`Update TEST-users with id: ${uid} with error: ${err}`)
  })
}

export const startSetAssessmentsList = (userID) => {
  // Get firestore Data and convert it to tableData format  
  return (dispatch) => {
    db.collection("users").doc(userID).collection("assessments").orderBy("updatedAt").get()
      .then(snapshot => {
        const values = snapshot.docs.map(doc => doc.data())
        //console.log(values)
        dispatch(setAssessmentsList(values))
      })
  }
}

export const setAssessmentsList = (values) => ({
  type: 'SET_ASSESSMENTS_LIST',
  values
})

export const updateAssessment = (assessmentObj, uid) => { 

  db.collection("users").doc(uid).collection("assessments").doc().set({
    ...assessmentObj,
    uid: uid,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    window.location.reload() 
  }).catch((err) => {
    return (`Error`)
  })
}

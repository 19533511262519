import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/personalAssessmentsPageStyle';

export function PersonalAssessmentsPage(props) {

    const { classes } = props;

    return (
        <div className={classes.container}>
            <h1>test</h1>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    // nothing yet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(PersonalAssessmentsPage);



export const modelNotification = {
  createdAt: "N/A",
  status: "new",
  action: "view"
}

export const initState = {
  notifications: {
    dataRows: [],
    notificationsReceived: false
  }
}


export default (state = initState, action) => {
  const { notifications } = state;
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      var toReturn = { ...state }
      toReturn.notifications.dataRows = action.notifications
      toReturn.notifications.notificationsReceived = true
      return toReturn

    case "DELETE_SINGLE_NOTIFICATION":
      var toReturn = { ...state }
      toReturn.notifications.dataRows = action.data
      return toReturn

    case 'NOTIFICATIONS_ADD':
      return {
        ...state,
        notifications: {
          dataRows: [...notifications.dataRows, { ...modelNotification, ...action.data, nid: action.nid }]
        }
      }
    case 'NOTIFICATIONS_MODIFY':
      return {
        ...state,
        notifications: {
          dataRows: notifications.dataRows.map(row => row.nid === action.nid ? { ...row, ...action.data } : row),
        },
      }
    case 'NOTIFICATIONS_REMOVE':
      return {
        ...state,
        notifications: {
          dataRows: notifications.dataRows.filter(row => row.nid !== action.nid)
        }
      }
    default:
      return state
  }
}

import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { NavLink, useParams } from "react-router-dom";
import firebase, { db, } from "firebase/fbConfig.js";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';


// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';


import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';


export function Results(props) {
    const { classes } = props;
    const { docID } = useParams();
    const [scores, setScores] = useState()
    const [allData, setAllData] = useState({
        agreeablenessScore: 0,
        conscientiousnessScore: 0,
        emotionalStabilityScore: 0,
        extraversionScore: 0,
        opennessExperiencesScore: 0,
    })
    function getScores() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                const sortedResults = [];
                const items = [];
                db.collection('users').doc(user.uid).collection('quizzes').doc('fiveFactorQuiz').collection('quizLists').doc(docID)
                    .get()
                    .then((queryResult) => {
                        sortedResults.push(queryResult.data().agreeablenessScore, queryResult.data().conscientiousnessScore, queryResult.data().emotionalStabilityScore,
                            queryResult.data().extraversionScore, queryResult.data().opennessExperiencesScore)
                        sortedResults.sort((a, b) => b - a)
                        setAllData({
                            agreeablenessScore: queryResult.data().agreeablenessScore,
                            conscientiousnessScore: queryResult.data().conscientiousnessScore,
                            emotionalStabilityScore: queryResult.data().emotionalStabilityScore,
                            extraversionScore: queryResult.data().extraversionScore,
                            opennessExperiencesScore: queryResult.data().opennessExperiencesScore
                        })
                    })
                    .then(() => {
                        setScores(sortedResults[0])
                    })
            }
        })
    }
    useEffect(() => {
        getScores();
    }, []);
    // console.log("scores: ", scores)
    // console.log("allData: ", allData.opennessExperiencesScore)
    return (
        <div className={classes.quizContainerPublic}>
            <Card>
                <CardHeader style={{ margin: "auto" }}>
                    <h4 className={classes.cardTitle}>Personality Inventory (TIPI)</h4>
                </CardHeader>
                <CardBody>
                    <Radar
                        width={300}
                        height={300}
                        padding={65}
                        domainMax={10}
                        highlighted={null}
                        onHover={(point) => {
                            if (point) {
                                //console.log('hovered over a data point');
                            } else {
                                //console.log('not over anything');
                            }
                        }}
                        data={{
                            variables: [
                                { key: 'extraversion', label: 'Extraversion' },
                                { key: 'agreeableness', label: 'Agreeableness' },
                                { key: 'conscientiousness', label: 'Conscientiousness' },
                                { key: 'emotionalStability', label: 'Emotional Stability' },
                                { key: 'opennessToExperiences', label: 'Openness to Experiences' },
                            ],
                            sets: [
                                {
                                    key: 'everyone',
                                    label: 'Everyone',
                                    values: {
                                        extraversion: allData.extraversionScore,
                                        agreeableness: allData.agreeablenessScore,
                                        conscientiousness: allData.conscientiousnessScore,
                                        emotionalStability: allData.emotionalStabilityScore,
                                        opennessToExperiences: allData.opennessExperiencesScore
                                    },
                                },
                            ],
                        }}
                    />
                    <div style={{ marginBottom: "100px" }}>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Category", "Score"]}
                            tableData={[
                                ["Openness", allData.opennessExperiencesScore],
                                ["Conscientiousness", allData.conscientiousnessScore],
                                ["Extraversion", allData.extraversionScore],
                                ["Agreeableness", allData.agreeablenessScore],
                                ["Emotional Stability", allData.emotionalStabilityScore],
                            ]}
                            coloredColls={[3]}
                            colorsColls={["primary"]}
                        />
                    </div>
                    {/* Top Attribute Description */}
                    {scores == allData.extraversionScore ?
                        (<div>
                            <p className={classes.attributeDescription}>
                                Individuals who score high in extroversion are characterized by their sociability and
                                talkativeness. They tend to be outgoing and gain energy in social situations. When speaking with
                                others, they are often optimistic, cheerful, and enthusiastic about whatever topic they are
                                speaking about. Due to this charismatic nature, they feel comfortable talking to anyone -
                                including new people! Their emotional expressiveness means you can easily interpret how an
                                extrovert is feeling by their facial expressions, words, or body language.
                                Extroverts thrive on being involved in discussion and activities with others! However, they tend
                                to get lost in the details. When they are speaking with others they tend to 'think to speak',
                                meaning they talk through their ideas and thoughts.
                            </p>
                        </div>) : (null)
                    }
                    {scores == allData.emotionalStabilityScore ?
                        (<div>
                            <p className={classes.attributeDescription}>
                                People who score high in emotional stability can effectively manage negative
                                feelings. They are unlikely to be overtaken by fear and react impulsively to adverse
                                situations. Their ability to calmly react to a crisis or emergency in their job, personal
                                or financial life leads to greater financial stability and happiness.
                            </p>
                        </div>) : (null)
                    }
                    {scores == allData.agreeablenessScore ?
                        (<div>
                            <p className={classes.attributeDescription}>
                                Those who are high in agreeableness tend to be focused on social harmony. They find it
                                important to get along with other people and put aside their interests for the greater goal of the
                                people - what we can refer to as 'we'-focused as opposed to 'me'-focused. These individuals
                                exhibit many prosocial behaviors including being cooperative, kind, and friendly. They try their
                                best to help those in need due to their empathetic nature, and try to understand the emotions of
                                others. They are always listening to the opinions of those around them and looking for the
                                common good in others.
                            </p>
                        </div>) : (null)
                    }
                    {scores == allData.conscientiousnessScore ?
                        (<div>
                            <p className={classes.attributeDescription}>
                                People who are high in conscientiousness are highly organized individuals. They are focused on
                                and enjoy the details of whatever they are working on or talking through. Their focus is typically
                                one item at a time, and they like to dive into that item fully and exhaust it before moving on to
                                the next topic. Due to this nature, they are highly structured individuals who enjoy following a
                                schedule that aligns with their goals. This also aligns with other personal traits such as their high
                                levels of thoughtfulness for others and strong impulse control.
                                Their focus tends to be on logical and rational thinking, and they desire information to be clear
                                and precise. Due to this focus on detail, they always plan ahead and are mindful of deadlines.
                            </p>
                        </div>) : (null)
                    }
                    {scores == allData.opennessExperiencesScore ?
                        (<div>
                            <p className={classes.attributeDescription}>
                                Individuals who score high in openness to experience are those who are imaginative, insightful,
                                and willing to take risks. They have a broad range of interests and are always trying to expand
                                these even more by trying out new experiences and learning new things.
                                Due to their adventurous nature, they tend to be extremely creative, self-reliant, and quick
                                thinking! However, they tend to dislike monotony and can get bored on the same topic quickly,
                                so they are always looking for ways to approach a task in a new way.
                            </p>
                        </div>) : (null)
                    }
                </CardBody>
            </Card>
        </div >
    );
}

Results.propTypes = {
    uid: PropTypes.string,
    classes: PropTypes.object.isRequired,
    photoURL: PropTypes.string,
    user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth, user, profile }) => {
    const { uid } = auth.user || {};
    const { editDialogOpen, photoDialogOpen } = profile;
    console.log("useruser: ", user.user)
    return {
        uid,
        user: user.user,
        profileURL: user.user.profileURL,
        editDialogOpen,
        photoDialogOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Results);
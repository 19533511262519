import * as firebase from "firebase";
// import { firebase } from "@firebase/app";
import "firebase/firestore";
// import "@firebase/firestore";
import "@firebase/auth";
import '@firebase/storage'

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(config);
var storage = firebase.storage();
firebase.firestore().settings({});
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
var db = firebase.firestore();
var functions = firebase.functions()
var sendEmail = functions.httpsCallable('sendEmail')
var sendSMS = functions.httpsCallable('sendSMS')
var createLinkToken = functions.httpsCallable('createLinkToken')
var sendPublicToken = functions.httpsCallable('sendPublicToken')
var getAccountBalances = functions.httpsCallable('getAccountBalances')
var sendWelcomeEmail = functions.httpsCallable('sendWelcomeEmail')
var sendAddedUserToEventEmail = functions.httpsCallable('sendAddedUserToEventEmail')
var sendMessageNotificationEmail = functions.httpsCallable('sendMessageNotificationEmail')
var inviteNewUser = functions.httpsCallable('inviteNewUser')
var twilioToken = functions.httpsCallable('twilioToken');

var saveStocks = functions.httpsCallable('saveStocks');

export {
  db,
  firebase,
  functions,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  sendEmail,
  sendWelcomeEmail,
  sendAddedUserToEventEmail,
  sendMessageNotificationEmail,
  sendSMS,
  saveStocks,
  createLinkToken,
  sendPublicToken,
  getAccountBalances,
  storage,
  inviteNewUser,
  twilioToken,
  firebase as default };

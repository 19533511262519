import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { useHistory } from 'react-router';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';



const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "#74EAE3",
                '&$checked': {
                    color: "#74EAE3",
                },
            },
        },
    },
});
export class FirstQuestion extends Component {



    back = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.riskAversion !== 0) {
            this.props.nextStep();
        }
    }

    render() {
        const { values, handleToggle, classes, globalTestResults, compare } = this.props;
        const usersSelection = values[compare]
        console.log('usersSelection', usersSelection)
        return (
            <div className={classes.quizContainer}>
                <h4 className={classes.questionHeader}>I prefer my investments to be low risk.</h4>
                <GridContainer className={classes.wealthManagementCheckboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        onClick={() => handleToggle('riskAversion', 1)}
                                        checked={usersSelection === 1 ? true : false}
                                        sx={{
                                            color: "#fff",
                                            "&.Mui-checked": {
                                                color: "#74EAE3"
                                            }
                                        }}
                                    />
                                </ThemeProvider>}
                            label='Disagree strongly'
                            className={classes.checkboxText}
                        />

                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 2)}
                                    checked={usersSelection === 2 ? true : false}
                                />}
                            label='Disagree moderately'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 3)}
                                    checked={usersSelection === 3 ? true : false}
                                />}
                            label='Disagree a little'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 4)}
                                    checked={usersSelection === 4 ? true : false}
                                />}
                            label='Neither agree nor disagree'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 5)}
                                    checked={usersSelection === 5 ? true : false}
                                />}
                            label='Agree a little'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 6)}
                                    checked={usersSelection === 6 ? true : false}
                                />}
                            label='Agree moderately'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('riskAversion', 7)}
                                    checked={usersSelection === 7 ? true : false}
                                />}
                            label='Agree strongly'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>


                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <Link to='/quizzes'>
                                    <ArrowLeft className={classes.arrowButton} />
                                </Link>
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>0 / 9</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>

                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>

                <CustomLinearProgress
                    className={classes.progressBar}
                    variant="determinate"
                    color="primary"
                    value={33}
                />
            </div>
        );
    }
}

export default withStyles(Style)(FirstQuestion);
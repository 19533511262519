import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { twilioToken } from 'firebase/fbConfig.js';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import VideoChat from 'components/Video/VideoChat';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/conferencingPageStyle';
import Layout from '../../components/VideoComponents/Layouts/Layout';
import ControlBar from '../../components/ControlBar/ControlBar';
import Room from 'components/Video/Room';

export function VideoConference(props) {

    const { classes, history, user } = props;
    const [token, setToken] = useState('');
    const [roomName, setRoomName] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [username, setUsername] = useState('');

    console.log('look username', user.firstName, user.lastName)

    const handleLogout = useCallback(async event => {
        // const stream = await navigator.mediaDevices.getUserMedia({
        //     audio: true,
        //     video: true
        // });
        // console.log('stream', stream)
        // stream.getAudioTracks()[0].stop();
        // stream.getVideoTracks()[0].stop();
        setToken(null);
        history.push('/conferencing')
    }, [token]);

    const getToken = async () => {
        const response = await twilioToken({ identity: `${user.firstName} ${user.lastName}`, roomName });
        return response.data.token;
    }

    useEffect(() => {
        if (user.firstName !== 'N/A') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get('room')) {
                setRoomName(urlParams.get('room'));
                getToken()
                    .then((res) => {
                        setToken(res);
                        setLoaded(true);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
            } else {
                setToken(props.location.state.state.data.token);
                setLoaded(true);
            }
        }
      }, [user.firstName])

    return (<>
        {loaded ?
            <Room token={token} roomName={roomName} handleLogout={handleLogout} /> :
            null
        }
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(VideoConference));
const users = {
  columns: [
    {
      Header: 'USERNAME',
      accessor: 'username',
    },
    {
      Header: 'FIRST NAME',
      accessor: 'firstName',
    },
    {
      Header: 'LAST NAME',
      accessor: 'lastName',
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
    },
    {
      Header: 'COUNTY',
      accessor: 'county',
    },
    {
      Header: 'PRECINCT',
      accessor: 'precinct',
    },
    {
      Header: 'GROUPS',
      accessor: 'groups',
    },
    {
      Header: 'SCORE',
      accessor: 'score',
    },
    {
      Header: 'VOL UID',
      accessor: 'volUID',
    },
    {
      Header: '',
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ],
  dataRows: [],
  dataLoaded: false,
};

const matches = []

const templateUser = {
  firstName: 'N/A',
  lastName: 'N/A',
  username: 'N/A',
  phone: 'N/A',
  email: 'N/A',
  county: 'N/A',
  precinct: 'N/A',
  score: 'N/A',
  userType: 'advisor',
  investmentType: 'N/A',
  city: 'N/A',
  state: 'N/A',
  experience: 'N/A',
};

const initState = {
  user: { ...templateUser },
  listeners: [],
  users,
};

export default (state = initState, action) => {
  const { user, users } = state;
  switch (action.type) {
    case 'USERS_SET_USER':
      return {
        ...state,
        user: { ...user, ...action.data },
        listeners: action.listeners,
      };
    case 'USERS_ADD_LISTENER':
      return { ...state, listeners: [...state.listeners, action.listener] };
    case 'PROFILE_EDIT_DIALOG_SAVE':
      return { ...state, user: { ...user, ...action.data } };
    case 'PROFILE_PHOTO_DIALOG_SAVE':
      return { ...state, user: { ...user, profileURL: action.url } };
    case 'USERS_ADD':
      return {
        ...state,
        users: {
          columns: users.columns,
          dataLoaded: true,
          dataRows: [
            ...users.dataRows,
            { uid: action.id, ...templateUser, ...action.data },
          ],
        },
      };

      case "SET_TEMP_USER_INFO":
        var toReturn = {...state}
      toReturn.user.firstName = action.firstName
      toReturn.user.lastName = action.lastName
      toReturn.user.email = action.email
      toReturn.user.phone = action.phone
        return toReturn

      case "SET_USER_MATCHES":
      var toReturn = {...state}
      toReturn.matches = action.matches
      return toReturn


    case 'USERS_MODIFY':
      return {
        ...state,
        users: {
          columns: users.columns,
          dataLoaded: true,
          dataRows: users.dataRows.map((row) =>
            row.uid === action.id ? { uid: action.id, ...row, ...action.data } : row
          ),
        },
        user: action.id === user.id ? { ...user, ...action.data } : user,
      };
    case 'USERS_DELETE':
      return {
        ...state,
        users: {
          columns: users.columns,
          dataLoaded: true,
          dataRows: users.dataRows.filter((row) => row.uid !== action.id),
        },
      };
    default:
      return state;
  }
};

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowForward from '@material-ui/icons/ArrowForward';
import loader from 'assets/img/loader.gif';
import ArrowBack from '@material-ui/icons/ArrowBack';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/matchesPageStyle';

import avatar from 'assets/img/default-avatar.png';

import SolicitationDialog from '../Components/SolicitationDialog.jsx';
import { openSolicitationDialog } from '../../store/actions/navigationActions.jsx';
import config from 'config';
import { sampleInvestors, sampleAdvisors } from "variables/sampleUsers.jsx";
import { db } from '../../firebase/fbConfig.js';
import { user } from 'firebase-functions/lib/providers/auth';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class RecommendationsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      refresh: false,
      volUID: '',
      username: '',
      active: props.active,
      advisorData: []
    };
  }

  componentDidMount() {
    if (!this.state.dataLoaded) {
      const results = this.advisorsData()
      console.log('resultssssxxx', results)
      this.setState({
        advisorData: results,
        dataLoaded: true
      })
    }
  }

  getMockPercentages = () => {
    var matchPercentage = Math.floor(Math.random() * (100 - 70)) + 70;
    matchPercentage = matchPercentage.toString() + '% Match';
    return matchPercentage;
  };

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  usersData = () => {
    return this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop };
      const volUID = colObj.volUID;
      const username = colObj.username;
      // Load Data for Summary Cards and add buttons
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={() => this.handleClickOpen('assignActionModal', volUID)}
          >
            ASSIGN ACTION
          </Button>
        ),
      };
    });
  };

  static compareIgnoreCase(a, b) {
    if (a === b) return true;
    if (!a || !b) return false;
    return a.toLowerCase().includes(b.toLowerCase());
  }

  fetchUsersMatches = () => {
    if (this.props.user.id) {
      let matchesArray = []
      db.collection("users").doc(this.props.user.id).collection("matches")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            matchesArray.push(doc.data())
          });
          this.setState({
            matches: matchesArray
          })
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  fetchUsersWhiteList = () => {
    if (this.props.user.id) {
      let whiteListArray = []
      db.collection("users").doc(this.props.user.id).collection("whiteList")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            whiteListArray.push(doc.data())
          });
          this.setState({
            whiteList: whiteListArray
          })
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }

  fetchUsersBlackList = () => {
    if (this.props.user.id) {
      let blackListArray = []
      db.collection("users").doc(this.props.user.id).collection("blackList")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            blackListArray.push(doc.data())
          });
          this.setState({
            blackList: blackListArray
          })
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    }
  }


  advisorsData = () => {
    let filteredUsersArray = []
    const { query, users, matchesArray } = this.props;
    let allUsers = users.dataRows.filter((user) => {
      return (
        (user.userType.toLowerCase() == 'advisor' &&
          RecommendationsMap.compareIgnoreCase(user.firstName, query)) ||
        RecommendationsMap.compareIgnoreCase(user.lastName, query) ||
        RecommendationsMap.compareIgnoreCase(user.volUID, query)
      );
    });

    let allFilteredUsers = users.dataRows.filter((user) => {
      let nonMatchedUsers = this.props.matchesArray.map((matchedUser) => {
        if (matchedUser.id != user.uid) {
          filteredUsersArray.push(matchedUser.id)
        }
      })
      let nonWhiteListUsers = this.props.whiteListArray.map((whiteListUser) => {
        if (whiteListUser.id != user.uid) {
          filteredUsersArray.push(whiteListUser.id)
        }
      })
      let nonBlackListUsers = this.props.blackListArray.map((blackListUser) => {
        if (blackListUser.id != user.uid) {
          filteredUsersArray.push(blackListUser.id)
        }
      })
    })

    let nonDuplicates = [...new Set(filteredUsersArray)]
    console.log('non', nonDuplicates)
    if (this.props.user.userType === 'investor') {
      return allUsers.filter((user) => {
        if (filteredUsersArray.includes(user.uid)) {
          return null
        } else {
          return user.firstName !== 'N/A' && user.userType === 'advisor';
        }
      });
    } else {
      return allUsers.filter((user) => {
        if (filteredUsersArray.includes(user.uid)) {
          return null
        } else {
          return user.firstName !== 'N/A' && user.userType === 'investor'
        }
      })
    }
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const {
      AuthBool,
      classes,
      query,
      loading,
      solicitationDialogOpen,
      openSolicitationDialog,
      cancelSolicitationDialog,
      continueSolicitationDialog,
      direction,
      id
    } = this.props;

    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
    };

    const searchButton = classes.top + ' ' + classes.searchButton;

    // if (!this.state.matches) {
    //   this.fetchUsersMatches()
    // }

    // if (!this.state.whiteList) {
    //   this.fetchUsersWhiteList()
    // }

    // if (!this.state.blackList) {
    //   this.fetchUsersBlackList()
    // }

    // if(investments) {this.filterData(query, investments.dataRows)}
    //console.log(investments.dataRows)
    const recommendations = this.state.advisorData.map((advisorRow, index) => (
      <Slide style={{ height: '150px', paddingBottom: '0px' }} index>
        <div key={advisorRow.uid}>
          <GridContainer style={{ textAlign: "center" }}>
            <GridItem xs={12}>
              <img
                src={advisorRow.profileURL || avatar}
                className={classes.recommendationsPic}
              />
            </GridItem>
            <GridItem xs={12} className={classes.recommendationsDescription}>
              <h5 className={classes.recommendationsName}>
                <b>{advisorRow.firstName}</b>
              </h5>
            </GridItem>
            <GridItem xs={12}>
              <Link
                to={{
                  pathname: `advisor/${advisorRow.uid}`,
                  state: { dataLoaded: false },
                }}
              >
                <Button className={classes.profileButton}>
                  <b>Profile</b>
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </Slide>
    ));

    return (
      <div>
        <SolicitationDialog />
        <div className={classes.recommendationsContainer}>
          {/* <Carousel {...settings} id="home"> */}
          <CarouselProvider
            orientation="horizontal"
            style={{ width: '100%' }}
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            visibleSlides={3}
            totalSlides={this.state.advisorData.length}
            isPlaying={true}
          >
            <Slider>{recommendations}</Slider>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <ButtonBack style={{
                borderRadius: '20px',
                border: '0px',
                background: 'white'
              }}><ArrowBack /></ButtonBack>
              <ButtonNext style={{
                borderRadius: '20px',
                border: '0px',
                background: 'white'
              }}><ArrowForward /></ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    user: state.user.user,
    users: state.user.users,
    solicitationDialogOpen: state.navigation.solicitationDialogOpen,
    loading: false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSolicitationDialog: () =>
      dispatch(openSolicitationDialog(config.solicitationWarningThread)), // TODO: add continue action
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(RecommendationsMap);

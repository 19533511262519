import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import TextField from '@material-ui/core/TextField';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const criticalitySelects = ['Want', 'Need']

const prioritySelects = ['Low', 'Medium', 'High']

export const GoalsForm = (props) => {

  const { classes, name, timeframe, cost, criticality, priority, index, goalsData, setGoalsData } = props;

  const handleUpdate = (e, value) => {
    let data = goalsData
    data[index][value] = e.target.value
    setGoalsData([...data])
  }

  return (
    <GridContainer className={classes.familyFormContainer}>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Name
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'name')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Timeframe
            </InputLabel>
            <TextField
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'timeframe')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true

              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>

      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Cost
            </InputLabel>
            <TextField
              type="number"
              style={{ width: '100%', color: 'black !important' }}
              onChange={(e) => handleUpdate(e, 'cost')}
              id="outlined-basic"
              variant="standard"
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true
              }}
              formControlProps={{
                fullWidth: true,
                disableUnderline: true
              }}
            />
          </FormControl>
        </Card>
      </GridItem>



      <GridItem xs={6}>
        <Card className={classes.inputCard}>
          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Criticality
            </InputLabel>
            <Select
              className={classes.inputColor}
              disableUnderline
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              InputProps={{
                classes: {
                  input: classes.inputColor,
                },
                disableUnderline: true
              }}
              required
              onChange={(e) => handleUpdate(e, 'criticality')}
              InputProps={{ disableUnderline: true }}
            >
              {criticalitySelects.map((item) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={item}
                  key={item}
                >{item}</MenuItem>
              ))
              }
            </Select>
          </FormControl>
        </Card>
      </GridItem>
      <GridItem xs={6}>
        <Card className={classes.inputCard}>

          <FormControl
            fullWidth
            className={classes.formControl}>
            <InputLabel
              shrink={true}
              htmlFor="InvestmentState"
              className={classes.selectLabel}>
              Priority
            </InputLabel>
            <Select
              className={classes.inputColor}
              disableUnderline
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              onChange={(e) => handleUpdate(e, 'priority')}
            >
              {prioritySelects.map((item) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  value={item}
                  key={item}
                >{item}</MenuItem>
              ))}


            </Select>
          </FormControl>
        </Card>
      </GridItem>


      <GridItem xs={12}>
        <hr style={{ borderTop: '1px solid black', margin: "0px auto", marginTop: "40px", width: "75%" }} />
      </GridItem>
    </GridContainer >
  );
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(GoalsForm);
import { firebase, db } from '../../firebase/fbConfig';
import { push } from 'connected-react-router';
import { sendEmail, sendWelcomeEmail } from "firebase/fbConfig.js"

export const addSignUpXpPoints = (authInfo, email) => {
  return (dispatch) => {
    // return db.collection()
    console.log('authInfoauthInfo', authInfo.user)
    return db.collection('users').doc(authInfo.user.uid).set({
      events: [{
        label: 'Joining WealthEnV',
        event: 'join',
        type: 'reward',
        tokens: 1000,
      },],
      email
    })
      .then(() => {
        console.log('added points')
      })
  };
};


export const addQuizXpPoints = (uid, events, label) => {
  return (dispatch) => {
    var doesEventExistAlready = false
    events.forEach((event) => {
      if (event.label === label) {
        doesEventExistAlready = true
      }
    })
    if (doesEventExistAlready) {
      console.log('event already exits')
      // dispatch something that says you already took this test so you dont get more points
    } else {
      return db.collection('users').doc(uid).set({
        events: [...events, {
          label: label,
          event: 'quiz',
          type: 'reward',
          tokens: 300,
        },]
      }, { merge: true })
        .then(() => {
          console.log('added points')
        })
    }
  };
}

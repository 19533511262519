import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';

import { startUpdateUser } from '../../store/actions/userActions';

import Style from 'assets/jss/material-dashboard-pro-react/views/setupStyle';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class FirstSignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertiser: false,
      userType: 'investor',
      legalDisclaimer: true,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      email: this.props.authUser.email,
    });
    console.log(this.state);
  };

  handleSelectChange = (e) => {
    this.setState({
      userType: e.target.value,
    });
    console.log(this.state);
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.startUpdateUser(this.props.authUser.uid, {
      signedLegalDisclaimer: this.state.signedLegalDisclaimer,
    });
  };

  onChange = (dob) => this.setState({ dob });

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleError = (error) => {
    var errorCode = error.code;

    this.setState({
      signupError: true,
      errorCode,
    });
  };

  signDisclaimer = () => {
    if (this.state.advertiser == 'undefined') {
      this.setState({
        signedLegalDisclaimer: true,
      });
    } else {
      this.setState({
        signedLegalDisclaimer: !this.state.signedLegalDisclaimer,
      });
    }
  };

  render() {
    const { classes, startGoogleLogin, authError } = this.props;

    if (!!authError && !this.state.signupError) this.handleError(authError);

    console.log(this.state);

    return (
      <div className={classes.container}>
        <GridContainer justify='center' style={{ marginTop: '20vh' }}>
          <GridItem xs={11} sm={10} md={8} lg={6}>
            <Card style={{ borderRadius: '10px' }}>
              {/*   <Wizard
          validate
          color="primary"
          steps={[
            { stepName: "About", stepComponent: Step1, stepId: "about" },
            { stepName: "Account", stepComponent: Step2, stepId: "account" },
            { stepName: "Practice", stepComponent: Step3, stepId: "address" }
          ]}
          title="Create Your Account"
          subtitle=""
          finishButtonClick={e => alert(e)}
        />*/}

              <h2
                className={classes.cardTitle}
                style={{
                  fontSize: '1.8rem',
                  padding: '10px',
                  color: '#fff',
                  backgroundColor: '#fc6046',
                }}
              >
                Legal Disclaimer{' '}
              </h2>
              {/*<div className={classes.center}>
                  <Button
                    justIcon
                    round
                    color="google"
                    onClick={startGoogleLogin}>
                    <i className="fab fa-google" />
                  </Button>
                  {` `}
    </div>*/}
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <br />
                <p>
                  To use this service, I'm aware i need to provide legally
                  accurate information. I am aware that advisors will need this
                  information to provide their services. By supplying investment
                  information and submitting an investment. I understand that I'm
                  soliciting advisors for legal representation.
                </p>
                <p>
                  To the best of my knowledge, all information supplied is
                  accurate.
                </p>
                {/*Email: {this.props.authUser ? this.props.authUser.email : "loading..."}*/}
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => this.signDisclaimer()}
                      checked={this.state.signedLegalDisclaimer}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      color='primary'
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{ label: classes.bodyText }}
                  label='I understand and agree to the terms'
                />
                <p style={{ textAlign: 'center', color: '#315B7B' }}>
                  <Link to='/privacy-policy'>
                    <b>View Our Privacy Policy</b>
                  </Link>
                </p>
                <div className={classes.center}>
                  <Button
                    type='submit'
                    //color="primary"
                    style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", fontSize: '1rem' }}
                  >
                    <b>Continue</b> <ArrowRightAltIcon />
                  </Button>
                </div>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startUpdateUser: (uid, data) => dispatch(startUpdateUser(uid, data)),
});

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    user: state.user.user,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(FirstSignupPage);

import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class ThirdQuestion extends Component {

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.dependableSelfDisciplined) {
            this.props.nextStep();
        }
    }

    render() {
        const { values, handleToggle, classes, compare } = this.props;
        const usersSelection = values[compare]
        return (
            <div className={classes.fiveFactorQuizContainer}>
                <h4 className={classes.questionHeader}>Financial Aptitude and Behavioral Finance</h4>
                <p className={classes.questionDescription}>
                    Here are a number of personality traits that may or may not apply to you.
                    Please select an option to each statement to indicate the extent to which you agree or disagree with that statement.
                    You should rate the extent to which the pair of traits applies to you, even if one characteristic applies more strongly than the other.
                </p>
                <h4 className={classes.questionHeader}>I see myself as:</h4>
                <h3 className={classes.questionHeader}>Dependable, responsible</h3>
                <GridContainer className={classes.checkboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        checked={usersSelection === 1 ? true : false}
                                        onClick={() => handleToggle('dependableSelfDisciplined', 1)}
                                        defaultValue={values.dependableSelfDisciplined}
                                    />
                                </ThemeProvider>}
                            label='Disagree strongly'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 2 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 2)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Disagree moderately'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 3 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 3)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Disagree a little'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 4 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 4)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Neither agree nor disagree'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 5 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 5)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Agree a little'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 6 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 6)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Agree moderately'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={usersSelection === 7 ? true : false}
                                    onClick={() => handleToggle('dependableSelfDisciplined', 7)}
                                    defaultValue={values.dependableSelfDisciplined}
                                />}
                            label='Agree strongly'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>
                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>2 / 10</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>





                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        );
    }
}

export default withStyles(Style)(ThirdQuestion);
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "rgba(146,205,145,1) !important",
        },
      },
    },
  },
});
export class ThirteenthQuestion extends Component {

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.thirteenthQuestion !== 0) {
      this.props.history.push('/risk-tolerance-results', {
        state: {
          values,
        }
      });
    }
  }


  render() {
    const { values, handleToggle, classes } = this.props;
    return (
      <div className={classes.riskQuizContainerThirteenthQuestion}>
        <h4 className={classes.questionHeader}>Risk Tolerance Assessment</h4>
        <p className={classes.questionDescription}>Your trusted friend and neighbor, an experienced geologist, is putting together a group of investors to fund an
          exploratory gold mining venture.  The venture could pay back 50 to 100 times the investment if successful.  If
          the mine is a bust, the entire investment is worthless.  Your friend estimates the chance of success is only 20%.
          If you had the money, how much would you invest?</p>
        <GridContainer className={classes.checkboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={<ThemeProvider theme={theme}>
                <Checkbox
                  onClick={() => handleToggle('thirteenthQuestion', 1)}
                  defaultValue={values.thirteenthQuestion}
                  checked={values.thirteenthQuestion === 1}
                /></ThemeProvider>}
              label='Nothing'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('thirteenthQuestion', 2)}
                  defaultValue={values.thirteenthQuestion}
                  checked={values.thirteenthQuestion === 2}
                />}
              label='One month’s salary'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('thirteenthQuestion', 3)}
                  defaultValue={values.thirteenthQuestion}
                  checked={values.thirteenthQuestion === 3}
                />}
              label='Three month’s salary'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('thirteenthQuestion', 4)}
                  defaultValue={values.thirteenthQuestion}
                  checked={values.thirteenthQuestion === 4}
                />}
              label='Six month’s salary'
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>
        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>12 / 13</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>


        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>
      </div>
    );
  }
}

export default withRouter(withStyles(Style)(ThirteenthQuestion));
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Style from 'assets/jss/material-dashboard-pro-react/views/assetsStyle';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/yourAssetsTitle.png';
import AddButtonContainerDropdown from './AddButtonContainerDropdown'
import { ReactComponent as YourDashboard } from 'assets/img/yourDashboard.svg';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as DiamondIcon } from 'assets/img/diamond.svg';
import { ReactComponent as ExpenseIcon } from 'assets/img/expense.svg';


const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "#72C5C8 !important",
        },
      },
    },
  },
});


const categorySelects = ['Category 1', 'Category 2', 'Category 3']

export function WealthBucket(props) {


  const assetsMockData = [{
    name: "Disney Stock",
    amount: "$24.64",
    type: "asset",
    change: "12.11%",
    upOrDown: "down",
    added: true
  }, {
    name: "Amazon Stock",
    amount: "$194.64",
    change: "2.11%",
    upOrDown: "up",
    type: "asset",
    added: false
  }, {
    name: "Cleaning Service",
    amount: "$100",
    change: "",
    upOrDown: "",
    type: "service",
    added: false
  }, {
    name: "Netflix",
    amount: "$12",
    change: "",
    upOrDown: "",
    type: "service",
    added: true
  }]

  const { classes, history, user } = props;

  const [assignHirearchy, setAssignHirearchy] = useState('')
  const [purposeOfBucket, setPurposeOfBucket] = useState('')
  const [nameOfGroup, setNameOfGroup] = useState('')
  const [legalOwner, setLegalOwner] = useState('')
  const [beneficialOwner, setBeneficialOwner] = useState('')

  return (
    <div className={classes.container}>
      <div style={{ textAlign: "center" }}>
        <YourDashboard style={{ height: "210px" }} />
      </div>


      <GridContainer className={classes.wealthBucketContainer}>

        <GridItem className={classes.column} xs={12}>
          <h3 className={classes.header}>ADD WEALTH BUCKET GROUP</h3>
          <p className={classes.details}>Include any assets you hold and relevant details here. More details means higher accuracy!</p>
        </GridItem>





        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Assign Hirearchy
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                required
                onChange={(e) => setAssignHirearchy(e)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {categorySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>



        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Name Of Group
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setNameOfGroup(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Purpose For Wealth Bucket
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setPurposeOfBucket(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>



        <GridItem xs={12}>
          <h3 style={{ color: '#4F4F4F', fontSize: "25px", lineHeight: 1 }} className={classes.header}>ASSIGN ACCOUNTS & INDIVIDUAL HOLDINGS</h3>
        </GridItem>
        {assetsMockData.map((d) => {
          return (
            <GridItem xs={6}>
              <Card className={classes.wealthGroupFormCard}>
                <div style={{ display: 'flex', marginBottom: "8px" }}>
                  {d.type === "asset" && (
                    <DiamondIcon style={{ width: "15px" }} />
                  )}
                  {d.type === "service" && (
                    <ExpenseIcon style={{ width: "15px" }} />
                  )}
                  <span className={classes.assetName}>{d.name}</span>
                </div>
                <div style={{ display: 'flex', marginBottom: "8px", justifyContent: d.change ? "space-between" : "center" }}>
                  <span className={classes.amount}>{d.amount}</span>
                  {d.change && (
                    <span className={classes.change} style={d.upOrDown === "up" ? { color: "#55DA53" } : { color: "#E53935" }}>{d.change}</span>
                  )}
                </div>
                {d.added ? (
                  <div style={{ display: 'flex', width: "100%" }}>
                    <Button style={{ background: "rgba(67, 108, 252, 0.5)" }} className={classes.addRemoveBtn}>Added</Button>
                    <Button style={{ background: "#4F4F4F" }} className={classes.addRemoveBtn}>Remove</Button>
                  </div>
                ) : (
                  <div style={{ display: 'flex', width: "100%", justifyContent: "center" }}>
                    <Button className={classes.addRemoveBtn} style={{ background: "#436CFC" }}>Add</Button>
                  </div>
                )}
              </Card>
            </GridItem>
          )
        })}




        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Beneficial Owner
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setBeneficialOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Legal Owner
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setLegalOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>




        <GridItem className={classes.justifyCenter} xs={12}>
          <Button
            className={classes.saveBtn}
            type="submit"

          >
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(WealthBucket);
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink, withRouter } from 'react-router-dom';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Search from "@material-ui/icons/Search";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Close from '@material-ui/icons/Close';
import { ReactComponent as BulletPoint } from 'assets/img/bullet.svg';
import Style from 'assets/jss/material-dashboard-pro-react/views/notificationsPageStyle';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { listenToNotificationsForUser, changeNotifcationStatusToOld, deleteSingleNotifcation } from 'store/actions/notificationsActions';
import Hidden from '@material-ui/core/Hidden';

import loader from "assets/img/loader.gif";


function compareIgnoreCase(field, criteria) {
    if (!criteria) return true;
    if (!field) return false;
    return field.toLowerCase().includes(criteria.toLowerCase());
}

function filterNotification(notification, criteria) {
    return !criteria || compareIgnoreCase(notification.from.firstName, criteria) || compareIgnoreCase(notification.from.lastName, criteria)
}


export function NotificationsPage(props) {
    const { classes, uid, notificationData, history, notificationsReceived } = props;
    // const [notificationsReceived, setNotificationsReceived] = useState(false)
    const [query, setQuery] = useState('')
    const [notifications, setNotifications] = useState([])
    useEffect(() => {
        if (!notificationsReceived && uid) {
            props.listenToNotificationsForUser(uid);
            // setNotificationsReceived(true);
        }
    }, [uid]);

    useEffect(() => {
        setNotifications(notificationData)
    }, [notificationData])

    const getDateAndTime = (dateTime) => {
        var date = new Date(dateTime.seconds * 1000)
        return moment(date).format("MMMM Do") + ' at ' + moment(date).format("h:mm a")
    }

    const navigateUserToNotification = (notification) => {
        if (notification.type === 'New Chat Message') {
            history.push(`/chat/${notification.threadId}`)
        } else if (notification.type === 'New Group Chat Message') {
            history.push(`/group-message/${notification.threadId}`)
        } else if (notification.type === 'New Event Invitation') {
            history.push(`/conferencing`)
        } else if (notification.type === 'New Match') {
            history.push(`/chat/${notification.from.id}`)
        } else {
            console.log('havent set up this clickable route yet')
        }
        props.changeNotifcationStatusToOld(notification.notificationId, uid)
    }

    const startDeleteNotifcation = (notification, index) => {
        var newNotifications = [...notifications]
        for (var x = 0; x < newNotifications.length; x++) {
            if (x === index) {
                newNotifications.splice(x, 1)
                break;
            }
        }
        setNotifications(newNotifications)
        props.deleteSingleNotifcation(notification.notificationId, uid, newNotifications)
    }

    return (
        <GridContainer justify="center">


            {/* mobile */}
            <Hidden mdUp>
                <GridItem className={classes.notificationsGridItem} xs={12}>
                    {notificationsReceived && notifications.length > 0 ? notifications.filter(notification => filterNotification(notification, query)).map((notification, index) => {
                        console.log('notification date', notification.createdDate)
                        return (
                            // <Card
                            //     key={index}
                            // >
                            //     <CardHeader style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "0px" }}>
                            //         <Close onClick={() => startDeleteNotifcation(notification, index)} className={classes.closeIcon} />
                            //     </CardHeader>
                            //     <CardBody onClick={() => navigateUserToNotification(notification)} style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "0px" }}>
                            <div key={index} onClick={() => navigateUserToNotification(notification)} style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "0px" }}>
                                {notification.status === 'New' ? (
                                    <BulletPoint style={{ marginRight: "20px" }} />
                                ) : (
                                    null
                                )}
                                <div className={classes.notificationInfo}>
                                    <div className={classes.typeAndFromInfoDiv}>
                                        <h1 className={classes.typeAndFromInfo} >
                                            {`${notification.type} from ${notification.from.firstName} ${notification.from.lastName}`}
                                        </h1>
                                        {/*
                                        {notification.message ? (
                                            <p>
                                                {`Message: ${notification.message}`}
                                            </p>
                                        ) : (
                                            null
                                        )}


                                        {notification.title ? (
                                            <p>
                                                {`Title: ${notification.title}`}
                                            </p>
                                        ) : (
                                            null
                                        )} */}
                                        <p>
                                            {getDateAndTime(notification.createdDate)}
                                        </p>

                                    </div>

                                </div>


                            </div>
                        )
                    }) : notificationsReceived && notifications.length === 0 ? (
                        < div className={classes.noNotificationsDiv} style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            <h6 className={classes.notificationMessage}>
                                You currently don't have any notifications. Message, event, and match alerts will be displayed here.
                            </h6>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>
                    )}
                </GridItem>
            </Hidden>



            {/* desktop */}

            <Hidden smDown>
                <GridItem xs={11}>
                    {notificationsReceived && notifications.length > 0 ? notifications.filter(notification => filterNotification(notification, query)).map((notification, index) => {
                        return (
                            <Card
                                key={index}
                            >
                                <CardHeader style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "0px" }}>
                                    <Close onClick={() => startDeleteNotifcation(notification, index)} className={classes.closeIcon} />
                                </CardHeader>
                                <CardBody onClick={() => navigateUserToNotification(notification)} style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "0px" }}>
                                    <NotificationsIcon className={classes.notificationIcon} style={notification.status === "New" ? { color: "#4775FB" } : {}} />
                                    <div className={classes.notificationInfo}>
                                        <div className={classes.typeAndFromInfoDiv}>
                                            <h1 className={classes.typeAndFromInfo} >
                                                {`${notification.type} from ${notification.from.firstName} ${notification.from.lastName}`}
                                            </h1>

                                            {notification.message ? (
                                                <p>
                                                    {`Message: ${notification.message}`}
                                                </p>
                                            ) : (
                                                null
                                            )}


                                            {notification.title ? (
                                                <p>
                                                    {`Title: ${notification.title}`}
                                                </p>
                                            ) : (
                                                null
                                            )}

                                        </div>
                                        <p>
                                            {`Created On ${getDateAndTime(notification.createdDate)}`}
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        )
                    }) : notificationsReceived && notifications.length === 0 ? (
                        < div className={classes.noNotificationsDiv} style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                            <h6 className={classes.notificationMessage}>
                                You currently don't have any notifications. Message, event, and match alerts will be displayed here.
                            </h6>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>
                    )}
                </GridItem>
            </Hidden>
        </GridContainer >
    )
}


const mapStateToProps = (state) => {
    return {
        uid: state.auth.user.uid,
        user: state.user.user,
        notificationData: state.notifications.notifications.dataRows,
        notificationsReceived: state.notifications.notifications.notificationsReceived
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // openNotificationActionDialog: (warningText, continueAction, rejectAction, cancelAction) => dispatch(openNotificationActionDialog(warningText, continueAction, rejectAction, cancelAction)),
        listenToNotificationsForUser: (uid) => dispatch(listenToNotificationsForUser(uid)),
        changeNotifcationStatusToOld: (notificationId, uid) => dispatch(changeNotifcationStatusToOld(notificationId, uid)),
        deleteSingleNotifcation: (notificationId, uid, newNotifications) => dispatch(deleteSingleNotifcation(notificationId, uid, newNotifications))
    }
}





export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(NotificationsPage));
import React, { Component } from 'react';
import FirstQuestion from './Questions/FirstQuestion';
import FirstQuestionGlobalResults from './GlobalResults/FirstQuestionGlobalResults';
import SecondQuestionGlobalResults from './GlobalResults/SecondQuestionGlobalResults';
import ThirdQuestionGlobalResults from './GlobalResults/ThirdQuestionGlobalResults';
import FourthQuestionGlobalResults from './GlobalResults/FourthQuestionGlobalResults';
import FifthQuestionGlobalResults from './GlobalResults/FifthQuestionGlobalResults';
import SixthQuestionGlobalResults from './GlobalResults/SixthQuestionGlobalResults';
import SeventhQuestionGlobalResults from './GlobalResults/SeventhQuestionGlobalResults';
import EighthQuestionGlobalResults from './GlobalResults/EighthQuestionGlobalResults';
import NinthQuestionGlobalResults from './GlobalResults/NinthQuestionGlobalResults';
import SecondQuestion from './Questions/SecondQuestion';
import ThirdQuestion from './Questions/ThirdQuestion';
import FourthQuestion from './Questions/FourthQuestion';
import FifthQuestion from './Questions/FifthQuestion';
import SixthQuestion from './Questions/SixthQuestion';
import SeventhQuestion from './Questions/SeventhQuestion';
import EigthQuestion from './Questions/EigthQuestion';
import NinthQuestion from './Questions/NinthQuestion';
import Confirm from './Confirm';
import Success from './Success';
import { db } from 'firebase/fbConfig.js';

export class WealthManagement extends Component {
    state = {
        step: 1,
        riskAversion: 0,
        riskSensationSeeking: 0,
        objectivePerceivedControl: 0,
        nonObjectivePerceivedControl: 0,
        contentSpecificRisk: 0,
        situationalSpecificRisk: 0,
        relationshipCommitment: 0,
        trust: 0,
        communicationEffectiveness: 0,
        dataLoaded: false,
        globalTestResults: {}
    };


    componentDidMount() {
        if (!this.state.dataLoaded) {
            return db.collection('global-test-results').doc('wealth-management').get().then((snapshot) => {
                const results = snapshot.data()
                console.log('results', results)
                this.setState({
                    dataLoaded: true,
                    globalTestResults: results
                })
            })
        }
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    // Go back to first question/retake quiz
    firstStep = () => {
        const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    // Handle toggle change
    handleToggle = (input, value) => {
        console.log('input', value, input)
        this.setState({
            [input]: value
        })
    }

    render() {
        const { classes } = this.props;
        const { step } = this.state;
        const { riskAversion, riskSensationSeeking, objectivePerceivedControl, nonObjectivePerceivedControl, contentSpecificRisk, situationalSpecificRisk, relationshipCommitment, trust, communicationEffectiveness } = this.state;
        const values = { riskAversion, riskSensationSeeking, objectivePerceivedControl, nonObjectivePerceivedControl, contentSpecificRisk, situationalSpecificRisk, relationshipCommitment, trust, communicationEffectiveness };

        switch (step) {
            case 1:
                return (
                    <FirstQuestion
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'riskAversion'}
                    />
                );

            case 2:
                return (
                    <FirstQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'riskAversion'}
                    />
                );
            case 3:
                return (
                    <SecondQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'riskSensationSeeking'}
                    />
                );
            case 4:
                return (
                    <SecondQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'riskSensationSeeking'}
                    />
                );
            case 5:
                return (
                    <ThirdQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'objectivePerceivedControl'}
                    />
                );

            case 6:
                return (
                    <ThirdQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'objectivePerceivedControl'}
                    />
                );
            case 7:
                return (
                    <FourthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'nonObjectivePerceivedControl'}
                    />
                );

            case 8:
                return (
                    <FourthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'nonObjectivePerceivedControl'}
                    />
                );
            case 9:
                return (
                    <FifthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'contentSpecificRisk'}
                    />
                );
            case 10:
                return (
                    <FifthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'contentSpecificRisk'}
                    />
                );
            case 11:
                return (
                    <SixthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'situationalSpecificRisk'}
                    />
                );
            case 12:
                return (
                    <SixthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'situationalSpecificRisk'}
                    />
                );
            case 13:
                return (
                    <SeventhQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'relationshipCommitment'}
                    />
                );
            case 14:
                return (
                    <SeventhQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'relationshipCommitment'}
                    />
                );
            case 15:
                return (
                    <EigthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'trust'}
                    />
                );
            case 16:
                return (
                    <EighthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'trust'}
                    />
                );
            case 17:
                return (
                    <NinthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        compare={'communicationEffectiveness'}
                    />
                );
            case 18:
                return (
                    <NinthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'communicationEffectiveness'}
                    />
                );
            case 19:
                return (
                    <Confirm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        firstStep={this.firstStep}
                        values={values}
                    />
                );
            case 20:
                return (
                    <Success
                        firstStep={this.firstStep}
                    />
                )
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default WealthManagement;
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';

// Core Components
import Button from 'components/CustomButtons/Button.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle.jsx';

// Forms
import CreateInvestorThreadsMessageForm from 'components/Forms/CreateInvestorThreadsMessageForm';

// Firebase Redux Integration
import InvestorThreadsMap from './InvestorThreadsMap';
const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class InvestorThreads extends React.Component {
  render() {
    const { classes, currentThread } = this.props;

    return (
      <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <InvestorThreadsMap />
        <div>
          {currentThread &&
            currentThread.documents &&
            currentThread.documents.map((document, index) => (
              <a key={index} href={document.url} target='_blank'>
                {document.name} <br />
                <Button
                  color='primary'
                  style={{
                    marginLeft: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <DescriptionIcon></DescriptionIcon>
                </Button>
              </a>
            ))}

          <CreateInvestorThreadsMessageForm />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentThread: state.threads.currentThread,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(InvestorThreads);

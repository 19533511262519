// Orders Data
const investors = {
  columns: [
    {
      Header: 'TITLE',
      accessor: 'title',
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
    },
    {
      Header: 'IMAGE',
      accessor: 'image',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
    {
      Header: 'CATEGORY',
      accessor: 'category',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
  ],
  dataLoaded: false,
};

export const initState = {
  investors,
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_INVESTORS_LIST':
      console.log('SET_INVESTORS_LIST');
      return {
        ...state,
        investors: {
          ...state.investors,
          dataRows: action.dataRows,
          dataLoaded: true,
        },
      };
    case 'ADD_INVESTOR_INVESTMENT':
      return {
        ...state,
        investments: [].concat(state.investments, action.data),
      };
    default:
      return state;
  }
};

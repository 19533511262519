import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { withRouter } from "react-router-dom";
import { addQuizXpPoints } from "store/actions/eventsActions"


export class Confirm extends Component {

    addToFirestore = () => {

        const values = this.props.location.state.state.values
        delete values['Do Not Know']
        Object.keys(values).forEach((key) => {
            if (values[key].includes('incorrect')) {
                values[key] = 'incorrect';
            }
        })
        return db.collection('global-test-results').doc('financialKnowledge').get().then((snapshot) => {
            const results = snapshot.data()
            for (var key in results) {
                if (key !== 'Do Not Know') {
                    const scoresObj = results[key]
                    scoresObj[values[key]]++
                }
            }
            return db.collection('global-test-results').doc('financialKnowledge').update({ ...results }).then(() => {
                return db.collection("users").doc(this.props.uid).collection("quizzes").doc("financialKnowledge").collection("quizLists").add({
                    quiz: "Financial Knowledge",
                    firstQuestion: this.props.location.state.state.values.firstQuestion,
                    secondQuestion: this.props.location.state.state.values.secondQuestion,
                    thirdQuestion: this.props.location.state.state.values.thirdQuestion,
                    fourthQuestion: this.props.location.state.state.values.fourthQuestion,
                    fifthQuestion: this.props.location.state.state.values.fifthQuestion,
                    sixthQuestion: this.props.location.state.state.values.sixthQuestion,
                    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    quizID: "Placeholder"
                })
                    .then((docRef) => {
                        db.collection("users").doc(this.props.uid).collection("quizzes").doc("financialKnowledge").collection("quizLists").doc(docRef.id).update({
                            quizID: docRef.id
                        })
                    }).then(() => {
                        this.props.addQuizXpPoints(this.props.uid, this.props.events, 'Financial Knowledge Quiz')
                        this.props.history.push('/financial-knowledge-success');
                    })
            })
        })
    }

    continue = () => {
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    retakeQuiz = e => {
        e.preventDefault();
        this.props.history.push('/financial-knowledge');
    }

    render() {
        const { classes } = this.props;
        const correctQuestionsCount = Object.values(this.props.location.state.state.values).filter((v) => {
            if (v === 'correct') {
                return true
            }
        })
        console.log('propssssx', this.props.location.state.state.values)

        return (
            <div className={classes.FinancialKnowledgeConfirmQuizContainer}>
                <div>
                    <h3 className={classes.questionHeader}>You completed the financial knowledge quiz! </h3>
                    <p style={{ color: "black", textAlign: 'center' }}>Either retake the quiz or submit your results below</p>
                </div>
                {/* <GridContainer>
                    <GridItem xs={12}>
                        <h1 style={{ color: "black" }}>
                            {correctQuestionsCount.length} / 6 correct!
                        </h1>
                    </GridItem>
                </GridContainer> */}
                <GridContainer style={{ marginTop: "50px" }}>
                    <GridItem style={{ padding: "0px" }} xs={6}>
                        <Button
                            style={{ width: "90%" }}
                            onClick={this.retakeQuiz}
                            className={classes.backButton}
                            variant="contained"
                        >Retake Quiz</Button>
                    </GridItem>
                    <GridItem style={{ padding: "0px" }} xs={6}>
                        <Button
                            style={{ width: "90%" }}
                            className={classes.submitButton}
                            onClick={this.addToFirestore}
                        >Submit</Button>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {}
    return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
    }
}

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Confirm));
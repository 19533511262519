export const initState = {
  authError: null,
  user: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN':
      console.log('LOGIN')
      return {
        ...state,
        user: action.user
      }
    case 'LOGIN_ERROR':
      console.log('LOGIN_ERROR')
      return {
        ...state,
        error: action.error
      }
    case 'SIGNIN_ERROR':
      console.log({ case: 'SIGNIN_ERROR', error: action.error })
      return {
        ...state,
        error: action.error
      }
    case 'SIGNUP_ERROR':
      console.log({ case: 'SIGNUP_ERROR', error: action.error })
      return {
        ...state,
        error: action.error
      }
    case 'LOGOUT':
      console.log('LOGOUT')
      return initState
    default:
      return state
  }
}

import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from 'react-redux';

import firebase, { storage } from "firebase/fbConfig.js"

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: props.currentPic,
      uploadProgress: ''
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        imageChanged: true
      });
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(userType) {
    const pic = this.state.file
    // create storage ref
    var storageRef = storage.ref('profilePics/' + this.props.uid + "/" + pic.name)
    // upload file
    var uploadTask = storageRef.put(pic)
    // update progress bar
    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and 
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          this.setState({ uploadProgress: ' Upload is paused' });
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          this.setState({ uploadProgress: 'Upload is running' });
          break;
      }
    }, (error) => {
      // Handle unsuccessful uploads
      console.log(error)
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({ uploadProgress: 'Upload finished!' });
        /*
        db.collection("users").doc(this.props.uid).set({
          profileURL: downloadURL
        }, { merge: true }).then(() => {
          db.collection(userType).doc(this.props.uid).set({
            profileURL: downloadURL
          }, { merge: true }).then(() => {
            this.props.handleSubmit(uid,downloadURL);
          })
        })
        */
        this.props.handleSubmit(this.props.uid, downloadURL);
      });
    })
  }
  render() {
    const { AuthBool, classes, user, assessments } = this.props;
    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={e => this.handleImageChange(e)} />
        </div>

        {
          this.state.file ? (
            this.state.uploadProgress ? (
              <p>{this.state.uploadProgress}</p>
            ) : (
                <Button
                  style={{backgroundColor:"#0fb3d8", width: "90%"}}
                  onClick={() => this.handleSubmit(user.userType.toLowerCase())}
                ><b>Update Image</b></Button>
              )
          ) : (
              <h6 className="description">Choose A Picture</h6>
            )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)
  return {
    uid: state.auth.user.uid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PictureUpload);
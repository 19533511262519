import { LOCATION_CHANGE } from 'connected-react-router';
import { pathToRegexp } from 'path-to-regexp';

// Orders Data
const investments = {
  columns: [
    {
      Header: 'TITLE',
      accessor: 'title',
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
    },
    {
      Header: 'IMAGE',
      accessor: 'image',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
    {
      Header: 'CATEGORY',
      accessor: 'category',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
  ],
  dataLoaded: false,
  dataRows: [],
};

const templateInvestment = {
  investmentNumber: 'N/A',
  name: 'N/A',
  investmentType: 'N/A',
  investor: null,
  documents: [],
  createdDate: 'N/A',
  serviceNeeded: 'N/A',
  summary: 'N/A',
  status: 'unassigned',
  owner: null,
  parties: [],
};

const investorThreadPath = pathToRegexp('/:cid/investorThreads/:tid');
const investmentChatPath = pathToRegexp('/investmentChat/:cid');
const investmentPath = pathToRegexp('/investment/:cid');
const archivedInvestmentPath = pathToRegexp('/investment/:type/:cid');
const investmentPermissionsPath = pathToRegexp('/investmentshare/:cid/:role');

function cidFromPath(path) {
  let match = investorThreadPath.exec(path);
  if (match) return match[1];
  match = investmentPath.exec(path);
  if (match) return match[1];
  match = archivedInvestmentPath.exec(path);
  if (match) return match[2];
  match = investmentChatPath.exec(path);
  if (match) return match[1];
  match = investmentPermissionsPath.exec(path);
  if (match) return match[1];
  return undefined;
}

export const initState = {
  investments,
  currentInvestment: {},
  uploading: false,
  uploadProgress: 0,
};

export default (state = initState, action) => {
  const { investments, currentInvestment } = state;
  switch (action.type) {
    case 'INVESTMENTS_ADD':
      return {
        ...state,
        investments: {
          columns: investments.columns,
          dataLoaded: true,
          dataRows: [
            ...investments.dataRows,
            { ...templateInvestment, ...action.data, cid: action.cid },
          ],
        },
        currentInvestment:
          currentInvestment && currentInvestment.cid === action.cid
            ? { ...currentInvestment, ...action.data }
            : currentInvestment,
      };
    case 'INVESTMENTS_MODIFY':
      return {
        ...state,
        investments: {
          columns: investments.columns,
          dataLoaded: true,
          dataRows: investments.dataRows.map((row) =>
            row.cid === action.cid ? { ...row, ...action.data } : row
          ),
        },
        currentInvestment:
          currentInvestment && currentInvestment.cid === action.cid
            ? { ...currentInvestment, ...action.data }
            : currentInvestment,
      };
    case 'INVESTMENTS_DELETE':
      return {
        ...state,
        investments: {
          columns: investments.columns,
          dataLoaded: true,
          dataRows: investments.dataRows.filter((row) => row.cid !== action.cid),
        },
      };
    case 'INVESTMENTS_SET_CURRENT':
      return {
        ...state,
        currentInvestment: investments.dataRows.find((row) => row.cid === action.cid),
      };
    case 'INVESTMENTS_UPDATE_CURRENT':
      return {
        ...state,
        currentInvestment: { ...currentInvestment, ...action.data },
      };
    case 'INVESTMENTS_CREATE_NEW':
      return {
        ...state,
        currentInvestment: {
          ...templateInvestment,
          owner: action.uid,
          parties: [action.uid],
        },
      };
    case 'INVESTMENTS_UPLOAD_PROGRESS':
      return {
        ...state,
        uploadProgress: action.progress,
      };
    case 'INVESTMENTS_START_UPLOAD':
      return {
        ...state,
        uploadProgress: 0,
        uploading: true,
      };
    case 'INVESTMENTS_END_UPLOAD':
      return {
        ...state,
        uploadProgress: 1,
        uploading: false,
      };
    case 'INVESTMENTS_SET_INVITATION':
      return {
        ...state,
        investments: {
          columns: investments.columns,
          dataRows: investments.dataRows.map((row) =>
            row.cid === action.cid
              ? {
                  ...row,
                  invitations: {
                    ...(row.invations || {}),
                    [action.email]: action.data,
                  },
                }
              : row
          ),
        },
        currentInvestment:
          currentInvestment && currentInvestment.cid === action.cid
            ? {
                ...currentInvestment,
                invitations: {
                  ...(currentInvestment.invitations || []),
                  [action.email]: action.data,
                },
              }
            : currentInvestment,
      };
    case 'INVESTMENTS_REMOVE_INVITATION':
      return {
        ...state,
        investments: {
          columns: investments.columns,
          dataRows: investments.dataRows.map((row) =>
            row.cid === action.cid
              ? {
                  ...row,
                  invitations: {
                    ...row.invitations,
                    [action.email]: undefined,
                  },
                }
              : row
          ),
        },
        currentInvestment:
          currentInvestment && currentInvestment.cid === action.cid
            ? {
                ...currentInvestment,
                invitations: {
                  ...currentInvestment.invitations,
                  [action.email]: undefined,
                },
              }
            : currentInvestment,
      };
    case LOCATION_CHANGE:
      const cid = cidFromPath(action.payload.location.pathname);
      return {
        ...state,
        currentInvestment:
          currentInvestment && currentInvestment.cid === cid
            ? currentInvestment
            : investments.dataRows.find((row) => row.cid === cid) ||
              (cid && { ...templateInvestment, cid }),
      };
    default:
      return state;
  }
};

import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Datetime from "react-datetime";

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import ExploreAssignInvestor from "../../views/Explore/ExploreAssignInvestor";

import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx";

class Form extends React.Component {
    state = {
        comment: '',
        messageSent: false,
        messageError: false
    }
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden"
        };
    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    handleChange = (e, uid, name) => {
        this.setState({
            [e.target.id]: e.target.value,
            uid: uid,
            name: name
        })
    }

    handlePickUpDateChange = (start) => {
        //console.log(pickUpDate._d)
        if (start) {
            this.setState(() => ({ start: start._d }))
        }
    }

    handleDropOffDateChange = (end) => {
        //console.log(dropOffDate)
        if (end) {
            this.setState(() => ({ end: end._d }))
        }
    }

    // only make future selectable  
    valid = (current) => {
        return current.isAfter(Datetime.moment().subtract(1, 'day'));
    };



    handleSubmit = (e) => {
        e.preventDefault();
        // update firestore document with PENDING and comment
        db.collection("users").doc(this.state.uid).collection("events").doc().set({
            title: this.state.title,
            start: this.state.start,
            end: this.state.end,
            investorID: this.state.investorID,
            investorFirstName: this.state.investorFirstName,
            investorLastName: this.state.investorLastName,
            investorEmail: this.state.investorEmail,
            zoom: this.state.zoom ? this.state.zoom : "",
            description: this.state.description,
            status: "proposed",
            createdDate: new Date()
        }, { merge: true }).then(() => {
            db.collection("users").doc(this.state.investorID).collection("events").doc().set({
                title: this.state.title,
                start: this.state.start,
                end: this.state.end,
                advisorID: this.state.uid,
                zoom: this.state.zoom ? this.state.zoom : "",
                description: this.state.description,
                status: "proposed",
                createdDate: new Date()
            }, { merge: true }).then(() => {
                db.collection("users").doc(this.state.investorID).collection("notifications").doc().set({
                    text: "You have a new event!",
                    datetime: new Date(),
                    type: "assignEvent",
                    status: "proposed",
                    createdDate: new Date()
                }, { merge: true }).then(() => {
                    console.log(``)
                    this.setState({
                        comment: '',
                        messageSent: true
                    })
                }).catch((err) => {
                    console.log(`${err}`)
                })
            }).catch((err) => {
                console.log(`${err}`)
            })
        }).catch((err) => {
            console.log(`${err}`)
        })
    }

    assignInvestor = (advisorID, firstName, lastName, email) => {
        this.setState({
            investorID: advisorID,
            investorFirstName: firstName,
            investorLastName: lastName,
            investorEmail: email
        })
    }



    resetRequest = () => {
        window.location.reload();
    }

    render() {
        const { classes, modalClose, authUser, user, blogID } = this.props;
        //console.log(this.state)
        // reset modal when closed
        if (this.state.messageSent) { this.resetRequest() }
        console.log(authUser.uid)
        return (
            <div>
                {this.state.messageSent ? (
                    <GridContainer justify="center">
                        <GridItem xs={11}>
                            <h4 className={classes.textPrimary}><center>Event Submitted!</center></h4>

                        </GridItem>
                    </GridContainer>
                ) : (
                    <div style={{ backgroundColor: "#264653", marginTop: "-24px" }}>
                        <form style={{ marginBottom: "-30px" }} onSubmit={this.handleSubmit}
                        >
                            <GridContainer justify="center">
                                <GridItem xs={11}>
                                    <h5 style={{ marginTop: "-39px" }} className={classes.bodyText}><b>Create A New Event</b></h5>
                                    {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
                                    <Card style={{ padding: "10px 10px 0px 10px" }}>
                                        <CustomInput
                                            labelText="Title"
                                            id="title"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            labelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                type: "text",
                                                multiline: false,
                                                rows: 1,
                                                onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                                            }}
                                        />
                                    </Card>
                                    <Card style={{ padding: "10px 10px 0px 10px" }}>
                                        <CustomInput
                                            labelText="Description"
                                            id="description"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            labelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                type: "text",
                                                multiline: false,
                                                rows: 1,
                                                onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                                            }}
                                        />
                                    </Card>
                                    <Card style={{ padding: "10px 10px 0px 10px" }}>
                                        <Datetime
                                            selected={this.state.start}
                                            onChange={this.handlePickUpDateChange}
                                            timeFormat={true}
                                            isValidDate={this.valid}
                                            renderInput={(dateInputProps, open, close) => (
                                                <CustomInput
                                                    inputProps={{ ...dateInputProps, id: 'start', name: 'start', required: true, }}
                                                    onBlur={close}
                                                    onFocus={open}
                                                    labelText="Start Time"
                                                    labelProps={{
                                                        shrink: true
                                                    }}

                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            )}
                                        />
                                    </Card>
                                    <Card style={{ padding: "10px 10px 0px 10px" }}>
                                        <Datetime
                                            selected={this.state.end}
                                            onChange={this.handleDropOffDateChange}
                                            timeFormat={true}
                                            isValidDate={this.valid}
                                            renderInput={(dateInputProps, open, close) => (
                                                <CustomInput
                                                    inputProps={{ ...dateInputProps, id: 'end', name: 'end', required: true, }}
                                                    onBlur={close}
                                                    onFocus={open}
                                                    labelText="End Time"
                                                    labelProps={{
                                                        shrink: true
                                                    }}

                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            )}
                                        />
                                    </Card>
                                    <Card style={{ padding: "10px 10px 0px 10px" }}>
                                        <CustomInput
                                            labelText="Conference Link"
                                            id="zoom"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            labelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                required: false,
                                                type: "text",
                                                multiline: false,
                                                rows: 1,
                                                onChange: (event) => this.handleChange(event, authUser.uid, authUser.uid)
                                            }}
                                        />
                                    </Card>
                                    <ExploreAssignInvestor onAssignAdvisor={this.assignInvestor} />
                                    <br />
                                </GridItem>
                            </GridContainer>
                            <Button
                                type="submit"
                                color="primary"
                                style={{
                                    width: "88%",
                                    marginLeft: "15px",
                                    marginBottom: "30px"
                                }}
                            ><b>
                                    Submit</b>
                            </Button>
                        </form>
                    </div>

                )}
            </div>
        );
    }
}

Form.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.user.user,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(Form);
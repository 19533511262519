import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import pagesRoutes from "routes/pages.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/ffpo2.jpg";
import UserTypeBackground from 'assets/img/userTypeBackground.png';
import setupBackground from 'assets/img/setupBackground.svg';

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    const { classes, pages, ...rest } = this.props;
    //console.log(pages)
    return (
      <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={window.location.pathname.includes('setup') ? classes.fullPageSetup : classes.fullPage}
            style={window.location.pathname.includes('usertype') ? { backgroundImage: `url(${UserTypeBackground})`, minHeight: '56vh', height: "56vh", alignItems: "start", padding: "80px 0px" } : window.location.pathname.includes('setup') ? { backgroundImage: `url(${setupBackground})` } : window.location.pathname.includes('login') || window.location.pathname.includes('signup') ? { background: "linear-gradient(165.26deg, #436CFC -20.8%, #75E8E2 40.92%, #D7F3F1 102.65%)", height: "100vh" } : { background: "#E5EDF1", height: "100vh" }}
          >
            <Switch>
              {pagesRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
    pages: state.content.pages
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(pagesStyle)
)(Pages);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/groupMessageThreadStyle';
import ThreadsMap from '../Chat/Threads/ThreadsMap.jsx';
import Threads from '../Chat/Threads/Threads.jsx';

import { addMessageToGroupThread } from 'store/actions/threadsActions';
import { getAttendessAndMessages } from 'store/actions/calendarActions';
import loader from 'assets/img/loader.gif';
import avatar from 'assets/img/default-avatar.png';
import CreateMessageForm from './CreateMessageForm';

export function OneOnOneChat(props) {
  const {
    classes,
    addMessageToGroupThread,
    user,
    attendees,
    uid,
    groupMessageThread,
    title,
  } = props;
  const messageId = window.location.pathname.slice(15);
  const [attendeesRetrieved, setAttendeesRetrieved] = useState(false);

  console.log('groupMessageThreadgroupMessageThread', groupMessageThread);

  var messagesEnd;

  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  });

  useEffect(() => {
    if (!attendeesRetrieved && uid) {
      props.getAttendessAndMessages(uid, messageId);
      setAttendeesRetrieved(true);
    }
  }, [uid]);

  return (
    <div className={classes.container}>
      <Card className={classes.containerCard}>
        {title ? <h1 className={classes.header}>{`${title} Group Chat`}</h1> : null}

        <CardBody className={classes.cardBody}>
          {groupMessageThread ? (
            groupMessageThread.map((message, index) => {
              // sometimes current user id property is under id and sometimes it is under uid
              console.log('checking message', message.intakeForm);
              if (message.intakeForm) {
                return (
                  <div style={{ background: "#72C5C8", color: "white" }} className={classes.messageDiv}>
                    <div className={classes.userTextBubble}>{message.message}</div>
                    <div className={classes.userPicAndInfo}>
                      <img
                        className={classes.recommendationsPic}
                        src={message.messageSentFrom.profileURL || avatar}
                      />
                      <p className={classes.nameOfSender}>You</p>
                    </div>
                  </div>
                );
              }

              if (message.messageSentFrom.id && message.messageSentFrom.id === uid) {
                return (
                  <div className={classes.messageDiv}>
                    <div className={classes.userTextBubble}>{message.message}</div>
                    <div className={classes.userPicAndInfo}>
                      <img
                        className={classes.recommendationsPic}
                        src={message.messageSentFrom.profileURL || avatar}
                      />
                      <p className={classes.nameOfSender}>You</p>
                    </div>
                  </div>
                );
              }
              if (
                message.messageSentFrom.uid &&
                message.messageSentFrom.uid === uid
              ) {
                return (
                  <div className={classes.messageDiv}>
                    <div className={classes.userTextBubble}>{message.message}</div>
                    <div className={classes.userPicAndInfo}>
                      <img
                        className={classes.recommendationsPic}
                        src={message.messageSentFrom.profileURL || avatar}
                      />
                      <p className={classes.nameOfSender}>You</p>
                    </div>
                  </div>
                );
              }
              return (
                <div className={classes.messageDiv}>
                  <div className={classes.otherSenderPicAndInfo}>
                    <img
                      className={classes.recommendationsPic}
                      src={message.messageSentFrom.profileURL || avatar}
                    />
                    <p
                      className={classes.nameOfSender}
                    >{`${message.messageSentFrom.firstName} ${message.messageSentFrom.lastName}`}</p>
                  </div>
                  <div className={classes.otherSenderTextBubble}>
                    {message.message}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={classes.loaderDiv}>
              <img className={classes.loader} src={loader} />
            </div>
          )}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={(el) => {
              messagesEnd = el;
            }}
          ></div>
        </CardBody>

        {/* <input
          onChange={(e) => setTextInput(e.target.value)}
          style={{ width: '100%' }}
        ></input>
        <button onClick={submitMessage}>Submit</button> */}
      </Card>
      <CreateMessageForm />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addMessageToGroupThread: (messageInfo, attendees) =>
    dispatch(addMessageToGroupThread(messageInfo, attendees)),
  getAttendessAndMessages: (uid, messageId) =>
    dispatch(getAttendessAndMessages(uid, messageId)),
});

const mapStateToProps = (state) => {
  console.log('stateeeeeeex', state.threads.groupMessageThread);
  return {
    user: state.user.user,
    attendees: state.events.events.attendees,
    uid: state.auth.user.uid,
    groupMessageThread: state.threads.groupMessageThread,
    title: state.events.events.title,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(OneOnOneChat);

import React from "react";
import PropTypes from "prop-types";

import axios from 'axios'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from '@material-ui/icons/Check';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateAssessment } from "store/actions/assessmentActions.jsx"

// Assets
import precincts from "variables/precincts.jsx"
import Question from "./Questions/Question";

class Form extends React.Component {
  state = {
  }

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      ...this.state,
    };
  }

  //Phone number code modified from
  // https://stackoverflow.com/questions/30058927/format-a-phone-number-as-a-user-types-using-pure-javascript 
  isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };

  enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!this.isNumericInput(event) && !this.isModifierKey(event)) {
      event.preventDefault();
    }
  };

  formatToPhone = (event) => {
    if (this.isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only    
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    let targetValue
    if (input.length > 6) { targetValue = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { targetValue = `(${zip}) ${middle}`; }
    else if (input.length > 0) { targetValue = `(${zip}`; }
    target.value = targetValue
    this.setState({
      phone: targetValue
    })
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  handleChange = (e) => {
    let value = e.target.value
    if (e.target.id === "firstName" || e.target.id === "lastName") {
      value = value.charAt(0).toUpperCase() + value.slice(1)
    }
    console.log(value)
    this.setState({
      [e.target.id]: value
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    updateAssessment(this.state, this.props.uid)
  }

  handleToggle(value, field) {
    const { question1 } = this.state;

    if (this.state[field] === value) {
      this.setState({
        [field]: '',
      });
    } else {
      this.setState({
        [field]: value,
      });
    }
    console.log(this.state)
  }


  render() {
    const { classes } = this.props;
    const selectedValue = this.state.selectedValue;
    const setSelectedValue = this.state.setSelectedValue;
    //console.log(this.state)
    return (
      <div className={classes.formContainer}>
        <form onSubmit={this.handleSubmit}
        >
          <GridContainer justify="center">
            <GridItem xs={11}>
              {this.state.messageError ? <h5 className={classes.bodyText}>There Has Been An Error</h5> : null}
              {/*<Question order="1" content={this.state.question1content} questionState={this.state.question1} field="question1" answer1={this.state.question1answer1} answer2={this.state.question1answer2} answer3={this.state.question1answer3} answer4={this.state.question1answer4} answer5={this.state.question1answer5}/>*/}
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 1</h5>
                <h5 className={classes.bodyText}>Has your organization intentionally set out to discover its single common purpose or mission (Purpose) beyond profit?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 1)}
                        checked={this.state.question1 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='NO - Organizational leaders have not yet  intentionally set out to discover a Purpose beyond profit.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 1)}
                        checked={this.state.question1 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Organizational leaders have started to have conversations about the role of Purpose in their organization.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 1)}
                        checked={this.state.question1 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders are committed to discovering the organization's Purpose and are in the process of setting up a taskforce to undertake the discovery process."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 1)}
                        checked={this.state.question1 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders have held formalized discussions and have started to uncover the organization’s reason for existence in terms of the problem it exists to solve."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 1)}
                        checked={this.state.question1 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders have a firm and compelling understanding of the organization’s reason for existence from a study of the organization’s history, formalized discussions and brainstorming; and leaders have studied how outsiders understand why the organization exists."
                  />
                </div><br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 2</h5>
                <h5 className={classes.bodyText}>Has your organization strategically worked to keep its Purpose as the fundamental reason for being in business?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 2)}
                        checked={this.state.question2 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='NO - Organizational leaders have not yet  intentionally set out to discover a Purpose beyond profit.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 2)}
                        checked={this.state.question2 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Organizational leaders have started to have conversations about the role of Purpose in their organization.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 2)}
                        checked={this.state.question2 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders are committed to discovering the organization's Purpose and are in the process of setting up a taskforce to undertake the discovery process."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 2)}
                        checked={this.state.question2 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders have held formalized discussions and have started to uncover the organization’s reason for existence in terms of the problem it exists to solve."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 2)}
                        checked={this.state.question2 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders have a firm and compelling understanding of the organization’s reason for existence from a study of the organization’s history, formalized discussions and brainstorming; and leaders have studied how outsiders understand why the organization exists."
                  />
                </div><br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 3</h5>
                <h5 className={classes.bodyText}>Does the company's senior leadership and strategy team appreciate and regularly discuss what Purpose means to them and the role of Purpose in their organization?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 3)}
                        checked={this.state.question3 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='NO - Purpose is never discussed in any of our management meetings.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 3)}
                        checked={this.state.question3 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='SOMEWHAT - Purpose has been discussed as a possible strategy for building customer loyalty or as one of our core values.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 3)}
                        checked={this.state.question3 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - There is a stated Purpose but there has been very little discussion as to what it means for our company."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 3)}
                        checked={this.state.question3 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES  - There is a stated Purpose and there have been some formal discussions among the leadership as to how Purpose will impact our operations."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 3)}
                        checked={this.state.question3 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Our senior leadership and strategy team continue to gain a better understanding of Purpose and its implications so it can be more useful as a guiding light and context for decision-making."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 4</h5>
                <h5 className={classes.bodyText}>Does leadership find the organization's Purpose meaningful and important to them and are they inspired by it?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 4)}
                        checked={this.state.question4 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Leadership is not really concerned whether the orgnaization's Purpose is meaningful and important to all of them."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 4)}
                        checked={this.state.question4 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - A majority of our senior management are behind it, but there is still some resistance from some on the language used to express it in an meaningful way."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 4)}
                        checked={this.state.question4 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - All of our senior managers and the strategy team are behind it, but it has not yet been communicated to the entire leadership."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 4)}
                        checked={this.state.question4 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Our entire leadership team says they are behind it, but currently there doesn't seem to be much discussion or enthusiasm about it on a daily basis."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 4)}
                        checked={this.state.question4 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - All of our leadership finds our single common purpose meaningful and important to them, are inspired by it, and are actively committed to its fulfillment."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 5</h5>
                <h5 className={classes.bodyText}>Do you believe your organization's Purpose is meaningful and important to others outside of your organization?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 5)}
                        checked={this.state.question5 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - We have only considered whether our leadership finds our Purpose meaningful and important to them."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 5)}
                        checked={this.state.question5 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Our Purpose is meaningful and important to our leaders and we are now considering who else should find our Purpose meaningful and important."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 5)}
                        checked={this.state.question5 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - We know it is meaningful and important to our employees and we are currently asking all of our other key stakeholders if they find it meaningful and important as well."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 5)}
                        checked={this.state.question5 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - We are getting feedback from all of our key stakeholders and are reframing our Purpose so that it will be meaningful and important to all of them."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 5)}
                        checked={this.state.question5 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - We believe our organization's Purpose is meaningful and important to our entire stakeholder community (all of our key stakeholders)."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 6</h5>
                <h5 className={classes.bodyText}>Does your organization measure the impact of its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 6)}
                        checked={this.state.question6 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - There is no planned measurement of Purpose delivery."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 6)}
                        checked={this.state.question6 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - It's informally and indirectly measured using current operating metrics."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 6)}
                        checked={this.state.question6 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - A plan for measuring purpose has been discussed."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 6)}
                        checked={this.state.question6 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Possible metrics to measure purpose delivery are being developed, measured and evaluated."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 6)}
                        checked={this.state.question6 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has a clear time-based plan for consistently measuring Purpose in relation to specific envisioned outcomes both externally and internally, which describe the difference the organization is making."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 7</h5>
                <h5 className={classes.bodyText}>Does your organization have a clear Purpose statement that articulates why the organization exists apart from what the organization does?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 7)}
                        checked={this.state.question7 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - We have a traditional mission statement that focuses on what we do or how we do what we do."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 7)}
                        checked={this.state.question7 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - We have an short phrase or tag line that expresses what our organization is committed to."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 7)}
                        checked={this.state.question7 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - A plan for measuring purpose has been discussed."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 7)}
                        checked={this.state.question7 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - We have a short statement that clearly communicates the underlying world problem the organization seeks to improve."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 7)}
                        checked={this.state.question7 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Our Purpose statement powerfully communicates the underlying world condition the organization seeks to improve and clearly identifies the problem the organization exists to solve."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 8</h5>
                <h5 className={classes.bodyText}>Has your organization involved other organizations and partners in delivering its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 8)}
                        checked={this.state.question8 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - There has not been strategic partnering around Purpose yet."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 8)}
                        checked={this.state.question8 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization is considering including partners to create a coalition to deliver its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 8)}
                        checked={this.state.question8 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has started talking to potential partners to create a coalition to deliver its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 8)}
                        checked={this.state.question8 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has enrolled a diverse group of partners that are committed to removing roadblocks for the organization to deliver its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 8)}
                        checked={this.state.question8 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has identified the critical roadblocks in delivering its Purpose and has enrolled multiple partners strategically to help deliver its Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 9</h5>
                <h5 className={classes.bodyText}>Has your organization identified its stakeholder world (environment or ecosystem) and its key stakeholders within that world (its stakeholder community)?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 9)}
                        checked={this.state.question9 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Its Stakeholder world has not been formally considered."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 9)}
                        checked={this.state.question9 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leadership is considering the concept of a stakeholder world and why it is important to distinguish, but they have not yet clearly identified who those stakeholders are."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 9)}
                        checked={this.state.question9 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leadership is in the process of identifying who is in their stakeholder world but has not considered redesigning that world."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 9)}
                        checked={this.state.question9 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leadership has undertaken redesigning its stakeholder world to meet its needs."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 9)}
                        checked={this.state.question9 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leadership has in place a stakeholder world that is actively supporting the fulfillment of the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 10</h5>
                <h5 className={classes.bodyText}>Has your organization prioritized its key stakeholders?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 10)}
                        checked={this.state.question10 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Key stakeholders have not been formally prioritized."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 10)}
                        checked={this.state.question10 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has considered prioritizing stakeholders on certain criteria."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 10)}
                        checked={this.state.question10 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has prioritized stakeholders through an intentional process."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 10)}
                        checked={this.state.question10 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has prioritized key stakeholders through an intentional process supported by the leadership team."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 10)}
                        checked={this.state.question10 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has prioritized key stakeholders based on the criteria of the influence and impact of the stakeholder on the organization's ability to deliver its Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 11</h5>
                <h5 className={classes.bodyText}>Does your organization engage in discussions with key stakeholders around its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 11)}
                        checked={this.state.question11 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Leadership has not talked about Purpose to any of its other key stakeholders."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 11)}
                        checked={this.state.question11 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Leadership informally discusses Purpose with some of the organization's key stakeholders; especially its employees."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 11)}
                        checked={this.state.question11 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leadership intends to discuss Purpose with its key stakeholders."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 11)}
                        checked={this.state.question11 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Most of the identified key stakeholders have regular discussions with leadership regarding the organization's Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 11)}
                        checked={this.state.question11 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - All key stakeholders are regularly in discussion with the organization and its leaders about the organization's Purpose and strategies have been implemented to respond to feedback."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 12</h5>
                <h5 className={classes.bodyText}>Are there people in your organization focused on inspiring and engaging stakeholders?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 12)}
                        checked={this.state.question12 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - There is no formal responsibility for inspiring and engaging stakeholders."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 12)}
                        checked={this.state.question12 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - People informally engage and enroll stakeholders but not on a consistent basis."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 12)}
                        checked={this.state.question12 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There are specific people and resources assigned to stakeholder engagement and enrollment."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 12)}
                        checked={this.state.question12 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - People and resources are allocated for stakeholder enrollment and engagement and there is an informal plan for ongoing stakeholder engagement initiatives."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 12)}
                        checked={this.state.question12 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - People and resources are allocated for stakeholder engagement and enrollment, and a formalized plan with success metrics for stakeholder engagement has been developed and implemented."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 13</h5>
                <h5 className={classes.bodyText}>Is your organizational culture centered on and around a "movement" to deliver the organization's Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 13)}
                        checked={this.state.question13 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - The culture is more results and efficiency oriented and there has not been any thought given to creating an emotional connection to a movement around Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 13)}
                        checked={this.state.question13 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="MAYBE - Although culture is more results and efficiency-oriented, it is considering developing a connection to a Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 13)}
                        checked={this.state.question13 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There are plans to create a shared felt commitment to a movement."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 13)}
                        checked={this.state.question13 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There is some reinforcement of the Purpose and a shared felt commitment to the movement."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 13)}
                        checked={this.state.question13 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There is consistent reinforcement of the Purpose and a shared, felt commitment to the movement."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 14</h5>
                <h5 className={classes.bodyText}>Does leadership ensure that all employees feel emotionally connected to the organization's Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 14)}
                        checked={this.state.question14 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - There is limited investment in cultivating an emotional connection to the Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 14)}
                        checked={this.state.question14 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - There have been discussions around the value of having employees feel emotionally connected to the organization's Purpose and how that might improve engagement."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 14)}
                        checked={this.state.question14 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There are some plans for communicating Purpose throughout the organization."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 14)}
                        checked={this.state.question14 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There are plans and actions taken for instilling Purpose among ALL employee groups, for addressing Purpose in such practices as on-boarding, training, and rewards, and for training supervisors to facilitate the emotional connection to the Movement."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 14)}
                        checked={this.state.question14 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Purpose is a key workforce development strategy and all managers and supervisors are held accountable for instilling Purpose and assessing employees' perceptions of the organization's Purpose in their every day work."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 15</h5>
                <h5 className={classes.bodyText}>Has your organization formally branded and widely and consistently communicated its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 15)}
                        checked={this.state.question15 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - The organization has not incorporated its Purpose into the brand strategy."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 15)}
                        checked={this.state.question15 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - The organization is considering how it might incorporate its Purpose into the brand strategy."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 15)}
                        checked={this.state.question15 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization intends to incorporate its Purpose into the brand strategy in the future."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 15)}
                        checked={this.state.question15 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization is working on specific messaging to each of its key stakeholders and building a cohesive brand strategy around Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 15)}
                        checked={this.state.question15 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has a compelling, cohesive brand strategy and/or tagline that effectively communicates Purpose, and has individually tailored its message so that it resonates with each key stakeholder group."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 16</h5>
                <h5 className={classes.bodyText}>Are organizational leaders formally considering Purpose when making strategic decisions?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 16)}
                        checked={this.state.question16 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Organizational leaders do not refer to Purpose when making strategic decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 16)}
                        checked={this.state.question16 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Organizational leaders consider Purpose informally and occasionally when making strategic decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 16)}
                        checked={this.state.question16 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders agree on the importance of considering Purpose when making strategic decisions but don't consistently do that."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 16)}
                        checked={this.state.question16 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders regularly refer to the organization's Purpose when making key decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 16)}
                        checked={this.state.question16 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizational leaders formally discuss and analyze strategic decisions in light of the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 17</h5>
                <h5 className={classes.bodyText}>How does the organization use and consider Purpose within its strategic planning?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 17)}
                        checked={this.state.question17 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Those responsible for strategic planning do not currently consider Purpose during the planning process."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 17)}
                        checked={this.state.question17 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="The planning team has discussed and generally understands the difference between Purpose and strategy."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 17)}
                        checked={this.state.question17 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="The planning team understands the difference and informally uses Purpose as a context for strategic planning."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 17)}
                        checked={this.state.question17 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="The planning team formally uses Purpose as the context for decision-making and Purpose is considered in every major strategic decision."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 17)}
                        checked={this.state.question17 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="The planning team formally uses Purpose as the context for decision-making, Purpose is considered in every strategic decision, and decisions regarding actions and priorities are made using Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 18</h5>
                <h5 className={classes.bodyText}>How does the organization generally analyze the quality of strategic decisions?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 18)}
                        checked={this.state.question18 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Organizational leaders uses traditional, ROI-focused criteria to make the majority of its strategic decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 18)}
                        checked={this.state.question18 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Organizational leaders are considering using Purpose as one of the criteria in analyzing and making strategic decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 18)}
                        checked={this.state.question18 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Organizational leaders are planning on using Purpose as one of the criteria in analysing and making strategic decisions."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 18)}
                        checked={this.state.question18 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Organizational leaders are designing ways to formally measure impact of its strategic choices on Purpose fulfillment in conjunction with more traditional decision-making criteria."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 18)}
                        checked={this.state.question18 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Organizational leaders primarily evaluate their strategic choices based on their impact on fulfilling the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 19</h5>
                <h5 className={classes.bodyText}>Does the organization use achieving its Purpose as a measure and metric of success?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 19)}
                        checked={this.state.question19 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Other measures of success associated with its business model are used."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 19)}
                        checked={this.state.question19 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="POSSIBLY - Leaders are considering using the fulfillment of its Purpose as one of its measures of success."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 19)}
                        checked={this.state.question19 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leaders have agreed to use the fulfillment of its Purpose as one of its measures of success."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 19)}
                        checked={this.state.question19 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Leaders are considering different ways of measuring the fulfillment of its Purpose as one of its measures of success."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 19)}
                        checked={this.state.question19 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The fulfillment of Purpose is the primary measure of success for the organization."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 20</h5>
                <h5 className={classes.bodyText}>How would you characterize the organization's current strategy?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 20)}
                        checked={this.state.question20 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Not aligned with its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 20)}
                        checked={this.state.question20 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Some of its strategy could be aligned with its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 20)}
                        checked={this.state.question20 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Some of its strategy is aligned with its Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 20)}
                        checked={this.state.question20 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="For the most part its strategy is aligned with Purpose subject to profit goals."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 20)}
                        checked={this.state.question20 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Its strategy is fully and explicitly aligned with its Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 21</h5>
                <h5 className={classes.bodyText}>Is the organization's business model driven by its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 21)}
                        checked={this.state.question21 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - The development of the business model has not formally considered Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 21)}
                        checked={this.state.question21 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The business model and Purpose are distinguished but are not yet aligned."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 21)}
                        checked={this.state.question21 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The business model and Purpose have been distinguished and there is agreement that Purpose drives business model."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 21)}
                        checked={this.state.question21 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The business model is mostly driven by Purpose and assessed by Purpose metrics. Business model adjustments are being made to optimize the delivery of the organization's Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 21)}
                        checked={this.state.question21 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - All business model activities are driven by and designed to forward the organization's Purpose. New business models are regularly considered that would better deliver the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 22</h5>
                <h5 className={classes.bodyText}>How has your strategy team approached increasing the level of engagement with the organization's stakeholder community?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 22)}
                        checked={this.state.question22 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Engagement strategies with its key stakeholders have not been specifically addressed."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 22)}
                        checked={this.state.question22 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Having engagement strategies with its key stakeholders is being considered."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 22)}
                        checked={this.state.question22 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="There is a comittment to develop engagement strategies with all of its key stakeholders."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 22)}
                        checked={this.state.question22 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="Engagement strategies with all of its key stakeholders are being developed."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 22)}
                        checked={this.state.question22 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="A comprehensive engagement strategy with each of its key stakeholders has been developed and is periodically measured for its effectivelness."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 23</h5>
                <h5 className={classes.bodyText}>Is Purpose utilized as the primary tool to recruit, select, onboard, train, and retain employees?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 23)}
                        checked={this.state.question23 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Purpose is not utilized as part of the recruitment and retention initiatives."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 23)}
                        checked={this.state.question23 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - The organization informally discusses Purpose as a important value within key employee processes."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 23)}
                        checked={this.state.question23 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Parts of the employee selection and development process are rooted in Purpose (i.e., onboarding and recruitment marketing)."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 23)}
                        checked={this.state.question23 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Purpose serves as the core messaging in recruitment, selection, onboarding, training, and promotion criteria."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 23)}
                        checked={this.state.question23 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - All employee development processes are built to foster the emotional commitment to the organization's Purpose as a primary objective, and promotion paths are largely dependent on a commitment to that Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 24</h5>
                <h5 className={classes.bodyText}>Has the organization evaluated its culture with a specific focus on Purpose alignment; that is, will its culture support Purpose fulfillment?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 24)}
                        checked={this.state.question24 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - There are no formal ways of regularly evaluating organizational culture."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 24)}
                        checked={this.state.question24 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - The organization has started to look at the role of Purpose in evaluating organizational culture."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 24)}
                        checked={this.state.question24 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - The organization evaluates organizational culture in times of change and need, and commitment to Purpose is one element of the evaluation."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 24)}
                        checked={this.state.question24 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has a formalized way of measuring organizational culture regularly and includes the commitment to Purpose as a key indicator of organizational health."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 24)}
                        checked={this.state.question24 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has a formalized way of measuring organizational culture with a focus on commitment to Purpose and regularly adjusts practices and performance evaluations to respond to results."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 25</h5>
                <h5 className={classes.bodyText}>Do your stakeholder evaluation tools (internal and external) measure people's emotional commitment to the organization and its Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 25)}
                        checked={this.state.question25 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Stakeholders' perceptions and commitment to the organization's Purpose are not evaluated."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 25)}
                        checked={this.state.question25 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Certain instruments which measure general stakeholder satisfaction with items loosely related to the Purpose are used."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 25)}
                        checked={this.state.question25 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Evaluations of stakeholder engagement measure perceptions of the organization's Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 25)}
                        checked={this.state.question25 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Stakeholders' commitment to the organization's Purpose and their perception of the organization's delivery of that Purpose are evaluated."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 25)}
                        checked={this.state.question25 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - A core way of collecting data and measuring stakeholder engagement (internal and external) is built around a better understanding of how stakeholders feel about the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 26</h5>
                <h5 className={classes.bodyText}>Has your organization intentionally designed and evaluated environments, practices, policies, and procedures around Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 26)}
                        checked={this.state.question26 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Processes and procedures largely exist due to historical reasons."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 26)}
                        checked={this.state.question26 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - There is some discussion about whether to bring existing policies and procedures more into alignment with Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 26)}
                        checked={this.state.question26 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - There is agreement among organizational leaders to begin bringing existing policies and procedures more into alignment with Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 26)}
                        checked={this.state.question26 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Organizatonal leaders are beginning to reevaluate policies, practices, and procedures in light of their alignment with the organization's Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 26)}
                        checked={this.state.question26 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - There is regular reevaluation of policies, practices, and procedures in light of their alignment with the organization's Purpose."
                  />
                </div> <br />
              </div>              
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 27</h5>
                <h5 className={classes.bodyText}>Does your organization's marketing and branding core messages tell the story of why we do what we do or simply tell the story of what we do?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 27)}
                        checked={this.state.question27 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Branding is more specification, differentiation, and positioning oriented."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 27)}
                        checked={this.state.question27 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='SOMEWHAT - Marketing is looking into how our Branding initiatives might incorporate messaging aroung "why" the organization exists in addition to "what" the organization does.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 27)}
                        checked={this.state.question27 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Marketing has agreed to begin incorporating in its branding messaging "why" the organization exists in additioan to "what" the organizaton does.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 27)}
                        checked={this.state.question27 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Marketing has started to communicate "why" the organization exists in addition to "what" the organizaton does in its branding messaging.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 27)}
                        checked={this.state.question27 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Branding initiatives strategically use storytelling to communicate "why" the organization exists before communicating "what" the organization does.'
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 28</h5>
                <h5 className={classes.bodyText}>Are all business development value propositions and messaging informed by the organization's Purpose?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 28)}
                        checked={this.state.question28 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - The value proposition and business development messaging is primarily centered around product specifications and differentiation."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 28)}
                        checked={this.state.question28 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='SOMEWHAT - There has been some discussions around refocusing value proposition messaging around Purpose.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 28)}
                        checked={this.state.question28 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='SOMEWHAT - Business development messaging and its value proposition are primarily focused on product specifications and differentiation with some consideration for Purpose.'
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 28)}
                        checked={this.state.question28 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Business development value propositions and messaging are now considering the organization's Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 28)}
                        checked={this.state.question28 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Business development messaging and its value proposition are centered around the organization's Purpose."
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 29</h5>
                <h5 className={classes.bodyText}>Has the organization developed core (already present) and goal (desired but not already present) values which represent what the Purpose looks like in "action" among employees?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 29)}
                        checked={this.state.question29 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - Company values have not been collaboratively developed."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 29)}
                        checked={this.state.question29 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - Core values have been developed, but do not specifically relate to Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 29)}
                        checked={this.state.question29 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Core values have been established and are aligned with Purpose."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 29)}
                        checked={this.state.question29 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - Core and goal values have been collaboratively developed and have been considered as to their impact on Purpose fulfillment."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 29)}
                        checked={this.state.question29 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='YES - Core and goal values have been collaboratively developed and demonstrate what Purpose "looks like in action." These values play a key role in our employee training and development programs and performance evaluation.'
                  />
                </div> <br />
              </div>
              <div className={classes.question}>
                <h5 className={classes.bodyText}>QUESTION 30</h5>
                <h5 className={classes.bodyText}>Does the organization have sales and profitability goals?</h5><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('0', 'question' + 30)}
                        checked={this.state.question30 === "0" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        color="primary"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has conventional sales and profitability goals."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('1', 'question' + 30)}
                        checked={this.state.question30 === "1" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="YES - The organization has conventional sales and profitability goals and is considering if they should have other goals to support Purpose fulfillment."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('2', 'question' + 30)}
                        checked={this.state.question30 === "2" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="SOMEWHAT - The organization has decided to replace its conventional sales and profitability goals at some point."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('3', 'question' + 30)}
                        checked={this.state.question30 === "3" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label="NO - In lieu of sales and profit goals, the organization is developing new goals to support a Purpose driven enterprise."
                  />
                </div><br />
                <div className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle('4', 'question' + 30)}
                        checked={this.state.question30 === "4" ? true : false}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.bodyText }}
                    label='NO - In lieu of sales and profit goals, the organization has expansion and economic sustainability goals that support fulfillment of its Purpose.'
                  />
                </div> <br />
              </div>

            </GridItem>
          </GridContainer>
          <Button
            disabled
            type="submit"
            color="primary"
            style={{
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            Save Changes
          </Button>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Form);

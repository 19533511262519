import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import loader from 'assets/img/loader.gif';
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SearchBox from 'components/SearchBox';
import MessagesSearchBox from 'components/SearchBox/MessagesSearchBox';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/matchesPageStyle';

import { sampleInvestors, sampleAdvisors } from "variables/sampleUsers.jsx";

import MatchesMap from '../Matches/MatchesMap.jsx';
import RecommendationsMap from '../Matches/RecommendationsMap.jsx';
import { db } from '../../firebase/fbConfig.js';
import avatar from 'assets/img/default-avatar.png';
import { ReactComponent as MatchesTitle } from 'assets/img/matchesTitle.svg';


export function MatchesMessagesMap(props) {
  const { classes, user, allMessagesData } = props;
  const history = useHistory();


  return (
    <>
      {allMessagesData.map((m, index) => {
        return (
          <GridContainer className={classes.messagesContainer} key={index}>
            <GridItem xs={3}>
              <img className={classes.messagesPic} src={m.from.profileURL ? m.from.profileURL : avatar} />
            </GridItem>
            <GridItem className={classes.fromGridItem} xs={6}>
              <span className={classes.fromName}>{m.from.firstName + ' ' + m.from.lastName}</span>
              <span>{m.firstMessage.length > 20 ? m.firstMessage.slice(0, 22) + '...' : m.firstMessage}</span>
            </GridItem>
            <GridItem xs={3}>
              <Button onClick={() => history.push(`/chat/${m.id}`)} className={classes.buttonToMessage}>
                <ArrowRightAltIcon />
              </Button>
            </GridItem>
          </GridContainer>
        )
      })}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    user: state.auth.user,
    userType: state.user.user.userType,
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(MatchesMessagesMap);
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import actionsReducer from './actionsReducer';
import assetsReducer from './assetsReducer';
import authReducer from './authReducer';
import brokeringReducer from './brokeringReducer';
import contentReducer from './contentReducer';
import profileReducer from './profileReducer';
import userReducer from './userReducer';
import assessmentReducer from './assessmentReducer';
import offersReducer from './offersReducer';
import investmentsReducer from './investmentsReducer';
import pendingInvestmentsReducer from './pendingInvestmentsReducer';
import assignedInvestmentsReducer from './assignedInvestmentsReducer';
import advisorsReducer from './advisorsReducer';
import investorsReducer from './investorsReducer';
import threadsReducer from './threadsReducer';
import investorThreadsReducer from './investorThreadsReducer';
import messagesReducer from './messagesReducer';
import calendarReducer from './calendarReducer';
import navigationReducer from './navigationReducer';
import notificationsReducer from './notificationsReducer';
import stocksReducer from './stocksReducer';
import consumers from './sfuReducers/consumers';
import dataConsumers from './sfuReducers/dataConsumers';
import dataProducers from './sfuReducers/dataProducers';
import me from './sfuReducers/me';
import notifications from './sfuReducers/notifications';
import peers from './sfuReducers/peers';
import producers from './sfuReducers/producers';
import room from './sfuReducers/room';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    actions: actionsReducer,
    auth: authReducer,
    brokering: brokeringReducer,
    content: contentReducer,
    profile: profileReducer,
    assessment: assessmentReducer,
    offers: offersReducer,
    investments: investmentsReducer,
    pendingInvestments: pendingInvestmentsReducer,
    assignedInvestments: assignedInvestmentsReducer,
    advisors: advisorsReducer,
    investors: investorsReducer,
    threads: threadsReducer,
    investorThreads: investorThreadsReducer,
    messages: messagesReducer,
    events: calendarReducer,
    user: userReducer,
    navigation: navigationReducer,
    notifications: notificationsReducer,
    stocks: stocksReducer,
    assets: assetsReducer,
    consumers,
    dataConsumers,
    dataProducers,
    me,
    sfuNotifications: notifications,
    peers,
    producers,
    room
  });

// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText,
  lightColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import { title } from "../../material-dashboard-pro-react";

const loginPageStyle = theme => ({
  container: {
    ...container,
    paddingLeft: "45px",
    paddingRight: "45px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      paddingTop: "100px"
    }
  },
  setupContainer: {
    ...container,
    paddingLeft: "45px",
    paddingRight: "45px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      height: "75vh"
    }
  },
  img: {
    width: "100%",
    padding: "0px 15px 0px 27px"
  },
  formContainer: {
    margin: "0 auto",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
    fontSize: '24px'
  },
  textCenter: {
    color: "#000",
    textAlign: "center"
  },
  linkText,
  textDanger: {
    fontSize: '12px',
    color: dangerColor,
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: whiteColor
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  modal: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  modalCloseButton: {
    color: lightColor
  },
  modalTitle: {
    ...title,
    fontWeight: "900",
    textAlign: "center"
  },
  modalCloseButton: {
  },
  subdescription: {
    color: "#ffffff",
    fontSize: "1rem",
    textAlign: "center",
    padding: "0px 15px 0px 27px"
  },
  modalBody: {
    padding: "0px"
  },
  loginInput: {
    background: "#fff",
    color: "#000",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    margin: "20px 0 20px 0",
    zIndex: "10"
  },
  cardContainer: {
    background: "#fff",
    width: "inherit",
    height: "50px"
  },
  appleLogo: {
    height: "50px",
    margin: "-10px 0 0 -4px"
  },
  loginPopup: {
    // Mobile
    [theme.breakpoints.down("sm")]: {
      margin: "100px 0 0 0"
    },
    // Desktop
    [theme.breakpoints.up("sm")]: {
      margin: "250px 0 0 0",
      width: "500px"
    }
  },
  signInCard: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '100px',
    margin: '15px 0px'
  },
  signInPaper: {
    borderRadius: '100px',
    padding: '10px 30px',
  },
  signInBtn: {
    width: "50%",
    borderRadius: "100px",
    background: '#436CFC',
    color: "white",
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  externalLoginBtn: {
    width: "50px",
    height: "50px",
    background: 'rgba(126, 219, 182, 0.37)',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '9px',
    display: "flex",
    justifyContent: "center"
  },
  registerBtn: {
    color: "#436CFC",
    textDecoration: "underline"
  },
  userTypeGridContainer: {
    minHeight: "65vh",
    display: "flex",
    flexDirection: 'column'
  },
  userTypeTitle: {
    display: "flex",
    margin: "0px auto",
    [theme.breakpoints.down("sm")]: {
      width: "350px"
    },
  },
  userTypeCardGridItem: {
    display: 'flex',
    justifyContent: "center",
  },
  userTypeCard: {
    zIndex: 500,
    [theme.breakpoints.down("sm")]: {
      width: "300px"
    },
  },
  carousel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  carouselItems: {
    display: 'flex',
    justifyContent: 'center'
  },
  profilePhoto: {
    display: "flex",
    justifyContent: "center"
  },
  setupGridItem: {
    padding: "0px 5px !important",
  },
  setupPaper: {
    padding: "0px 15px 5px 15px",
    borderRadius: '100px',
    border: '1px solid #72C5C8'
  },
  profilePic: {
    [theme.breakpoints.down("sm")]: {
      height: "250px",
      borderRadius: "100px"
    },
    "&:hover": {
      cursor: "pointer"
    }
  }
});

export default loginPageStyle;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import InvestmentSummaryInput from 'components/InputInvestmentSummary';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import DocListToUpload from 'components/DocListToUpload';
import UploadButton from 'components/ButtonUpload';
import FormSelect from 'components/FormSelect';
import InputCardField from 'components/InputCardField';
import { startCreateOrModifyInvestment, startUploadToStorage } from 'store/actions/investmentsActions';
import server from 'services/ServerService';
import config, { investmentFlow, investmentStatus } from 'config';
import styles from 'assets/jss/material-dashboard-pro-react/components/CreateInvestmentFormStyle.jsx';
import miniLoading from 'assets/img/simple_loading.gif';


const CreateInvestmentForm = ({
    classes,
    uploading,
    progress,
    uid,
    user,
    onSubmit,
    noDocSubmit
}) => {
    const [file, setFile] = useState(null);
    const [value, setValue] = useState({
        name: '',
        investmentType: '',
        serviceType: '',
        InvestmentState: '',
    });

    const onInput = (key, val) => {
        setValue({ ...value, [key]: val });
    };

    const onSelectFile = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFile(file);
        };
    };

    const removeDoc = () => {
        setFile(null);
    };

    const parties = [uid];
    const owner = uid;
    const investor = user && user.userType === 'investor' && uid;
    const flow = investor ? investmentFlow.INVESTOR_CREATED : investmentFlow.ADVISOR_CREATED;
    const status = investmentStatus.NEW;

    return (
        <div className={classes.formContainer}>
            <GridContainer justify="center">
                <GridItem xs={11}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <InputCardField name="name" label="Investment Name" onInput={onInput} />
                        </GridItem>
                    </GridContainer>
                    {[
                        { field: 'InvestmentState', options: config.states },
                        { field: 'investmentType', options: config.areasOfInvestment },
                        {
                            field: 'serviceType',
                            label: 'Service Needed',
                            options: config.serviceTypes,
                        },
                    ].map((data) => (
                        <GridContainer key={data.field} style={{ marginTop: '20px' }}>
                            <GridItem xs={12}>
                                <FormSelect
                                    data={data}
                                    value={value[data.field]}
                                    onSelect={onInput}
                                />
                            </GridItem>
                        </GridContainer>
                    ))}
                    <div style={{ marginTop: '20px' }}>
                        <InvestmentSummaryInput onInput={onInput} />
                    </div>
                    <GridItem xs={12}>
                        <div style={{ textAlign: 'center' }}>
                            {!file ? (
                                <div>
                                    <UploadButton onClick={(e) => onSelectFile(e)} /><p>(Optional)</p>
                                    <Button style={{ marginTop: "20px", width: "20%" }} onClick={() => noDocSubmit(uid, { ...value, investor, owner, parties, flow, status })} color="primary">
                                        <b>Submit</b>
                                    </Button>
                                </div>
                            ) : progress ? (
                                <p style={{ color: '#FFFFFF' }}>{progress}</p>
                            ) : (
                                        <div>
                                            <DocListToUpload name={file.name} onRemove={removeDoc} />
                                            {uploading ? (

                                                <img style={{ width: '10%' }} src={miniLoading} alt="..." />

                                            ) : (
                                                    <Button style={{ width: "20%" }} onClick={() => onSubmit(uid, { ...value, investor, owner, parties, flow, status }, file)} color="primary">
                                                        <b>Submit</b>
                                                    </Button>
                                                )}
                                        </div>
                                    )}
                        </div>
                    </GridItem>
                </GridItem>
            </GridContainer>
        </div>
    );
};

CreateInvestmentForm.propTypes = {
    uid: PropTypes.string,
    addInvestment: PropTypes.func,
    user: PropTypes.shape({
        lastName: PropTypes.string,
        firstName: PropTypes.string,
    }),
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        uid: state.auth.user.uid,
        user: state.user.user || {},
        progress: state.investments.uploadProgress,
        uploading: state.investments.uploading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (uid, data, file) => {
            return dispatch(startUploadToStorage(uid, file))
                .then(url => dispatch(startCreateOrModifyInvestment({ ...data, documents: [{ name: file.name, url: url }] })))
                .then(dispatch(push('/investments')))
        },
        noDocSubmit: (uid, data) => {
            return dispatch(startCreateOrModifyInvestment({ ...data }))
                .then(dispatch(push('/investments')))
        }
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(CreateInvestmentForm);

import { db, storage } from 'firebase/fbConfig.js';

import { addUserListener } from 'store/actions/userActions.js'

// Get Users for Users Panel
export function listenToInvestmentsForUser(store, uid) {
  db.collection("investments").where("parties", "array-contains", uid).onSnapshot(
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const cid = change.doc.id;
        switch (change.type) {
          case 'added': 
            store.dispatch(addInvestment(cid, data));
            store.dispatch(addUserListener(listenToInvitationsForInvestment(store, uid, cid)))            
            break;
          case 'modified': 
            store.dispatch(modifyInvestment(cid, data));
            break;
          case 'removed' : 
            store.dispatch(removeInvestment(cid)); 
            break;
          default:
            console.warn("Unknown change type", change.type);
            break;
        }
      })
    }
  )  
}

export function listenToInvitationsForInvestment(store, uid, cid) {
  return db.collection("investments").doc(cid).collection("invitations").onSnapshot(
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const id = change.doc.id;
        switch (change.type) {
          case 'added': 
          case 'modified': 
            store.dispatch(setInvitation(cid, id, data));
            break;
          case 'removed' : 
            store.dispatch(removeInvitation(cid, id)); 
            break;
          default:
            console.warn("Unsupported message operation:", change.type);
        }
      })
    }
  )  
}

export function startInvitation(cid, email, data) {
  return dispatch => {
    let invitationDoc = { cid, email, createdAt: new Date(), ...data };
    return db.collection("investments").doc(cid).collection("invitations").doc(email).set(invitationDoc);
  }
}

export function startModifyInvestment(cid, data) {
  return dispatch => {
      console.log('startModifyInvestment', cid, data);
      return db.collection("investments").doc(cid).update(data).catch(error=>console.error(error));
  }
}

export function startArchiveInvestment(cid) {
  return startModifyInvestment(cid, { archived: true });
}

export function startUnarchiveInvestment(cid) {
  return startModifyInvestment(cid, { archived: false })
}

export function startCreateOrModifyInvestment(data) {
  return dispatch => {
    if (data.cid) {
      return db.collection("investments").doc(data.cid).update(data);
    } else {
      return db.collection("investments").add(data).then(ref => dispatch(updateCurrentInvestment({ ...data, cid: ref.id })));
    }
  }
}

export function startUploadToStorage (uid, file) {
  const uploadTask = storage.ref(`documents/${uid}_${file.name}`).put(file);
  return dispatch => {
    dispatch(setStartUpload());
    uploadTask.on(
      'state_changed',
      ({ bytesTransferred, totalBytes }) => {
        dispatch(uploadProgress(bytesTransferred / totalBytes));
      },
      (error) => {
        dispatch(uploadError(error));
      },
      () => {
        dispatch(setEndUpload());
      }
    );
    return uploadTask.then(snapshot => snapshot.ref.getDownloadURL());
  };
};

export function addInvestment(cid, data) {
  return {
    type: 'INVESTMENTS_ADD',
    cid,
    data
  }
}

export function modifyInvestment(cid, data) {
  return {
    type: 'INVESTMENTS_MODIFY',
    cid,
    data
  }
}

export function removeInvestment(cid) {
  return {
    type: 'INVESTMENTS_DELETE',
    cid
  }
}

export function createInvestment(uid) {
  return {
    type: 'INVESTMENTS_CREATE',
    uid
  }
}

export function setCurrentInvestment(cid) {
 return {
   type: 'INVESTMENTS_SET_CURRENT',
   cid
 } 
}

export function updateCurrentInvestment(data) {
  return {
    type: 'INVESTMENTS_UPDATE_CURRENT',
    data
  } 
 }

export function uploadProgress(progress) {
  return {
    type: 'INVESTMENTS_UPLOAD_PROGRESS',
    progress
  }
}

export function setStartUpload() {
  return {
    type: 'INVESTMENTS_START_UPLOAD',
  }
}

export function setEndUpload() {
  return {
    type: 'INVESTMENTS_END_UPLOAD'
  }
}

export function uploadError(error) {
  return {
    type: 'INVESTMENTS_DOCUMENT_UPLOAD_ERROR',
    error
  }
}

export function setInvitation(cid, email, data) {
  return {
    type: 'INVESTMENTS_SET_INVITATION',
    cid,
    email,
    data
  }
}

export function removeInvitation(cid, email) {
  return {
    type: 'INVESTMENTS_REMOVE_INVITATION',
    cid,
    email
  }
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import fs from 'fs';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button';

import style from 'assets/jss/material-dashboard-pro-react/components/videoStyle';

const ParticipantList = ({ self, participants, classes, user }) => {

  const downloadParticipants = async () => {
    const list = [...participants];
    list.push(self);
    const listRes = await db.collection('conferences').add({ users: list });
    const profileUpdate = await db.collection('users').doc(user.id).collection('conferences').add({ id: listRes.id });
  }

  return (
    <div className={classes.participantList}>
      <h3 style={{ marginTop: 0 }}>Participants</h3>
      <div className={classes.remoteList}>
        <p>{self}</p>
        {participants ? participants.map((participant) => {
          return <p>{participant}</p>;
        }) : null}
      </div>
      {/* <Button onClick={downloadParticipants}>Download Participants List</Button> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = ({ auth, user, profile }) => {
  return {
    user: user.user
}};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style)
)(ParticipantList);
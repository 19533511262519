import React, { Component } from 'react';
import FirstQuestion from './Questions/FirstQuestion';
import SecondQuestion from './Questions/SecondQuestion';
import ThirdQuestion from './Questions/ThirdQuestion';
import FourthQuestion from './Questions/FourthQuestion';
import FifthQuestion from './Questions/FifthQuestion';
import SixthQuestion from './Questions/SixthQuestion';
import Confirm from './Confirm';
import Success from './Success';

import FirstQuestionGlobalResults from './GlobalResults/FirstQuestionGlobalResults'
import SecondQuestionGlobalResults from './GlobalResults/SecondQuestionGlobalResults'
import ThirdQuestionGlobalResults from './GlobalResults/ThirdQuestionGlobalResults'
import FourthQuestionGlobalResults from './GlobalResults/FourthQuestionGlobalResults'
import FifthQuestionGlobalResults from './GlobalResults/FifthQuestionGlobalResults'
import SixthQuestionGlobalResults from './GlobalResults/SixthQuestionGlobalResults'

import { db } from 'firebase/fbConfig.js';

export class RiskProfile extends Component {
    state = {
        step: 1,
        firstQuestion: 0,
        secondQuestion: 0,
        thirdQuestion: 0,
        fourthQuestion: 0,
        fifthQuestion: 0,
        sixthQuestion: 0
    };


    componentDidMount() {
        if (!this.state.dataLoaded) {
            return db.collection('global-test-results').doc('risk-profile').get().then((snapshot) => {
                const results = snapshot.data()
                console.log('results', results)
                this.setState({
                    dataLoaded: true,
                    globalTestResults: results
                })
            })
        }
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    // Go back to first question/retake quiz
    firstStep = () => {
        const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    // Handle toggle change
    handleToggle = (input, value) => {
        this.setState({
            [input]: value
        })
    }

    render() {
        const { classes } = this.props;
        const { step } = this.state;
        const { firstQuestion, secondQuestion, thirdQuestion, fourthQuestion, fifthQuestion, sixthQuestion } = this.state;
        const values = { firstQuestion, secondQuestion, thirdQuestion, fourthQuestion, fifthQuestion, sixthQuestion };

        switch (step) {
            case 1:
                return (
                    <FirstQuestion
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        prevStep={this.prevStep}
                        values={values}
                        classes={classes}
                    />
                );
            case 2:
                return (
                    <FirstQuestionGlobalResults
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        prevStep={this.prevStep}
                        globalTestResults={this.state.globalTestResults}
                        compare={'firstQuestion'}
                    />
                );
            case 3:
                return (
                    <SecondQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 4:
                return (
                    <SecondQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'secondQuestion'}
                    />
                );
            case 5:
                return (
                    <ThirdQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 6:
                return (
                    <ThirdQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'thirdQuestion'}
                    />
                );
            case 7:
                return (
                    <FourthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 8:
                return (
                    <FourthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'fourthQuestion'}
                    />
                );
            case 9:
                return (
                    <FifthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 10:
                return (
                    <FifthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'fifthQuestion'}
                    />
                );
            case 11:
                return (
                    <SixthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 12:
                return (
                    <SixthQuestionGlobalResults
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                        globalTestResults={this.state.globalTestResults}
                        compare={'sixthQuestion'}
                    />
                );
            case 13:
                return (
                    <Confirm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        firstStep={this.firstStep}
                        values={values}
                    />
                );
            case 14:
                return (
                    <Success
                        values={values}
                        firstStep={this.firstStep}
                    />
                )
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default RiskProfile;
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';
import { VictoryLine, VictoryTheme, VictoryAxis, VictoryChart } from 'victory';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/stocksStyle';

import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/stocksTitle.png';
import StockDetailsHeader from 'assets/img/wealth-env/stockDetails.png';

import { getStockNews, getStockInfo, getStockProfile } from 'store/actions/stocksActions.jsx';

export function StockDetail(props) {

    const { classes, history, user, getStockNews, stockNews, getStockInfo, stockInfo, dataLoaded } = props;

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      setSymbol(params.get('symbol'));
    },[])

    const testData = [
      {x: new Date(2021, 1, 1), y: 0},
      {x: new Date(2021, 4, 1), y: 4},
      {x: new Date(2021, 6, 1), y: 2},
      {x: new Date(2021, 10, 1), y: 6},
      {x: new Date(2021, 12, 1), y: 4},
      {x: new Date(2022, 1, 1), y: 8},
      {x: new Date(2022, 3, 1), y: 6}
    ]

    let [profile, setProfile] = useState({});
    let [shortSummary, setShortSummary] = useState('');
    let [symbol, setSymbol] = useState('');

    useEffect(() => {
      async function getData() {
        await getStockInfo(symbol);
        getStockNews(symbol);
        if (symbol) {
        getStockProfile(symbol)
          .then((data) => {
            if (data) {
              setProfile(data.assetProfile);
            }
          })}
      }
      getData();
    }, [symbol])

    useEffect(() => {
      if (profile && profile.longBusinessSummary && profile.longBusinessSummary.length > 150) {
        setShortSummary(profile.longBusinessSummary.slice(0, 150) + "...");
      }
    }, [stockInfo])

    return (
        <div className={classes.container}>

            {/* mobile */}
            <Hidden mdUp>
              {stockInfo && stockInfo[symbol] ?
                <>
                <div style={{ textAlign: "center" }}>
                  <img src={StockDetailsHeader} style={{ margin: "45px 15px 13px", maxHeight: "6rem" }} />
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.priceHeader}>{stockInfo[symbol][0].regularMarketPrice}</p>
                    <p className={classes.priceChange}>{stockInfo[symbol][0].regularMarketChangePercent > 0 ? "⬆︎" : "⬇︎"}{stockInfo[symbol][0].regularMarketChangePercent}</p>
                  </div>
                  </div>
                  <div style={{ marginTop: "-24px" }}>
                    <Button className={classes.headerButton}>Track Investment ⟶</Button>
                    <Button className={classes.headerButton}>Analyze Stock ⟶</Button>
                  </div>
                </div>
                <div className={classes.gridContainerDiv} style={{ marginTop: 0 }}>
                    {/* <div style={{ height: "8rem", margin: "5px", border: "solid 1px" }}>
                      GRAPH
                    </div> */}
                    <VictoryChart>
                      <VictoryAxis
                        scale="time"
                        tickFormat={(x) => {
                          console.log('look x', x)
                          const date = new Date(x);
                          return `${date.getMonth()}/${date.getFullYear()}`
                        }}
                      />
                      <VictoryAxis dependentAxis />
                      <VictoryLine theme={VictoryTheme.material} data={testData} scale={{x: 'time', y: 'linear'}}/>
                    </VictoryChart>
                    <div className={classes.detailsBubble}>
                      <p className={classes.aboutHeader}>ABOUT {stockInfo[symbol][0].displayName ? stockInfo[symbol][0].displayName : stockInfo[symbol][0].shortName ? stockInfo[symbol][0].shortName : stockInfo[symbol][0].longName}</p>
                      <p>{shortSummary ? shortSummary : profile && profile.longBusinessSummary ? profile.longBusinessSummary : "No summary provided"}</p>
                    </div>
                    <div>
                      <p className={classes.newsHeader}>{stockInfo[symbol][0].displayName ? stockInfo[symbol][0].displayName : stockInfo[symbol][0].shortName ? stockInfo[symbol][0].shortName : stockInfo[symbol][0].longName} IN THE NEWS</p>
                      {stockNews && stockNews[symbol] ? stockNews[symbol].item.map((news) => {
                        return (
                          <div className={classes.detailsBubble} style={{ margin: "1rem 0", paddingTop: "1rem" }}>
                            <p className={classes.newsTitle}>{news.title.slice(0, 50) + "..."}</p>
                            <p className={classes.newsTitle} style={{ fontWeight: 400 }}>{news.description.slice(0, 50) + "..."}</p>
                            <a href={news.link} target={"_blank"}>
                              <Button className={classes.newsButton}>Read More</Button>
                            </a>
                          </div>
                        )
                      }) : null}
                      <div></div>
                    </div>
                </div>
                </>
                : null}
            </Hidden>
            <Hidden smDown>
            {stockInfo && stockInfo[symbol] && stockInfo[symbol][0] ?
                <>
                <div style={{ textAlign: "center" }}>
                  {/* <img src={StockDetailsHeader} style={{ margin: "45px 15px 13px", maxHeight: "6rem" }} /> */}
                  <h1 style={{ marginTop: "5rem", fontFamily: "NOVA", fontSize: "xxx-large" }}>{stockInfo[symbol][0].displayName ? stockInfo[symbol][0].displayName : stockInfo[symbol][0].shortName ? stockInfo[symbol][0].shortName : stockInfo[symbol][0].longName}</h1>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className={classes.priceHeader} style={{ color: "black" }}>{stockInfo[symbol][0].regularMarketPrice}</p>
                    <p className={classes.priceChange}>{stockInfo[symbol][0].regularMarketChangePercent > 0 ? "⬆︎" : "⬇︎"}{stockInfo[symbol][0].regularMarketChangePercent}</p>
                  </div>
                  </div>
                  <div style={{ marginTop: "-24px" }}>
                    <Button className={classes.headerButton}>Track Investment ⟶</Button>
                    <Button className={classes.headerButton}>Analyze Stock ⟶</Button>
                  </div>
                </div>
                <div className={classes.gridContainerDiv} style={{ marginTop: 0, padding: "4rem" }}>
                    {/* <div style={{ height: "8rem", margin: "5px", border: "solid 1px" }}>
                      GRAPH
                    </div> */}
                    <VictoryChart>
                      <VictoryAxis
                        scale="time"
                        tickFormat={(x) => {
                          console.log('look x', x)
                          const date = new Date(x);
                          return `${date.getMonth()}/${date.getFullYear()}`
                        }}
                      />
                      <VictoryAxis dependentAxis />
                      <VictoryLine theme={VictoryTheme.material} data={testData} scale={{x: 'time', y: 'linear'}}/>
                    </VictoryChart>
                    <div className={classes.detailsBubble}>
                      <p className={classes.aboutHeader}>ABOUT {stockInfo[symbol][0].displayName ? stockInfo[symbol][0].displayName : stockInfo[symbol][0].shortName ? stockInfo[symbol][0].shortName : stockInfo[symbol][0].longName}</p>
                      <p>{shortSummary ? shortSummary : profile && profile.longBusinessSummary ? profile.longBusinessSummary : "No summary provided"}</p>
                    </div>
                    <div>
                      <p className={classes.newsHeader}>{stockInfo[symbol][0].displayName ? stockInfo[symbol][0].displayName : stockInfo[symbol][0].shortName ? stockInfo[symbol][0].shortName : stockInfo[symbol][0].longName} IN THE NEWS</p>
                      {stockNews && stockNews[symbol] ? stockNews[symbol].item.map((news) => {
                        return (
                          <div className={classes.detailsBubble} style={{ margin: "1rem 0", paddingTop: "1rem" }}>
                            <p className={classes.newsTitle}>{news.title.slice(0, 50) + "..."}</p>
                            <p className={classes.newsTitle} style={{ fontWeight: 400 }}>{news.description.slice(0, 50) + "..."}</p>
                            <a href={news.link} target={"_blank"}>
                              <Button className={classes.newsButton}>Read More</Button>
                            </a>
                          </div>
                        )
                      }) : null}
                      <div></div>
                    </div>
                </div>
                </>
                : null}
            </Hidden>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getStockNews: (symbol) => dispatch(getStockNews(symbol)),
    getStockInfo: (symbol) => dispatch(getStockInfo(symbol)),
});

const mapStateToProps = (state) => ({
    user: state.user.user,
    stockInfo: state.stocks.stockInfo,
    stockNews: state.stocks.stockNews,
    dataLoaded: state.stocks.stocks.dataLoaded
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(StockDetail);
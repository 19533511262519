import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Style from 'assets/jss/material-dashboard-pro-react/views/assetsStyle';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/yourAssetsTitle.png';
import AddButtonContainerDropdown from './AddButtonContainerDropdown'
import { ReactComponent as YourDashboard } from 'assets/img/yourDashboard.svg';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "#72C5C8 !important",
        },
      },
    },
  },
});


const categorySelects = ['Category 1', 'Category 2', 'Category 3']

export function ExpenseForm(props) {

  const { classes, history, user } = props;
  const [nameOfExpense, setNameOfExpense] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [frequency, setFrequency] = useState('')
  const [assignGroup, setAssignGroup] = useState('')
  const [beneficialOwner, setBeneficialOwner] = useState('')
  const [legalOwner, setLegalOwner] = useState('')

  return (
    <div className={classes.container}>
      <div style={{ textAlign: "center" }}>
        <YourDashboard style={{ height: "210px" }} />
      </div>


      <GridContainer className={classes.familyFormContainer}>
        <GridItem className={classes.column} xs={12}>
          <h3 style={{ margin: "0px" }} className={classes.header}>ADD EXPENSE</h3>
          <p className={classes.details}>Include any recurring expenses you have and relevant details here. More details means higher accuracy!</p>
        </GridItem>
        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Name Of Expense
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setNameOfExpense(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>




        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Amount
              </InputLabel>
              <TextField
                type="number"
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setAmount(e)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true
                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Currency
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                required
                onChange={(e) => setCurrency(e)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {categorySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Frequency
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                required
                onChange={(e) => setFrequency(e)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {categorySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Assign Group
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                required
                onChange={(e) => setAssignGroup(e)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {categorySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>



        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Beneficial Owner
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setBeneficialOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Legal Owner
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setLegalOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>




        <GridItem className={classes.justifyCenter} xs={12}>
          <Button
            className={classes.saveBtn}
            type="submit"

          >
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(ExpenseForm);
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class NinthQuestion extends Component {

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.ninthQuestion !== 0) {
            this.props.history.push('/demographics-results', {
                state: {
                    values,
                }
            });
        }
    }

    render() {
        const { values, handleToggle, classes } = this.props;
        return (
            <div className={classes.demographicQuizContainerAdjustedShorter}>
                <h4 className={classes.questionHeader}>Demographics Assessment</h4>
                <p className={classes.questionDescription}>Have you received any monetary contributions of gifts that included rent or utility payments from someone who does not live with you?</p>
                <GridContainer className={classes.checkboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={<ThemeProvider theme={theme}>
                                <Checkbox
                                    onClick={() => handleToggle('ninthQuestion', 1)}
                                    defaultValue={values.ninthQuestion}
                                    checked={values.ninthQuestion === 1}
                                /></ThemeProvider>}
                            label='Yes'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('ninthQuestion', 2)}
                                    defaultValue={values.ninthQuestion}
                                    checked={values.ninthQuestion === 2}
                                />}
                            label='No'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('ninthQuestion', 3)}
                                    defaultValue={values.ninthQuestion}
                                    checked={values.ninthQuestion === 3}
                                />}
                            label='N/A'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('ninthQuestion', 4)}
                                    defaultValue={values.ninthQuestion}
                                    checked={values.ninthQuestion === 4}
                                />}
                            label='Prefer not to say'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>
                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>8 / 9</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>


                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        );
    }
}

export default withRouter(withStyles(Style)(NinthQuestion));
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "#74EAE3",
        },
      },
    },
  },
});
export class SeventhQuestion extends Component {

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.relationshipCommitment !== 0) {
      this.props.nextStep();
    }
  }

  render() {
    const { values, handleToggle, classes, compare } = this.props;
    const usersSelection = values[compare]
    return (
      <div className={classes.quizContainer}>
        {/* Third Question */}
        <h4 className={classes.questionHeader}>I would like to work with one financial advisor over a long period of time.</h4>
        <GridContainer className={classes.wealthManagementCheckboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 1 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 1)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Disagree strongly'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 2 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 2)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Disagree moderately'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 3 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 3)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Disagree a little'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 4 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 4)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Neither agree nor disagree'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 5 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 5)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Agree a little'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 6 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 6)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Agree moderately'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={usersSelection === 7 ? true : false}
                  onClick={() => handleToggle('relationshipCommitment', 7)}
                  defaultValue={values.relationshipCommitment}
                />}
              label='Agree strongly'
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>



        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft
                  onClick={this.back}
                  className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>6 / 9</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>

        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={33}
        />
      </div>
    );
  }
}

export default withStyles(Style)(SeventhQuestion);
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Style from 'assets/jss/material-dashboard-pro-react/views/assetsStyle';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/yourAssetsTitle.png';
import AddButtonContainerDropdown from './AddButtonContainerDropdown'
import { ReactComponent as YourDashboard } from 'assets/img/yourDashboard.svg';
import { ReactComponent as WealthEnv } from 'assets/img/wealthEnvIcon.svg';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "#72C5C8 !important",
        },
      },
    },
  },
});


const categorySelects = ['Category 1', 'Category 2', 'Category 3']

export function ExpenseForm(props) {

  const { classes, history, user } = props;
  const [nameOfExpense, setNameOfExpense] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [frequency, setFrequency] = useState('')
  const [assignGroup, setAssignGroup] = useState('')
  const [beneficialOwner, setBeneficialOwner] = useState('')
  const [legalOwner, setLegalOwner] = useState('')

  return (
    <div className={classes.container}>
      <div style={{ textAlign: "center" }}>
        <YourDashboard style={{ height: "210px" }} />
      </div>


      <GridContainer className={classes.familyFormContainer}>
        <GridItem className={classes.column} xs={12}>
          <h3 style={{ margin: "0px" }} className={classes.header}>ADD GROUP</h3>
          {/* <p className={classes.details}>Include any recurring expenses you have and relevant details here. More details means higher accuracy!</p> */}
        </GridItem>

        <GridItem xs={12}>
          <Card onClick={() => history.push('/add-group-form/wealth-bucket')} className={classes.groupFormCard}>
            <CardHeader className={classes.groupFormCardHeader}>
              <WealthEnv />
              <h5 style={{ marginLeft: "15px" }} className={classes.header}>WEALTH BUCKET</h5>
            </CardHeader>
            <CardBody>
              <span className={classes.groupFormInfo}>Creating a Wealth Bucket allows you to group individual Holdings and/or Accounts orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card onClick={() => history.push('/add-group-form/custom-group')} className={classes.groupFormCard}>
            <CardHeader className={classes.groupFormCardHeader}>
              <WealthEnv />
              <h5 style={{ marginLeft: "15px" }} className={classes.header}>CUSTOM GROUP</h5>
            </CardHeader>
            <CardBody>
              <span className={classes.groupFormInfo}>Custom Groups provide flexibility while creating associations between Investments lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
            </CardBody>
          </Card>
        </GridItem>

      </GridContainer>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(ExpenseForm);
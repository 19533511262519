import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { NavLink, useParams } from "react-router-dom";
import firebase, { db, } from "firebase/fbConfig.js";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';


// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';


import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';


export function Results(props) {
    const { classes } = props;
    const { docID } = useParams();
    const [allData, setAllData] = useState({
        firstQuestion: "",
        secondQuestion: "",
        thirdQuestion: "",
        fourthQuestion: "",
        fifthQuestion: "",
        sixthQuestion: "",
    })
    function getScores() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                const sortedResults = [];
                const items = [];
                db.collection('users').doc(user.uid).collection('quizzes').doc('financialKnowledge').collection('quizLists').doc(docID)
                    .get()
                    .then((queryResult) => {
                        setAllData({
                            firstQuestion: queryResult.data().firstQuestion,
                            secondQuestion: queryResult.data().secondQuestion,
                            thirdQuestion: queryResult.data().thirdQuestion,
                            fourthQuestion: queryResult.data().fourthQuestion,
                            fifthQuestion: queryResult.data().fifthQuestion,
                            sixthQuestion: queryResult.data().sixthQuestion,
                        })
                    })
            }
        })
    }
    useEffect(() => {
        getScores();
    }, []);
    return (
        <div className={classes.quizContainer}>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>Suppose you have $100 in a savings account earning 2 percent interest a year. After five years, how much would you have?</h4>
                {allData.firstQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>You'll have more than $102 at the end of five years because your interest will compound over time. In other words, you earn interest on the money you save and on the interest your savings earned in prior years. Here’s how the math works. A savings account with $100 and a 2 percent annual interest rate would earn $2 in interest for an ending balance of $102 by the end of the first year. Applying the same 2 percent interest rate, the $102 would earn $2.04 in the second year for an ending balance of $104.04 at the end of that year. Continuing in this same pattern, the savings account would grow to $110.41 by the end of the fifth year.</p>
            </Card>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>Imagine that the interest rate on your savings account is 1 percent a year and inflation is 2 percent a year. After one year, would the money in the account buy more than it does today, exactly the same or less than today?</h4>
                {allData.secondQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>The reason you have less is inflation. Inflation is the rate at which the price of goods and services rises. If the annual inflation rate is 2 percent but the savings account only earns 1 percent, the cost of goods and services has outpaced the buying power of the money in the savings account that year. Put another way, your buying power has not kept up with inflation.</p>
            </Card>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>If interest rates rise, what will typically happen to bond prices? Rise, fall, stay the same, or is there no relationship?</h4>
                {allData.thirdQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>When interest rates rise, bond prices fall. And when interest rates fall, bond prices rise. This is because as interest rates go up, newer bonds come to market paying higher interest yields than older bonds already in the hands of investors, making the older bonds worth less.</p>
            </Card>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>True or false: A 15-year mortgage typically requires higher monthly payments than a 30-year mortgage but the total interest over the life of the loan will be less.</h4>
                {allData.fourthQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>Assuming the same interest rate for both loans, you will pay less in interest over the life of a 15-year loan than you would with a 30-year loan because you repay the principal at a faster rate. This also explains why the monthly payment for a 15-year loan is higher. Let’s say you get a 30-year mortgage at 6 percent on a $150,000 home. You will pay $899 a month in principal and interest charges. Over 30 years, you will pay $173,757 in interest alone. But a 15-year mortgage at the same rate will cost you less. You will pay $1,266 each month but only $77,841 in total interest—nearly $100,000 less.</p>
            </Card>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>True or false: Buying a single company's stock usually provides a safer return than a stock mutual fund.</h4>
                {allData.fifthQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>In general, investing in a stock mutual fund is less risky than investing in a single stock because mutual funds offer a way to diversify. Diversification means spreading your risk by spreading your investments. With a single stock, all your eggs are in one basket. If the price falls when you sell, you lose money. With a mutual fund that invests in the stocks of dozens (or even hundreds) of companies, you lower the chances that a price decline for any single stock will impact your return. Diversification generally may result in a more consistent performance in different market conditions.</p>
            </Card>
            <Card className={classes.questionCard}>
                <h4 className={classes.questionHeader}>Suppose you owe $1,000 on a loan and the interest rate you are charged is 20% per year compounded annually. If you didn't pay anything off, at this interest rate, how many years would it take for the amount you owe to double?</h4>
                {allData.sixthQuestion == 'correct' ?
                    (<h3 className={classes.correctFont}>You answered Correctly!</h3>) :
                    (<h3 className={classes.incorrectFont}>Oops! You answered incorrectly</h3>)
                }
                <p className={classes.questionDescription}>If someone did not understand how compound interest operates, they might conclude that borrowing at 20 percent per year would lead to doubling in five years. However, someone who knew about interest on interest would understand that the answer would be somewhat less than five years. Further, someone who knows the 'rule of 72' heuristic would know that it would be about 3.6 years, which makes the correct answer "2 to 4 years." In finance, the rule of 72 is a method for estimating an investment's doubling time. The rule number (i.e., 72) is divided by the interest percentage per period to obtain the approximate number of periods (usually years) required for doubling. The other responses reflect a misunderstanding of the concept of interest accrual.</p>
            </Card>
        </div >
    );
}

Results.propTypes = {
    uid: PropTypes.string,
    classes: PropTypes.object.isRequired,
    photoURL: PropTypes.string,
    user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth, user, profile }) => {
    const { uid } = auth.user || {};
    const { editDialogOpen, photoDialogOpen } = profile;
    console.log("useruser: ", user.user)
    return {
        uid,
        user: user.user,
        profileURL: user.user.profileURL,
        editDialogOpen,
        photoDialogOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Results);
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

// React Component For Creating Dynamic Tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SlideUI from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// Core Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import ArtTrack from "@material-ui/icons/ArtTrack";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/offersStyle";
import { Grid } from "@material-ui/core";
import marc from "assets/img/faces/marc.jpg";
import card from "assets/img/bg-pricing.jpeg";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration
import { startSetOffersList } from "store/actions/offersActions"

const Transition = (props) => {
  return <SlideUI direction="down" {...props} />
}

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: ''
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let offersData = this.props.offers.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
      }
    })

    this.setState({
      offersData,
      dataLoaded: true
    })
  }

  handleChange = (docID) => {
    console.log(docID)
  }

  render() {
    const { AuthBool, classes, offers } = this.props;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton;

    if (AuthBool) {
      if (this.props.offers.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }

    return (
      <div>
        {this.state.dataLoaded ? (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={11}>
              { /* https://www.npmjs.com/package/pure-react-carousel*/}
              <CarouselProvider style={{padding: "20px"}}
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={3}
                interval={3000}
                isPlaying={true}
                playDirection={'forward'}
              >
                <Slider>
                  <Slide index={0}>
                    <Card product style={{ display: "inline-flex", margin: "10px 0px 30px 0px"}}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[0].link)}>
                          <img style={{marginTop: "20px"}} src={this.state.offersData[0].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[0].title}</b></i>
                          </a>
                        </h4>
                        <b><p className={classes.cardProductDesciprion}>
                            <br />
                          {this.state.offersData[0].description}
                        </p></b>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[0].link)}>
                          <b>LEARN MORE</b>
                  </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                  <Slide index={1}>
                    <Card product style={{ display: "inline-flex", margin: "10px 0px 30px 0px" }}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[1].link)}>
                          <img style={{marginTop: "20px"}} src={this.state.offersData[1].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[1].title}</b></i>
                          </a>
                        </h4>
                        <b><p className={classes.cardProductDesciprion}>
                            <br />
                          {this.state.offersData[1].description}
                        </p></b>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[1].link)}>
                          <b>LEARN MORE</b>
                </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                  <Slide index={2}>
                    <Card product style={{ display: "inline-flex", margin: "10px 0px 30px 0px" }}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[2].link)}>
                          <img style={{marginTop: "20px"}} src={this.state.offersData[2].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[2].title}</b></i>
                          </a>
                        </h4>
                        <b><p className={classes.cardProductDesciprion}>
                            <br />
                          {this.state.offersData[2].description}
                        </p></b>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[2].link)}>
                          <b>LEARN MORE</b>
                </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                </Slider>
                {/* <ButtonBack>Back</ButtonBack>
                <ButtonNext>Next</ButtonNext> */}
              </CarouselProvider>
            </GridItem>
          </GridContainer>

        ) : (
          <img
                  style={{margin: "12px 0% 12px 45%", position: "relative", width: "10%"}}
                  src={miniLoading}
                  alt="..."
                />               )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.user.users,
    offers: state.offers.offers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetOffersList: dispatch(startSetOffersList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Offers);


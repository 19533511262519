import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { db } from 'firebase/fbConfig.js';
import { useParams } from 'react-router-dom'

import PhoneIcon from '@material-ui/icons/Phone';
import ChatIcon from '@material-ui/icons/Chat';
import VideoCallIcon from '@material-ui/icons/VideoCall';
// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import ViewList from '@material-ui/icons/ViewList';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

// core components
import HeaderLinks from './HeaderLinks';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';
import AssignAdvisorForm from '../Forms/AssignAdvisorForm';

import Notifications from '../../views/Notifications/NotificationsMini';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

function Header(props) {

  const [openEditMenu, setOpenEditMenu] = React.useState(null);
  const [openChatMenu, setOpenChatMenu] = React.useState(null);
  const params = useParams();



  const handleClickEditMenu = (event) => {
    if (openEditMenu && openEditMenu.contains(event.target)) {
      console.log('EditMenu was true!');
      setOpenEditMenu(null);
    } else {
      console.log(
        'openEditMenu && openEditMenu.contains(event.target) were not true'
      );
      setOpenEditMenu(event.currentTarget);
    }
  };

  const handleCloseEditMenu = () => {
    setOpenEditMenu(null);
  };

  const [openShareInvestment, setOpenShareInvestment] = React.useState(null);
  const shareInvestment = (event) => {
    if (openShareInvestment && openShareInvestment.contains(event.target)) {
      console.log('ShareInvestment was true!');
      setOpenShareInvestment(null);
    } else {
      console.log(
        'openEditMenu && openEditMenu.contains(event.target) were not true'
      );
      setOpenShareInvestment(event.currentTarget);
    }
  };

  const [openNotification, setOpenNotification] = React.useState(null);
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };


  function makeBrand() {
    // if (props.screenName && props.screenName.includes('Direct Chat')) {

    //   const getUserName = async () => {
    //     const uid = window.location.pathname.slice(6)
    //     const nameResultsreturn db.collection('users').doc(uid).get().then((snapshot) => {
    //       const results = snapshot.data()
    //       return results.firstName + ' ' + results.lastName
    //     })
    //   }
    //   const nameResults = getUserName()
    //   console.log('nameResults', nameResults)

    // }
    if (props.investmentName && props.threadName) {
      return <div style={{ lineHeight: '20px' }}>{props.investmentName}<small><p style={{ marginBottom: '0px' }}>{props.threadName}</p></small></div>
    }

    if (props.investmentName || props.threadName) {
      return props.investmentName || props.threadName;
    }

    if (props.screenName && props.screenName !== 'Profile' && props.screenName !== 'Quizzes' && props.screenName !== 'Matches' && props.screenName !== 'Conferencing') {
      return props.screenName;
    }
  }

  console.log('headerpropsssss', props)

  const { classes, color, rtlActive, editPermissionsLink, assignAdvisorLink, assignInvestorLink, editDetailsLink, chatLink, doAction, archiveAction, unarchiveAction, push, isInvestmentOwner, isThreadOwner, userType } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  var pathname = props.location.pathname;
  var n = pathname.lastIndexOf('/');
  var id = pathname.substring(n + 1);

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : window.location.pathname.includes('/video-conference') ? (null) : (
              <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        {/* {props.goBackLink && (
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={() => props.push(props.goBackLink)}
          >
            <ArrowBack style={{ color: '#fff' }} />
          </Button>
        )} */}


        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu
              style={{
                marginTop: '-3px',
                width: '35px',
                height: '35px',
                color: '#fff',
              }}
            />
          </Button>
        </Hidden>

        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <Paper
                className={classes.dropdown}
                style={{ position: 'absolute', right: '-38px', width: '150px' }}
              >
                <ClickAwayListener
                  onClickAway={() => setTimeout(handleCloseNotification)}
                >
                  <Notifications />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Popper
          open={Boolean(openEditMenu)}
          anchorEl={openEditMenu}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openEditMenu,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: '0 0 0' }}
            >
              <ClickAwayListener
                onClickAway={() => setTimeout(handleCloseEditMenu)}
              >
                <Paper className={classes.dropdown}>
                  {editDetailsLink && (isInvestmentOwner || isThreadOwner) && (
                    <MenuList role="menu">
                      <Link to={'/investment/' + id}>
                        <MenuItem
                          onClick={handleCloseEditMenu}
                          className={dropdownItem}
                        >
                          Edit Details
                        </MenuItem>
                      </Link>
                    </MenuList>
                  )}
                  {editPermissionsLink && (
                    <MenuList role="menu">
                      <Link to={editPermissionsLink}>
                        <MenuItem
                          onClick={handleCloseEditMenu}
                          className={dropdownItem}
                        >
                          Edit Permissions
                        </MenuItem>
                      </Link>
                    </MenuList>
                  )}
                  {assignAdvisorLink && userType === 'investor' && isInvestmentOwner && (
                    <MenuList role="menu">
                      <Link to={assignAdvisorLink}>
                        <MenuItem
                          onClick={handleCloseEditMenu}
                          className={dropdownItem}
                        >
                          Assign Advisor
                        </MenuItem>
                      </Link>
                    </MenuList>
                  )}
                  {assignInvestorLink && userType === 'advisor' && isInvestmentOwner && (
                    <MenuList role="menu">
                      <Link to={assignInvestorLink}>
                        <MenuItem
                          onClick={handleCloseEditMenu}
                          className={dropdownItem}
                        >
                          Assign Investor
                        </MenuItem>
                      </Link>
                    </MenuList>
                  )}
                  {archiveAction && (isInvestmentOwner || isThreadOwner) && (
                    <MenuList role="menu">
                      <MenuItem
                        onClick={() => { handleCloseEditMenu(); doAction(archiveAction).then(() => push(props.goBackLink)) }}
                        className={dropdownItem}
                      >
                        Archive
                      </MenuItem>
                    </MenuList>
                  )}
                  {unarchiveAction && (isInvestmentOwner || isThreadOwner) && (
                    <MenuList role="menu">
                      <MenuItem
                        onClick={() => { handleCloseEditMenu(); doAction(unarchiveAction).then(() => push(props.goBackLink)) }}
                        className={dropdownItem}
                      >
                        Restore
                      </MenuItem>
                    </MenuList>
                  )}
                  {chatLink && (
                    <MenuList role="menu">
                      <Link to={chatLink}>
                        <MenuItem
                          onClick={handleCloseEditMenu}
                          className={dropdownItem}
                        >
                          Threads
                        </MenuItem>
                      </Link>
                    </MenuList>
                  )}
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>

        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button
            href="#"
            className={classes.title}
            style={{ padding: '0px 0px 0px 5px', fontSize: '1.2rem', width: "100%" }}
            color="transparent"
          >
            <b style={{
              textAlign: 'center', fontFamily: 'NOVA', letterSpacing: '0.025em', fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '32px'
            }}>{makeBrand()}</b>
          </Button>
        </div>


        <Hidden smDown implementation="css">
          <HeaderLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          {window.location.pathname.includes('chat') && (
            <div>
              <PhoneIcon />
              <VideoCallIcon style={{ marginLeft: "10px" }} />
            </div>
          )}
          {props.showEditMenu && (
            <Button
              color="transparent"
              justIcon
              aria-label="EditMenus"
              aria-owns={openEditMenu ? 'EditMenu-menu-list' : null}
              aria-haspopup="true"
              onClick={handleClickEditMenu}
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : '',
              }}
            >
              <MoreVert
                className={
                  classes.headerLinksSvg +
                  ' ' +
                  (rtlActive
                    ? classes.links + ' ' + classes.linksRTL
                    : classes.links)
                }
                style={{
                  marginTop: '-3px',
                  width: '25px',
                  height: '25px',
                  color: '#fff',
                }}
              />
            </Button>
          )}
          {props.showNotifications && (
            <Link to="/notifications">
              <Button
                color="transparent"
                justIcon
                aria-label="Notifications"
                aria-owns={
                  openNotification ? 'notification-menu-list' : null
                }
                aria-haspopup="true"
                // onClick={handleClickNotification}
                className={
                  rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                }
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : '',
                }}
              >
                <NotificationsIcon
                  style={{
                    marginTop: '-3px',
                    width: '25px !important',
                    height: '25px !important',
                    color: '#fff',
                    fontSize: "40px !important"
                  }}
                  className={
                    classes.headerLinksSvg +
                    ' ' +
                    (rtlActive
                      ? classes.links + ' ' + classes.linksRTL
                      : classes.links)
                  }
                />
                {props.newNotification && (
                  <span className={classes.notifications} />
                )}
              </Button>
            </Link>
          )}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    editPermissionsLink: state.navigation.header.editPermissionsLink,
    editDetailsLink: state.navigation.header.editDetailsLink,
    chatLink: state.navigation.header.chatLink,
    assignAdvisorLink: state.navigation.header.assignAdvisorLink,
    assignInvestorLink: state.navigation.header.assignInvestorLink,
    goBackLink: state.navigation.header.goBackLink,
    archiveAction: state.navigation.header.archiveAction,
    unarchiveAction: state.navigation.header.unarchiveAction,
    showNotifications: state.navigation.header.showNotifications,
    showEditMenu: state.navigation.header.showEditMenu,
    threadName: state.threads.currentThread && state.threads.currentThread.name,
    investmentName: state.investments.currentInvestment && state.investments.currentInvestment.name,
    screenName: state.navigation.header.screenName,
    isInvestmentOwner: state.investments.currentInvestment && state.user.user.id === state.investments.currentInvestment.owner,
    isThreadOwner: state.threads.currentThread && state.user.user.id === state.threads.currentThread.owner,
    userType: state.user.user.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: url => dispatch(push(url)),
    doAction: action => dispatch(action)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(headerStyle)
)(Header);

import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';

import Button from 'components/CustomButtons/Button';
import useIsTrackEnabled from './Hooks/useIsTrackEnabled';

const AVControls = (props) => {
  const {
    audioTrack,
    videoTrack,
    screenTrack,
    handleLogout,
    classes,
    children,
  } = props;
  let [audioEnabled, setAudioEnabled] = useState(true);
  let [videoEnabled, setVideoEnabled] = useState(true);

  const toggleAudio = () => {
    if (audioTrack) {
      audioEnabled
        ? audioTrack.disable() && setAudioEnabled(false)
        : audioTrack.enable() && setAudioEnabled(true);
    }
  };

  const toggleVideo = () => {
    if (videoTrack) {
      videoEnabled
        ? videoTrack.disable() && setVideoEnabled(false)
        : videoTrack.enable() && setVideoEnabled(true);
    }
  };

  return (
    <>
      <Button
        style={{
          background: '#4775FB',
          marginLeft: '1%',
          borderRadius: '15px',
          fontSize: '20px',
          fontWeight: 500,
        }}
        onClick={toggleAudio}
      >
        {audioEnabled ? 'Mute' : 'Unmute'}
      </Button>
      <Button
        style={{
          background: '#4775FB',
          marginLeft: '2%',
          borderRadius: '15px',
          fontSize: '20px',
          fontWeight: 500,
        }}
        onClick={toggleVideo}
      >
        {videoEnabled ? 'Hide Camera' : 'Show Camera'}
      </Button>
      {children}
    </>
  );
};

export default AVControls;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { FooterText } from './FooterText';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import trophy from 'assets/img/wealth-env/trophy.png'
import MatchesPageIcon from 'assets/img/wealth-env/footer-match.png';
import ProfilePageIcon from 'assets/img/wealth-env/footer-profile.png';
import Person from 'assets/img/wealth-env/person.svg';
import Camera from 'assets/img/wealth-env/camera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/trophy.svg';
import Question from 'assets/img/wealth-env/question.svg';
import QuizzesPageIcon from 'assets/img/wealth-env/footer-quiz.png';
import Quizzes from 'assets/img/wealth-env/Quizzes.svg';
import ConferencingPageIcon from 'assets/img/wealth-env/footer-video.png';
import AchievementsPageIcon from 'assets/img/wealth-env/trophy-gradient.png';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ChatIcon from '@material-ui/icons/Chat';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { ReactComponent as PersonIcon } from 'assets/img/personIcon.svg';
import { ReactComponent as QuestionIcon } from 'assets/img/QuestionIcon.svg';
import { ReactComponent as PeopleIcon } from 'assets/img/PeopleIcon.svg';
import { ReactComponent as VideoChatIcon } from 'assets/img/VideoChatIcon.svg';
import { ReactComponent as TrophyIcon } from 'assets/img/TrophyIcon.svg';


import { pushChat } from 'store/actions/navigationActions';

function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive, pushChat, uid } = props;
  const [selectedIcon, setSelectedIcon] = useState('')
  console.log('selectedIcon', selectedIcon)
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });

  useEffect(() => {
    setSelectedIcon(window.location.pathname.split('/')[1])
    console.log('window.location.pathname', window.location.pathname)
  }, [window.location.pathname])

  if (
    window.location.pathname.includes('investorthreads') ||
    window.location.pathname.includes('create-investment')
  ) {
    return <div />;
  } else {
    return (
      <footer className={classes.footer} style={{ width: '100%', zIndex: 500 }}>
        <div className={container}>
          <div
            className={classes.right}
            style={{ textAlign: 'center', width: '100%', padding: '12px 5px 5px 5px' }}
          >
            <GridContainer style={{ marginTop: '3px', display: 'flex', justifyContent: "space-around" }}>
              <GridItem onClick={() => setSelectedIcon('profile')} xs={2}>
                <Link to={{ pathname: '/profile' }} >
                  <div style={selectedIcon === 'profile' ? { background: '#60D5C4' } : {}} className={classes.selectedIconDiv}>
                    <PersonIcon />
                  </div>
                </Link>
              </GridItem>
              <GridItem onClick={() => setSelectedIcon('quizzes')} xs={2}>
                <Link
                  to={{
                    pathname: `/quizzes`,
                  }}
                >
                  <div style={selectedIcon === 'quizzes' ? { background: '#60D5C4' } : {}} className={classes.selectedIconDiv}>
                    <QuestionIcon />
                  </div>
                </Link>
              </GridItem>
              <GridItem onClick={() => setSelectedIcon('matches')} xs={2}>
                <Link
                  to={{
                    pathname: `/matches`,
                  }}
                >
                  <div style={selectedIcon === 'matches' ? { background: '#60D5C4' } : {}} className={classes.selectedIconDiv}>
                    <PeopleIcon />
                  </div>
                </Link>
              </GridItem>
              <GridItem onClick={() => setSelectedIcon('conferencing')} xs={2}>
                <Link
                  to={{
                    pathname: `/conferencing`,
                  }}
                >
                  <div style={selectedIcon === 'conferencing' ? { background: '#60D5C4' } : {}} className={classes.selectedIconDiv}>
                    <VideoChatIcon />
                  </div>
                </Link>
              </GridItem>
              <GridItem onClick={() => setSelectedIcon('achievements')} xs={2}>
                <Link
                  to={{
                    pathname: `/achievements`,
                  }}
                >
                  <div style={selectedIcon === 'achievements' ? { background: '#60D5C4' } : {}} className={classes.selectedIconDiv}>
                    <TrophyIcon />
                  </div>
                </Link>
              </GridItem>
            </GridContainer>
            {/* <FooterText style={{ display: "none" }} anchor={anchor} rtlActive={rtlActive} /> */}
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    uid: state.user.user.id
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(footerStyle)
)(Footer);
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// core components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import { capitalize } from '../../util';

// icons
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const InvestmentListItem = ({ data, baseUrl, onClick }) => (
  <Card>
    <CardHeader>
      <Link to={{ pathname: `${baseUrl}/${data.cid}` }}>
        <h5 style={{ color: 'white' }}>
          <ArrowForwardIcon style={{ float: 'right', color: '#315B7B' }} />
          <b>{data.name}</b> <br />
        </h5>
        <h5 style={{ color: 'white' }}>
          {' '}
          <i>Investment #{data.cid}</i> <br />
        </h5>
      </Link>
    </CardHeader>
    <CardBody>
      <Link to={{ pathname: `${baseUrl}/${data.cid}` }}>
        {[
          { label: 'Investment Type', key: 'investmentType' },
          { label: 'Service Needed', key: 'serviceType' },
          { label: 'Status', key: 'status' },
        ].map((e) => (
          <p key={e.key}>
            <span style={{ color: 'white' }}>{e.label}:</span>{' '}
            <span
              style={{
                fontWeight: '900',
                color: '#FFFFFF',
              }}
            >
              {data[e.key] ? capitalize(data[e.key]) : 'N/A'}
            </span>{' '}
          </p>
        ))}
      </Link>
      <Accordion
        active={1}
        collapses={[
          {
            title: 'Summary',
            content: data.summary,
          },
        ]}
      />
    </CardBody>
  </Card>
);

InvestmentListItem.propTypes = {
  data: PropTypes.shape({
    cid: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    investmentType: PropTypes.string.isRequired,
    serviceType: PropTypes.string.isRequired,
  }).isRequired,
  baseUrl: PropTypes.string.isRequired
};

export default InvestmentListItem;

import React, { Component } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink } from "react-router-dom";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import logo from 'assets/img/wealth-env/logo-with-text.png'
import trophy from 'assets/img/wealth-env/trophy.png'
import coins from 'assets/img/wealth-env/success.png'

export class Success extends Component {

    retakeQuiz = e => {
        e.preventDefault();
        this.props.firstStep();
    }

    render() {

        const { classes } = this.props;

        return (
            <div style={{ marginTop: "115px" }} className={classes.FinancialKnowledgeConfirmQuizContainer}>
                <GridContainer>
                    {/* <GridItem xs={12} >
                        <img src={logo} className={classes.trophyIcon} />
                    </GridItem> */}
                    <div className={classes.successDescription}>
                        <h3 className={classes.questionHeader}>Congratulations!</h3>
                        <p className={classes.questionDescription}>You've completed this quiz and earned 300 XP!</p>
                        <p className={classes.questionDescription}>Credits can be spent on WealthEnV Activities.</p>
                        <p className={classes.questionDescription}>More Activities completed = more points!</p>
                        <br />
                        <h3 className={classes.questionHeader}>EARNED XP</h3>
                        <div className={classes.xpContainer}>
                            <img className={classes.coins} src={coins} />
                            <h3 className={classes.questionHeader}><b>300</b></h3>
                        </div>
                    </div>
                    <br />
                    <GridContainer style={{ marginTop: "50px" }}>
                        <GridItem xs={6}>
                            <Button
                                style={{ width: "90%" }}
                                onClick={this.retakeQuiz}
                                className={classes.backButton}
                                variant="contained"
                            >Retake Quiz</Button>
                        </GridItem>
                        <GridItem xs={6}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    style={{ width: "90%" }}
                                    className={classes.submitButton}
                                    variant="contained"
                                >Back to Quizzes</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(Style)(Success);
import React, { useState, createRef } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";
import { db, storage } from 'firebase/fbConfig.js';
import { useHistory, useLocation } from 'react-router-dom'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import logo from "assets/img/grayLogo.png";
import { capitalize } from '../../util';
import config from 'config';

import appleLogo from "assets/img/apple-logo.png"

// actions
import { emailSignup, emailSignIn, startGoogleLogin, startFacebookLogin, startTwitterLogin, passwordReset } from "../../store/actions/authActions"

import { handleProfilePicSubmission } from "../../store/actions/userActions"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { ReactComponent as ProfilePhoto } from 'assets/img/profilePhoto.svg';


const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

const SetupPage = (props) => {
    const { classes, authError, uid } = props
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const history = useHistory();
    const profilePhotoRef = createRef(null)
    const [file, setFile] = useState(null)
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        const userInfo = { firstName, lastName, phoneNumber, uid }
        props.handleProfilePicSubmission(file, userInfo, history)
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log('file', file)
        reader.onloadend = () => {
            setFile(file)
            setImagePreviewUrl(reader.result)
        };
        reader.readAsDataURL(file);
    }



    return (
        <div className={classes.setupContainer}>
            <GridContainer justify='center'>
                <GridItem xs={12} sm={6} md={4} className={classes.profilePhoto}>
                    {imagePreviewUrl ? (
                        <img
                            onClick={() => {
                                profilePhotoRef.current.click();
                            }}
                            src={imagePreviewUrl}
                            className={classes.profilePic}
                            alt="..."
                        />
                    ) : (
                        <ProfilePhoto onClick={() => {
                            profilePhotoRef.current.click();
                        }} />
                    )}
                    <input onChange={handleImageChange} type='file' id='midRecording' ref={profilePhotoRef} style={{ display: 'none' }} />
                </GridItem>
                <br /> <br />
                <GridContainer>
                    <GridItem xs={12} className={classes.setupGridItem}>
                        <Card className={classes.signInCard}>
                            <Paper className={classes.signInPaper}>
                                <TextField
                                    style={{ width: '100%', color: 'black !important' }}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="standard"
                                    inputProps={{
                                        autocomplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    autoComplete='off'
                                    InputProps={{
                                        classes: {
                                            input: classes.inputColor,
                                        },
                                        disableUnderline: true

                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        disableUnderline: true
                                    }}
                                />
                            </Paper>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} className={classes.setupGridItem}>
                        <Card className={classes.signInCard}>
                            <Paper className={classes.signInPaper}>
                                <TextField
                                    style={{ width: '100%', color: 'black !important' }}
                                    onChange={(e) => setLastName(e.target.value)}
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="standard"
                                    inputProps={{
                                        autocomplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    autoComplete='off'
                                    InputProps={{
                                        classes: {
                                            input: classes.inputColor,
                                        },
                                        disableUnderline: true

                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        disableUnderline: true
                                    }}
                                />
                            </Paper>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} className={classes.setupGridItem}>
                        <Card className={classes.signInCard}>
                            <Paper className={classes.signInPaper}>
                                <TextField
                                    style={{ width: '100%', color: 'black !important' }}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    id="outlined-basic"
                                    label="Phone Number"
                                    variant="standard"
                                    inputProps={{
                                        autocomplete: 'new-password',
                                        form: {
                                            autocomplete: 'off',
                                        },
                                    }}
                                    autoComplete='off'
                                    InputProps={{
                                        classes: {
                                            input: classes.inputColor,
                                        },
                                        disableUnderline: true

                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        disableUnderline: true
                                    }}
                                />
                            </Paper>
                        </Card>
                    </GridItem>

                </GridContainer>



                <div style={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "30px", marginBottom: "10px" }}>
                    <Button onClick={handleSubmit} className={classes.signInBtn}>Submit</Button>
                </div>
            </GridContainer>
        </div >
    )
}

SetupPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
    return {
        authError: state.auth.error,
        id: ownProps.match.params.id,
        investmentID: ownProps.match.params.investmentID,
        uid: state.user.user.id
    }
}

const mapDispatchToProps = (dispatch) => ({
    startGoogleLogin: () => dispatch(startGoogleLogin()),
    startFacebookLogin: () => dispatch(startFacebookLogin()),
    startTwitterLogin: () => dispatch(startTwitterLogin()),
    emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
    passwordReset: (email) => dispatch(passwordReset(email)),
    emailSignup: (authObject) => dispatch(emailSignup(authObject)),
    handleProfilePicSubmission: (file, userInfo, history) => dispatch(handleProfilePicSubmission(file, userInfo, history))
})

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle)
)(SetupPage);

import React from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';
import compose from 'recompose/compose';
import moment from 'moment';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';

import { startUpdateUser } from '../../store/actions/userActions';

import Style from 'assets/jss/material-dashboard-pro-react/views/setupStyle';
import CreateInvestmentForm from '../../components/Forms/CreateInvestmentForm';

const Transition = (props) => {
  return <Slide direction='down' {...props} />;
};

class FirstSignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        advertiser: false,
        email: this.props.authUser ? this.props.authUser.email : 'no email',
        continueForm: false,
        setup: true,
        userType: 'advisor',
        legalDisclaimer: true,
      },
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle,
    });
  };

  setData(data) {
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        ...data,
      },
    }));
  }

  handleChange = (e) => {
    this.setData({
      [e.target.id]: e.target.value,
      //email: this.props.authUser.email
    });
    console.log(this.state);
  };

  handleSelectChange = (e) => {
    this.setData({
      userType: e.target.value,
    });
    console.log(this.state);
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.startUpdateUser(this.props.authUser.uid, this.state.data);
  };

  // onChange = dob => this.setState({ dob })

  handleDateChange = (dob) => {
    console.log(dob);
    if (dob) {
      this.setState(() => ({
        dob: dob,
        dobAPI: moment(dob).format('YYYY-MM-DD'),
      }));
    }
  };

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleError = (error) => {
    var errorCode = error.code;

    this.setState({
      signupError: true,
      errorCode,
    });
  };

  isAdvertiser = () => {
    if (this.state.advertiser == 'undefined') {
      this.setState({
        advertiser: true,
      });
    } else {
      this.setState({
        advertiser: !this.state.advertiser,
      });
    }
  };

  continueForm = () => {
    this.setState({
      continueForm: true,
    });
  };

  render() {
    const { classes, startGoogleLogin, authError } = this.props;

    if (!!authError && !this.state.signupError) this.handleError(authError);

    console.log(this.state);

    // if (this.props.user.userType) {
    //     return <Redirect to='/profile' />
    // }
    return (
      <div className={classes.container}>
        {!this.state.continueForm && (
          <GridContainer justify='left' style={{ marginTop: '20vh', marginLeft: '5%' }}>
            <GridItem xs={12} sm={10} md={8} lg={6}>
              {/*   <Wizard
                       validate
                       color="primary"
                       steps={[
                         { stepName: "About", stepComponent: Step1, stepId: "about" },
                         { stepName: "Account", stepComponent: Step2, stepId: "account" },
                         { stepName: "Practice", stepComponent: Step3, stepId: "address" }
                       ]}
                       title="Create Your Account"
                       subtitle=""
                       finishButtonClick={e => alert(e)}
                     />*/}

              <h2
                className={classes.cardTitle}
                style={{ fontSize: '1.8rem', color: '#FFF' }}
              >
                Investment Firm Information
              </h2>
              {/*<div className={classes.center}>
                               <Button
                                 justIcon
                                 round
                                 color="google"
                                 onClick={startGoogleLogin}>
                                 <i className="fab fa-google" />
                               </Button>
                               {` `}
                 </div>*/}
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <Card
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                  }}
                >
                  <CustomInput
                    labelText='Years Experience'
                    id='experience'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: 'text',
                      disableUnderline: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </Card>
                <Card
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                  }}
                >
                  <CustomInput
                    labelText='Investment Types'
                    id='investmentType'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      disableUnderline: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </Card>
                <Card
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                  }}
                >
                  <CustomInput
                    labelText='Firm Name'
                    id='firmName'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      disableUnderline: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </Card>
                <Card
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                  }}
                >
                  <CustomInput
                    labelText='Firm Address'
                    id='currentFirm'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: '16',
                      type: 'text',
                      disableUnderline: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </Card>

                <Card
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '0',
                    marginTop: '20px',
                  }}
                >
                  <CustomInput
                    labelText='Firm Email'
                    id='firmEmail'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      maxLength: '16',
                      type: 'text',
                      disableUnderline: true,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </Card>

                {this.state.data.firmEmail ? (
                  <div className={classes.center}>
                    <Button
                      type='submit'
                      //onClick={() => this.continueForm()}
                      //color="primary"
                      style={{
                        width: '100%',
                        marginTop: '20px',
                        backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)",
                        fontSize: '1rem',
                      }}
                    >
                      <b>Continue</b> <ArrowRightAltIcon />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <br />
                    <center>
                      <b>Please complete the form to continue...</b>
                    </center>
                  </div>
                )}
                <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal + ' ' + classes.modalLarge,
                  }}
                  open={this.state.openAssignAdvisor}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose('openAssignAdvisor')}
                  aria-labelledby='shipment-modal-slide-title'
                  aria-describedby='shipment-modal-slide-description'
                >
                  <DialogTitle
                    id='shipment-modal-slide-title'
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <h2 className={classes.modalTitle}>
                      <b>Legal Disclaimer</b>
                    </h2>
                  </DialogTitle>
                  <DialogContent
                    style={{ backgroundColor: '#264653' }}
                    id='shipment-modal-slide-description'
                    className={classes.modalBody}
                  >
                    {' '}
                    <br />
                    <GridContainer>
                      <GridItem xs={6}>
                        <Button
                          className={classes.ltrButtonWhite}
                          style={{
                            backgroundColor: '#170f56',
                            marginTop: '-68px',
                            color: 'white',
                            width: '100%',
                          }}
                          key='close'
                          aria-label='Close'
                          onClick={() => this.handleClose('openAssignAdvisor')}
                        >
                          <b>PREVIOUS</b>
                        </Button>
                      </GridItem>
                      <GridItem xs={6}>
                        <Button
                          className={classes.ltrButtonWhite}
                          style={{
                            backgroundColor: '#170f56',
                            marginTop: '-68px',
                            color: 'white',
                            width: '100%',
                          }}
                          key='close'
                          aria-label='Close'
                          type='submit'
                        >
                          <b>CONTINUE</b>
                        </Button>
                      </GridItem>
                    </GridContainer>
                    {/* FORM */}
                  </DialogContent>
                </Dialog>
              </form>
            </GridItem>
          </GridContainer>
        )}
        {this.state.continueForm && !this.state.continueForm2 && (
          <GridContainer>
            <GridItem xs={12}>
              {this.state.userType == 'investor' ? (
                <CreateInvestmentForm />
              ) : (
                <p>Advisor</p>
              )}
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startUpdateUser: (uid, data) =>
    dispatch(startUpdateUser(uid, data)).then(dispatch(push('/notifications'))),
});

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    user: state.user.user,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(FirstSignupPage);

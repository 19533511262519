import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import { Link } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: ''
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadAdvisorNotificationsData = (uid) => {
        let data = db.collection("users").doc(this.props.authUser.uid).collection("notifications")
            .get().then((snapshot) => {
                const data = []
                // Parse data into array like in firebase.js
                snapshot.forEach((doc) => {
                    var docData = doc.data()
                    var docObj = {
                        ...docData,
                        id: doc.id
                    }
                    data.push(docObj);
                })
                return (data)
            }, (error) => {
                console.log('error fetching data: ', error)
            }).then(data => {
                this.setState({
                    notificationsData: data,
                    dataLoaded: true,
                    refresh: false
                })
            })

        //console.log(this.state)
        //let relatedConceptsData = this.props.relatedConcepts.dataRows

        // this.setState({
        //   relatedConceptsData,
        //   dataLoaded: true
        // })
    }

    handleChange = (docID) => {
        console.log(docID)
    }

    render() {
        const { AuthBool, classes } = this.props;
        const searchButton =
            classes.top +
            " " +
            classes.searchButton;

        if (AuthBool) {
            if (this.props.users.dataLoaded
                && !this.state.dataLoaded) {
                this.loadAdvisorNotificationsData()
            }
        }

        console.log(this.state)

        return (
            <div>
                {
                    this.state.notificationsData ? (
                        <MenuList role="menu" style={{ width: "150px" }}>
                            {this.state.notificationsData.map(notification => {
                                return (
                                    <MenuItem
                                        style={{ fontSize: "12px", width: "150px" }}
                                    //onClick={handleCloseNotification}
                                    //className={dropdownItem}
                                    >
                                        
                                            {
                                                (notification.type == "assignAdvisor") ?
                                                <Link to={{
                                                    pathname: `/investment/${notification.investmentID}`,
                                                    state: { dataLoaded: false }
                                                  }}>
                                                <p style={{ fontSize: "12px", color: "black !important", width: "120px", whiteSpace: "pre-line", marginTop: "10px" }}>{notification.text} </p>
                                                </Link>
                                                : 
                                                
                                                <p style={{ fontSize: "12px", width: "120px", whiteSpace: "pre-line", marginTop: "10px" }}>{notification.text} </p>
                                            }
                                           
                                       
                                    </MenuItem>
                                )
                            })}
                        </MenuList>

                    ) : (
                        <div>
                            <img
                            style={{margin: "5% 0% 3% 41%", position: "relative", width: "20%"}}
                            src={miniLoading}
                            alt="..."
                            />
                        </div>                          )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        users: state.user.users
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Users);

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx';
import Search from '@material-ui/icons/Search';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

export const SearchBox = ({ classes, onInput, id, matchesPage }) => (
  <Card
    className={matchesPage ? classes.matchesPageCard : classes.card}
  // style={ matchesPage ? {
  //   margin: '0',
  //   minWidth: "119%"
  // } : {
  //   margin: '0',
  // }}
  >
    <div
      className={classes.searchBoxDiv}
    >
      <Search
        style={{
          color: '#bbb',
          marginBottom: '-8px',
          marginRight: '10px',
          fontSize: '1rem',
        }}
        className={classes.headerLinksSvg + ' ' + classes.searchIcon}
      />

      <CustomInput
        searchBox={true}
        formControlProps={{
          className: classes.top + ' ' + classes.search,
          style: { width: '85%', paddingTop: '10px' },
        }}
        id={id || "query"}
        inputProps={{
          placeholder: 'Search...',
          onChange: ({ target: { value } }) => onInput(value),
          inputProps: {
            'aria-label': 'Search',
            className: classes.searchInput,
          },
        }}
      />
    </div>
  </Card>
);

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired,
};

export default withStyles(Style)(SearchBox);

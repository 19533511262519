import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';

// @material-ui/icons
import Close from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import loader from 'assets/img/loader.gif';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import OfferSlider from 'views/Offers/OfferSlider.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/calendarStyle.jsx';

// Forms
import CreateEventForm from '../../components/Forms/CreateEventForm';
import CalendarOld from './CalendarOld';

const Transition = (props) => {
    return <Slide direction="down" {...props} />;
};

class Investments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newInvestmentModal: false,
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: '',
            query: '',
            advisorEventsDataLoaded: false,
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID,
        });
    };

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    };

    loadData = () => {
        let usersData = this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop };
            const volUID = colObj.volUID;
            const username = colObj.username;
            // Load Data for Summary Cards and add buttons
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => this.handleClickOpen('assignActionModal', volUID)}
                    >
                        ASSIGN ACTION
                    </Button>
                ),
            };
        });

        this.setState({
            usersData,
            dataLoaded: true,
        });
    };

    loadAdvisorData = (uid) => {
        console.log(this.props.authUser.uid)
        let data = db
            .collection('advisor')
            .doc(this.props.uid)
            .collection('events')
            .orderBy('start')
            .get()
            .then(
                (snapshot) => {
                    const data = [];
                    // Parse data into array like in firebase.js
                    snapshot.forEach((doc) => {
                        var docData = doc.data();
                        var docObj = {
                            id: doc.id,
                            ...docData,
                            start: docData.start ? docData.start.toDate() : new Date(),
                            end: docData.end ? docData.end.toDate() : new Date(),
                            startFormat: docData.start ? docData.start : new Date(),
                            endFormat: docData.end ? docData.end : new Date(),
                        };
                        data.push(docObj);
                    });
                    return data;
                },
                (error) => {
                    console.log('error fetching data: ', error);
                }
            )
            .then((data) => {
                this.setState({
                    eventsData: data,
                    advisorEventsDataLoaded: true,
                    refresh: false,
                });
            });
    };

    loadInvestorData = (uid) => {
        let data = db
            .collection('investor')
            .doc(this.props.uid)
            .collection('events')
            .orderBy('start')
            .get()
            .then(
                (snapshot) => {
                    const data = [];
                    // Parse data into array like in firebase.js
                    snapshot.forEach((doc) => {
                        var docData = doc.data();
                        var docObj = {
                            id: doc.id,
                            ...docData,
                            start: docData.start ? docData.start.toDate() : new Date(),
                            end: docData.end ? docData.end.toDate() : new Date(),
                        };
                        data.push(docObj);
                    });
                    return data;
                },
                (error) => {
                    console.log('error fetching data: ', error);
                }
            )
            .then((data) => {
                this.setState({
                    eventsData: data,
                    advisorEventsDataLoaded: true,
                    refresh: false,
                });
            });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
        //console.log(this.state)
    };

    render() {
        const { AuthBool, authUser, classes, user, investments } = this.props;
        // console.log()
        const searchButton = classes.top + ' ' + classes.searchButton;

        if (this.props.uid) {
            if (this.props.users.dataLoaded && !this.state.dataLoaded) {
                this.loadData();
            }
            if (
                !this.state.advisorEventsDataLoaded &&
                this.props.user.userType == 'advisor'
            ) {
                console.log(this.props.uid)
                this.loadAdvisorData();
            }
            if (
                !this.state.advisorEventsDataLoaded &&
                this.props.user.userType == 'investor'
            ) {
                this.loadInvestorData();
            }
        }

        return (
            <div>
                {this.state.dataLoaded ? (
                    <div>
                        {user.userType == 'advisor' && (
                            <Fab
                                color="primary"
                                aria-label="add"
                                className={classes.fab}
                                onClick={() => this.handleClickOpen('newInvestmentModal')}
                            >
                                <AddIcon />
                            </Fab>
                        )}
                        <CalendarOld events={this.state.eventsData} />
                        <GridContainer justify="center">
                            {/*<GridItem xs={2}>
              <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
          </GridItem>*/}
                            <GridItem xs={11}>
                                {/* <CalendarChild uid={authUser.uid} /> */}
                                <Card>
                                    {/* <Link to={{
                                                        pathname: `/event/${event.id}`,
                                                        state: { dataLoaded: false }
                                                    }}> */}
                                    <CardHeader>
                                        <h5
                                            style={{
                                                marginBottom: '0px',
                                                color: '#170f56',
                                                fontSize: '26px',
                                            }}
                                        >
                                            {' '}
                                            <b>Upcoming Events</b>
                                            <br />
                                        </h5>
                                    </CardHeader>
                                    {/* <img src="https://firebasestorage.googleapis.com/v0/b/wealth-env.appspot.com/o/Screen%20Shot%202020-03-13%20at%204.18.43%20PM.png?alt=media&token=e4ad6c6d-3653-4e4b-ae13-57a0b8d63ee5" style={{ maxWidth: "500px" }} /> */}
                                    <GridContainer justify="center">
                                        <GridItem style={{ marginTop: '-20px' }} xs={11}>
                                            <SnackbarContent
                                                message={
                                                    <b>
                                                        You can add an event by tapping the orange "plus" button
                                                        below. Tap the event to add it to a calendar of your
                                                        choice.
                                                    </b>
                                                }
                                                color="info"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {/* </Link> */}
                                    {this.state.eventsData &&
                                        this.state.eventsData.map((event) => {
                                            // console.log(event.start)
                                            return (
                                                <Link
                                                    to={{
                                                        pathname: `/event/${event.id}`,
                                                        state: { dataLoaded: false },
                                                    }}
                                                >
                                                    <CardBody>
                                                        {/* <Link to={{
                                                            pathname: `/event/${event.id}`,
                                                            state: { dataLoaded: false }
                                                        }}> */}

                                                        <p>
                                                            <img
                                                                src="https://firebasestorage.googleapis.com/v0/b/wealth-env.appspot.com/o/Screen%20Shot%202020-03-13%20at%204.17.27%20PM.png?alt=media&token=f06f1c3d-176d-4e3b-a30c-98b36c2942dd"
                                                                style={{ maxWidth: '25px' }}
                                                            />
                                                            <span
                                                                style={{
                                                                    fontWeight: '900',
                                                                    color: '#170f56',
                                                                    fontSize: '26px',
                                                                    marginLeft: '10px',
                                                                    marginTop: '10px',
                                                                }}
                                                            >
                                                                {event.title}
                                                            </span>{' '}
                                                        </p>
                                                        <p>
                                                            <span
                                                                style={{
                                                                    color: '#111',
                                                                    fontSize: '20px',
                                                                    marginLeft: '40px',
                                                                    marginTop: '10px',
                                                                }}
                                                            >
                                                                {moment
                                                                    .utc(event.startFormat.seconds * 1000)
                                                                    .format('dddd MMM D, YYYY')}
                                                            </span>{' '}
                                                        </p>

                                                        {/* </Link> */}
                                                    </CardBody>
                                                </Link>
                                            );
                                        })}
                                </Card>
                            </GridItem>
                        </GridContainer>

                        <OfferSlider />

                        {/* NEW INVESTMENT MODAL */}
                        <Dialog
                            className={classes.eventFormRoot}
                            classes={{
                                root: classes.modalRoot,
                                paper: classes.modal + " " + classes.modalLarge
                            }}
                            open={this.state.newInvestmentModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.handleClose('newInvestmentModal')}
                            aria-labelledby="shipment-modal-slide-title"
                            aria-describedby="shipment-modal-slide-description"
                        >
                            <DialogContent
                                id="shipment-modal-slide-description"
                                className={classes.eventForm}
                                style={{ paddingLeft: '0', paddingRight: '0' }}
                            >
                                <Button
                                    style={{
                                        margin: '3.5% 1% 0% 0%',
                                        color: 'black',
                                    }}
                                    simple
                                    className={classes.modalCloseButton}
                                    key="close"
                                    aria-label="Close"
                                    onClick={() => this.handleClose('newInvestmentModal')}
                                >
                                    {' '}
                                    <Close
                                        style={{ marginTop: '-28px' }}
                                        className={classes.modalClose}
                                    />
                                </Button>

                                {/* FORM */}
                                <CreateEventForm />
                            </DialogContent>
                        </Dialog>
                        <div style={{ margin: '60px' }} />
                    </div>
                ) : (
                    <div
                        style={{
                            backgroundColor: '#f0f1f5',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <img
                            style={{ margin: '20% 0% 0% 41%', position: 'relative', width: '20%' }}
                            src={loader}
                            alt="..."
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        AuthBool: !!state.auth.user,
        users: state.user.users,
        authUser: state.auth.user,
        user: state.user.user,
        investments: state.investments.investments,
        uid: state.auth.user.uid,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(Style)
)(Investments);

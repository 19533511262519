import React from "react";
import { connect } from 'react-redux';

// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";

// dependency plugin for react-big-calendar
import moment from "moment";

// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";
import 'pure-react-carousel/dist/react-carousel.es.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "assets/img/loader.gif";

// Core Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import SlideUI from "@material-ui/core/Slide";
import { events } from "variables/general.jsx";
import { compose } from "recompose";

// Firebase Redux Integration
import { startSetOffersList } from "store/actions/offersActions"

const localizer = BigCalendar.momentLocalizer(moment);

const Transition = (props) => {
  return <SlideUI direction="down" {...props} />
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      alert: null,
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: ''
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  selectedEvent(event) {
    console.log(event)
    window.location.replace(`/event/${event.id}`)
  }

  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Input something"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      )
    });
  }
  addNewEvent(e, slotInfo) {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });
    this.setState({
      alert: null,
      events: newEvents
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let offersData = this.props.offers.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
      }
    })

    this.setState({
      offersData,
      dataLoaded: true
    })
  }

  render() {
    const { AuthBool, classes, events, offers } = this.props;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton;

    if (AuthBool) {
      if (this.props.offers.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }
    console.log(this.state)
    console.log(events)
    return (
      <div>
        {this.state.alert}
        {this.state.dataLoaded && events ? (

          <GridContainer justify="center">
            <GridItem xs={11} sm={11} md={11}>
              <Card>
                <CardBody calendar>
                  <BigCalendar
                    selectable
                    localizer={localizer}
                    events={events}
                    defaultView="month"
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => this.selectedEvent(event)}
                    onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
                    eventPropGetter={this.eventColors}
                  />
                </CardBody>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={11}>
            <Card>
              <CardHeader>
                <h5><b>Appointment #1</b> <br /><small> </small></h5>
              </CardHeader>
              <CardBody>
                Investment Type: Intellectual Property <br />Service Needed: Document Preparation
                  <Accordion
                  active={1}
                  collapses={[
                    {
                      title: "Summary",
                      content:
                        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                    }
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5><b>Appointment #2</b> <br /><small> </small></h5>
              </CardHeader>
              <CardBody>
                Investment Type: Intellectual Property <br />Service Needed: Document Preparation
                  <Accordion
                  active={1}
                  collapses={[
                    {
                      title: "Summary",
                      content:
                        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                    }
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5><b>Appointment #3</b> <br /><small> </small></h5>
              </CardHeader>
              <CardBody>
                Investment Type: Intellectual Property <br />Service Needed: Document Preparation
                  <Accordion
                  active={1}
                  collapses={[
                    {
                      title: "Summary",
                      content:
                        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS."
                    }
                  ]}
                />
              </CardBody>
            </Card>
                </GridItem>*/}
            {/* <GridItem xs={12} sm={12} md={11}> */}
            { /* https://www.npmjs.com/package/pure-react-carousel*/}
            {/* <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={75}
                totalSlides={3}
                interval={3000}
                isPlaying={true}
                playDirection={'forward'}
              >
                <Slider>
                  <Slide index={0}>
                    <Card product style={{ display: "inline-flex" }}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[0].link)}>
                          <img src={this.state.offersData[0].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[0].title}</b></i>
                          </a>
                        </h4>
                        <p className={classes.cardProductDesciprion}>
                          Offer Details: <br />
                          {this.state.offersData[0].description}
                        </p>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[0].link)}>
                          LEARN MORE
                  </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                  <Slide index={1}>
                    <Card product style={{ display: "inline-flex" }}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[1].link)}>
                          <img src={this.state.offersData[1].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[1].title}</b></i>
                          </a>
                        </h4>
                        <p className={classes.cardProductDesciprion}>
                          Offer Details: <br />
                          {this.state.offersData[1].description}
                        </p>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[1].link)}>
                          LEARN MORE
                </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                  <Slide index={2}>
                    <Card product style={{ display: "inline-flex" }}>
                      <CardHeader image>
                        <a href="#pablo" onClick={() => window.open(this.state.offersData[2].link)}>
                          <img src={this.state.offersData[2].image} alt="..." />
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardProductTitle}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <i> <b>{this.state.offersData[2].title}</b></i>
                          </a>
                        </h4>
                        <p className={classes.cardProductDesciprion}>
                          Offer Details: <br />
                          {this.state.offersData[2].description}
                        </p>
                      </CardBody>
                      <CardFooter product>
                        <Button style={{ width: "100%" }} color="primary" onClick={() => window.open(this.state.offersData[2].link)}>
                          LEARN MORE
                </Button>
                      </CardFooter>
                    </Card>
                  </Slide>
                </Slider> */}
            {/* <ButtonBack>Back</ButtonBack>
                <ButtonNext>Next</ButtonNext> */}
            {/* </CarouselProvider>               */}
            {/* </GridItem> */}
          </GridContainer>

        ) : (
          <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
            <img
              style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
              src={loader}
              alt="..."
            />
          </div>)
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.user.users,
    offers: state.offers.offers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSetOffersList: dispatch(startSetOffersList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(buttonStyle)
)(Calendar);

const assetsStyle = (theme) => ({
    container: {
        color: "#000",
        margin: "-50px 0 0 0",
        height: "120vh",
        [theme.breakpoints.down('sm')]: {
            height: "100%",
        }
    },
    gridContainerDiv: {
        [theme.breakpoints.down('sm')]: {
            padding: "1rem 1.5rem",
            paddingBottom: "100px",
            marginTop: "90px",
        }
    },
    optionCard: {
        borderRadius: "10px",
        padding: "10px",
        height: "34vh",
        margin: "30px 0 0 0",
        boxShadow: "0px 4px 9px gray",
        display: "flex",
        alignItems: "center"
    },
    lockedCard: {
        borderRadius: "10px",
        padding: "10px",
        height: "33vh",
        backgroundColor: "#a7a7a7",
        margin: "30px 0 0 0"

    },
    addOnIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(297.41deg, #75E8E2 9.14%, #4672FB 106.58%)",
        borderRadius: "50%",
        width: "150px",
        height: "150px",
        [theme.breakpoints.down('md')]: {
            width: "90px",
            height: "90px",
        }
    },
    additonalQuizIcon: {
        height: "187px",
        marginTop: "22px",
        [theme.breakpoints.down('md')]: {
            height: "140px",
        }
    },
    videoClassIcon: {
        height: "60px",
        [theme.breakpoints.down('md')]: {
            height: "38px",
        }
    },
    accountIcon: {
        height: "80px",
        [theme.breakpoints.down('md')]: {
            height: "50px",
        }
    },
    investmentIcon: {
        height: "130px",
        [theme.breakpoints.down('md')]: {
            height: "83px",
        }
    },
    socialIcon: {
        height: "165px",
        marginTop: "20px",
        [theme.breakpoints.down('md')]: {
            height: "100px",
        }
    },
    headerDescription: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#4F4F4F",
        [theme.breakpoints.up('md')]: {
            fontSize: "30px",
        }
    },
    description: {
        fontSize: "12px",
        [theme.breakpoints.up('md')]: {
            fontSize: "20px",
        }
    },
    investmentDescription: {
        fontSize: "12px",
        margin: 0,
    },
    header: {
        textAlign: "center",
        fontWeight: "600",
        marginTop: "60px"
    },
    cardContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    gridContainer: {
        padding: "20px",
        justifyContent: "space-around"
    },
    addOnTitle: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#4F4F4F'
    },
    imgGridItem: {
        width: "100%",
        borderRadius: "20px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#4775FB"
    },
    infoGridItem: {
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #72C5C8',
        display: 'flex',
        flexDirection: "column",
        borderRadius: "20px",
        padding: "10px !important"
    },
    header: {
        fontFamily: "NOVA",
        fontWeight: 400,
        fontSize: "32px",
        color: "#4672FB"
    },
    sectionHeader: {
        fontFamily: "NOVA",
        fontWeight: 400,
        fontSize: "25px",
    },
    section: {
        border: "solid 1px rgb(105,189,192)",
        margin: "10px 0",
        borderRadius: "20px",
        background: "white",
        padding: "1rem"
    },
    searchBar: {
        width: "100%",
        height: "39px",
        borderRadius: "7px",
        border: "1px solid #72C5C8",
        boxShadow: "0px 0px 4px rgb(0 0 0 / 15%)",
        background: "white",
        display: "flex",
        alignItems: "center"
    },
    balance: {
        color: "#4775FB",
        fontWeight: 800,
        fontSize: "18px"
    },
    checkingBody: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginTop: "-0.5rem"
    },
    typeSpan: {
        color: "#4672FB",
        fontSize: "12px",
        fontWeight: 700,
        marginLeft: "1rem"
    },
    details: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#4F4F4F',
    },
    familyFormContainer: {
        padding: '0px 20px !important',
        marginBottom: '40px',
        marginTop: '40px',
    },
    wealthBucketContainer: {
        padding: '0px 20px !important',
        marginBottom: '40px',
        marginTop: '40px',
    },
    inputCard: {
        borderRadius: '40px',
        height: '50px',
        marginBottom: '0px',
        padding: '10px',
    },
    groupFormCard: {
        borderRadius: '40px',
        marginBottom: '0px',
        padding: '15px 10px',
        border: "1px solid #72C5C8"
    },
    amount: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        color: '#4F4F4F'
    },
    change: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 900,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
    },
    wealthGroupFormCard: {
        borderRadius: '40px',
        margin: '0px 0px 10px 0px',
        padding: '13px',
        border: "1px solid #72C5C8"
    },
    inputColor: {
        fontSize: '10px',
        marginTop: '10px',
    },
    checkboxText: {
        color: "#000",
        margin: "-3px"
    },
    align: {
        display: 'flex',
        alignItems: "center",
        marginTop: "20px"
    },
    justifyCenter: {
        display: 'flex',
        justifyContent: "center"
    },
    saveBtn: {
        background: '#436CFC',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '34px',
        width: "75%",
        marginTop: "30px"
    },
    column: {
        display: 'flex',
        flexDirection: "column"
    },
    groupFormCardHeader: {
        display: 'flex',
        alignItems: "center",
        paddingBottom: "0px"
    },
    groupFormInfo: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '138.8%',
        color: '#4F4F4F'
    },
    assetName: {
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        color: '#4F4F4F',
        marginLeft: "5px"
    },
    addRemoveBtn: {
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '15px',
        width: "50%",
        color: "white"
    },
    groupSelect: {
        borderRadius: "34px",
        border: "1px solid #436CFC",
        margin: "10px 0",
        fontFamily: "NOVA",
        fontWeight: 400,
        fontSize: "32px",
        display: "flex",
        justifyContent: "space-between",
        height: "44px",
        alignItems: "center"
    },
    groupSelectDesk: {
        paddingTop: "12px",
        borderRadius: "34px",
        border: "1px solid #436CFC",
        margin: "10px 0",
        fontFamily: "NOVA",
        fontWeight: 400,
        fontSize: "32px",
        display: "flex",
        justifyContent: "space-between",
        height: "44px",
        alignItems: "center"
    },
    selected: {
        borderRadius: "34px",
        background: "#436CFC",
        color: "white",
        zIndex: 1,
        height: "100%",
        width: "fit-content",
        textAlign: "center",
        padding: "2% 6% 6%",
        position: "sticky"
    },
    selectedDesk: {
        borderRadius: "34px",
        background: "#436CFC",
        color: "white",
        zIndex: 1,
        width: "fit-content",
        textAlign: "center",
        position: "sticky",
        padding: "0.5% 6% 0%",
        marginBottom: "12px",
        height: "42px"
    },
    notSelected: {
        margin: "0 2%",
        padding: "1% 4% 2.5%",
        textAlign: "center",
        position: "sticky",
    }
})

export default assetsStyle;
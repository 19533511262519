const addOnsStyle = (theme) => ({
    container: {
        color: "#000",
        margin: "-50px 0 0 0",
        height: "120vh"
    },
    gridContainerDiv: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: "100px"
        }
    },
    optionCard: {
        borderRadius: "10px",
        padding: "10px",
        height: "34vh",
        margin: "30px 0 0 0",
        boxShadow: "0px 4px 9px gray",
        display: "flex",
        alignItems: "center"
    },
    lockedCard: {
        borderRadius: "10px",
        padding: "10px",
        height: "33vh",
        backgroundColor: "#a7a7a7",
        margin: "30px 0 0 0"

    },
    addOnIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(297.41deg, #75E8E2 9.14%, #4672FB 106.58%)",
        borderRadius: "50%",
        width: "150px",
        height: "150px",
        [theme.breakpoints.down('md')]: {
            width: "90px",
            height: "90px",
        }
    },
    additonalQuizIcon: {
        height: "187px",
        marginTop: "22px",
        [theme.breakpoints.down('md')]: {
            height: "140px",
        }
    },
    videoClassIcon: {
        height: "60px",
        [theme.breakpoints.down('md')]: {
            height: "38px",
        }
    },
    accountIcon: {
        height: "80px",
        [theme.breakpoints.down('md')]: {
            height: "50px",
        }
    },
    investmentIcon: {
        height: "130px",
        [theme.breakpoints.down('md')]: {
            height: "83px",
        }
    },
    socialIcon: {
        height: "165px",
        marginTop: "20px",
        [theme.breakpoints.down('md')]: {
            height: "100px",
        }
    },
    headerDescription: {
        fontSize: "20px",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            fontSize: "30px",
        }
    },
    description: {
        textAlign: "center",
        fontSize: "12px",
        [theme.breakpoints.up('md')]: {
            fontSize: "20px",
        }
    },
    header: {
        textAlign: "center",
        fontWeight: "600",
        marginTop: "60px"
    },
    cardContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    gridContainer: {
        padding: "20px",
        justifyContent: "space-around"
    },
    addOnTitle: {
        fontWeight: 700,
        fontSize: '14px',
        color: '#4F4F4F'
    },
    imgGridItem: {
        width: "100%",
        borderRadius: "20px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#4775FB"
    },
    infoGridItem: {
        width: "100%",
        background: '#FFFFFF',
        border: '1px solid #72C5C8',
        display: 'flex',
        flexDirection: "column",
        borderRadius: "20px",
        padding: "10px !important"
    }
})

export default addOnsStyle;
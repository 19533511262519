import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useHistory } from 'react-router';

import RoomContext from '../../sfu/RoomContext';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { IconButton } from "@material-ui/core";
import { Videocam, VideocamOff, ScreenShare, StopScreenShare, Mic, MicOff, CallEnd } from "@material-ui/icons";

import Style from "assets/jss/material-dashboard-pro-react/components/controlBarStyles"

function ControlBar(props) {
    const { classes } = props;

    const [webcamOff, setWebcamOff] = useState(false);
    const [micOff, setMicOff] = useState(false);
    const [shareOff, setShareOff] = useState(true);

    let history = useHistory();

    const sfu = useContext(RoomContext)

    const handleWebcam = () => {
        if (webcamOff) {
            setWebcamOff(false);
            sfu.enableWebcam();

        }
        else {
            setWebcamOff(true);
            sfu.disableWebcam();
        }
    }

    const handleMic = () => {
        if (micOff) {
            setMicOff(false);
            sfu.enableMic();
        } else {
            setMicOff(true);
            sfu.disableMic();
        }
    }

    const handleShare = () => {
        if (shareOff) {
            setShareOff(false);
            sfu.enableShare();
        } else {
            setShareOff(true);
            sfu.disableShare();
        }
    }

    const handleEndCall = () => {
        history.push("/conferencing");
        window.location.reload(false);
    }

    return (
        <div className={classes.container}>
            <div >
                {webcamOff ? (<IconButton className={classes.buttonContainer} onClick={handleWebcam}><VideocamOff className={classes.buttonIcon} /></IconButton>) : <IconButton className={classes.buttonContainer} onClick={handleWebcam}><Videocam className={classes.buttonIcon} /></IconButton>}
            </div>
            <div >
                {shareOff ? (<IconButton className={classes.buttonContainer} onClick={handleShare}><ScreenShare className={classes.buttonIcon} /></IconButton>) : <IconButton className={classes.buttonContainer} onClick={handleShare}><StopScreenShare className={classes.buttonIcon} /></IconButton>}
            </div>
            <div >
                {micOff ? (<IconButton className={classes.buttonContainer} onClick={handleMic}><MicOff className={classes.buttonIcon} /></IconButton>) : <IconButton className={classes.buttonContainer} onClick={handleMic}><Mic className={classes.buttonIcon} /></IconButton>}
            </div>
            <div>
                <IconButton className={classes.endCallButtonContainer} onClick={handleEndCall} ><CallEnd className={classes.buttonIcon} /></IconButton>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    // nothing yet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(ControlBar);
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import PropTypes from "prop-types";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";


// @material-ui/icons
import Close from "@material-ui/icons/Close";

// import LockOutline from "@material-ui/icons/LockOutline";

// Core Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import DocumentUpload from "components/CustomUpload/DocumentUpload.jsx"

import styles from "assets/jss/material-dashboard-pro-react/components/CreateInvestorThreadsMessageFormStyle.jsx";

import { closeFileUploadDialog } from 'store/actions/navigationActions';

const Transition = (props) => {
    return <Slide direction="down" {...props} />
  }
  

function MultiFileUploadDialog(props) {

    const { classes, open, handleClose, storageLocation, documents } = props;
    const [ updatedDocuments, setDocuments ] = React.useState(false);

    // Whatever ANYONE says, react hooks don't implement componentWillReceiveProps in a useful way.
    const documentsToDisplay = updatedDocuments || documents;

    function setDocument(index, document) {
        const update = [ ...documentsToDisplay ];
        update[index] = document;
        setDocuments(update);
    }

    return (
    <Dialog
        classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalLarge
        }}
        open={open || false}
        TransitionComponent={Transition}
        aria-labelledby="create-thread-dialog-title"
        aria-describedby="create-thread-dialog-description"
    >
        <DialogContent
            id="create-thread-dialog-description"
            className={classes.modalBody}
        >
            <Button
                style={{
                    margin: "3.5% 1% 0% 0%",
                    color: "black",
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={()=>{ handleClose(documentsToDisplay) }}
            >
                {"Close"}
                <Close className={classes.modalClose} />
            </Button>

            {/* FORM */}
            <GridContainer>
                {documentsToDisplay.map((document, index) =>
                    <GridItem key={index} xs={6} sm={6}>
                        <DocumentUpload currentDoc={document} storageLocation={storageLocation} setDocument={(name, url) => setDocument(index, { name, url })} />
                    </GridItem>
                )}
                <GridItem key={documentsToDisplay.length} xs={6} sm={6}>
                    <DocumentUpload currentDoc={{name:'New document'}} storageLocation={storageLocation} setDocument={(name, url) => setDocument(documentsToDisplay.length, { name, url })} />
                </GridItem>
            </GridContainer>
        </DialogContent>
    </Dialog>
    )
}

MultiFileUploadDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    storageLocation: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};
  
const mapStateToProps = (state) => {
    return {
        open: state.navigation.fileUploadDialogOpen
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleClose: (documents)=> { return props.onUpdate(documents).then(()=>dispatch(closeFileUploadDialog())); }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(MultiFileUploadDialog);
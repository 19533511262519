import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// react component for creating dynamic tables

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

// @material-ui/icons

import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

import avatar from 'assets/img/faces/user.png';

// Forms

// Firebase Redux Integration

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class ThreadsMap extends React.Component {
  render() {
    const { classes, currentThread, user, users } = this.props;

    console.log('usersusers', users);

    return (
      <div>
        {currentThread &&
          currentThread.messages.map((message, index) => {
            if (message.uid === user.uid) {
              return (
                <div key={index}>
                  <GridContainer style={{ padding: '0px 10px 0px 20px' }}>
                    <GridItem xs={9}>
                      <Card style={{ marginTop: '0px' }}>
                        <CardBody>
                          <p
                            style={{ marginTop: '-10px', textAlign: 'right' }}
                            className={classes.cardCategory}
                          >
                            {message.message}
                          </p>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={2}>
                      <img
                        src={user.profileURL || avatar}
                        style={{ borderRadius: '50%', height: '50px' }}
                        alt="..."
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              );
            } else {
              const sender = users.find((user) => user.uid === message.uid) || {};
              return (
                <div key={index}>
                  <GridContainer style={{ padding: '0px 10px 0px 20px' }}>
                    <GridItem xs={2}>
                      <img
                        src={sender.profileURL || avatar}
                        style={{ borderRadius: '50%', height: '50px' }}
                        alt="..."
                      />
                    </GridItem>
                    <GridItem xs={9}>
                      <Card style={{ marginTop: '0px' }}>
                        <CardBody>
                          <p
                            style={{ marginTop: '-10px' }}
                            className={classes.cardCategory}
                          >
                            {message.message}
                          </p>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              );
            }
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.users.dataRows,
    user: state.user.user,
    currentThread: state.threads.currentThread,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  //   console.log(ownProps)
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(ThreadsMap);

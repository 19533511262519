import axios from "axios";
import { db, firebase } from 'firebase/fbConfig.js';

export const saveFavorite = (uid, stock) => {
  return async (dispatch) => {
    return db.collection('users')
      .doc(uid)
      .collection('favorite-stocks')
      .doc(stock.symbol)
      .update(stock)
      .then(() => {
        console.log('Added favorite stock: ', stock.symbol);
      })
      .catch((err) => {
        db.collection('users').doc(uid).collection('favorite-stocks').doc(stock.symbol).set(stock)
        .then((snap) => {
          console.log('look snap', snap)
        })
      })
  }
};

export const getStockProfile = (symbol) => {
  const options = {
    method: 'GET',
    url: 'https://mboum-finance.p.rapidapi.com/qu/quote/asset-profile',
    params: {symbol: symbol},
    headers: {
      'X-RapidAPI-Key': 'f4bff41287mshcdbd21a1b4122c0p1916e8jsn07b0f5101c41',
      'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
    }
  };

  return axios.request(options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    })
}

export const getStocks = () => {
  const options = {
    method: 'GET',
    url: 'https://mboum-finance.p.rapidapi.com/co/collections/day_gainers',
    params: {start: '0'},
    headers: {
      'X-RapidAPI-Key': 'f4bff41287mshcdbd21a1b4122c0p1916e8jsn07b0f5101c41',
      'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
    }
  };
  return (dispatch) => {
    axios.request(options)
      .then((res) => {
        dispatch(setStocks(res.data));
      })
      .catch((err) => {
        console.error(err);
      })
  }
};

export const getStockNews = (symbol) => {
  const options = {
    method: 'GET',
    url: 'https://mboum-finance.p.rapidapi.com/ne/news/',
    params: {symbol: symbol},
    headers: {
      'X-RapidAPI-Key': 'f4bff41287mshcdbd21a1b4122c0p1916e8jsn07b0f5101c41',
      'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
    }
  };
  return (dispatch) => {
    axios.request(options)
      .then((res) => {
        dispatch(setStockNews(symbol, res.data));
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export const getStockInfo = (symbol) => {
  const options = {
    method: 'GET',
    url: 'https://mboum-finance.p.rapidapi.com/qu/quote/',
    params: {symbol: symbol},
    headers: {
      'X-RapidAPI-Key': 'f4bff41287mshcdbd21a1b4122c0p1916e8jsn07b0f5101c41',
      'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
    }
  };
  return (dispatch) => {
    axios.request(options)
      .then((res) => {
        dispatch(setStockInfo(symbol, res.data));
      })
      .catch((err) => {
        console.error(err);
      })
  }
}

export const setStocks = (stockData) => {
  return {
    type: "SET_STOCKS",
    stockData
  }
};

export const setStockNews = (symbol, stockNews) => {
  return {
    type: "SET_STOCK_NEWS",
    symbol,
    stockNews
  }
}

export const setStockInfo = (symbol, stockInfo) => {
  return {
    type: "SET_STOCK_INFO",
    symbol,
    stockInfo
  }
}
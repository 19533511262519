// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  darkColor,
  lightColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const footerStyle = theme => ({
  block: {},
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px"
  },
  footer: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    backgroundColor: "#E5EDF1",
    bottom: "0",
    boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.75)",
    padding: "2px 0",
    ...defaultFont,
    zIndex: 4,
    position: "fixed",
    height: "66px",
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.07)',
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  selectedIconDiv: {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px'
  }
});

export default footerStyle;

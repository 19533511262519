
import React, { Component } from 'react';
import FirstQuestion from './Questions/FirstQuestion';
import SecondQuestion from './Questions/SecondQuestion';
import ThirdQuestion from './Questions/ThirdQuestion';
import FourthQuestion from './Questions/FourthQuestion';
import FifthQuestion from './Questions/FifthQuestion';
import SixthQuestion from './Questions/SixthQuestion';
import SeventhQuestion from './Questions/SeventhQuestion';
import EighthQuestion from './Questions/EighthQuestion';
import NinthQuestion from './Questions/NinthQuestion';
import Confirm from './Confirm';
import Success from './Success';

export class Demographics extends Component {
    state = {
        step: 1,
        firstQuestion: 0,
        secondQuestion: 0,
        thirdQuestion: 0,
        fourthQuestion: 0,
        fifthQuestion: 0,
        sixthQuestion: 0,
        seventhQuestion: 0,
        eighthQuestion: 0,
        ninthQuestion: 0
    };

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    // Go back to first question/retake quiz
    firstStep = () => {
        const { step } = this.state;
        this.setState({
            step: 1
        })
    }

    // Handle toggle change
    handleToggle = (input, value) => {
        this.setState({
            [input]: value
        })
    }

    render() {
        const { classes } = this.props;
        const { step } = this.state;
        const { firstQuestion, secondQuestion, thirdQuestion, fourthQuestion, fifthQuestion, sixthQuestion, seventhQuestion, eighthQuestion, ninthQuestion } = this.state;
        const values = { firstQuestion, secondQuestion, thirdQuestion, fourthQuestion, fifthQuestion, sixthQuestion, seventhQuestion, eighthQuestion, ninthQuestion };;
        // const uid = this.state.auth.user.uid

        switch (step) {
            case 1:
                return (
                    <FirstQuestion
                        nextStep={this.nextStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    // uid={uid}
                    />
                );
            case 2:
                return (
                    <SecondQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 3:
                return (
                    <ThirdQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 4:
                return (
                    <FourthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 5:
                return (
                    <FifthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 6:
                return (
                    <SixthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 7:
                return (
                    <SeventhQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 8:
                return (
                    <EighthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 9:
                return (
                    <NinthQuestion
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleToggle={this.handleToggle}
                        values={values}
                        classes={classes}
                    />
                );
            case 10:
                return (
                    <Confirm
                        nextStep={this.nextStep}
                        firstStep={this.firstStep}
                        prevStep={this.prevStep}
                        values={values}
                    />
                );
            case 11:
                return (
                    <Success
                        firstStep={this.firstStep}
                    />
                )
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default Demographics;
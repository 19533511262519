import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from 'firebase/fbConfig.js';
import moment from 'moment';
import { Link } from 'react-router-dom';

// react component for creating dynamic tables
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

// @material-ui/icons
import Close from '@material-ui/icons/Close';
import loader from 'assets/img/loader.gif';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

// Forms
import EditInvestmentForm from '../../components/Forms/EditInvestmentForm';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      assignAdvisorModal: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      refresh: false,
      messageSize: null,
      query: db
        .collection('TEST-users')
        .doc(this.props.uid)
        .collection('threads')
        .doc(this.props.id)
        .collection('messages'),
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  //   loadData = (uid, id) => {
  //     let data = db.collection("TEST-users").doc(uid).collection("investments").doc(id)
  //     .get().then((snapshot) => {
  //         const data = []
  //         // Parse data into array like in firebase.js
  //         snapshot.forEach((doc) => {
  //           var docData = doc.data()
  //           console.log(docData)
  //           var docObj = {
  //             id: doc.id,
  //             message: docData.message ? docData.message : "N/A",
  //             uid: docData.uid ? docData.uid : "N/A",
  //             createdTime: docData.createdTime ? docData.createdTime : "N/A"
  //           }
  //           data.push(docObj);
  //         })
  //         return (data)
  //       }, (error) => {
  //         console.log('error fetching data: ', error)
  //       }).then(data => {
  //         this.setState({
  //           data: data,
  //           dataLoaded: true,
  //           refresh: false
  //         })
  //       })

  //console.log(this.state)
  //let relatedConceptsData = this.props.relatedConcepts.dataRows

  // this.setState({
  //   relatedConceptsData,
  //   dataLoaded: true
  // })
  //   }

  // loadData = (uid, id) => {
  //     let investmentsData = db.collection("TEST-users").doc(uid).collection("investments").doc(id).get().then(doc => {
  //         if (!doc.exists) {
  //           console.log('No such document!');
  //         } else {
  //           console.log('Document data:', doc.data());
  //           return(doc.data())
  //         }
  //       })
  //       .catch(err => {
  //         console.log('Error getting document', err);
  //       });

  //     console.log(investmentsData)

  //     this.setState({
  //         investmentsData,
  //         dataLoaded: true
  //     })
  // }
  //     loadData = (uid, id) => {
  //         db.collection("TEST-users").doc(uid).collection("investments").doc(id)
  //             .get().then((snapshot) => {
  //              let data = snapshot.data()
  //           }, (error) => {
  //             console.log('error fetching data: ', error)
  //           }).then(data => {
  //               console.log(data)
  //             this.setState({
  //               data: data,
  //               dataLoaded: true,
  //               refresh: false
  //             })
  //           })
  //    }
  loadData = (uid, id) => {
    console.log('loading data');
    let data = db
      .collection('advisor')
      .doc(uid)
      .collection('pending-investments')
      .doc(id)
      .get()
      .then(
        (snapshot) => {
          var docData = snapshot.data();
          if (docData) {
            var docObj = {
              id: snapshot.id,
              investmentType: docData.investmentType ? docData.investmentType : 'N/A',
              investorFirstName: docData.investorFirstName
                ? docData.investorFirstName
                : 'N/A',
              investorLastName: docData.investorLastName
                ? docData.investorLastName
                : 'N/A',
              createdDate: docData.createdDate ? docData.createdDate : 'N/A',
              advisorID: docData.advisorID ? docData.advisorID : 'N/A',
              advisorAssigned: docData.advisorAssigned
                ? docData.advisorAssigned
                : 'N/A',
              serviceType: docData.serviceType ? docData.serviceType : 'N/A',
              summary: docData.summary ? docData.summary : 'N/A',
              document: docData.document ? docData.document : 'N/A',
            };
            console.log(docObj);
            return docObj;
          } else {
            return null;
          }
        },
        (error) => {
          console.log('error fetching data: ', error);
        }
      )
      .then((docObj) => {
        this.setState({
          data: docObj,
          dataLoaded: true,
        });
      });

    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  };

  advisorLoadData = (uid, id) => {
    console.log('advisor data');
    console.log(this.props.user.userType);
    if (this.props.user.userType == 'advisor') {
      console.log('loading data');
      let data = db
        .collection('advisor')
        .doc(uid)
        .collection('pending-investments')
        .doc(id)
        .get()
        .then(
          (snapshot) => {
            var docData = snapshot.data();
            if (docData) {
              var docObj = {
                id: snapshot.id,
                ...docData,
              };
              console.log(docObj);
              return docObj;
            } else {
              return null;
            }
          },
          (error) => {
            console.log('error fetching data: ', error);
          }
        )
        .then((docObj) => {
          this.setState({
            data: docObj,
            dataLoaded: true,
          });
        });
    } else {
      console.log('error');
    }

    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
  };

  refresh = () => {
    this.setState({
      refresh: true,
    });
  };

  changeMessageSize = (size) => {
    this.setState({
      messageSize: size,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };
  componentWillMount() { }

  render() {
    const {
      AuthBool,
      classes,
      currentInvestment,
      messages,
      currentThread,
      uid,
      id,
    } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;
    if (AuthBool) {
      if (!this.state.dataLoaded) {
        this.advisorLoadData(uid, id);
      }
    }

    // let observer = this.state.query.onSnapshot(querySnapshot => {
    //   console.log(`Received query snapshot of size ${querySnapshot.size}`);
    //   if(this.state.messageSize === null) {
    //   this.changeMessageSize(querySnapshot.size)
    //   } else if (this.state.messageSize !== querySnapshot.size) {
    //     this.changeMessageSize(querySnapshot.size)
    //     this.loadData(uid, id)
    //   }
    //   // ...
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });

    // let profile;

    // if (this.props.users.dataRows !== undefined) {
    //   //let index = props.users.dataRows.indexOf(id);
    //   let obj = this.props.users.dataRows.find(o => o.volUID === id);
    //   console.log(this.props.users.dataRows)
    //   console.log(obj)
    //   profile =  obj.profileURL
    // }

    // console.log(profile)

    // let profilePhoto = DefaultProfile
    // if (AuthBool) {
    //   (user.dataLoaded && user.profileURL) ? (
    //     profilePhoto = user.profileURL
    //   ) : (
    //       // add link to profile photo to firestore
    //       profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
    //       // add profile photo to firebase storage
    //       // update redux with link
    //     )
    // }

    console.log(this.state);
    console.log(this.props);
    return (
      <div>
        {this.state.data ? (
          <div>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  Posted By:
                  <b>
                    {' '}
                    {' ' +
                      this.state.data.investorFirstName +
                      ' ' +
                      this.state.data.investorLastName +
                      ' '}
                  </b>
                  on
                  <b>
                    {' ' +
                      moment(this.state.data.createdDate.toDate()).format(
                        'MM-DD-YYYY'
                      )}
                  </b>
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  Investment Type:
                  <b> {' ' + this.state.data.investmentType}</b>
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  Service Needed:
                  <b> {' ' + this.state.data.serviceType}</b>
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  {' '}
                  <b> Summary: </b>
                  {' ' + this.state.data.summary}
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  Assigned Advisor:
                  <b>
                    {' '}
                    {this.state.data.advisorID
                      ? ' ' + this.state.data.advisorID
                      : 'No advisor assigned'}
                  </b>
                  <br />
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  {' '}
                  <b> Investment Files </b>
                  <Button
                    style={{ backgroundColor: '#0db2d8' }}
                    onClick={() => this.handleClickOpen('editInvestmentModal')}
                  >
                    + Add New
                  </Button>
                  <p>
                    <a href={this.state.data.document} target="_blank">
                      Document link
                    </a>
                  </p>
                </span>
              </CardBody>
            </Card>
            <Card
              style={{ marginTop: '10px', marginBottom: '10px' }}
              className={classes.investmentCards}
            >
              <CardBody>
                <span>
                  <Link
                    to={{
                      pathname: `/investmentchat/${id}`,
                      state: { dataLoaded: false },
                    }}
                  >
                    <Button
                      style={{ backgroundColor: '#0db2d8' }}
                      onClick={() => console.log('click')}
                    >
                      Chat
                    </Button>
                  </Link>
                </span>
              </CardBody>
            </Card>
            {/* NEW INVESTMENT MODAL */}
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + ' ' + classes.modalLarge,
              }}
              open={this.state.editInvestmentModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.handleClose('editInvestmentModal')}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
                style={{ paddingLeft: '0', paddingRight: '0' }}
              >
                <Button
                  style={{
                    margin: '3.5% 1% 0% 0%',
                    color: 'black',
                  }}
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => this.handleClose('editInvestmentModal')}
                >
                  {' '}
                  <Close className={classes.modalClose} />
                </Button>

                {/* FORM */}
                <EditInvestmentForm id={id} />
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: '#f0f1f5',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <img
              style={{ margin: '20% 0% 0% 41%', position: 'relative', width: '20%' }}
              src={loader}
              alt="..."
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.user.users,
    threads: state.threads.threads,
    messages: state.messages.messages,
    currentInvestment: state.investments.currentInvestment,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  //   console.log(ownProps)
  return {
    //startSetUsersList: dispatch(startSetUsersList()),
    //startGetCurrentInvestment: dispatch(startGetCurrentInvestment(ownProps.uid, ownProps.id)),
    //startSetMessagesList: dispatch(startSetMessagesList(ownProps.uid, ownProps.id))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(Style)
)(Users);

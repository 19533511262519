import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Hidden from '@material-ui/core/Hidden';
import GridItem from 'components/Grid/GridItem.jsx';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Video from 'twilio-video';
import Participant from './Participant';
import ParticipantList from './ParticipantList';
import loader from 'assets/img/loader.gif';
import style from 'assets/jss/material-dashboard-pro-react/components/videoStyle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import AVControls from './AVControls';
import Button from 'components/CustomButtons/Button';


const Room = ({ roomName, token, handleLogout, classes }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [dominantSpeaker, setDominantSpeaker] = useState({});
  const [gridRatio, setGridRatio] = useState(0);
  const [screenShared, setScreenShared] = useState(null);
  const [mainParticipant, setMainParticipant] = useState(null);
  const [roomVideoTracks, setRoomVideoTracks] = useState({});
  const [shareScreenTrack, setShareScreenTrack] = useState(null);
  const [selfTracks, setSelfTracks] = useState({});
  const [localScreenShared, setLocalScreenShared] = useState(false);

  let participantNames;

  const setMainScreenShared = (sid) => {
    setScreenShared(sid);
  }
  const capitalize = (identity) => {
    let name = identity.replace('-', ' ').replace(/[^a-zA-Z\s:]/g, '');
    let nameArr = name.split(' ');
    for (let i = 0; i < nameArr.length; i++) {
      nameArr[i] = nameArr[i][0].toUpperCase() + nameArr[i].substr(1);
    }
    return nameArr.join(' ');
  };

  if (participants) {
    participantNames = participants.map((participant) => {
      return capitalize(participant.identity);
    });
  }

  const handleMainParticipant = () => {
    // if (dominantSpeaker && dominantSpeaker[0]) {
    //   for (let i = 0; i < participants.length; i++) {
    //     if (dominantSpeaker[0].sid === participants[i].sid) {
    //       return (
    //         <Participant
    //           self={false}
    //           key={participants[i].sid}
    //           participant={participants[i]}
    //           classes
    //         />
    //       )
    //     }
    //   }
    // If screenShared and not local
      // return screenshared participant
    // if (screenShared) {
    //   const main = participants.filter((participant) => {
    //     console.log('look', participant, screenShared)
    //     return participant.sid === screenShared;
    //   })[0];
    //   console.log('main', main)
    //   return <Participant
    //       key={main.sid}
    //       id={main.id}
    //       participant={main}
    //       room={room}
    //       setMainScreenShared={setMainScreenShared}
    //       classes
    //       self={false}
    //     />
    // } else {
    let main;
    let realSelf = false; // Sorry will change later
    if (mainParticipant) {
      main = mainParticipant;
    } else if (dominantSpeaker && Object.keys(dominantSpeaker).length > 0) {
      main = dominantSpeaker;
    }
    if (!main) {
      main = room.localParticipant;
      realSelf = true;
    }
      return (
        <Participant
          key={main.sid}
          id={main.sid}
          participant={main}
          room={room}
          handleLogout={handleLogout}
          classes
          setMainScreenShared={setMainScreenShared}
          setRoomVideoTracks={setRoomVideoTracks}
          setSelfTracks={realSelf ? setSelfTracks : null}
          selfTracks={realSelf ? selfTracks : null}
          localScreenShared={localScreenShared}
          shareScreenTrack={shareScreenTrack}
          self
          realSelf={realSelf}
        />
      )
    // }
  }

  const shareScreenHandler = (e) => {
    // e.preventDefault();
    if (!selfTracks.video[1]) {
      navigator.mediaDevices
        .getDisplayMedia()
        .then((stream) => {
          const track = new Video.LocalVideoTrack(stream.getTracks()[0]);
          setSelfTracks((tracks) => ({...tracks, video: [...tracks.video, track]}));
          setShareScreenTrack(track);
          setLocalScreenShared(true);
          room.localParticipant.publishTrack(track);
          track.mediaStreamTrack.onended = () => {
            shareScreenHandler();
          };
        })
        .catch(() => {
          alert('Could not share the screen');
        });
    } else {
      room.localParticipant.unpublishTrack(selfTracks.video[1]);
      selfTracks.video[1].stop();
      setLocalScreenShared(false);
      setShareScreenTrack(null);
      setSelfTracks((tracks) => {
        let videos = tracks.video;
        videos.pop();
        return {...tracks, video: videos};
      });
    }
  };

  const sideParticipants = () => {
    // if (dominantSpeaker && dominantSpeaker[0]) {
    //   let participantArr = participants.map((participant, index) => {
    //     if (dominantSpeaker[0].sid !== participant.sid) {
    //       return <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
    //         <Participant
    //           self={false}
    //           key={participant.sid}
    //           participant={participant}
    //           classes
    //         />
    //       </Slide>
    //     }
    //   });
    //   participantArr.push(
    //     <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
    //         <Participant
    //           key={room.localParticipant.sid}
    //           participant={room.localParticipant}
    //           room={room}
    //           handleLogout={handleLogout}
    //           classes
    //           self
    //         />
    //     </Slide>
    //   );
    //   return participantArr;
    // } else {
      // if screenShared && screenShared !== local:
        // slice participants, remove index of screenShared, add local
    // if (screenShared) {
    //   participants.filter((participant) => {
    //     return participant.sid !== screenShared;
    //   }).map((participant, index) => (
    //       <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
    //         <Participant
    //           self={false}
    //           key={participant.sid}
    //           id={participant.sid}
    //           participant={participant}
    //           classes
    //           setMainScreenShared={setMainScreenShared}
    //           dominant={dominantSpeaker&&dominantSpeaker.sid === participant.sid}
    //         />
    //       </Slide>
    //     )).push((
    //       <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
    //         <Participant
    //           key={room.localParticipant.sid}
    //           id={room.localParticipant.sid}
    //           participant={room.localParticipant}
    //           room={room}
    //           handleLogout={handleLogout}
    //           classes
    //           setMainScreenShared={setMainScreenShared}
    //           self
    //         />
    //     </Slide>
    //     ))
    // } else {
      if (mainParticipant) {
        const filtered = participants.filter((participant) => {
          return participant.sid !== mainParticipant.sid;
        });
        const mapped = filtered.map((participant, index) => {
          return (
            <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
              <Participant
                self={false}
                key={participant.sid}
                id={participant.sid}
                participant={participant}
                classes
                setMainScreenShared={setMainScreenShared}
                setRoomVideoTracks={setRoomVideoTracks}
                dominant={dominantSpeaker&&dominantSpeaker.sid === participant.sid}
              />
            </Slide>
        )});
        const pushed = mapped.push((
          <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
            <Participant
              key={room.localParticipant.sid}
              id={room.localParticipant.sid}
              participant={room.localParticipant}
              handleLogout={handleLogout}
              classes
              setMainScreenShared={setMainScreenShared}
              setRoomVideoTracks={setRoomVideoTracks}
              setSelfTracks={setSelfTracks}
              realSelf
            />
          </Slide>
        ));
        return mapped;
      } else {
        return participants.map((participant, index) => {
          return (
          <Slide style={{ height: '200px', paddingBottom: '0px' }} index>
            <Participant
              self={false}
              key={participant.sid}
              id={participant.sid}
              participant={participant}
              classes
              setMainScreenShared={setMainScreenShared}
              setRoomVideoTracks={setRoomVideoTracks}
              dominant={dominantSpeaker&&dominantSpeaker.sid === participant.sid}
            />
          </Slide>
        )})
      }
};

  const onSpeakerChange = (dominantSpeaker) => {
    setDominantSpeaker(dominantSpeaker);
    // if self is dom speaker, it's set to null?
    // Else:
  };

  const scaleCarousel = () => {
    if (participants.length < 4) {
      return participants.length;
    }
    return 3;
  };

  // when dom speaker changes, if null, show self
  // else iterate over participants, if sid of dom === sid of participant, show that participant

  useEffect(() => {
    const participantConnected = (participant) => {
      participant.tracks.forEach((pub) => {
        if (pub.isSubscribed) {
          setMainScreenShared(false)
        }
      })
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    Video.connect(token, {
      name: roomName,
      dominantSpeaker: true,
    }).then((room) => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.on('dominantSpeakerChanged', onSpeakerChange);
      room.participants.forEach(participantConnected);
    });
    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  useEffect(() => {
    if (participants.length > 0) {
      setGridRatio(12 / participants.length);
    }
  }, [participants]);

  useEffect(() => {
    for (let i in roomVideoTracks) {
      if (roomVideoTracks[i].length > 1) {
        setMainParticipant(room.participants.get(i));
      }
    }
  }, [roomVideoTracks])

  if (!room) {
    return (
      <div
        style={{
          backgroundColor: '#F4F6F8',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: "flex"
        }}
      >
        <img
          style={{
            // margin: '20% 0% 0% 38%',
            // position: 'relative',
            alignSelf: "center",
            width: '20%',
          }}
          src={loader}
          alt="..."
        />
      </div>
    );
  }

  return (
    <GridContainer>
      <GridItem
        className={classes.gridItem}
        xs={participants.length === 0 ? 12 : 10}
      >
        {handleMainParticipant()}
      </GridItem>
      {participants.length === 0 ? null : (
        <GridItem className={classes.gridItem} xs={2}>
          <Card style={{ height: '100%', marginTop: '0px', padding: '30px 0px' }}>
            <h3 className={classes.participantsList}>Participants</h3>
            <CarouselProvider
              orientation="vertical"
              style={{ width: '100%' }}
              naturalSlideWidth={100}
              naturalSlideHeight={100}
              visibleSlides={3}
              totalSlides={participants.length}
              isPlaying={false}
            >
              <Slider>{sideParticipants()}</Slider>
            </CarouselProvider>
          </Card>
        </GridItem>
      )}
      {selfTracks.audio && selfTracks.video ?
        <div style={{ borderRadius: '15px' }} className={classes.controls}>
        {console.log('look audio', room.localParticipant)}
          <AVControls
            audioTrack={selfTracks.audio[0]}
            videoTrack={selfTracks.video[0]}
            screenTrack={selfTracks.video[1]}
            handleLogout
            classes
          >
            <Button
              style={{
                background: '#72E5E4',
                marginLeft: '2%',
                marginRight: '1%',
                borderRadius: '15px',
                fontSize: '20px',
                fontWeight: 500,
              }}
              onClick={shareScreenHandler}
            >
              {selfTracks.video[1] ? 'Stop Sharing' : 'Share Screen'}
            </Button>
            <Button
              style={{
                background: 'red',
                marginLeft: '2%',
                borderRadius: '15px',
                fontSize: '20px',
                fontWeight: 500,
              }}
              onClick={handleLogout}
              className={classes.logout}
            >
              Leave Call
            </Button>
          </AVControls>
        </div> :
        null}
      {/* <ParticipantList
            self={capitalize(room.localParticipant.identity)}
            participants={participantNames}
          /> */}
    </GridContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => {
  // nothing yet
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style)
)(Room);

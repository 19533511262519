const IntakeFormStyle = (theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      height: '100% !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'end',
      marginTop: '200px',
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '1000px !important',
      padding: '55px',
      color: 'white',
      border: '1px solid',
      borderRadius: '15px',
      boxShadow: '0px 4px 9px gray',
      margin: '50px auto',
      width: '80%',
      backgroundColor: 'white',
      padding: '15px',
    },
  },
  landingHeader: {
    color: 'black',
    textAlign: 'center',
  },
  clickButton: {
    color: 'black',
    textAlign: 'center',
  },
  startButton: {
    width: '50vw',
    marginTop: '15px',
    margin: 'auto',
    display: 'block',
    background: '#436CFC',
    color: '#fff',
    borderRadius: '10px',
  },
  gridContainer: {
    display: 'flex',
  },
  addButton: {
    background: '#72C5C8 !important',
    width: '100%',
    margin: 'auto',
    display: 'flex',
    color: '#fff',
    borderRadius: '10px',
    '&.active': {
      background: '#72C5C8',
    },
  },
  nextButton: {
    width: '100%',
    margin: 'auto',
    display: 'block',
    background: '#436CFC',
    color: '#fff',
    borderRadius: '10px',
  },
  inputCard: {
    borderRadius: '40px',
    height: '50px',
    marginBottom: '0px',
    padding: '10px',
  },
  familyFormContainer: {
    padding: '0px 5px !important',
    marginBottom: '40px',
  },
  inputColor: {
    fontSize: '10px',
    marginTop: '10px',
  },
});

export default IntakeFormStyle;

const conferenceCalendarStyle = (theme) => ({
  calendarGridItem: {
    [theme.breakpoints.up('md')]: {
      marginTop: '80px',
      height: '725px',
    },
  },
  modalClose: {
    width: '500px',
  },
  addNewEventFab: {
    position: 'fixed',
    backgroundColor: '#4775FB',
    right: '20px',
    bottom: '90px',
    zIndex: '1000',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#6f93fc',
    },
    [theme.breakpoints.up('xs')]: {
      bottom: '65px',
      right: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '5vh',
      right: '100px',
      width: '80px',
      height: '80px',
    },
  },
  addNewEventIcon: {
    fontSize: 'xxx-large',
    color: 'white',
  },
  addNewEventModal: {
    [theme.breakpoints.up('md')]: {
      minwidth: '900px !important',
    },
  },
  formDiv: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '500px',
      height: '100%',
      justifyContent: 'space-around',
      marginBottom: '60px',
    },
  },
  modalPaper: {
    [theme.breakpoints.up('md')]: {
      // backgroundColor: '#E9EDF1',
      // padding: '10px',
      width: '800px',
    },
  },
  modalCard: {
    [theme.breakpoints.up('md')]: {
      width: '95%',
      margin: '20px auto',
    },
  },
  modalTitle: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#4775FB',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '600px',
      overflow: 'scroll',
    },
  },
  navBtns: {
    width: '110%',
    margin: '0px auto',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  navDivItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      height: '200px',
    },
  },
  individualNavDiv: {
    borderRadius: '30px',
    backgroundColor: '#4775FB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50px',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      height: '50%',
    },
  },
  messageIcon: {
    color: 'white',
    [theme.breakpoints.up('md')]: {
      fontSize: '60px',
    },
  },
  navDescriptor: {
    textAlign: 'center',
    fontWeight: '500',
  },
  selectedUsersInEvent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0px',
  },
  recommendationsPic: {
    borderRadius: '50%',
    height: '50px',
    width: '50px',
    [theme.breakpoints.up('md')]: {
      height: '64px',
      width: '64px',
    },
  },
  removeBtn: {
    backgroundColor: 'red',
    color: 'white',
    width: '50px',
    '&:hover': {
      backgroundColor: 'red',
    },
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  userInfoDiv: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: "18px",
    [theme.breakpoints.down('md')]: {
      fontSize: "13px"
    },
  },
  inputColor: {
    width: '100%',
    color: 'black !important',
    height: '50px',
  },
});

export default conferenceCalendarStyle;

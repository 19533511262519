import React from 'react';
import loader from 'assets/img/loader.gif';

export const Loader = ({ customStyle }) => (
    <div
        style={{
            backgroundColor: '#f0f1f5',
            position: 'absolute',
            width: '100%',
            height: '100%',
            ...customStyle
        }}
    >
        <img
            style={{ margin: '20% 0% 0% 41%', position: 'relative', width: '20%' }}
            src={loader}
            alt="..."
        />
    </div>
);

export default Loader;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';


import { addQuizXpPoints } from "store/actions/eventsActions"

export class Confirm extends Component {

    addToFirestore = () => {

        console.log('results pre', this.props.location.state.state.values)
        const values = this.props.location.state.state.values
        return db.collection('global-test-results').doc('demographics').get().then((snapshot) => {
            const results = snapshot.data()
            for (var key in results) {
                const scoresObj = results[key]
                scoresObj[values[key]]++
            }
            console.log('results post', results)
            return db.collection('global-test-results').doc('demographics').update({ ...results }).then(() => {
                return db.collection("users").doc(this.props.uid).collection("quizzes").doc("demographics").collection("quizLists").add({
                    quiz: "Demographics",
                    firstQuestion: this.props.location.state.state.values.firstQuestion,
                    secondQuestion: this.props.location.state.state.values.secondQuestion,
                    thirdQuestion: this.props.location.state.state.values.thirdQuestion,
                    fourthQuestion: this.props.location.state.state.values.fourthQuestion,
                    fifthQuestion: this.props.location.state.state.values.fifthQuestion,
                    sixthQuestion: this.props.location.state.state.values.sixthQuestion,
                    seventhQuestion: this.props.location.state.state.values.seventhQuestion,
                    eighthQuestion: this.props.location.state.state.values.eighthQuestion,
                    ninthQuestion: this.props.location.state.state.values.ninthQuestion,
                    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    quizID: "Placeholder"
                })
                    .then((docRef) => {
                        return db.collection("users").doc(this.props.uid).collection("quizzes").doc("demographics").collection("quizLists").doc(docRef.id).update({
                            quizID: docRef.id
                        })
                    }).then(() => {
                        this.props.addQuizXpPoints(this.props.uid, this.props.events, 'Demographics Quiz')
                        this.props.history.push('/demographics-success');
                    })
            })
        })
    }

    retakeQuiz = e => {
        e.preventDefault();
        this.props.history.push('/demographics');
    }

    continue = () => {
        this.props.nextStep();
    };


    render() {

        const { classes } = this.props;

        return (
            <div className={classes.demographicsConfirmQuizContainer}>
                <h3 className={classes.questionHeader}>Thank you for taking the Demographics Quiz!</h3>
                <GridContainer>
                    <GridItem xs={12}>
                        <Button
                            onClick={this.retakeQuiz}
                            className={classes.backButton}
                            variant="contained"
                        >Retake Quiz</Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <Button
                            className={classes.submitButton}
                            // onClick={this.assignRiskType}
                            onClick={this.addToFirestore}
                        >Submit My Results</Button>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {}
    return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Confirm);
import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { NavLink, useParams } from "react-router-dom";
import firebase, { db, } from "firebase/fbConfig.js";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';


// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';


import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { query } from 'chartist';


export function Results(props) {
    const { classes } = props;
    const { docID } = useParams();
    const [riskProfileScore, setRiskProfileScore] = useState(0)
    function getScores() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                const items = [];
                let score = 0;
                db.collection('users').doc(user.uid).collection('quizzes').doc('riskProfile').collection('quizLists').doc(docID)
                    .get()
                    .then((queryResult) => {
                        items.push(queryResult.data().firstQuestion, queryResult.data().secondQuestion, queryResult.data().thirdQuestion,
                            queryResult.data().fourthQuestion, queryResult.data().fifthQuestion, queryResult.data().sixthQuestion)
                    })
                    .then(() => {
                        score = items.reduce(function summarize(sum, number) {
                            const updatedSum = sum + number;
                            return updatedSum;
                        }, 0);
                        setRiskProfileScore(score)
                    })

            }
        })
    }
    useEffect(() => {
        getScores();
    }, []);
    console.log("score is: ", riskProfileScore)
    return (
        <div className={classes.quizContainer}>
            {riskProfileScore <= 6 && riskProfileScore >= 10 ?
                (<div>
                    <h3 className={classes.questionHeader}>Your Financial Risk Score is Conservative!</h3>
                    <p className={classes.questionDescription}>
                        A Conservative investor values protecting principal over seeking appreciation. This investor
                        is comfortable accepting lower returns for a higher degree of liquidity and/or stability. Typically, a Conservative
                        investor primarily seeks to minimize risk and loss of principal.
                    </p>
                </div>
                ) :
                (null)
            }
            {riskProfileScore >= 11 && riskProfileScore <= 16 ?
                (<div>
                    <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderately Conservative!</h3>
                    <p className={classes.questionDescription}>
                        A Moderately Conservative investor values principal preservation, but is
                        comfortable accepting a small degree of risk and volatility to seek some degree of appreciation. This investor
                        desires greater liquidity, is willing to accept lower returns, and is willing to accept minimal losses.
                    </p>
                </div>
                ) :
                (null)
            }
            {riskProfileScore >= 17 && riskProfileScore <= 21 ?
                (<div>
                    <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderate!</h3>
                    <p className={classes.questionDescription}>
                        A Moderate investor values reducing risks and enhancing returns equally. This investor is willing
                        to accept modest risks to seek higher long-term returns. A Moderate investor may endure a short-term loss of
                        principal and lower degree of liquidity in exchange for long-term appreciation.
                    </p>
                </div>
                ) :
                (null)
            }
            {riskProfileScore >= 22 && riskProfileScore <= 26 ?
                (<div>
                    <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderately Aggressive!</h3>
                    <p className={classes.questionDescription}>
                        A Moderately Aggressive investor primarily values higher long-term returns and is
                        willing to accept significant risk. This investor believes higher long-term returns are more important than protecting
                        principal. A Moderately Aggressive investor may endure large losses in favor of potentially higher long-term
                        returns. Liquidity may not be a concern to a Moderately Aggressive investor.
                    </p>
                </div>
                ) :
                (null)
            }
            {riskProfileScore >= 27 ?
                (<div>
                    <h3 className={classes.questionHeader}>Your Financial Risk Score is Aggressive!</h3>
                    <p className={classes.questionDescription}>
                        An Aggressive investor values maximizing returns and is willing to accept substantial risk. This
                        investor believes maximizing long-term returns is more important than protecting principal. An Aggressive investor
                        may endure extensive volatility and significant losses. Liquidity is generally not a concern to an Aggressive
                        investor.
                    </p>
                </div>
                ) :
                (null)
            }
        </div >
    );
}

Results.propTypes = {
    uid: PropTypes.string,
    classes: PropTypes.object.isRequired,
    photoURL: PropTypes.string,
    user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth, user, profile }) => {
    const { uid } = auth.user || {};
    const { editDialogOpen, photoDialogOpen } = profile;
    console.log("useruser: ", user.user)
    return {
        uid,
        user: user.user,
        profileURL: user.user.profileURL,
        editDialogOpen,
        photoDialogOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Results);
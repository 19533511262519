import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { NavLink, useParams } from "react-router-dom";
import firebase, { db, } from "firebase/fbConfig.js";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';


// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Radar from 'react-d3-radar';
import Table from "components/Table/Table.jsx";
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';


import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';


export function Results(props) {
    const { classes } = props;
    const { docID } = useParams();
    const [scores, setScores] = useState()
    const [allData, setAllData] = useState({
        riskAversion: 0,
        riskSensationSeeking: 0,
        objectivePerceivedControl: 0,
        nonObjectivePerceivedControl: 0,
        contentSpecificRisk: 0,
        situationalSpecificRisk: 0,
        relationshipCommitment: 0,
        trust: 0,
        communicationEffectivesness: 0
    })
    function getScores() {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                const sortedResults = [];
                const items = [];
                db.collection('users').doc(user.uid).collection('quizzes').doc('wealthManagement').collection('quizLists').doc(docID)
                    .get()
                    .then((queryResult) => {
                        sortedResults.push(queryResult.data().riskAversion, queryResult.data().riskSensationSeeking, queryResult.data().objectivePerceivedControl,
                            queryResult.data().nonObjectivePerceivedControl, queryResult.data().contentSpecificRisk, queryResult.data().situationalSpecificRisk, queryResult.data().relationshipCommitment,
                            queryResult.data().trust, queryResult.data().communicationEffectivesness)
                        sortedResults.sort((a, b) => b - a)
                        setAllData({
                            riskAversion: queryResult.data().riskAversion,
                            riskSensationSeeking: queryResult.data().riskSensationSeeking,
                            objectivePerceivedControl: queryResult.data().objectivePerceivedControl,
                            nonObjectivePerceivedControl: queryResult.data().nonObjectivePerceivedControl,
                            contentSpecificRisk: queryResult.data().contentSpecificRisk,
                            situationalSpecificRisk: queryResult.data().situationalSpecificRisk,
                            relationshipCommitment: queryResult.data().relationshipCommitment,
                            trust: queryResult.data().trust,
                            communicationEffectivesness: queryResult.data().communicationEffectivesness
                        })
                        console.log('sortedResults', sortedResults)
                        setScores(sortedResults[0])
                    })
            }
        })
    }
    useEffect(() => {
        getScores();
    }, []);
    console.log('sortedResults', scores, allData)
    return (
        <div className={classes.quizContainer}>
            {scores == allData.riskAversion ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Risk Aversion!</h3>
                    <p className={classes.questionDescription}>
                        This means you tend to avoid situations that lack certainty or
                        feel like a gamble. You prefer low risk- or risk-free circumstances where you typically know the
                        outcome
                    </p>
                </div>) : (null)
            }
            {scores == allData.riskSensationSeeking ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Risk Sensation Seeking!</h3>
                    <p className={classes.questionDescription}>
                        Woo-hoo! You scored high in risk sensation seeking meaning you seek out risks in the hope of
                        higher rewards. The rewards you seek would not have been gained if not for the risk or have
                        taken more a much greater time.
                    </p>
                </div>) : (null)
            }
            {scores == allData.objectivePerceivedControl ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Objective Perceived Control!</h3>
                    <p className={classes.questionDescription}>
                        This means you prefer to be involved in actions
                        that directly affect you and believe your actions have an ability to impact your future. Others
                        who score low in this have low levels of interest in being involved in decisions surrounding them
                        because they believe they lack control over their fate and future.
                    </p>
                </div>) : (null)
            }
            {scores == allData.contentSpecificRisk ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Content Specific Risk Perception!</h3>
                    <p className={classes.questionDescription}>
                        Incoming news – you scored high in content specific risk perception. This means you tend to
                        seek out and/or listen to information that comes from unconventional sources including social
                        media, blogs, and other opinion-based resources.
                    </p>
                </div>) : (null)
            }
            {scores == allData.situationalSpecificRisk ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Situational Specific Risk Perception!</h3>
                    <p className={classes.questionDescription}>
                        News alert – you scored high in situational specific risk perception. This means you make
                        decisions based on information gathered from a wide variety of trusted and credible sources.
                    </p>
                </div>) : (null)
            }
            {scores == allData.relationshipCommitment ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Relationship Commitment!</h3>
                    <p className={classes.questionDescription}>
                        You scored high in relationship commitment which means you tend to remain loyal to those you
                        have worked with. You enjoy building relationships over time and sticking with people.
                    </p>
                </div>) : (null)
            }
            {scores == allData.trust ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Trust!</h3>
                    <p className={classes.questionDescription}>
                        People like you who score high in trust believe, in general, that the people they choose to work
                        with are competent and reliable.
                    </p>
                </div>) : (null)
            }
            {scores == allData.communicationEffectivesness ?
                (<div>
                    <h3 className={classes.questionHeader}>You scored highest in Communication Effectiveness!</h3>
                    <p className={classes.questionDescription}>
                        A high score in communication effectiveness means you are able to communicate reliably with
                        others and share necessary information in a timely fashion with others.
                    </p>
                </div>) : (null)
            }
        </div >
    );
}

Results.propTypes = {
    uid: PropTypes.string,
    classes: PropTypes.object.isRequired,
    photoURL: PropTypes.string,
    user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth, user, profile }) => {
    const { uid } = auth.user || {};
    const { editDialogOpen, photoDialogOpen } = profile;
    console.log("useruser: ", user.user)
    return {
        uid,
        user: user.user,
        profileURL: user.user.profileURL,
        editDialogOpen,
        photoDialogOpen
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Results);
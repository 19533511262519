import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { NavLink } from "react-router-dom";

const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class SecondQuestion extends Component {

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.secondQuestion) {
            this.props.nextStep();
        }
    }


    render() {
        const { values, handleToggle, classes } = this.props;
        return (
            <div className={classes.financialKnowledgeQuizContainer}>
                <h4 className={classes.questionHeader}>Financial Knowledge Assessment</h4>
                <p className={classes.questionDescription}>Imagine that the interest rate on your savings account is 1% a year and inflation is 2% a year. After one year, would the money in the account buy more than it does today, eactly the same, or less than today?</p>
                <GridContainer className={classes.checkboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        onClick={() => handleToggle('secondQuestion', 'incorrect1')}
                                        defaultValue={values.secondQuestion}
                                        checked={values.secondQuestion === 'incorrect1'}
                                    /> </ThemeProvider>}
                            label='More'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('secondQuestion', 'incorrect2')}
                                    defaultValue={values.secondQuestion}
                                    checked={values.secondQuestion === 'incorrect2'}
                                />}
                            label='Same'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('secondQuestion', 'correct')}
                                    defaultValue={values.secondQuestion}
                                    checked={values.secondQuestion === 'correct'}
                                />}
                            label='Less'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>


                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>1 / 6</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>



                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        );
    }
}

export default withStyles(Style)(SecondQuestion);
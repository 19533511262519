import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js"

// react component used to create a calendar with events on it
import BigCalendar from "react-big-calendar";

// dependency plugin for react-big-calendar
import moment from "moment";

// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
// import OfferSlider from "views/Offers/OfferSlider.jsx";

// core components

import AddIcon from "@material-ui/icons/Add";
import CreateInvestmentForm from "../../components/Forms/CreateInvestmentForm";
import calendarStyle from "assets/jss/material-dashboard-pro-react/views/calendarStyle.jsx";
import { events } from "variables/general.jsx";
import CalendarChild from "./CalendarChild";

const localizer = BigCalendar.momentLocalizer(moment);

const Transition = (props) => {
    return <Slide direction="down" {...props} />
  }

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: events,
      newInvestmentModal: false,
      alert: null,
      volUID: '',
      username: '',

    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  selectedEvent(event) {
    alert(event.title);
  }
  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Input something"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      )
    });
  }
  addNewEvent(e, slotInfo) {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });
    this.setState({
      alert: null,
      events: newEvents
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  handleClickOpen = (modal, volUID) => {
      console.log("triggered")
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = (uid) => {

    let data =  db.collection("TEST-users").doc(uid).collection("registeredCourses")
    .get().then((snapshot) => {
      const data = []
      // Parse data into array like in firebase.js
      snapshot.forEach((doc) => {
        var docData = doc.data()
        var docObj = {
          id: doc.id,
          title: docData.title ? docData.title : "N/A",
          start: docData.start ? moment(docData.start.toDate()).format('MM-DD-YYYY HH:mm') : new Date(),
          end: docData.end ? moment(docData.end.toDate()).format('MM-DD-YYYY HH:mm') : new Date(),
        }
        data.push(docObj);
      })
      console.log(data)
      return (data)
    }, (error) => {
            console.log('error fetching data: ', error)
        }).then(data => {
            this.setState({
                data: data,
                dataLoaded: true
            })
        })
    //console.log(this.state)
    //let relatedConceptsData = this.props.relatedConcepts.dataRows

    // this.setState({
    //   relatedConceptsData,
    //   dataLoaded: true
    // })
}

  render() {
    const { AuthBool, authUser, classes, user, courses } = this.props;
    if (AuthBool) {
      if (!this.state.dataLoaded) {
          // this.loadData(authUser.uid)
      }
  }

    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth();
    var d = today.getDate();
    console.log(new Date(y, m, 1))

    const events = [
      {
        title: "All Day Event",
        //allDay: true,
        start: new Date(y, m, 1),
        //end: new Date(y, m, 1),
        //color: "default"
      },
      {
        title: "Meeting",
        start: new Date(y, m, d - 1, 10, 30),
        //end: new Date(y, m, d - 1, 11, 30),
        //allDay: false,
        //color: "green"
      }
    ];
    //console.log(courses.dataRows)
    //console.log(events)
    console.log(this.state)
    return (
      <div>
          { AuthBool ?
        <CalendarChild uid={authUser.uid}/> :
        null}
        <div style={{margin:"65px"}}></div>
              <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.handleClickOpen("newInvestmentModal")}>
                <AddIcon />
              </Fab>
              {/* <OfferSlider/> */}
              {/* NEW EVENT MODAL */}
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal + " " + classes.modalLarge
                }}
                open={this.state.newInvestmentModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("newInvestmentModal")}
                aria-labelledby="shipment-modal-slide-title"
                aria-describedby="shipment-modal-slide-description"
              >

                <DialogContent
                  id="shipment-modal-slide-description"
                  className={classes.eventForm}
                  style={{paddingLeft: "0", paddingRight: "0"}}
                >
                <Button
                  style={{
                      margin: "3.5% 1% 0% 0%",
                      color: "black",
                    }}
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("newInvestmentModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>

                  {/* FORM */}
                  <CreateInvestmentForm />
                </DialogContent>
              </Dialog>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.user.user,
    events: state.events.events
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //startSetEventsList: dispatch(startSetEventsList())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(calendarStyle)
)(Calendar);

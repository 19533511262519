import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { Link } from 'react-router-dom';


// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};


class ChatThreads extends React.Component {


  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  changeMessageSize = (size) => {
    this.setState({
      messageSize: size,
    });
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const {
      classes,
      currentInvestment,
      currentInvestmentThreads,
      currentInvestmentOwner,
      pushInvestmentAndThread
    } = this.props;
    const searchButton = classes.top + ' ' + classes.searchButton;

    console.log("ChatInvestmentsDetailsMap", this.props);
    console.log(currentInvestment)
    return (
      <div>
        <div>
          <Card style={{ marginBottom: '10px' }} className={classes.investmentCards}>
            <CardBody>
              <span style={{ color: '#315B7B' }}>
                <b>Owner:</b>
              </span>
              <GridContainer>
                <GridItem xs={3}>
                  <img
                    src={
                      currentInvestmentOwner.profileURL !== 'N/A'
                        ? currentInvestmentOwner.profileURL
                        : 'https://firebasestorage.googleapis.com/v0/b/wealth-env.appspot.com/o/default-avatar.png?alt=media&token=1402931b-2c70-4833-80ce-7a877a8b501b'
                    }
                    style={{ borderRadius: '50%', height: '40px' }}
                    alt="..."
                  />
                </GridItem>
                <GridItem xs={8}>
                  <p style={{ fontSize: '18px', marginBottom: '0px' }}>
                    <b>
                      {' '}
                      {' ' +
                        currentInvestmentOwner.firstName +
                        ' ' +
                        currentInvestmentOwner.lastName +
                        ' '}
                    </b>
                  </p>
                  <p style={{ fontSize: '14px', marginBottom: '0px' }}>
                    {currentInvestmentOwner.city}, {currentInvestmentOwner.state}
                  </p>
                </GridItem>
                <GridItem xs={11}>
                  <div
                    style={{
                      backgroundColor: '#264653',
                      padding: '10px',
                      borderRadius: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <p style={{ fontSize: '16px', marginBottom: '0px' }}>
                      {currentInvestmentOwner.title}
                    </p>
                  </div>
                </GridItem>
              </GridContainer>
              <p style={{ color: '#315B7B', marginTop: '10px' }}>
                <b>Investment:</b>
              </p>
              <p>
                <i>
                  Investment Number: <b>#{currentInvestment.id}</b>
                  <br />
                  Investment Type: <b>{currentInvestment.investmentType}</b>
                  <br />
                  Service Needed: <b>{currentInvestment.serviceType}</b>
                  <br />
                  {/* Budget: <b>$150/hr</b> */}
                </i>
              </p>
            </CardBody>
          </Card>
          <p style={{ color: '#315B7B', marginTop: '10px' }}>
            <b>Threads:</b>
          </p>

          {currentInvestmentThreads &&
            currentInvestmentThreads.map((investorThread) => {
              const documents = investorThread.documents || []
              return (
                <div key={investorThread.tid}>
                  <Link
                    to={{
                      pathname: `/${currentInvestment.cid}/investorthreads/${investorThread.tid}`
                    }}
                  >
                    <Card>
                      <CardHeader>
                        <GridContainer>
                          <GridItem xs={3}>
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/wealth-env.appspot.com/o/Screen%20Shot%202019-10-19%20at%2010.46.43%20AM.png?alt=media&token=7b9f98cf-f886-41d9-90d5-f09a3b4f9a6e"
                              style={{ borderRadius: '20%', height: '75px' }}
                              alt="..."
                            />
                          </GridItem>
                          <GridItem xs={8}>
                            <i>ID: {investorThread.tid}</i>
                            <p style={{ fontSize: '16px', marginBottom: '0px' }}>
                              <b>{investorThread.name} </b>{' '}
                            </p>
                            <p style={{ color: '#0db2d8', marginBottom: '0px' }}>
                              <small>{documents.length} document{documents.length === 1 ? '' : 's'} attached </small>
                            </p>
                            <p style={{ marginBottom: '0px' }}>
                              <small>
                                {' '}
                                {moment(investorThread.createdAt.toDate()).format(
                                  'MM-DD-YYYY'
                                )}
                              </small>
                            </p>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                    </Card>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if (state.investments.currentInvestment) {
    return {
      currentInvestment: state.investments.currentInvestment,
      currentInvestmentOwner: state.user.users.dataRows.find(row => row.uid === state.investments.currentInvestment.owner) || {},
      currentInvestmentThreads: state.threads.threads.dataRows.filter(row => row.cid === state.investments.currentInvestment.cid)
    };
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(Style)
)(ChatThreads);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PlaidLink from 'components/PlaidLink/PlaidLink.jsx';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "85%",
  height: "50%",
  background: "linear-gradient(180deg, #4672FB -16.67%, #73E8E3 147.45%)",
  border: 'none',
  borderRadius: "40px",
  fontFamily: "Archivo",
  color: "#FFFFFF",
  textAlign: "center",
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
  padding: "3rem",
  pt: 2,
  px: 4,
  pb: 3,
};

const deskStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  height: "50%",
  marginLeft: "8rem",
  background: "linear-gradient(180deg, #4672FB -16.67%, #73E8E3 147.45%)",
  border: 'none',
  borderRadius: "40px",
  fontFamily: "Archivo",
  color: "#FFFFFF",
  textAlign: "center",
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
  padding: "3rem",
  paddingTop: "10rem",
  pt: 2,
  px: 4,
  pb: 3,
};

const PlaidModal = ({ user, open, handleClose, desktop }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        style={desktop ? deskStyle : style}
      >
        <h2 style={{ fontWeight: 700, fontSize: "21px", margin: "0 0 2rem 0" }}>Help us help you</h2>
        <p style={{ margin: "1rem 0"}}>You don't have any assets or financial information saved yet.</p>
        <p style={{ margin: "1rem 0"}}>Unlock the best WealthEnV has to offer by manually saving your details or integrating seamlessly with Plaid now!</p>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Link to="/add-asset-form">
            <button onClick={()=>console.log('navigate to forms')} style={{
              height: "36px",
              borderRadius: "44px",
              background: "#FFFFFF",
              border: "none",
              color: "#274B73",
              boxShadow: "0px 0px 4px rgba(0 0 0 / 25%)",
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: "16px",
              marginTop: "1rem",
              width: "15rem"
            }}>Manually Enter Information</button>
          </Link>
          <PlaidLink userId={user.id} />
        </div>
      </Box>
    </Modal>
  )
}

export default PlaidModal;
import { LOCATION_CHANGE } from 'connected-react-router';
import { pathToRegexp } from 'path-to-regexp';

// Orders Data
const threads = {
  columns: [
    {
      Header: 'TITLE',
      accessor: 'title',
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
    },
    {
      Header: 'IMAGE',
      accessor: 'image',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
    {
      Header: 'CATEGORY',
      accessor: 'category',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
  ],
  dataRows: [],
  dataLoaded: false,
};

export const modelThread = {
  threadName: 'N/A',
  createdDate: 'N/A',
  messages: [],
  invitations: {},
  documents: [],
};

export const initState = {
  threads,
  currentThread: undefined,
};

const investorThreadPath = pathToRegexp('/:cid/investorThreads/:tid');
const threadPath = pathToRegexp('/threads/:tid');
const threadPermissionsPath = pathToRegexp('/threadshare/:tid');

function tidFromPath(path) {
  let match = investorThreadPath.exec(path);
  if (match) return match[2];
  match = threadPath.exec(path);
  if (match) return match[1];
  match = threadPermissionsPath.exec(path);
  if (match) return match[1];
  return undefined;
}

function updateDocuments(documents, index, data) {
  documents = [...documents];
  documents[index] = data;
  return documents;
}

export default (state = initState, action) => {
  const { threads, currentThread } = state;
  switch (action.type) {
    case 'SET_ONE_ON_ONE_MESSAGE_THREAD':
      var toReturn = { ...state };
      toReturn.oneOnOneMessages = action.oneOnOneMessages;
      toReturn.otherAttendeeInfo = action.otherAttendeeInfo
      return toReturn;

    case 'SET_GROUP_MESSAGE_THREAD':
      var toReturn = { ...state };
      toReturn.groupMessageThread = action.groupMessages;
      return toReturn;

    case 'THREADS_ADD':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: [
            ...threads.dataRows,
            { ...modelThread, ...action.data, tid: action.id },
          ],
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? { ...currentThread, ...action.data }
            : currentThread,
      };
    case 'THREADS_MODIFY':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.map((row) =>
            row.tid === action.id ? { ...modelThread, ...action.data } : row
          ),
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? { ...currentThread, ...action.data }
            : currentThread,
      };
    case 'THREADS_ADD_MESSAGE':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.map((row) =>
            row.tid === action.id
              ? { ...row, messages: [...row.messages, action.data] }
              : row
          ),
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? {
              ...currentThread,
              messages: [...(currentThread.messages || []), action.data],
            }
            : currentThread,
      };
    case 'THREADS_SET_INVITATION':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.map((row) =>
            row.tid === action.id
              ? {
                ...row,
                invitations: {
                  ...(row.invations || {}),
                  [action.email]: action.data,
                },
              }
              : row
          ),
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? {
              ...currentThread,
              invitations: {
                ...(currentThread.invitations || []),
                [action.email]: action.data,
              },
            }
            : currentThread,
      };
    case 'THREADS_REMOVE_INVITATION':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.map((row) =>
            row.tid === action.id
              ? {
                ...row,
                invitations: { ...row.invitations, [action.email]: undefined },
              }
              : row
          ),
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? {
              ...currentThread,
              invitations: {
                ...currentThread.invitations,
                [action.email]: undefined,
              },
            }
            : currentThread,
      };
    case 'THREADS_SET_DOCUMENT':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.map((row) =>
            row.tid === action.id
              ? {
                ...row,
                documents: updateDocuments(
                  row.documents,
                  action.fileNo,
                  action.data
                ),
              }
              : row
          ),
        },
        currentThread:
          currentThread && currentThread.tid === action.id
            ? {
              ...currentThread,
              documents: updateDocuments(
                currentThread.documents,
                action.fileNo,
                action.data
              ),
            }
            : currentThread,
      };
    case 'THREADS_REMOVE':
      return {
        ...state,
        threads: {
          columns: threads.columns,
          dataLoaded: true,
          dataRows: threads.dataRows.filter((row) => row.tid !== action.id),
        },
      };
    case 'THREADS_SET_CURRENT':
      return {
        ...state,
        currentThread: threads.dataRows.find((row) => row.tid === action.id),
      };
    case 'THREADS_UPDATE_CURRENT':
      return {
        ...state,
        currentThread: action.replace
          ? { ...action.data }
          : { ...currentThread, ...action.data },
      };
    case LOCATION_CHANGE:
      const tid = tidFromPath(action.payload.location.pathname);
      return {
        ...state,
        currentThread:
          currentThread && currentThread.tid === tid
            ? currentThread
            : threads.dataRows.find((row) => row.tid === tid) ||
            (tid && { ...modelThread, tid }),
      };

    default:
      return state;
  }
};

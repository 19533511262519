import { db, storage } from 'firebase/fbConfig.js';

export const handleProfilePicSubmission = (file, userInfo, history) => {
  return (dispatch) => {
    if (file) {
      const storageRef = storage.ref().child(`profilePics/${userInfo.uid}/`);
      storageRef.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          return db
            .collection('users')
            .doc(userInfo.uid)
            .update({
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              phoneNumber: userInfo.phoneNumber,
              profileURL: downloadURL,
            })
            .then(() => {
              history.push('/profile');
            });
        });
      });
    } else {
      return db
        .collection('users')
        .doc(userInfo.uid)
        .update({
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phoneNumber: userInfo.phoneNumber,
        })
        .then(() => {
          history.push('/profile');
        });
    }
  };
};

// Get Users for Users Panel
export function listenToUsersForUser(store, uid) {
  return db.collection('users').onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const data = change.doc.data();
      const id = change.doc.id;
      switch (change.type) {
        case 'added':
          store.dispatch(addUser(id, data));
          break;
        case 'modified':
          store.dispatch(modifyUser(id, data));
          break;
        case 'removed':
          store.dispatch(removeUser(id));
          break;
        default:
          console.warn('Uknown change type', change.type);
          break;
      }
    });
  });
}

export function startSetUser(uid, listeners) {
  return async (dispatch) => {
    let stocks = await db.collection('users').doc(uid)
      .collection('favorite-stocks')
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => doc.data());
      });
    return db
      .collection('users')
      .doc(uid)
      .get()
      .then((snapshot) => {
        dispatch(setUser({ ...snapshot.data(), id: uid, stocks: stocks }, listeners));
      });
  };
}

export function startSetUserQuiz(uid, listeners) {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('quizzes')
      .doc('fiveFactorQuiz')
      .get()
      .then((snapshot) => {
        dispatch(setUser({ ...snapshot.data(), id: uid }, listeners));
      });
  };
}

export function startSetUserMatches(uid, listeners) {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(uid)
      .collection('matches')
      .get()
      .then((snapshot) => {
        snapshot = snapshot.docs;
        const matchesArray = snapshot.map((doc) => doc.data());
        dispatch(setUserMatches(matchesArray));
      });
    // const matchesArray = data.docs.map(doc => doc.data())
    // .then((snapshot) => {
    // dispatch(setUserMatches(matchesArray));
    // });
  };
}

export function setUserMatches(matches) {
  return {
    type: 'SET_USER_MATCHES',
    matches,
  };
}

export function startUpdateUser(uid, data) {
  console.log('startUpdateUser>>>', uid, data);
  return (dispatch) => {
    console.log('dattaaaaaaaaaxxxx', data);
    dispatch(setTempUserInfo(data.phone, data.firstName, data.lastName, data.email));
    return db.collection('users').doc(uid).set(data, { merge: true });
  };
}

export const setTempUserInfo = (phone, firstName, lastName, email) => {
  return {
    type: 'SET_TEMP_USER_INFO',
    phone,
    firstName,
    lastName,
    email,
  };
};

export function addUser(id, data) {
  return {
    type: 'USERS_ADD',
    data,
    id,
  };
}

export function addUserListener(id, listener) {
  return {
    type: 'USERS_ADD_LISTENER',
    id,
    listener,
  };
}

export function modifyUser(id, data) {
  return {
    type: 'USERS_MODIFY',
    data,
    id,
  };
}

export function removeUser(id) {
  return {
    type: 'USERS_REMOVE',
    id,
  };
}

export function setUser(data, listeners) {
  return {
    type: 'USERS_SET_USER',
    data,
    listeners,
  };
}

import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';

// @material-ui/icons
import Close from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import Loader from 'components/Loader';

import Style from 'assets/jss/material-dashboard-pro-react/views/threadsStyle';

import PermissionsMap from './PermissionsMap';
import SolicitationDialog from './SolicitationDialog';

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class Permissions extends React.Component {

  render() {
    const { classes, ...mapProps } = this.props;

    return (
      <div>
          <div>
            <SolicitationDialog/>
            <PermissionsMap {...mapProps}/>
          </div>
      </div>
    );
  }
}

Permissions.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentObject: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  threads: PropTypes.array.isRequired,
  investments: PropTypes.array.isRequired,
  sendInvite: PropTypes.func.isRequired,
  role: PropTypes.string,
  warningMap: PropTypes.func.isRequired
};

export default withStyles(Style)(Permissions);

import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class FirstQuestion extends Component {

    nextStep = (e, values) => {
        e.preventDefault();
        if (values.firstQuestion !== 0) {
            this.props.nextStep();
        }
    }

    render() {
        const { values, handleToggle, classes } = this.props;
        return (
            <div className={classes.demographicQuizContainer}>

                <h4 className={classes.questionHeader}>Demographics Assessment</h4>
                <p className={classes.questionDescription}>Which of these describes your personal income last year?</p>
                <GridContainer className={classes.checkboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={<ThemeProvider theme={theme}>
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 1)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 1}
                                /></ThemeProvider>}
                            label='$0'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 2)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 2}
                                />}
                            label='$1 to $9,999'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 3)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 3}
                                />}
                            label='$10,000 to $24,999'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 4)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 4}
                                />}
                            label='$25,000 to $49,999'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 5)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 5}
                                />}
                            label='$50,000 to $74,999'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 6)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 6}
                                />}
                            label='$75,000 to $99,000'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 7)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 7}
                                />}
                            label='$100,000 to $149,000'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 8)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 8}
                                />}
                            label='$150,000 and greater'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 9)}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 9}
                                />}
                            label='Prefer not to answer'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>

                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <Link to='/quizzes'>
                                    <ArrowLeft className={classes.arrowButton} />
                                </Link>
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>0 / 9</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>


                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
            </div>
        );
    }
}

export default withStyles(Style)(FirstQuestion);
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useHistory } from 'react-router';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import TokenTransactionItem from 'components/TokenTransactionItem';
import Loader from 'components/Loader';
import Style from 'assets/jss/material-dashboard-pro-react/views/walletPageStyle';

import WalletCoinImage from 'assets/img/wealth-env/coins white logo single 1.png';


import Person from 'assets/img/wealth-env/person.svg';
import Camera from 'assets/img/wealth-env/camera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';

import Slider from "@material-ui/core/Slider";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const completedMuiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#FFFF",
            },
            track: {
                color: "#FFFF",
                height: "15px !important",
            },
            rail: {
                color: "#4672FB",
                height: "5px !important",
            }
        }
    }
});


const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            root: {
                padding: "13px 0",
            },
            thumb: {
                color: "#4672FB",
            },
            track: {
                backgroundImage: 'linear-gradient(161.29deg, #4672FB -4.43%, #75E8E2 61.61%)',
                height: "15px !important",
            },
            rail: {
                color: "rgb(154 212 204)",
                height: "15px !important",
            }
        }
    }
});


// images
// import Token from 'assets/img/arcq/arq-token.png';

const bestInClassMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '2.5K',
    },
    {
        value: 50,
        label: '5k',
    },
    {
        value: 75,
        label: '7.5K',
    },
    {
        value: 100,
        label: '10K',
    },
];

const quizzesMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50,
        label: '1',
    },
    {
        value: 100,
        label: '2',
    },
];

const clubChatMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 20,
        label: '1',
    },
    {
        value: 40,
        label: '2',
    },
    {
        value: 60,
        label: '3',
    },
    {
        value: 80,
        label: '4',
    },
    {
        value: 100,
        label: '5',
    },
];

const yieldMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 10,
        label: '1',
    },
    {
        value: 20,
        label: '2',
    },
    {
        value: 30,
        label: '3',
    },
    {
        value: 40,
        label: '4',
    },
    {
        value: 50,
        label: '5',
    },
    {
        value: 60,
        label: '6',
    },
    {
        value: 70,
        label: '7',
    },
    {
        value: 80,
        label: '8',
    },
    {
        value: 90,
        label: '9',
    },
    {
        value: 100,
        label: '10',
    },
];


// function valuetext(value) {
//     return `${value}°C`;
// }
function valueLabelFormat(value) {
    return bestInClassMarks.findIndex((mark) => mark.value === value) + 1;
}


const WalletPage = ({ uid, classes, events }) => {
    const [earned, spent] = events.reduce(
        (r, v) => {
            const fee = v.type === 'fee';
            r[fee ? 1 : 0] += v.tokens;
            return r;
        },
        [0, 0]
    );

    const history = useHistory();

    const rewardNames = [{ name: 'Best In Class', link: 'best-in-class', descriptor: 'Earn a total of 10k XP' }, { name: 'Market Master', link: 'market-master', descriptor: 'Complete two quizzes' }, { name: 'Trustful Trustee', link: 'trustful-trustee', descriptor: 'Be a part of five investment club chats' }, { name: 'High Yield Investment', link: 'high-yield- investment', descriptor: 'Reach a 10 day streak' }]


    if (!uid) return <Loader />

    return (
        <div>
            <GridContainer className={classes.container}>
                <GridItem className={classes.totalPoints} xs={12} sm={12} md={12}>
                    <Card style={{ marginBottom: "0px" }} className={classes.topCard}>
                        <CardBody style={{ padding: "0px" }}>
                            <div className={classes.topCardBody}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img className={classes.coinImage} src={WalletCoinImage} />
                                    <p className={classes.availXp}>Available XP</p>
                                </div>
                                <p className={classes.xp}>{earned - spent}</p>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <Card onClick={() => history.push('/rewards')} className={classes.midCard}>
                        <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                            <span>Earn</span>
                            <ArrowRightAltIcon />
                        </div>
                    </Card>
                </GridItem>
                <GridItem xs={6}>
                    <Card className={classes.midCard}>
                        <div style={{ display: 'flex', width: "100%", justifyContent: "space-between" }}>
                            <span>Redeem</span>
                            <ArrowRightAltIcon />
                        </div>
                    </Card>
                </GridItem>
            </GridContainer>

            <GridContainer className={classes.copyContainer}>
                <GridItem xs={12}>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <span className={classes.referFriends}>Refer your friends and gain 500 XP!</span>
                        <Card className={classes.copyCard}>
                            <span>
                                https://www.weathenv/23
                            </span>
                            <Button className={classes.copyBtn}>
                                Copy
                            </Button>
                        </Card>
                    </div>
                </GridItem>
            </GridContainer>

            <GridContainer>
                {rewardNames.map((d) => {
                    return (
                        <Card className={classes.cardForReward}>
                            <GridContainer className={classes.cardForQuizContainer}>
                                <GridItem xs={8}>
                                    <div style={{ display: "flex", flexDirection: 'column' }}>
                                        <span className={classes.rewardDescriptor}>{d.descriptor}</span>
                                        {/* {d.data.length > 0 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ fontSize: "75%" }}>{d.name}</span>
                                                <Check />
                                            </div>
                                        ) : (
                                            <span style={{ fontSize: "75%" }}>{d.name}</span>
                                        )} */}
                                        <span className={classes.rewardName}>{d.name}</span>
                                        <ThemeProvider theme={completedMuiTheme}>
                                            <Slider
                                                className={classes.slider}
                                                aria-label="Restricted values"
                                                step={null}
                                                valueLabelDisplay="auto"
                                                disabled={true}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </GridItem>
                                <GridItem xs={4}>
                                    <Button onClick={() => history.push(`/${d.link}`)} className={classes.buttonToReward}>
                                        <ArrowRightAltIcon />
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </Card>
                    )
                })}
            </GridContainer>

            {/* <GridContainer className={classes.container}>
                <GridItem className={classes.totalPoints} xs={12} sm={12} md={12}>
                    <Card style={{ marginTop: "0px" }} className={classes.topCard}>
                        <CardHeader>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h5 className={classes.totalPointsText} style={{ fontWeight: 500 }}>
                                    Achievements
                                </h5>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.mainAchievementsColumn}>
                                <div className={classes.achievementRow}>
                                    <div className={classes.leftSectionBestInClass}>
                                        <img className={classes.trophyImg} src={Trophy} />
                                    </div>
                                    <div className={classes.rightSection}>
                                        <h5 className={classes.sectionTitle}>Best In Class</h5>
                                        <p>Earn 10,000 XP</p>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                className={classes.slider}
                                                aria-label="Restricted values"
                                                defaultValue={25}
                                                valueLabelFormat={valueLabelFormat}
                                                // getAriaValueText={valuetext}
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={bestInClassMarks}
                                                disabled={true}
                                            />
                                        </ThemeProvider>

                                    </div>
                                </div>

                                <div className={classes.achievementRow}>
                                    <div className={classes.leftSectionMarketMaster}>
                                        <img className={classes.img} src={Question} />
                                    </div>
                                    <div className={classes.rightSection}>
                                        <h5 className={classes.sectionTitle}>Market Master</h5>
                                        <p>Complete 2 Quizzes</p>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                className={classes.slider}
                                                aria-label="Restricted values"
                                                defaultValue={50}
                                                valueLabelFormat={valueLabelFormat}
                                                // getAriaValueText={valuetext}
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={quizzesMarks}
                                                disabled={true}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>

                                <div className={classes.achievementRow}>
                                    <div className={classes.leftSectionTrustfulTrustee}>
                                        <img className={classes.trustfulImg} src={Chat} />
                                    </div>
                                    <div className={classes.rightSection}>
                                        <h5 className={classes.sectionTitle}>Trustful Trustee</h5>
                                        <p>Participate in 5 Investment Club Chats</p>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                className={classes.slider}
                                                aria-label="Restricted values"
                                                defaultValue={80}
                                                valueLabelFormat={valueLabelFormat}
                                                // getAriaValueText={valuetext}
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={clubChatMarks}
                                                disabled={true}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>


                                <div className={classes.achievementRow}>
                                    <div className={classes.leftSectionYieldInvestment}>
                                        <img className={classes.yieldImg} src={Time} />
                                    </div>
                                    <div className={classes.rightSection}>
                                        <h5 className={classes.sectionTitle}>High Yield Investment</h5>
                                        <p>Reach a 10 day streak</p>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                className={classes.slider}
                                                aria-label="Restricted values"
                                                defaultValue={20}
                                                valueLabelFormat={valueLabelFormat}
                                                // getAriaValueText={valuetext}
                                                step={null}
                                                valueLabelDisplay="auto"
                                                marks={yieldMarks}
                                                disabled={true}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>



                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer> */}


            {/* <GridContainer style={{ padding: '0px 20px' }}>
                <Card style={{ backgroundColor: '#4775FB' }}>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                <h4
                                    style={{
                                        color: 'white',
                                        fontSize: '30px',
                                        fontWeight: '600',
                                        display: 'inline-block',
                                        marginRight: '5px',
                                    }}
                                    className={classes.cardTitle}
                                >
                                    {earned - spent}{' '}
                                </h4>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        color: 'white',
                                        fontWeight: '600',
                                        fontSize: '25px',
                                    }}
                                >
                                    {' '}
                                    Current Points
                                </span>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridContainer>
            <GridContainer style={{ marginTop: '-20px', padding: '0px 20px' }}>
                <GridItem xs={4} sm={4} md={4}>
                    <Card style={{ backgroundColor: '#4775FB', padding: '5%' }}>
                        <CardHeader color="rose" icon>
                            <h4
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                }}
                                className={classes.cardTitle}
                            >
                                <b>{earned - spent}</b>
                            </h4>
                            <p
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                }}
                            >
                                <small>Earned</small>
                            </p>
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                    <Card style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", padding: '5%' }}>
                        <CardHeader color="info" icon>
                            <h4
                                style={{
                                    color: '#FFF',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                }}
                                className={classes.cardTitle}
                            >
                                <b>{Math.floor(Math.random() * 100 + 1)}%</b>
                            </h4>
                            <p
                                style={{
                                    color: '#FFF',
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                }}
                            >
                                <small>Quizzes Taken</small>
                            </p>
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                    <Card style={{ backgroundColor: '#4775FB', padding: '5%' }}>
                        <CardHeader color="rose" icon>
                            <h4
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '10px',
                                }}
                                className={classes.cardTitle}
                            >
                                <b>{spent}</b>
                            </h4>
                            <p
                                style={{
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                }}
                            >
                                <small>Spent</small>
                            </p>
                        </CardHeader>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ margin: '0px 20px 0px 20px' }}>
                <GridItem xs={12}>
                    <h3
                        style={{ margin: '0px 0px 0px -10px', fontSize: '22px', color: "#000" }}

                    >
                        Transactions
                    </h3>
                </GridItem>
            </GridContainer>
            {events
                .filter((item) => item.type === 'reward' || item.type === 'fee')
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((item) => (
                    <TokenTransactionItem
                        key={item.createdAt}
                        label={item.label}
                        tokens={item.tokens}
                        isReward={item.type === 'reward'}
                    />
                ))}
            <GridContainer style={{ margin: '0px 20px 80px 20px' }}>
                <GridItem xs={12} sm={12} md={12}>
                    <Link
                        to={{
                            pathname: '/rewards-fees',
                        }}
                    >
                        <Button
                            style={{
                                width: '96%',
                                margin: '15px 0px 0px -8px',
                                padding: '18px 20px',
                                borderRadius: '10px',
                                fontSize: '16px',
                                backgroundColor: '#4775FB',
                                textTransform: 'capitalize',
                            }}
                        >
                            Learn How You Can Earn More WealthEnV Utilities
                        </Button>
                    </Link>
                </GridItem>
            </GridContainer> */}
        </div>
    );
};

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {};
    return { uid, user: user.user, events: user.user.events };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

WalletPage.defaultProps = {
    events: [],
};

WalletPage.propTypes = {
    uid: PropTypes.string,
    classes: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            tokens: PropTypes.number.isRequired,
        })
    ),
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(WalletPage);


import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { Redirect } from 'react-router-dom';

// Preloader
import Loader from "assets/img/preloader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/ffpologo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";



import Style from "assets/jss/material-dashboard-pro-react/views/financialAptitudeStyle";


import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"
import TakeAssessmentForm from "components/Forms/takeAssessmentForm.jsx"
// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class FinancialAptitude extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: true,
            updateProfileModal: false,
            updatePhotoModal: false
        };
    }

    handleClickOpen = (modal) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadData = () => {
        this.setState({
            dataLoaded: true
        })
    }



    render() {
        const { classes, user } = this.props;

        // if (user.dataLoaded
        //     && !this.state.dataLoaded) {
        //     this.loadData()
        // }

        // if (user.termGroups) {
        //     console.log(user.termGroups.length)

        //     if (user.termGroups.length < 2) {
        //         return <Redirect to='/profile' />
        //     }
        // }


        return (
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={10} md={10}>
                        <h3>Welcome to the Lead from Purpose Organizational Self-Assessment.</h3>
                        <h4>You’ve taken the first step toward building a more purposeful organization.</h4>
                        <p>This exercise is designed to take you through a series of questions to help you learn the key practices to building a purposeful organization that engages and compels your workers, your community, and the world.
                            Please choose the best answer that describes the current state of your organization as you perceive it and not what you may think is the "right" answer to each question.
                            This will help you to customize your journey with the Foundation for Purposeful Organizations and access the resources with the most leverage to transform and optimize your organization around purpose.
                            This assessment will take around 30 minutes to complete.</p>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <TakeAssessmentForm
                                        user={this.props.user}
                                        uid={this.props.authUser.uid}
                                        modalOpen={this.state.updateProfileModal}
                                    />
                                </GridItem>
                            </GridContainer>

                        

                            {/* <div className={classes.easeInOut}>
                                <Hidden smDown implementation="css">
                                    <div className={classes.preloaderDesktop}
                                        style={{
                                            background: "white",
                                            position: "absolute",
                                            width: "110%",
                                            margin: "-25% 0% 0% -8%",
                                            height: "180%",
                                            zIndex: "99999",
                                        }}
                                    >
                                        <img
                                            style={{
                                                margin: "30% 0% 0% 33%",
                                                position: "relative",
                                                width: "25%"
                                            }}
                                            src={Logo}
                                            alt="..."
                                        />
                                        <img
                                            style={{
                                                margin: "2% 0% 0% 38%",
                                                position: "relative",
                                                width: "20%"
                                            }}
                                            src={Loader}
                                            alt="..."
                                        />
                                    </div>
                                </Hidden>
                                <Hidden mdUp implementation="css">
                                    <div className={classes.preloaderMobile}
                                        style={{
                                            background: "white",
                                            position: "absolute",
                                            width: "110%",
                                            margin: "-30% 0% 0% -10%",
                                            height: "110%",
                                            zIndex: "9999",
                                        }}
                                    >
                                        <img
                                            style={{
                                                margin: "30% 0% 0% 20%",
                                                position: "relative",
                                                width: "50%"
                                            }}
                                            src={Logo}
                                            alt="..."
                                        />
                                        <img
                                            style={{
                                                margin: "15% 0% 0% 38%",
                                                position: "relative",
                                                width: "20%"
                                            }}
                                            src={Loader}
                                            alt="..."
                                        />
                                    </div>
                                </Hidden>
                            </div> */}
                    
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        authUser: state.auth.user,
        user: state.profile.user
    }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserScore: (score) => dispatch(setUserScore(score))
//   }
// }

export default compose(
    connect(mapStateToProps),
    withStyles(Style)
)(FinancialAptitude);
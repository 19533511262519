export const initState = {
  stocks: {
    data: []
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_STOCKS':
      console.log('SET_STOCKS');
      return {
        ...state,
        stocks: {
          ...state.stocks,
          data: action.stockData,
          dataLoaded: true,
        }
      };
    case 'SET_STOCK_NEWS':
      console.log('SET_STOCK_NEWS');
      return {
        ...state,
        stockNews: {
          ...state.stockNews,
          [action.symbol]: action.stockNews
        }
      }
    case 'SET_STOCK_INFO':
      console.log('SET_STOCK_INFO');
      return {
        ...state,
        stockInfo: {
          ...state.stockInfo,
          [action.symbol]: action.stockInfo
        }
      }
    default:
      return state;
  }
};
import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"
import moment from 'moment'
import { Link } from 'react-router-dom'

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";
import Accordion from "components/Accordion/Accordion.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";
import { Grid } from "@material-ui/core";

// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

// Firebase Redux Integration

const Transition = (props) => {
    return <Slide direction="down" {...props} />
}

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            assignActionModal: false,
            volUID: '',
            username: ''
        };
    }

    handleClickOpen = (modal, volUID) => {
        var x = [];
        x[modal] = true;
        this.setState({
            ...x,
            volUID
        });
    }

    handleClose = (modal) => {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    loadData = () => {
        let usersData = this.props.users.dataRows.map((prop, key) => {
            let colObj = { ...prop }
            const volUID = colObj.volUID
            const username = colObj.username
            // Load Data for Summary Cards and add buttons     
            return {
                ...colObj,
                // status: (<span style={{ color: "red" }}>assigned</span>),
                id: key,
                actions: (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={
                            () => this.handleClickOpen("assignActionModal",
                                volUID
                            )}
                    >ASSIGN ACTION</Button>)
            }
        })

        this.setState({
            usersData,
            dataLoaded: true
        })
    }

    handleChange = (docID) => {
        console.log(docID)
    }

    // filterData = (query, investmentsData) => {
    //     if (investmentsData) {
    //       var newArray = investmentsData.filter(function (el) {
    //         return el.investorFirstName.includes(query)
    //       });
    //       console.log(newArray);
    //           this.setState({
    //             filteredInvestmentsData: newArray,
    //             dataFiltered:
    //           })

    //     } 

    // }

    assignAdvisor = (advisorID, firstName, lastName, email) => {
        //console.log(advisorID)
        this.props.onAssignAdvisor(advisorID, firstName, lastName, email)
    }

    render() {
        const { AuthBool, classes, advisors, query } = this.props;
        //console.log(advisors.dataRows)
        const searchButton =
            classes.top +
            " " +
            classes.searchButton;

        if (AuthBool) {
            if (this.props.users.dataLoaded
                && !this.state.dataLoaded) {
                this.loadData()
            }
        }

        let filteredAdvisorsData = []
        //console.log(Array.isArray(advisors.dataRows))
        if (Array.isArray(advisors.dataRows)) {
            if (advisors.dataRows) {
                var newArray = advisors.dataRows.filter(function (el) {
                    return el.firstName.includes(query) || el.firstName.toLowerCase().includes(query) || el.lastName.includes(query) || el.lastName.toLowerCase().includes(query) || el.id.includes(query)
                });
                //console.log(newArray);
                filteredAdvisorsData = newArray
            }
        }
        // if(investments) {this.filterData(query, investments.dataRows)}
        //console.log(investments.dataRows)

        return (
            <div>
                {
                    this.state.dataLoaded ? (
                        <div>
                            {filteredAdvisorsData && filteredAdvisorsData.map(advisorRow => {
                                return (
                                    <div>
                                        <Card>
                                            <CardHeader>
                                                <GridContainer>
                                                    <GridItem xs={4}>
                                                        <img src={(advisorRow.profileURL !== "N/A") ? advisorRow.profileURL : "https://firebasestorage.googleapis.com/v0/b/wealth-env.appspot.com/o/default-avatar.png?alt=media&token=1402931b-2c70-4833-80ce-7a877a8b501b"}
                                                            style={{ borderRadius: "50%", height: "45px", marginTop: "10px" }} alt="..." />
                                                    </GridItem>
                                                    <GridItem xs={8}>{advisorRow.id}
                                                        <h5 style={{ color: "white", fontSize: ".9rem" }}><b>{advisorRow.firstName} {advisorRow.lastName}</b> <br />
                                                            <b>Investment Types:</b> {advisorRow.investmentType} | {advisorRow.city}, {advisorRow.state}<br />
                                                            <b>Experience Level:</b> {advisorRow.experience} years
                                                        </h5>
                                                    </GridItem>
                                                </GridContainer>
                                                <p style={{ color: "#315B7B", fontSize: ".9rem", fontWeight: "900" }}>Specializes in:</p>

                                                <p style={{ color: "#315B7B", fontSize: ".9rem", fontWeight: "900" }}>Services:</p>
                                                <GridContainer >
                                                    <GridItem xs={12}>
                                                        <Button
                                                            color="primary"
                                                            style={{
                                                                backgroundColor: "#3f51b5",
                                                                marginLeft: "10px",
                                                                marginBottom: "10px"
                                                            }}
                                                            onClick={() => this.assignAdvisor(advisorRow.id, advisorRow.firstName, advisorRow.lastName, advisorRow.email)}
                                                        >
                                                            <b>Assign Advisor</b>
                                                        </Button>
                                                    </GridItem>
                                                </GridContainer>


                                                {/*<br/><small> Listed {moment(investmentRow.createdDate.toDate()).format('MM-DD-YYYY')}</small>*/}
                                            </CardHeader>
                                            {/*<CardBody>
                                           <Link to={{
                    pathname: `/investment/${investmentRow.id}`,
                    state: { dataLoaded: false }
                  }}>

                        <p ><span style={{color: "black"}}>Investment Type:</span> <span style={{fontWeight: "900", color:"rgb(133, 0, 85)"}}>{investmentRow.investmentType}</span> </p>
                        <p><span style={{color: "black"}}>Service Needed:</span>  <span style={{fontWeight: "900", color:"rgb(133, 0, 85)"}}>{investmentRow.serviceNeeded}</span></p>
                                             </Link>   

                      <Accordion
                        active={1}
                        collapses={[
                          {
                            title: "Summary",
                            content:
                             investmentRow.summary
                          }
                        ]}
                      />
                    </CardBody>*/}

                                        </Card>
                                    </div>
                                )
                            })}


                        </div>
                    ) : (
                        <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
                            <img
                                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                                src={loader}
                                alt="..."
                            />
                        </div>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        AuthBool: !!state.auth.user,
        users: state.user.users,
        advisors: state.advisors.advisors
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    //console.log(ownProps.uid)
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Users);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import userEvents from 'config/userEvents';
import DoubleButton from 'components/DoubleButton';
import Style from 'assets/jss/material-dashboard-pro-react/views/chatStyle';
import Hidden from '@material-ui/core/Hidden';

const fees = userEvents.filter((e) => e.type === 'fee');
const rewards = userEvents.filter((e) => e.type === 'reward');
const lists = [rewards, fees];
const titles = ['Earn credits by...', 'Spend tokens to...'];
const operators = ['+', '-'];

const RewardsFeesPage = ({ classes }) => {
    const [index, setIndex] = useState(0);

    const onSelect = (ind) => {
        setIndex(ind);
    };

    return (
        <div style={{ marginBottom: '80px' }}>
            {/* <GridContainer style={{ margin: '0px 20px' }}>
                <GridItem xs={12}>
                    <h3
                        style={{ margin: '0px 0px 0px -10px', fontSize: '22px', color: "#000" }}
                        className={classes.title}
                    >
                        {titles[index]}
                    </h3>
                </GridItem>
            </GridContainer> */}
            <Card className={classes.topCard}>
                <GridContainer style={{ margin: '0px 20px' }}>
                    <GridItem xs={12}>
                        <h3
                            style={{ margin: '0px 0px 0px -10px', fontSize: '22px', color: "#000" }}
                            className={classes.title}
                        >
                            {titles[index]}
                        </h3>
                    </GridItem>
                </GridContainer>
                {lists[index].map((item) => (
                    <GridContainer key={item.event} style={{ margin: '10px' }}>
                        <GridItem xs={2}>
                            <Card
                                className={classes.listCard}
                            >
                                <p
                                    style={{
                                        color: 'white',
                                        fontWeight: '800',
                                        fontSize: '18px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {operators[index]} {item.tokens}
                                </p>
                            </Card>
                        </GridItem>
                        <GridItem xs={10}>
                            <Card
                                style={{
                                    position: 'relative',
                                    zIndex: '35',
                                    margin: '9px 0px 0px -45px',
                                    padding: '3px 0px 3px 50px',
                                    backgroundColor: '#4775FB',
                                }}
                            >
                                <CardBody style={{ padding: '0.35rem 18px .35rem 3px' }}>
                                    <h5
                                        style={{
                                            color: 'white',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {item.label}
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ))}
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

RewardsFeesPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Style)(RewardsFeesPage);
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';
const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "black !important",
                '&$checked': {
                    color: "rgba(146,205,145,1) !important",
                },
            },
        },
    },
});
export class FirstQuestion extends Component {


    nextStep = (e, values) => {
        e.preventDefault();
        if (values.firstQuestion) {
            this.props.nextStep();
        }
    }

    render() {
        const { values, handleToggle, classes, compare } = this.props;
        const usersSelection = values[compare]
        return (
            <div className={classes.financialKnowledgeQuizContainer}>
                <h4 className={classes.questionHeader}>Financial Knowledge Assessment</h4>
                <p className={classes.questionDescription}>Suppose you have $100 in a savings account earning 2 percent interest in a year. After five years, how much would you have?</p>
                <GridContainer className={classes.wealthManagementCheckboxContainer}>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        onClick={() => handleToggle('firstQuestion', 'correct')}
                                        defaultValue={values.firstQuestion}
                                        checked={values.firstQuestion === 'correct'}
                                    /></ThemeProvider>}
                            label='More than $102'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 'incorrect1')}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 'incorrect1'}
                                />}
                            label='Exactly $102'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onClick={() => handleToggle('firstQuestion', 'incorrect2')}
                                    defaultValue={values.firstQuestion}
                                    checked={values.firstQuestion === 'incorrect2'}
                                />}
                            label='Less than $102'
                            className={classes.checkboxText}
                        />
                    </GridItem>
                </GridContainer>

                <Hidden smUp>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <div className={classes.arrowButtonsDiv}>
                                <Link to='/quizzes'>
                                    <ArrowLeft className={classes.arrowButton} />
                                </Link>
                                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.quizTotalContainer}>
                        <GridItem className={classes.quizTotalGridItem} xs={12}>
                            <div style={{ color: "black" }} className={classes.graphDiv}>
                                <span>0 / 6</span>
                                <span className={classes.completedText}>Completed</span>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Hidden>

                <Hidden mdDown>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <Button
                                className={classes.nextButton}
                                variant="contained"
                                onClick={(e) => this.nextStep(e, values)}
                            >Next Question</Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.wealthManagementCheckboxContainer}>
                        <GridItem xs={12}>
                            <NavLink to={'/quizzes'}>
                                <Button
                                    className={classes.backButton}
                                    variant="contained"
                                >Back to Quizzes Page</Button>
                            </NavLink>
                        </GridItem>
                    </GridContainer>
                </Hidden>
                {/* <GridContainer>
                    <GridItem xs={12}>
                        <Button
                            className={classes.nextButton}
                            variant="contained"
                            onClick={this.nextStep}
                        >Next Question</Button>
                    </GridItem>
                </GridContainer> */}
                {/* <GridContainer>
                    <GridItem xs={12}>
                        <NavLink to={'/quizzes'}>
                            <Button
                                className={classes.backButton}
                                variant="contained"
                            >Back to Quizzes Page</Button>
                        </NavLink>
                    </GridItem>
                </GridContainer>
                <CustomLinearProgress
                    className={classes.progressBar}
                    variant="determinate"
                    color="primary"
                    value={16.5}
                /> */}
            </div>
        );
    }
}

export default withStyles(Style)(FirstQuestion);
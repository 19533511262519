import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Button from 'components/CustomButtons/Button';
import Loader from 'assets/img/loader.gif';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import style from 'assets/jss/material-dashboard-pro-react/components/videoStyle';

const Lobby = ({
  loaded,
  handleSubmit,
  classes
}) => {
  return (
    <form onSubmit={handleSubmit} className={classes.lobby}>
      <h1 className={classes.lobbyHeader}>Video Conferencing</h1>
      {loaded ?
        <Button
          type="submit"
          style={{ background: "#4775FB", color: "white", borderRadius: "44px", width: "40%" }}
          >Join</Button> :
          <img width="10%" style={{ margin: "10%" }} src={Loader} />
      }
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // nothing yet
});

const mapStateToProps = (state) => {
  // nothing yet
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style)
)(Lobby);
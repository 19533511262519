const groupMessageThreadStyle = (theme) => ({
  container: {
    // border: "1px solid black"
    width: '100%',
    marginBottom: "100px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "150px",
      marginTop: "100px",
    },
  },
  containerCard: {
    boxShadow: '0px 4px 9px grey',
    borderRadius: '15px',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '800px',
    },
  },
  header: {
    margin: '0px',
    padding: '50px',
    color: 'white',
    textAlign: 'center',
    backgroundImage:
      'linear-gradient(180deg, #4775FB, #5B9B9A, #4B828E, #315A7A, #4775FB)',
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  description: {
    color: '#000',
    textAlign: 'center',
  },
  messageDiv: {
    display: 'flex',
    margin: "0px auto",
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxHeight: "100px !important",
      justifyContent: 'end',
      padding: "0px 20px",
      marginBottom: "20px"
    }
  },
  userPicAndInfo: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '30px',
  },
  otherSenderPicAndInfo: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '30px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    }
  },
  nameOfSender: {
    textAlign: 'center',
    color: "black"
  },
  recommendationsPic: {
    borderRadius: '50%',
    height: '50px',
    width: '50px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      height: '64px',
      width: '64px',
    },
  },
  userTextBubble: {
    width: '80%',
    margin: '50px auto',
    background: '#4775FB',
    padding: '20px',
    fontWeight: '900',
    color: 'white',
    fontFamily: 'arial',
    position: 'relative',
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  otherSenderTextBubble: {
    width: '80%',
    margin: '50px auto',
    background: '#E8E8E8',
    padding: '20px',
    fontWeight: '900',
    color: 'black',
    fontFamily: 'arial',
    position: 'relative',
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      margin: '15px',
    },
  },
  loaderDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {},
  formContainer: {
    backgroundColor: 'white',
    color: 'black',
    zIndex: '50',
    borderRadius: '10px',
    width: '110%',
    height: '13vh',
    padding: '10px 30px',
    marginTop: '20px',
    boxShadow: '0px 0px 43px -22px rgba(0,0,0,0.75)',
    position: 'fixed',
    bottom: '66px',
    marginLeft: '-11px',
    [theme.breakpoints.up('md')]: {
      padding: '0px 50px 10px 40px',
      marginLeft: '-40px',
      width: '115%',
      height: '11vh',
      bottom: '0px',
      padding: '10px 10px 60px 10px',
    },
  },
  formGridItem: {
    [theme.breakpoints.up('md')]: {
      padding: '0px !important',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "15px !important",
      display: 'flex',
      alignItems: "center"
    },
  },
  sendBtn: {
    backgroundColor: '#4775FB',
    [theme.breakpoints.up('md')]: {
      marginTop: '20px',
      marginLeft: '10px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: "0px"
    },
  },
});

export default groupMessageThreadStyle;

import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "rgba(146,205,145,1) !important",
        },
      },
    },
  },
});
export class FirstQuestions extends Component {

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.extravertedEnthusiastic) {
      this.props.nextStep();
    }
  }

  render() {
    const { values, handleToggle, classes, compare } = this.props;
    const usersSelection = values[compare]
    return (
      <div className={classes.fiveFactorQuizContainer}>
        <h4 className={classes.questionHeader}>Financial Aptitude and Behavioral Finance</h4>
        <p className={classes.questionDescription}>
          Here are a number of personality traits that may or may not apply to you.
          Please select an option to each statement to indicate the extent to which you agree or disagree with that statement.
          You should rate the extent to which the pair of traits applies to you, even if one characteristic applies more strongly than the other.
        </p>
        <h4 className={classes.questionHeader}>I see myself as:</h4>
        <h3 className={classes.questionHeader}>Extraverted, enthusiastic</h3>
        <GridContainer className={classes.checkboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <ThemeProvider theme={theme}>
                  <Checkbox
                    color='secondary'
                    onClick={() => handleToggle('extravertedEnthusiastic', 1)}
                    checked={usersSelection === 1 ? true : false}
                  />
                </ThemeProvider>}
              label='Disagree strongly'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 2)}
                  checked={usersSelection === 2 ? true : false}
                />}
              label='Disagree moderately'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 3)}
                  checked={usersSelection === 3 ? true : false}
                />}
              label='Disagree a little'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 4)}
                  checked={usersSelection === 4 ? true : false}
                />}
              label='Neither agree nor disagree'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 5)}
                  checked={usersSelection === 5 ? true : false}
                />}
              label='Agree a little'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 6)}
                  checked={usersSelection === 6 ? true : false}
                />}
              label='Agree moderately'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('extravertedEnthusiastic', 7)}
                  checked={usersSelection === 7 ? true : false}
                />}
              label='Agree strongly'
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>

        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <Link to='/quizzes'>
                  <ArrowLeft className={classes.arrowButton} />
                </Link>
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>0 / 10</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>





        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>
        {/*
        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={10}
        /> */}
      </div>
    );
  }
}

export default withStyles(Style)(FirstQuestions);
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.jsx";
import { connect } from "react-redux";
import compose from "recompose/compose";
import styles from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx";
import { ReactComponent as DiamondIcon } from 'assets/img/diamond.svg';
import { ReactComponent as ExpenseIcon } from 'assets/img/expense.svg';
import { ReactComponent as GroupIcon } from 'assets/img/group.svg';
import PlaidIcon from 'assets/img/plaid icon.png';
const useStyles = makeStyles(styles);

function AddButtonContainerDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (param) => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };
  const {
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret,
    hoverColor,
    dropPlacement,
    rtlActive,
    noLiPadding,
    innerDropDown,
    navDropdown,
    adminNavbar,
    logout,
    history,
    openPlaid
  } = props;
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretDropup]: dropup && !anchorEl,
    [classes.caretActive]: Boolean(anchorEl) && !dropup,
    [classes.caretRTL]: rtlActive,
  });
  const dropdownItem = classNames({
    [classes.addButtonDropdownItem]: true,
    [classes[hoverColor + "Hover"]]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });
  const dropDownMenu = (
    <MenuList role="menu" className={classes.section}>
      {dropdownList.map((prop, key) => {
        return (
          <MenuItem
            key={key}
            value={prop}
            className={dropdownItem}
            onClick={(e) => {
              console.log('value', prop)
              if (prop === "Asset") {
                history.push('/add-asset-form')
              }
              if (prop === "Expense") {
                history.push('/add-expense-form')
              }
              if (prop === "Group") {
                history.push('/add-group-form')
              }
              if (prop === "Plaid") {
                openPlaid();
              }
              handleCloseMenu(prop);
            }}
          >
            <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
              {prop === "Asset" && (
                <DiamondIcon />
              )}
              {prop === "Expense" && (
                <ExpenseIcon />
              )}
              {prop === "Group" && (
                <GroupIcon />
              )}
              {prop === "Plaid" && (
                <img src={PlaidIcon} style={{ height: "1.5rem" }}/>
              )}
              <span>{prop}</span>
            </div>
          </MenuItem>
        );
      })}
    </MenuList>
  );
  return (
    <div
      style={adminNavbar ? { width: "1px" } : {}}
      className={innerDropDown ? classes.innerManager : classes.manager}
    >
      <div className={buttonText !== undefined ? "" : classes.target}>
        <Button
          style={
            adminNavbar
              ? { color: "black", background: "transparent", boxShadow: "none" }
              : {}
          }
          aria-label="Notifications"
          aria-owns={anchorEl ? "menu-list" : null}
          aria-haspopup="true"
          {...buttonProps}
          onClick={handleClick}
        >
          {buttonIcon !== undefined ? (
            <props.buttonIcon className={classes.buttonIcon} />
          ) : null}
          {buttonText !== undefined ? buttonText : null}
          {caret ? <b className={caretClasses} /> : null}
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={dropPlacement}
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
          [classes.popperNav]: Boolean(anchorEl) && navDropdown,
        })}
      >
        {innerDropDown ? (
          dropDownMenu
        ) : (
          <ClickAwayListener onClickAway={handleClose}>
            {dropDownMenu}
          </ClickAwayListener>
        )}
        {/* {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={
              dropup
                ? { transformOrigin: "0 100% 0" }
                : { transformOrigin: "0 0 0" }
            }
          >
            <Paper className={classes.dropdown}>
              {innerDropDown ? (
                dropDownMenu
              ) : (
                <ClickAwayListener onClickAway={handleClose}>
                  {dropDownMenu}
                </ClickAwayListener>
              )}
            </Paper>
          </Grow>
        )} */}
      </Popper>
    </div>
  );
}

AddButtonContainerDropdown.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: "#FFC720",
};

AddButtonContainerDropdown.propTypes = {
  hoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.object,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end",
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    // nothing yet
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AddButtonContainerDropdown);

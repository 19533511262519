import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db } from "firebase/fbConfig.js"

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Fab from "@material-ui/core/Fab";


// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import loader from "assets/img/loader.gif";
import miniLoading from "assets/img/simple_loading.gif";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Search from "@material-ui/icons/Search";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx";
import { Grid } from "@material-ui/core";

import ExploreMap from "./ExploreMap"
// Forms
import AssignActionForm from "components/Forms/AssignActionForm.jsx"

import ExploreMapAssignAdvisor from "./ExploreMapAssignAdvisor";
import ExploreMapAssignInvestor from "./ExploreMapAssignInvestor";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentModal: false,
      dataLoaded: false,
      assignActionModal: false,
      volUID: '',
      username: '',
      assignInvestorQuery: 'asdfasdf'
    };
  }

  handleClickOpen = (modal, volUID) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      volUID
    });
  }

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  loadData = () => {
    let usersData = this.props.users.dataRows.map((prop, key) => {
      let colObj = { ...prop }
      const volUID = colObj.volUID
      const username = colObj.username
      // Load Data for Summary Cards and add buttons     
      return {
        ...colObj,
        // status: (<span style={{ color: "red" }}>assigned</span>),
        id: key,
        actions: (
          <Button
            color="primary"
            fullWidth
            onClick={
              () => this.handleClickOpen("assignActionModal",
                volUID
              )}
          >ASSIGN ACTION</Button>)
      }
    })

    this.setState({
      usersData,
      dataLoaded: true
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    //console.log(this.state)
  }

  assignAdvisor = (advisorID, firstName, lastName, email) => {
    this.setState({ advisorID: advisorID });
    this.props.onAssignAdvisor(advisorID, firstName, lastName, email)
  }


  render() {
    const { AuthBool, classes, user, investments } = this.props;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton;

    if (AuthBool) {
      if (this.props.users.dataLoaded
        && !this.state.dataLoaded) {
        this.loadData()
      }
    }

    return (
      <div>
        {
          this.state.dataLoaded ? (
            <div>
              <GridContainer style={{ backgroundColor: "#264653" }} justify="center">
                {/*<GridItem xs={2}>
              <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
          </GridItem>*/}
                <GridItem xs={12}>
                  <Card
                    style={{
                      margin: "0"
                    }}
                  >
                    <div style={{ width: "95%", margin: "0 auto", marginTop: "0px", marginBottom: "0px" }}>
                      <Search style={{ color: "#bbb", marginBottom: "-8px", marginRight: "10px", fontSize: "1.25rem" }} className={classes.headerLinksSvg + " " + classes.searchIcon} />

                      <CustomInput
                        formControlProps={{
                          className: classes.top + " " + classes.search,
                          style: { width: "85%", paddingTop: "10px" }
                        }}
                        id="assignInvestorQuery"
                        inputProps={{
                          placeholder: "Search...",
                          onChange: (event) => this.handleChange(event),
                          inputProps: {
                            "aria-label": "Search",
                            className: classes.searchInput
                          }
                        }}
                      />

                    </div>
                  </Card>
                </GridItem>
                <GridItem xs={12}>
                  <ExploreMapAssignInvestor uid={user.uid} query={this.state.assignInvestorQuery} onAssignAdvisor={this.assignAdvisor} />
                </GridItem>

              </GridContainer>

              {/* <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.handleClickOpen("newInvestmentModal")}>
                <AddIcon />
              </Fab> */}

              {/* NEW INVESTMENT MODAL */}
              {/* <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal + " " + classes.modalLarge
                }}
                open={this.state.newInvestmentModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.handleClose("newInvestmentModal")}
                aria-labelledby="shipment-modal-slide-title"
                aria-describedby="shipment-modal-slide-description"
              >

                <DialogContent
                  id="shipment-modal-slide-description"
                  className={classes.modalBody}
                  style={{paddingLeft: "0", paddingRight: "0"}}
                >
                <Button
                  style={{
                      margin: "3.5% 1% 0% 0%",
                      color: "black",
                    }}
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.handleClose("newInvestmentModal")}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button> */}

              {/* FORM */}
              {/* <CreateInvestmentForm /> */}
              {/* </DialogContent> */}
              {/* </Dialog> */}
            </div>

          ) : (
            <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
              <img
                style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
                src={loader}
                alt="..."
              />
            </div>)
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    users: state.user.users,
    user: state.auth.user,
    investments: state.investments.investments,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log(this.props)
  return {
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Explore);

import React from 'react';
import { connect } from 'react-redux';

import Permissions from 'views/Components/Permissions';
import { startInvitation } from 'store/actions/threadsActions';


const mapStateToProps = (state, ownProps) => {
  return {
    id: state.threads.currentThread.tid,
    user:  state.user.user,
    users: state.user.users.dataRows,
    investments: state.investments.investments.dataRows,
    threads: state.threads.threads.dataRows,
    currentObject: state.threads.currentThread,
    role: 'collaborator'
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendInvite: (tid, email, data) => dispatch(startInvitation(tid, email, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);

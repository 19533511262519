import {
  defaultFont,
  primaryColor,
  dangerColor,
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx";

const videoStyle = {
  lobby: {
    margin: "2%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  lobbyHeader: {
    fontFamily: 'NOVA',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "105.8%",
    color: "#4672FB"
  },
  room: {
  },
  participants: {
    marginRight: "2%"
  },
  localParticipant: {
    minWidth: "100%"
  },
  remoteParticipants: {

  },
  participantList: {

  },
  remoteList: {

  },
  participantName: {
    position: "absolute",
    top: '80px',
    right: "80px",
    color: "#4775FB",
    fontSize: "50px",
    fontWeight: 800,
    zIndex: 2,
  },
  smallParticipantName: {
    position: "absolute",
    top: "65%",
    left: "22%",
    fontSize: "90%",
    fontWeight: 800,
    color: "#4775FB"
  },
  participant: {
    position: "relative"
  },
  smallParticipant: {
    position: "relative",
    margin: "2%",
    zIndex: 2,
  },
  activeSpeaker: {

  },
  video: {
    width: "100%"
  },
  screenShare: {
    width: "100%",
  },
  controls: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    display: "flex",
    padding: "0 23%",
    marginBottom: "1%",
    zIndex: 1
  },
  logout: {

  },
  largeVideo: {
    width: "100%",
    position: "fixed"
  },
  screenSharedVideo: {
    position: "absolute",
    zIndex: 1,
    width: "25%",
    marginTop: "36%",
    marginLeft: "1%"
  },
  '@media (max-width: 1024px)': {
    room: {
      flexDirection: "column"
    },
    controls: {
      padding: 0,
      zIndex: 10000,
      background: "white"
    },
    participantName: {
      position: "absolute",
      top: "75%",
      left: "4%",
      color: "#4775FB",
      fontSize: "x-large",
      fontWeight: 800
    },
    smallParticipantName: {
      position: "absolute",
      top: "63%",
      fontSize: "90%",
      fontWeight: 800,
      color: "#4775FB"
    }
  },
  gridItem: {
    padding: '0px !important'
  },
  participantsList: {
    fontFamily: "Nova",
    color: "#474747",
    fontSize: "40px !important",
    letterSpacing: "2px",
    margin: "0px",
    textAlign: 'center'
  }
};
export default videoStyle;

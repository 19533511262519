import React from 'react';
import PropTypes from 'prop-types';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';

export const InputInvestmentSummary = ({ onInput }) => (
  <Card style={{ padding: '0 20px' }}>
    <CustomInput
      labelText="Summary"
      id="summary"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        required: true,
        type: 'text',
        disableUnderline: true,
        multiline: true,
        rows: 5,
        onChange: ({ target: { id, value } }) => onInput(id, value),
      }}
    />
  </Card>
);

InputInvestmentSummary.propTypes = {
  onInput: PropTypes.func.isRequired,
};

export default InputInvestmentSummary;

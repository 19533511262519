import React, { useState } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import logo from "assets/img/grayLogo.png";
import { capitalize } from '../../util';
import config from 'config';

import appleLogo from "assets/img/apple-logo.png"

// actions
import { emailSignup, emailSignIn, startGoogleLogin, startFacebookLogin, startTwitterLogin, passwordReset } from "../../store/actions/authActions"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const LoginPage = (props) => {
  const { classes, authError } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordNotice, setPasswordNotice] = useState(false)


  const handlePasswordReset = (e) => {
    e.preventDefault()
    props.passwordReset(email)
    setPasswordNotice("If you email is in our system you will receive a link to reset your password.")
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
          <img src={logo} alt="logo" className={classes.img} />
          <br /> <br />
          <p className={classes.subdescription}>
            <b style={{ color: "black" }}>Let’s get you signed in to WealthEnv</b>
          </p>
          <br />
          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setEmail(e.target.value)}
                id="outlined-basic"
                label="Email"
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>
          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setPassword(e.target.value)}
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                id="outlined-basic"
                label="Password"
                variant="standard"
                type="password"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>
          {authError ? (
            <div className={classes.justifyContentCenter}>
              <p className={classes.textDanger}><br /><b>Error:</b> {authError.message}<br /><br /><b>Did you forget your password?</b></p>
              <Button
                style={{ backgroundColor: "rgb(133, 0, 85)", fontWeight: "600", color: "#FFFFFF" }}
                type="button"
                onClick={handlePasswordReset}
                color="danger"
                simple size="lg"
                block>
                Reset Password
              </Button>
            </div>
          ) : null}
          <div style={{ width: "100%", display: 'flex', justifyContent: "end", color: 'black' }}>
            <span onClick={handlePasswordReset}>Forgot Password?</span>
          </div>
          {passwordNotice ? <p style={{ color: "black" }} className={classes.textDanger}>{passwordNotice}</p> : null}
          <div style={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "30px", marginBottom: "60px" }}>
            <Button onClick={() => {
              const authInfo = { email, password }
              props.emailSignIn(authInfo)
            }} className={classes.signInBtn}>Sign In</Button>
          </div>
          <div style={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "30px", flexDirection: "column" }}>
            <span style={{ color: "black", textAlign: 'center' }}>Or Sign In Using</span>
            <div className={classes.buttonContainer}>
              <div className={classes.externalLoginBtn}>
                <Button
                  justIcon
                  round
                  color="google"
                  onClick={startGoogleLogin}
                >
                  <i className="fab fa-google" />
                </Button>
              </div>
              <div className={classes.externalLoginBtn}>
                <Button
                  justIcon
                  round
                  color="facebook"
                  onClick={startFacebookLogin}
                >
                  <i className="fab fa-facebook" />
                </Button>
              </div>
              <div className={classes.externalLoginBtn}>
                <Button
                  justIcon
                  round
                  color="twitter"
                  onClick={startTwitterLogin}
                >
                  <i className="fab fa-twitter" />
                </Button>
              </div>
              <div className={classes.externalLoginBtn}>
                <Button
                  justIcon
                  round
                // onClick={startGoogleLogin}
                >
                  <img src={appleLogo} className={classes.appleLogo} />
                </Button>
              </div>
            </div>
            <span style={{ color: "black", textAlign: 'center' }}>Don’t have an account? <Link to='/pages/signup-page' className={classes.registerBtn}>Register</Link></span>
          </div>

        </GridItem>
      </GridContainer>
    </div>
  )
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(startGoogleLogin()),
  startFacebookLogin: () => dispatch(startFacebookLogin()),
  startTwitterLogin: () => dispatch(startTwitterLogin()),
  emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
  passwordReset: (email) => dispatch(passwordReset(email))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginPage);

import React, { useState } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";
import { db } from 'firebase/fbConfig.js';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import logo from "assets/img/grayLogo.png";
import { capitalize } from '../../util';
import config from 'config';
import { useHistory, useLocation } from 'react-router-dom'

import appleLogo from "assets/img/apple-logo.png"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// actions
import { emailSignup, emailSignIn, startGoogleLogin, startFacebookLogin, startTwitterLogin, passwordReset } from "../../store/actions/authActions"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { ReactComponent as UserTypeTitle } from 'assets/img/userTypeTitle.svg';
import { default as AdvisorCard } from 'assets/img/advisorCard.svg';
import { default as FaClientCard } from 'assets/img/faClientCard.svg';

import { default as InvestorCard } from 'assets/img/investorCard.svg';



const Transition = (props) => {
  return <Slide direction="down" {...props} />
}
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1
  }
};


const Usertype = (props) => {
  const { classes, authError, uid } = props
  const [usertype, setUserType] = useState('')
  const history = useHistory();


  console.log('clickeddd', uid)
  const handleClick = () => {
    return db.collection('users').doc(uid).update({
      userType: usertype
    }).then(() => {
      history.push("/pages/setup");
    })
  }


  return (
    <div style={{ width: "100%", overflow: "hidden", padding: "30px" }}>
      <UserTypeTitle className={classes.userTypeTitle} />
      <GridContainer className={classes.userTypeGridContainer} justify='center'>
        <GridItem className={classes.userTypeCardGridItem} xs={12}>
          <Carousel
            infinite={true}
            swipeable={true}
            containerClass={classes.carousel}
            dotListClass="custom-dot-list-style"
            itemClass={classes.carouselItems}
            draggable={false}
            showDots={false}
            responsive={responsive}
            arrows={true}
            rtl={true}
          >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", zIndex: 555 }}>
              <img onClick={() => setUserType('advisor')} src={AdvisorCard} className={classes.userTypeCard} />
            </div>
            {/* <div onClick={() => setUserType('fa client')} style={{ display: "flex", flexDirection: "column", alignItems: "center", zIndex: 555 }}>
              <img src={FaClientCard} className={classes.userTypeCard} />
            </div> */}
            <div onClick={() => setUserType('investor')} style={{ display: "flex", flexDirection: "column", alignItems: "center", zIndex: 555 }}>
              <img src={InvestorCard} className={classes.userTypeCard} />
            </div>
          </Carousel>
        </GridItem>
        {usertype ? (
          <GridItem xs={12}>
            <Button onClick={handleClick} style={{ width: "100%", background: "#436CFC", height: "60px", marginTop: "50px" }}>
              Select {usertype}
            </Button>
          </GridItem>
        ) : (
          null
        )}
      </GridContainer>
    </div>
  )
}

Usertype.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.error,
    id: ownProps.match.params.id,
    investmentID: ownProps.match.params.investmentID,
    uid: state.user.user.id
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(startGoogleLogin()),
  startFacebookLogin: () => dispatch(startFacebookLogin()),
  startTwitterLogin: () => dispatch(startTwitterLogin()),
  emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
  passwordReset: (email) => dispatch(passwordReset(email)),
  emailSignup: (authObject) => dispatch(emailSignup(authObject)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(Usertype);

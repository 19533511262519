export default [
    {
        label: 'Joining WealthEnV',
        event: 'join',
        type: 'reward',
        tokens: 1000,
    },
    {
        label: 'Take Video Classs',
        event: 'video-class',
        type: 'reward',
        tokens: 750,
    },
    {
        label: 'Account Aggregation',
        event: 'account-aggregation',
        type: 'reward',
        tokens: 1000,
    },
    {
        label: 'Play a Financial Game',
        event: 'play-game',
        type: 'reward',
        tokens: 350,
    },
    {
        label: 'Social Media Integration',
        event: 'social-integration',
        type: 'reward',
        tokens: 500,
    },
    {
        label: 'Join Investment Club Chat',
        event: 'invenstment-chat',
        type: 'reward',
        tokens: 350,
    },
    {
        label: 'Get WealthEnV Giveaway',
        event: 'giveaway',
        type: 'reward',
        tokens: 150,
    },
    {
        label: 'LOCKED - Use Stock Evaluator',
        event: 'stock-evaluator',
        type: 'reward',
        tokens: 75,
    },
    {
        label: 'LOCKED - Portfolio Checkup',
        event: 'portfolio-checkup',
        type: 'reward',
        tokens: 1250,
    },
    {
        label: 'LOCKED - Advisor Matching',
        event: 'advisor-matching',
        type: 'reward',
        tokens: 1250,
    },
    {
        label: 'Take Video Classs',
        event: 'video-class',
        type: 'fee',
        tokens: 500,
    },
    {
        label: 'Account Aggregation',
        event: 'account-aggregation',
        type: 'fee',
        tokens: 750,
    },
    {
        label: 'Play a Financial Game',
        event: 'play-game',
        type: 'fee',
        tokens: 250,
    },
    {
        label: 'Social Media Integration',
        event: 'social-integration',
        type: 'fee',
        tokens: 0,
    },
    {
        label: 'Join Investment Club Chat',
        event: 'invenstment-chat',
        type: 'fee',
        tokens: 250,
    },
    {
        label: 'Get WealthEnV Giveaway',
        event: 'giveaway',
        type: 'fee',
        tokens: 100,
    },
    {
        label: 'LOCKED - Use Stock Evaluator',
        event: 'stock-evaluator',
        type: 'fee',
        tokens: 50,
    },
    {
        label: 'LOCKED - Portfolio Checkup',
        event: 'portfolio-checkup',
        type: 'fee',
        tokens: 1000,
    },
    {
        label: 'LOCKED - Advisor Matching',
        event: 'advisor-matching',
        type: 'fee',
        tokens: 1000,
    },
];
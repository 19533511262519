import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import SearchBox from 'components/SearchBox';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import Loader from 'components/Loader';
import ButtonCreateInvestment from 'components/ButtonCreateInvestment';
import InvestmentListItem from './InvestmentListItem';

import { pushInvestment } from 'store/actions/navigationActions';

let allInvestments = [];

const Investments = (props) => {
  const { type } = props.match.params;
  const { uid, investments, userType, pushInvestment } = props;
  const [query, setQuery] = useState(null);
  const baseUrl = type ? `/investment/${type}` : '/investment';

  let filteredList = investments;

  if (query) {
    const reg = new RegExp(query, 'i');
    filteredList = filteredList.filter((e) => reg.test(e.name) || reg.test(e.summary));
  }

  if (!type) {
    filteredList = filteredList.filter(e => !e.archived);
  }

  if (type && type == 'archived') {
    filteredList = filteredList.filter(e => e.archived);
  }

  const onSearchInput = (input) => {
    const term = input.trim();
    console.log("Investments search term", term);
    if (term)
      setQuery(term);
    else
      setQuery(null);
  };

  return !uid ? (
    <Loader />
  ) : !investments.length ? (
    <div
      style={{
        fontSize: '20px',
        textAlign: 'center',
      }}
    >
      <b>No investments found</b>
      {<ButtonCreateInvestment />}
    </div>
  ) : (
    <div>
      <GridContainer justify="center">
        <GridItem xs={11}>
          <SearchBox id="investmentsQuery" onInput={onSearchInput} />
        </GridItem>
        <GridItem xs={11}>
          {filteredList.length ? (
            filteredList
              .sort((a, b) => a.createdAt - b.createdAt)
              .map((data) => <InvestmentListItem baseUrl={baseUrl} key={data.cid} data={data} />)
          ) : (
            <div
              style={{
                fontSize: '20px',
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              <b>No investments found</b>
            </div>
          )}
        </GridItem>
        <GridItem xs={11} />
        <GridItem style={{ marginTop: '-40px' }} xs={11}>
          <SnackbarContent
            message={
              <b>
                Welcome to WealthEnV! To get started, click the orange "plus" button
                below to create an investment...
              </b>
            }
            color="info"
          />
        </GridItem>
      </GridContainer>
      {type !== 'archived' && <ButtonCreateInvestment />}
    </div>
  );
};

Investments.propTypes = {
  uid: PropTypes.string,
  investments: PropTypes.array,
  userType: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  console.log(state)
  return {
    uid: state.auth.user.uid,
    investments: state.investments.investments.dataRows,
    userType: state.user.user.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Investments);

// _TODO: Make upload button functionable
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { Link } from 'react-router-dom';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import server from 'services/ServerService';
import Loader from 'components/Loader';
import Button from 'components/CustomButtons/Button.jsx';
import UploadButtonBlue from 'components/ButtonUploadBlue';
import CardInfoBox from 'components/CardInfoBox';
import Style from 'assets/jss/material-dashboard-pro-react/views/actionsStyle';
import MultiFileUploadDialog from 'components/CustomUpload/MultiFileUploadDialog';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { startModifyInvestment } from 'store/actions/investmentsActions';

import { investmentFlow, investmentStatus }  from 'config';

function relatedThreads(threads, cid) {
  return threads ? threads.filter(thread => thread.cid === cid) : []
}

function relatedDocuments(currentInvestment, threads) {
  if (currentInvestment)
    return relatedThreads(threads, currentInvestment.cid)
      .reduce((a,thread)=>a.concat(thread.documents || []), currentInvestment.documents || []);
  else
    return [];
}

function investorInformation(currentInvestment, users) {
  return (currentInvestment && users && users.find(row=>row.uid === currentInvestment.investor));
}

function ownerInformation(currentInvestment, users) {
  return (currentInvestment && users && users.find(row=>row.uid === currentInvestment.owner));
}

const InvestmentDetails = (props) => {
  const { investor, owner, data, relatedDocuments, startModifyInvestment } = props;
  const { id } = props.match.params;

  return data ? (
    <GridContainer justify="center">
      <GridItem xs={11} style={{ margin: '-20px' }}>
        <CardInfoBox>
          Investment Id:
          <b> {' ' + data.cid}</b><br/>
          created:
          <b>{`  ${moment(data.createdAt).format('MM-DD-YYYY')}`}</b>          
        </CardInfoBox>
        <CardInfoBox>
          Investor:
          <b> { investor ? ` ${investor.firstName || 'N/A'} ${investor.lastName || 'N/A'}  ` : 'No investor assigned'}</b>
        </CardInfoBox>
        <CardInfoBox>
          Investment Type:
          <b> {' ' + data.investmentType}</b>
        </CardInfoBox>
        <CardInfoBox>
          Service Needed:
          <b> {' ' + data.serviceType}</b>
        </CardInfoBox>
        <CardInfoBox>
          {' '}
          <b> Summary: </b>
          {' ' + data.summary}
        </CardInfoBox>
        <CardInfoBox>
          Assigned Advisor:
          <b> { data.flow === investmentFlow.ADVISOR_CREATED || (data.status === investmentStatus.ASSIGNED || data.status === investmentStatus.ARCHIVED) ? ` ${owner.firstName || 'N/A'} ${owner.lastName || 'N/A'}  ` : 'No advisor assigned'}</b>
          <br />
        </CardInfoBox>
        <CardInfoBox>
          {' '}
          <b> Investment Files </b>
          <p
            style={{
              width: '90%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          > 
            { relatedDocuments.map((document, key) => 
              <a style={{display: 'block'}} key={key} href={document.url} target="_blank" rel="noopener noreferrer">
                {document.name}
              </a>
            )}
          </p>
        </CardInfoBox>
        <CardInfoBox>
          <div style={{ float: 'left', marginRight: '10px' }}>
            <UploadButtonBlue />
            <MultiFileUploadDialog 
              storageLocation={`investment/${id}`}
              documents={data.documents || []}
              onUpdate={documents=>startModifyInvestment(id, { documents })}
            />
          </div>
          <Link to={{ pathname: `/investmentchat/${id}` }}>
            <Button
              style={{ backgroundColor: '#0db2d8', margin: '0', float: 'left' }}
              onClick={() => console.log('click')}
            >
              Chat
            </Button>
          </Link>
        </CardInfoBox>
      </GridItem>
    </GridContainer>
  ) : (
    <Loader />
  );
};

InvestmentDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  uid: PropTypes.string,
  classes: PropTypes.object.isRequired,
  userType: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return { 
    userType: state.user.user.userType, 
    uid: state.auth.user.uid,
    data: state.investments.currentInvestment,
    relatedDocuments: relatedDocuments(state.investments.currentInvestment, state.threads.threads.dataRows),
    investor: investorInformation(state.investments.currentInvestment, state.user.users.dataRows),
    owner: ownerInformation(state.investments.currentInvestment, state.user.users.dataRows)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startModifyInvestment: (cid, data) => dispatch(startModifyInvestment(cid, data))
  }
}

export default compose(
  connect(
    mapStateToProps, mapDispatchToProps
  ),
  withStyles(Style)
)(InvestmentDetails);

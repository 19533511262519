import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import compose from 'recompose/compose';

import Hidden from '@material-ui/core/Hidden';
import RoomContext from '../../sfu/RoomContext';

import withStyles from '@material-ui/core/styles/withStyles';

import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamIcon from '@material-ui/icons/Videocam';
import Card from 'components/Card/Card.jsx';
import Style from 'assets/jss/material-dashboard-pro-react/views/conferencingPageStyle';



export const CreateCallButton = (props) => {
    const { classes, roomId, mediaPerms } = props;

    const location = useLocation();
    const history = useHistory();

    const sfu = useContext(RoomContext);


    useEffect(() => {
        location.pathname === "/" && history.push(encodeURIComponent(roomId));
    }, [roomId]);

    async function _getMediaPermissions() {
        if (mediaPerms.video || mediaPerms.audio) {
            navigator.mediaDevices.getUserMedia(mediaPerms);
        }
    }

    async function createRoom() {
        if (!roomId) {
            window.alert('Please enter your own Room ID in edit profile.');
        } else {
            await _getMediaPermissions();

            const encodedRoomId = encodeURIComponent(roomId);

            history.push(`/video-conference/?room=${encodeURIComponent(roomId)}`);

            sfu.join({ roomId: encodedRoomId, videoPerms: mediaPerms.video, audioPerms: mediaPerms.audio })
        }
    }

    return (
        <>
            <Hidden smDown implementation="css">
                <Card className={classes.cardContainer} onClick={createRoom}>
                    <div className={classes.iconContainer}>
                        <VideocamIcon className={classes.icon} />
                    </div>
                    <h6 className={classes.cardTitle}>Join Personal Room</h6>
                </Card>
            </Hidden>
            <Hidden mdUp>
                <div className={classes.findMoreButton} onClick={createRoom}>
                    Join Personal Room
                </div>
            </Hidden>
        </>

    );
};

const mapStateToProps = (state) => ({
    authUser: state.auth.user,
    user: state.user.user,
    roomId: state.user.user.roomId,
    users: state.user.users,
    uid: state.auth.user.uid,
    data: state.events.events.data,
    mediaPerms: state.me.mediaPerms
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(Style))(CreateCallButton);

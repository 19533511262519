import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import { routerMiddleware } from 'connected-react-router';
import { ConnectedRouter } from 'connected-react-router';
import logger from 'redux-logger';
import domready from 'domready';
import randomString from 'random-string';
import EventEmitter from 'events';

import { firebase } from './firebase/fbConfig';
// import {
//   startSetActionsList,
//   startSetUsersList,
//   startSetPendingActionsList,
// } from './store/actions/actionsActions';
// import { startSetAssessmentsList } from './store/actions/assessmentActions';
import SFU from './sfu/SFU';
import RoomContext from './sfu/RoomContext';
import rootReducer from './store/reducers/rootReducer';
import indexRoutes from 'routes/index.jsx';
import fbConfig from './firebase/fbConfig';
import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';

import { login } from './store/actions/authActions';
import { listenToInvestmentsForUser } from './store/actions/investmentsActions';
import { listenToThreadsForUser } from './store/actions/threadsActions';
import { listenToNotificationsForUser } from './store/actions/notificationsActions';
import {
  startSetUser,
  startSetUserQuiz,
  startSetUserMatches,
  listenToUsersForUser,
} from './store/actions/userActions';
import { getAccounts } from './store/actions/assetsActions';
import * as serviceWorker from './serviceWorker';
import deviceInfo from './sfu/deviceInfo';

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';

let sfu;

const emitter = new EventEmitter();

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(hist),
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      routerMiddleware(hist),
      logger
    ),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
  )
);

window.STORE = store;

SFU.init({ store });

// FB.getLoginStatus(function (response) {
//   statusChangeCallback(response);
//   console.log(response)
// });

domready(async () => {
  run();
});

async function run() {
  const peerId = randomString({ length: 8 }).toLowerCase();
  const urlParser = new URL(window.location);
  const parameters = urlParser.searchParams;
  const displayName = parameters.get('displayName');
  const produce = parameters.get('produce') !== 'false';
  const consume = parameters.get('consume') !== 'false';

  const forceTcp = parameters.get('forceTcp') === 'true';

  console.log(forceTcp);

  const { pathname } = window.location;

  let basePath = pathname.substring(0, pathname.lastIndexOf('/'));

  if (!basePath) basePath = '/';

  const device = deviceInfo();

  sfu = new SFU({
    peerId,
    device,
    produce,
    forceTcp,
    consume,
  });

  firebase.auth().onAuthStateChanged((user) => {
    console.log('Authstate', user);

    if (user) {
      // Name, email address, and profile photo Url
      store.dispatch(login(user));

      //emits the login event to capture the uid for sfu initiation
      emitter.emit('login');
      // store.dispatch(startSetActionsList(user.uid));
      // ADMIN PANEL ONLY SHOWS IF firebase > TEST-users > Loggedin user document > admin: true
      // store.dispatch(startSetUsersList(user));
      // store.dispatch(startSetAssessmentsList(user.uid));

      const listeners = [
        listenToUsersForUser(store, user.uid),
        listenToInvestmentsForUser(store, user.uid),
        listenToNotificationsForUser(store, user.uid),
        listenToThreadsForUser(store, user.uid),
      ];

      store.dispatch(startSetUser(user.uid, listeners));
      store.dispatch(startSetUserQuiz(user.uid, listeners));
      store.dispatch(startSetUserMatches(user.uid, listeners));
      store.dispatch(getAccounts(user.uid));
      if (hist.location.pathname === '/') {
        hist.push('/profile');
      }
    } else {
      const listeners = store.getState().user.listeners;
      console.log("checking listeners", listeners)
      if (listeners) for (let listener of listeners) listener();
      if(window.location.pathname.includes('/login')){
        hist.push('/pages/login-page');
      }
      else{
        hist.push('/pages/quizpage');
      }
    }
  });

  emitter.on('login', () => {});

  ReactDOM.render(
    <Provider store={store}>
      <RoomContext.Provider value={sfu}>
        <ConnectedRouter history={hist}>
          <Router history={hist}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route path={prop.path} component={prop.component} key={key} />
                );
              })}
            </Switch>
          </Router>
        </ConnectedRouter>
      </RoomContext.Provider>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import moment from "moment";
// core components
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { withRouter } from 'react-router-dom';
import { addQuizXpPoints } from "store/actions/eventsActions"



export class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            riskProfileStatus: ""
        }
    }

    addToFirestore = () => {
        console.log('results pre', this.props.location.state.state.values)
        const values = this.props.location.state.state.values
        return db.collection('global-test-results').doc('risk-profile').get()
            .then((snapshot) => {
                const results = snapshot.data()
                for (var key in results) {
                    const scoresObj = results[key]
                    scoresObj[values[key]]++
                }
                console.log('results post', results)
                return db.collection('global-test-results').doc('risk-profile').update({ ...results }).then(() => {
                    return db.collection("users").doc(this.props.uid).collection("quizzes").doc("riskProfile").collection("quizLists").add({
                        quiz: "Risk Profile",
                        firstQuestion: this.props.location.state.state.values.firstQuestion,
                        secondQuestion: this.props.location.state.state.values.secondQuestion,
                        thirdQuestion: this.props.location.state.state.values.thirdQuestion,
                        fourthQuestion: this.props.location.state.state.values.fourthQuestion,
                        fifthQuestion: this.props.location.state.state.values.fifthQuestion,
                        sixthQuestion: this.props.location.state.state.values.sixthQuestion,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                        quizID: "Placeholder"
                        // riskProfileStatus: this.state.riskProfileStatus
                    })
                        .then((docRef) => {
                            return db.collection("users").doc(this.props.uid).collection("quizzes").doc("riskProfile").collection("quizLists").doc(docRef.id).update({
                                quizID: docRef.id
                            }).then(() => {
                                this.props.addQuizXpPoints(this.props.uid, this.props.events, 'Risk Profile Quiz')
                                this.props.history.push('/risk-profile-success');
                            })
                        })

                })
            })
    }

    assignRiskType = () => {
        if (this.riskProfileScore <= 6 && this.riskProfileScore >= 10) {
            this.setState({
                riskProfileStatus: "Conservative"
            })
                .then(() => {
                    this.addToFirestore()
                })
        }
        else if (this.riskProfileScore >= 11 && this.riskProfileScore <= 16) {
            this.setState({
                riskProfileStatus: "Moderately Conservative"
            })
                .then(() => {
                    this.addToFirestore()
                })
        }
        else if (this.riskProfileScore >= 17 && this.riskProfileScore <= 21) {
            this.setState({
                riskProfileStatus: "Moderate"
            })
                .then(() => {
                    this.addToFirestore()
                })
        }
        else if (this.riskProfileScore >= 22 && this.riskProfileScore <= 26) {
            this.setState({
                riskProfileStatus: "Moderately Aggressive"
            })
                .then(() => {
                    this.addToFirestore()
                })
        }
        else if (this.riskProfileScore >= 27) {
            this.setState({
                riskProfileStatus: "Aggressive"
            })
                .then(() => {
                    this.addToFirestore()
                })
        }
    }

    continue = () => {
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    retakeQuiz = e => {
        e.preventDefault();
        this.props.history.push('/risk-profile')
    }


    render() {

        const { classes } = this.props;

        const riskProfileScore = [
            this.props.location.state.state.values.firstQuestion,
            this.props.location.state.state.values.secondQuestion,
            this.props.location.state.state.values.thirdQuestion,
            this.props.location.state.state.values.fourthQuestion,
            this.props.location.state.state.values.fifthQuestion,
            this.props.location.state.state.values.sixthQuestion,
        ].reduce((accumulatedScore, iteratedScore) => accumulatedScore + iteratedScore, 0)
        return (
            <div className={classes.quizResultsContainer}>
                {console.log("risk profile score: ", riskProfileScore)}
                {riskProfileScore <= 6 && riskProfileScore >= 10 ?
                    (<div>
                        <h3 className={classes.questionHeader}>Your Financial Risk Score is Conservative!</h3>
                        <p className={classes.questionDescription}>
                            A Conservative investor values protecting principal over seeking appreciation. This investor
                            is comfortable accepting lower returns for a higher degree of liquidity and/or stability. Typically, a Conservative
                            investor primarily seeks to minimize risk and loss of principal.
                        </p>
                    </div>
                    ) :
                    (null)
                }
                {riskProfileScore >= 11 && riskProfileScore <= 16 ?
                    (<div>
                        <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderately Conservative!</h3>
                        <p className={classes.questionDescription}>
                            A Moderately Conservative investor values principal preservation, but is
                            comfortable accepting a small degree of risk and volatility to seek some degree of appreciation. This investor
                            desires greater liquidity, is willing to accept lower returns, and is willing to accept minimal losses.
                        </p>
                    </div>
                    ) :
                    (null)
                }
                {riskProfileScore >= 17 && riskProfileScore <= 21 ?
                    (<div>
                        <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderate!</h3>
                        <p className={classes.questionDescription}>
                            A Moderate investor values reducing risks and enhancing returns equally. This investor is willing
                            to accept modest risks to seek higher long-term returns. A Moderate investor may endure a short-term loss of
                            principal and lower degree of liquidity in exchange for long-term appreciation.
                        </p>
                    </div>
                    ) :
                    (null)
                }
                {riskProfileScore >= 22 && riskProfileScore <= 26 ?
                    (<div>
                        <h3 className={classes.questionHeader}>Your Financial Risk Score is Moderately Aggressive!</h3>
                        <p className={classes.questionDescription}>
                            A Moderately Aggressive investor primarily values higher long-term returns and is
                            willing to accept significant risk. This investor believes higher long-term returns are more important than protecting
                            principal. A Moderately Aggressive investor may endure large losses in favor of potentially higher long-term
                            returns. Liquidity may not be a concern to a Moderately Aggressive investor.
                        </p>
                    </div>
                    ) :
                    (null)
                }
                {riskProfileScore >= 27 ?
                    (<div>
                        <h3 className={classes.questionHeader}>Your Financial Risk Score is Aggressive!</h3>
                        <p className={classes.questionDescription}>
                            An Aggressive investor values maximizing returns and is willing to accept substantial risk. This
                            investor believes maximizing long-term returns is more important than protecting principal. An Aggressive investor
                            may endure extensive volatility and significant losses. Liquidity is generally not a concern to an Aggressive
                            investor.
                        </p>
                    </div>
                    ) :
                    (null)
                }
                <GridContainer>
                    <GridItem xs={12}>
                        <Button
                            onClick={this.retakeQuiz}
                            className={classes.backButton}
                            variant="contained"
                        >Retake Quiz</Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <Button
                            className={classes.submitButton}
                            // onClick={this.assignRiskType}
                            onClick={this.addToFirestore}
                        >Submit My Results</Button>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, user }) => {
    const { uid, events } = auth.user || {}
    return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addQuizXpPoints: (uid, events, label) => dispatch(addQuizXpPoints(uid, events, label))
    }
}


export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(Confirm));
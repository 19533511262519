import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateTimePicker from 'react-datetime-picker';
import { Redirect } from 'react-router-dom';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import config from 'config';

// import LockOutline from "@material-ui/icons/LockOutline";
import Check from '@material-ui/icons/Check';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';

import { startUpdateUser } from '../../store/actions/userActions';

import Style from 'assets/jss/material-dashboard-pro-react/views/setupStyle';

class FirstSignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        advertiser: false,
      },
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle,
    });
  };

  setData(data) {
    this.setState((state) => ({
      ...state,
      data: {
        ...state.data,
        ...data,
      },
    }));
  }

  handleChange = (e) => {
    this.setData({
      [e.target.id]: e.target.value,
      email: this.props.authUser.email,
    });
    console.log(this.state);
  };

  handleSelectChange = (e) => {
    this.setData({
      userType: e.target.value,
    });
    console.log(this.state);
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.userType.toLowerCase());
    this.props.startUpdateUser(this.props.authUser.uid, this.state.data);
  };

  onChange = (dob) => this.setData({ dob });

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setData({
      checked: newChecked,
    });
  }

  handleError = (error) => {
    var errorCode = error.code;

    this.setState({
      signupError: true,
      errorCode,
    });
  };

  isAdvertiser = () => {
    if (this.state.advertiser == 'undefined') {
      this.setData({
        advertiser: true,
      });
    } else {
      this.setData({
        advertiser: !this.state.advertiser,
      });
    }
  };

  render() {
    const { classes, startGoogleLogin, authError } = this.props;

    if (!!authError && !this.state.signupError) this.handleError(authError);

    console.log(this.state);

    if (this.props.user.userType) {
      return <Redirect to='/profile' />;
    }
    return (
      <div className={classes.container}>
        <GridContainer justify='center' style={{ marginTop: '20vh' }}>
          <GridItem xs={12} sm={10} md={8} lg={6}>
            {/*   <Wizard
          validate
          color="primary"
          steps={[
            { stepName: "About", stepComponent: Step1, stepId: "about" },
            { stepName: "Account", stepComponent: Step2, stepId: "account" },
            { stepName: "Practice", stepComponent: Step3, stepId: "address" }
          ]}
          title="Create Your Account"
          subtitle=""
          finishButtonClick={e => alert(e)}
        />*/}

            <h2
              className={classes.cardTitle}
              style={{ fontSize: '1.8rem', color: '#333' }}
            >
              Legal{' '}
            </h2>
            {/*<div className={classes.center}>
                  <Button
                    justIcon
                    round
                    color="google"
                    onClick={startGoogleLogin}>
                    <i className="fab fa-google" />
                  </Button>
                  {` `}
    </div>*/}
            <form className={classes.form} onSubmit={this.handleSubmit}>
              {/*Email: {this.props.authUser ? this.props.authUser.email : "loading..."}*/}
              <Card
                style={{
                  paddingLeft: '20px',
                  marginBottom: '0',
                  marginTop: '20px',
                }}
              >
                <CustomInput
                  labelText='First Name'
                  id='firstName'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    disableUnderline: true,
                    onChange: (event) => this.handleChange(event),
                  }}
                />
              </Card>
              <Card
                style={{
                  paddingLeft: '20px',
                  marginBottom: '0',
                  marginTop: '20px',
                }}
              >
                <CustomInput
                  labelText='Last Name'
                  id='lastName'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    disableUnderline: true,
                    onChange: (event) => this.handleChange(event),
                  }}
                />
              </Card>
              <Card
                style={{
                  paddingLeft: '20px',
                  marginBottom: '0',
                  marginTop: '20px',
                }}
              >
                Date of Birth
                <br />
                <DateTimePicker
                  onChange={this.onChange}
                  value={this.state.dob}
                />
              </Card>
              <Card
                style={{
                  paddingLeft: '20px',
                  marginBottom: '0',
                  marginTop: '20px',
                }}
              >
                <CustomInput
                  labelText='Phone Number'
                  id='phone'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    maxLength: '16',
                    type: 'text',
                    disableUnderline: true,
                    onChange: (event) => this.handleChange(event),
                  }}
                />
              </Card>

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor='userType' className={classes.selectLabel}>
                  User Type:
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  required
                  value={this.state.userType}
                  onChange={this.handleSelectChange}
                  inputProps={{
                    id: 'userType',
                    name: 'userType',
                  }}
                >
                  {config.userTypes.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    onClick={() => this.isAdvertiser()}
                    checked={this.state.advertiser}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    color='primary'
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{ label: classes.bodyText }}
                label='Advertiser?'
              />
              {this.state.userType ? (
                <div className={classes.center}>
                  <Button
                    type='submit'
                    //color="primary"
                    style={{ backgroundImage: "linear-gradient(to right, #315B7B,#4775FB)", fontSize: '1rem' }}
                  >
                    <b>Continue</b> <ArrowRightAltIcon />
                  </Button>
                </div>
              ) : (
                'Please fill out form.'
              )}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startUpdateUser: (uid, data) => dispatch(startUpdateUser(uid, data)),
});

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    user: state.user.user,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(FirstSignupPage);

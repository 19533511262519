import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Hidden from '@material-ui/core/Hidden';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { useHistory } from 'react-router';
import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';
import { Link } from 'react-router-dom';

import ChartistGraph from "react-chartist";

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        visibility: "hidden !important",
        color: "#74EAE3",
        '&$checked': {
          color: "#74EAE3",
        },
      },
    },
  },
});
export class EighthQuestionGlobalResults extends Component {



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.disorganizedCareless !== 0) {
      this.props.nextStep();
    }
  }

  render() {
    const { values, handleToggle, classes, globalTestResults, compare } = this.props;
    console.log('MaxNumber', globalTestResults[compare])
    const usersSelection = values[compare]
    const MaxNumber = Math.max.apply(Math, Object.values(globalTestResults[compare]))
    const choseLikeOthers = globalTestResults[compare][usersSelection] === MaxNumber
    console.log('MaxNumber', choseLikeOthers)
    const totalVotes = Object.values(globalTestResults[compare]).reduce((a, b) => a + b, 0)

    const delays2 = 80,
      durations2 = 500;
    const pieChart = {
      data: {
        labels: [`${Math.floor(globalTestResults[compare][2] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][4] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][6] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][8] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][10] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][12] / totalVotes * 100)}%`, `${Math.floor(globalTestResults[compare][14] / totalVotes * 100)}%`],


        series: [Math.floor(globalTestResults[compare][2] / totalVotes * 100), Math.floor(globalTestResults[compare][4] / totalVotes * 100), Math.floor(globalTestResults[compare][6] / totalVotes * 100), Math.floor(globalTestResults[compare][8] / totalVotes * 100), Math.floor(globalTestResults[compare][10] / totalVotes * 100), Math.floor(globalTestResults[compare][12] / totalVotes * 100), Math.floor(globalTestResults[compare][14] / totalVotes * 100),]
      },
      options: {
        height: "230px"
      }
    };

    // const simpleBarChart = {
    //   data: {
    //     labels: [
    //       "Disagree strongly",
    //       "Disagree moderately",
    //       "Disagree a little",
    //       "Neither agree nor disagree",
    //       "Agree a little",
    //       "Agree moderately",
    //       "Agree strongly",
    //     ],
    //     series: [[Math.floor(globalTestResults[compare][1] / totalVotes * 100), Math.floor(globalTestResults[compare][2] / totalVotes * 100), Math.floor(globalTestResults[compare][3] / totalVotes * 100), Math.floor(globalTestResults[compare][4] / totalVotes * 100), Math.floor(globalTestResults[compare][5] / totalVotes * 100), Math.floor(globalTestResults[compare][6] / totalVotes * 100), Math.floor(globalTestResults[compare][7] / totalVotes * 100)]]
    //   },
    //   options: {
    //     seriesBarDistance: 10,
    //     axisX: {
    //       showGrid: false
    //     },
    //     stretch: true
    //   },
    //   responsiveOptions: [
    //     [
    //       "screen and (max-width: 640px)",
    //       {
    //         seriesBarDistance: 5,
    //         // axisX: {
    //         //   labelInterpolationFnc: function(value) {
    //         //     return value[0];
    //         //   }
    //         // }
    //       }
    //     ]
    //   ],
    //   animation: {
    //     draw: function (data) {
    //       if (data.type === "bar") {
    //         data.element.animate({
    //           opacity: {
    //             begin: (data.index + 1) * delays2,
    //             dur: durations2,
    //             from: 0,
    //             to: 1,
    //             easing: "ease"
    //           }
    //         });
    //       }
    //     }
    //   }
    // };

    console.log('totalVotes', choseLikeOthers)

    return (
      // <div style={{ height: "125vh" }} className={classes.quizGlobalResults}>
      <div className={classes.quizGlobalResults125vhPublic}>

        <span style={{
          fontFamily: "Nova", fontStyle: 'normal', fontWeight: 400,
          fontSize: '27px', color: '#4F4F4F', width: "100%"
        }}>Did You Know?</span>

        {choseLikeOthers ? (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}> Most investors agree with you about this question!</span>
        ) : (
          <span style={{
            fontStyle: 'normal', fontWeight: 400,
            fontSize: '20px', color: '#4F4F4F', width: "100%", margin: "15px 0px"
          }}> This is how other investors voted.</span>
        )}

        <ChartistGraph
          data={pieChart.data}
          type="Pie"
          options={pieChart.options}
          style={{ marginLeft: "70px" }}
        />
        {/* <ChartistGraph
          className="profit-chart"
          style={{ width: "365px !important" }}
          data={simpleBarChart.data}
          type="Bar"
          options={simpleBarChart.options}
          responsiveOptions={simpleBarChart.responsiveOptions}
          listener={simpleBarChart.animation}
        /> */}
        <h3 className={classes.questionHeader}>Disorganized, forgetful</h3>
        <GridContainer className={classes.wealthManagementCheckboxContainer} style={{ justifyContent: "center" }}>
          <GridItem xs={6}>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      style={{ display: "hidden" }}
                      defaultChecked={usersSelection === 1 ? true : false}
                      sx={{
                        display: "none",
                        color: "#fff",
                        "&.Mui-checked": {
                          color: "#74EAE3"
                        }
                      }}
                    />
                  </ThemeProvider>}
                label={`Disagree strongly (${Math.floor(globalTestResults[compare][14] / totalVotes * 100)}%)`}
                style={{ color: "#0544d3" }}
                className={classes.checkboxText}
              />

            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 3 ? true : false}
                  />}
                label={`Disagree a little (${Math.floor(globalTestResults[compare][10] / totalVotes * 100)}%)`}
                style={{ color: "#453d3f" }}
                className={classes.checkboxText}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 5 ? true : false}
                  />}
                label={`Agree a little (${Math.floor(globalTestResults[compare][6] / totalVotes * 100)}%)`}
                style={{ color: "#f4c63d" }}
                className={classes.checkboxText}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 7 ? true : false}
                  />}
                label={`Agree strongly (${Math.floor(globalTestResults[compare][2] / totalVotes * 100)}%)`}
                style={{ color: "#00bcd4" }}
                className={classes.checkboxText}
              />
            </GridItem>

          </GridItem>

          <GridItem xs={6}>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 2 ? true : false}
                  />}
                label={`Disagree moderately (${Math.floor(globalTestResults[compare][12] / totalVotes * 100)}%)`}
                style={{ color: "#59922b" }}
                className={classes.checkboxText}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 4 ? true : false}
                  />}
                label={`Neither agree nor disagree (${Math.floor(globalTestResults[compare][8] / totalVotes * 100)}%)`}
                style={{ color: '#d17905' }}
                className={classes.checkboxText}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={usersSelection === 6 ? true : false}
                  />}
                label={`Agree moderately (${Math.floor(globalTestResults[compare][4] / totalVotes * 100)}%)`}
                style={{ color: "#f05b4f" }}
                className={classes.checkboxText}
              />
            </GridItem>
          </GridItem>

          <div style={{ display: "block", marginBottom: "35px" }}>
            <Button
              className={classes.nextButton}
              variant="contained"
              onClick={(e) => this.nextStep(e, values)}
            >Next Question</Button>

            <NavLink to={'/pages/login-page'}>
              <Button
                className={classes.backButton}
                variant="contained"
              >Back to Login Page</Button>
            </NavLink>
          </div>

          <CustomLinearProgress
            className={classes.progressBar}
            variant="determinate"
            color="primary"
            value={33}
          />
        </GridContainer>


        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft
                  onClick={this.back}
                  className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>8 / 10</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        {/* <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/pages/login-page'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Login Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>

        <CustomLinearProgress
          className={classes.progressBar}
          variant="determinate"
          color="primary"
          value={33}
        /> */}
      </div>
    );
  }
}

export default withStyles(Style)(EighthQuestionGlobalResults);
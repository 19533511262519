import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import Style from 'assets/jss/material-dashboard-pro-react/views/quizzesPageStyle';

import { ReactComponent as ArrowRight } from 'assets/img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from 'assets/img/arrowLeft.svg';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "rgba(146,205,145,1) !important",
        },
      },
    },
  },
});
export class TwelfthQuestion extends Component {

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  nextStep = (e, values) => {
    e.preventDefault();
    if (values.twelfthQuestion !== 0) {
      this.props.nextStep();
    }
  }


  render() {
    const { values, handleToggle, classes } = this.props;
    return (
      <div className={classes.riskQuizContainer}>
        <h4 className={classes.questionHeader}>Risk Tolerance Assessment</h4>
        <p className={classes.questionDescription}>If you had to invest $20,000, which of the following investment choices would you find most appealing?</p>
        <GridContainer className={classes.checkboxContainer}>
          <GridItem xs={12}>
            <FormControlLabel
              control={<ThemeProvider theme={theme}>
                <Checkbox
                  onClick={() => handleToggle('twelfthQuestion', 1)}
                  defaultValue={values.twelfthQuestion}
                  checked={values.twelfthQuestion === 1}
                /></ThemeProvider>}
              label='60% in low-risk investments, 30% in medium-risk investments, 10% in high-risk investments'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('twelfthQuestion', 2)}
                  defaultValue={values.twelfthQuestion}
                  checked={values.twelfthQuestion === 2}
                />}
              label='30% in low-risk investments, 40% in medium-risk investments, 30% in high-risk investments'
              className={classes.checkboxText}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => handleToggle('twelfthQuestion', 3)}
                  defaultValue={values.twelfthQuestion}
                  checked={values.twelfthQuestion === 3}
                />}
              label='10% in low-risk investments, 40% in medium-risk investments, 50% in high-risk investments'
              className={classes.checkboxText}
            />
          </GridItem>
        </GridContainer>
        <Hidden smUp>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <div className={classes.arrowButtonsDiv}>
                <ArrowLeft onClick={this.back} className={classes.arrowButton} />
                <ArrowRight onClick={(e) => this.nextStep(e, values)} className={classes.arrowButton} />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.quizTotalContainer}>
            <GridItem className={classes.quizTotalGridItem} xs={12}>
              <div style={{ color: "black" }} className={classes.graphDiv}>
                <span>11 / 13</span>
                <span className={classes.completedText}>Completed</span>
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>


        <Hidden mdDown>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <Button
                className={classes.nextButton}
                variant="contained"
                onClick={(e) => this.nextStep(e, values)}
              >Next Question</Button>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.wealthManagementCheckboxContainer}>
            <GridItem xs={12}>
              <NavLink to={'/quizzes'}>
                <Button
                  className={classes.backButton}
                  variant="contained"
                >Back to Quizzes Page</Button>
              </NavLink>
            </GridItem>
          </GridContainer>
        </Hidden>
      </div>
    );
  }
}

export default withRouter(withStyles(Style)(TwelfthQuestion));
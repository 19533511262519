import React from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export const DocListToUpload = ({ name, onRemove }) => (
  <p>
    <DescriptionIcon />{' '}
    <span
      style={{
        display: 'inline-block',
        fontSize: '16px',
        overflow: 'hidden',
        maxWidth: '75%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {name}
    </span>
    <Tooltip title="Delete" style={{ position: 'relative', top: '-8px' }}>
      <IconButton onClick={onRemove} aria-label="delete">
        <DeleteIcon style={{color: 'white'}} />
      </IconButton>
    </Tooltip>
  </p>
);

DocListToUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default DocListToUpload;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';
import { submitAsset, getGroups, addToGroup, submitGroup } from 'store/actions/assetsActions';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Icons
import chat from 'assets/img/wealth-env/chat-icon.png';
import profile from 'assets/img/wealth-env/profile-icon.png'
import question from 'assets/img/wealth-env/question-icon.png'
import social from 'assets/img/wealth-env/social-icon.png'
import video from 'assets/img/wealth-env/video-icon.png'
import Hidden from '@material-ui/core/Hidden';
import { twilioToken } from 'firebase/fbConfig.js';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Style from 'assets/jss/material-dashboard-pro-react/views/assetsStyle';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Person from 'assets/img/wealth-env/whitePerson.svg';
import Camera from 'assets/img/wealth-env/whiteCamera.svg';
import Matches from 'assets/img/wealth-env/Matches.svg';
import Trophy from 'assets/img/wealth-env/whiteTrophy.svg';
import Question from 'assets/img/wealth-env/whiteQuestion.svg';
import Chat from 'assets/img/wealth-env/whiteChat.svg';
import Time from 'assets/img/wealth-env/time.svg';
import SocialMedia from 'assets/img/wealth-env/whiteSocialMedia.svg';
import AssetTitle from 'assets/img/yourAssetsTitle.png';
import AddButtonContainerDropdown from './AddButtonContainerDropdown'
import { ReactComponent as YourDashboard } from 'assets/img/yourDashboard.svg';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "black !important",
        '&$checked': {
          color: "#72C5C8 !important",
        },
      },
    },
  },
});


const categorySelects = ['Depository', 'Credit', 'Loan', 'Investment', 'Other'];
const depositorySelects = ['Checking', 'Savings', 'HSA', 'CD', 'Money Market', 'Paypal', 'Cash Management', 'EBT'];
const creditSelects = ['Credit Card', 'Paypal'];
const loanSelects = ['Auto', 'Business', 'Commercial', 'Construction', 'Consumer', 'Home Equity', 'Loan', 'Mortgage', 'Overdraft', 'Line of Credit', 'Student', 'Other'];
const investmentSelects = ['529', '401a', '401k', '403B', '457b', 'Brokerage', 'Cash ISA', 'Crypto Exchange', 'Education Savings Account', 'Fixed Annuity', 'GIC', 'Health Reimbursement Arrangement', 'HSA', 'IRA', 'ISA', 'Keogh', 'LIF', 'Life Insurance', 'LIRA', 'LRIF', 'LRSP', 'Mutual Fund', 'Non-Custodial Wallet', 'Non-Taxable Brokerage Account', 'Other', 'Other Annuity', 'Other Insurance', 'Pension', 'PRIF', 'Profit Sharing Plan', 'QSHR', 'RDSP', 'RESP', 'Retirement', 'RLIF', 'Roth', 'Roth 401k', 'RRIF', 'RRSP', 'SARSEP', 'SEP IRA', 'Simple IRA', 'SIPP', 'Stock Plan', 'TFSA', 'Trust', 'UGMA', 'UTMA', 'Variable Annuity'];
const currencySelects = ['USD', 'EUR', 'GBP', 'JPY', 'CHF', 'AUD', 'CAD', 'CNY', 'NZD', 'INR', 'BZR', 'SEK', 'ZAR', 'HKD', 'KRW'];

export function AssetsForm(props) {

  const { classes, history, user, submitAsset, getGroups, groups, addToGroup, submitGroup } = props;
  const [nameOfAsset, setNameOfAsset] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [group, setGroup] = useState('');
  const [customGroup, setCustomGroup] = useState('');
  const [current, setCurrent] = useState('');
  const [available, setAvailable] = useState('');
  const [currency, setCurrency] = useState('');
  const [mask, setMask] = useState('');
  const [edited, setEdited] = useState({});
  // const [accruals, setAccruals] = useState('')
  // const [accountNumber, setAccountNumber] = useState('')
  // const [firm, setFirm] = useState('')
  // const [custodian, setCustodian] = useState('')
  // const [legalOwner, setLegalOwner] = useState('')
  // const [beneficialOwner, setBeneficialOwner] = useState('')
  const [localGroups, setLocalGroups] = useState([]);

  const onSubmit = async () => {
    let parentId;
    const setParent = (id) => {
      parentId = id;
    }
    if (group) {
      setParent(group.group_id);
    }
    if (customGroup) {
      await submitGroup(customGroup, user.id, setParent);
    }
    const toSubmit = {
      name: nameOfAsset,
      balances: {
        available,
        current,
        iso_currency_code: currency
      },
      mask,
      type,
      subtype,
      official_name: nameOfAsset,
      parent_group_id: parentId
    };
    submitAsset(toSubmit, user.id);
    history.push('/assets');
  }

  useEffect(() => {
    if (user.id) {
      getGroups(user.id);
    }
  }, [user])

  useEffect(() => {
    if (groups) {
      setLocalGroups(groups.groups.map((group) => {
        return group.title;
      }))
    }
  }, [groups])

  useEffect(() => {
    if (history.location.state && history.location.state.toEdit) {
      const toEdit = history.location.state.toEdit;
      if (groups && groups.groups) {
        groups.groups.forEach((group) => {
          if (group.group_id === toEdit.parent_group_id) {
            setGroup(group);
          }
        })
      }
      setEdited(toEdit);
      setNameOfAsset(toEdit.name);
      setMask(toEdit.mask);
      setSubtype(toEdit.subtype);
      setType(toEdit.type);
      setCurrent(toEdit.balances.current);
      setAvailable(toEdit.balances.available);
      setCurrency(toEdit.balances.iso_currency_code);
    }
  }, [history.location, groups])

  const selectGroup = (groupName) => {
    groups.groups.forEach((group) => {
      if (group.title === groupName) {
        setGroup(group);
      }
    })
  }

  const getSubtypes = () => {
    switch (type) {
      case 'Depository':
        return depositorySelects;
      case 'Credit':
        return creditSelects;
      case 'Loan':
        return loanSelects;
      case 'Investment':
        return investmentSelects;
      default:
        return [];
    }
  }

  return (
    <div className={classes.container}>
      <div style={{ textAlign: "center" }}>
        <YourDashboard style={{ height: "210px" }} />
      </div>
      <GridContainer className={classes.familyFormContainer}>

        <GridItem className={classes.column} xs={12}>
          <h3 className={classes.header}>ADD ACCOUNT OR HOLDING</h3>
          <p className={classes.details}>Include any assets you hold and relevant details here. More details means higher accuracy!</p>
        </GridItem>


        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Name Of Asset
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setNameOfAsset(e.target.value)}
                id="outlined-basic"
                value={nameOfAsset}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Type
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={type}
                required
                onChange={(e) => setType(e.target.value)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {categorySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>

        {type && type !== 'Other' ?
          <GridItem xs={6}>
            <Card className={classes.inputCard}>
              <FormControl
                fullWidth
                className={classes.formControl}>
                <InputLabel
                  shrink={true}
                  className={classes.selectLabel}>
                  Subtype
                </InputLabel>
                <Select
                  className={classes.inputColor}
                  disableUnderline
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  required
                  onChange={(e) => setSubtype(e.target.value)}
                  value={subtype}
                  inputProps={{
                    id: 'investmentSubtype',
                    name: 'investmentSubtype',
                  }}
                  InputProps={{ disableUnderline: true }}
                >
                  {getSubtypes().map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem
                      }}
                      value={item}
                      key={item}
                    >{item}</MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
            </Card>
          </GridItem> : <GridItem xs={6} />
        }

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Group
              </InputLabel>
              {groups && !showNewGroup ?
                (<Select
                  className={classes.inputColor}
                  disableUnderline
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  required
                  onChange={(e) => {
                    if (e.target.value === "ADD NEW GROUP") {
                      setShowNewGroup(true);
                    } else {
                      selectGroup(e.target.value);
                    }
                  }}
                  inputProps={{
                    id: 'investmentType',
                    name: 'investmentType',
                  }}
                  value={group.title}
                  InputProps={{ disableUnderline: true }}
                >
                  {localGroups.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem
                      }}
                      value={item}
                      key={item}
                    >{item}</MenuItem>
                  ))
                  }
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem
                      }}
                      value={'ADD NEW GROUP'}
                      key={'new group'}
                    >Add New Group</MenuItem>
                </Select>) :
                (<TextField
                  style={{ width: '100%', color: 'black !important' }}
                  onChange={(e) => setCustomGroup(e.target.value)}
                  id="outlined-basic"
                  value={customGroup}
                  variant="standard"
                  InputProps={{
                    classes: {
                      input: classes.inputColor
                    },
                    disableUnderline: true

                  }}
                  formControlProps={{
                    fullWidth: true,
                    disableUnderline: true
                  }}
                />)
              }
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Available Balance
              </InputLabel>
              <TextField
                type="number"
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setAvailable(e.target.value)}
                id="outlined-basic"
                variant="standard"
                value={available}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true
                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Current Balance
              </InputLabel>
              <TextField
                type="number"
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setCurrent(e.target.value)}
                id="outlined-basic"
                variant="standard"
                value={current}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true
                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                className={classes.selectLabel}>
                Currency
              </InputLabel>
              <Select
                className={classes.inputColor}
                disableUnderline
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={currency}
                required
                onChange={(e) => setCurrency(e.target.value)}
                inputProps={{
                  id: 'investmentType',
                  name: 'investmentType',
                }}
                InputProps={{ disableUnderline: true }}
              >
                {currencySelects.map((item) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem
                    }}
                    value={item}
                    key={item}
                  >{item}</MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Mask
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setMask(e.target.value)}
                id="outlined-basic"
                variant="standard"
                value={mask}
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        {/* <GridItem className={classes.align} xs={6}>
          <div className={classes.inputCard}>
            <FormControlLabel
              control={<ThemeProvider theme={theme}>
                <Checkbox
                  onClick={() => setTrackOnly(!trackOnly)}
                  defaultValue={trackOnly}
                /></ThemeProvider>}
              label='Track Only'
              className={classes.checkboxText}
            />
          </div>
        </GridItem>


        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Accruals
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setAccruals(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem> */}

        {/* <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Account Number
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setAccountNumber(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Firm
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setFirm(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={6}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Custodian
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setCustodian(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Legal Owner*
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setLegalOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem>


        <GridItem xs={12}>
          <Card className={classes.inputCard}>
            <FormControl
              fullWidth
              className={classes.formControl}>
              <InputLabel
                shrink={true}
                htmlFor="InvestmentState"
                className={classes.selectLabel}>
                Beneficial Owner
              </InputLabel>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setBeneficialOwner(e.target.value)}
                id="outlined-basic"
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </FormControl>
          </Card>
        </GridItem> */}

        <GridItem className={classes.justifyCenter} xs={12}>
          <Button
            className={classes.saveBtn}
            type="submit"
            onClick={onSubmit}
          >
            Save
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  submitAsset: (asset, uid) => dispatch(submitAsset(asset, uid)),
  getGroups: (uid) => dispatch(getGroups(uid)),
  addToGroup: (uid, accountId, groupId) => dispatch(addToGroup(uid, accountId, groupId)),
  submitGroup: (groupName, uid, callback) => dispatch(submitGroup(groupName, uid, callback))
});

const mapStateToProps = (state) => ({
  user: state.user.user,
  groups: state.assets.groups
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AssetsForm);
// Orders Data
const events = {
  columns: [
    {
      Header: 'TITLE',
      accessor: 'title',
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
    },
    {
      Header: 'IMAGE',
      accessor: 'image',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
    {
      Header: 'CATEGORY',
      accessor: 'category',
    },
    {
      Header: 'LINK',
      accessor: 'link',
    },
  ],
  dataLoaded: false,
  data: [],
};

const userRemoved = false

export const initState = {
  events,
  userRemoved
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_EVENTS_LIST':
      console.log('SET_EVENTS_LIST');
      return {
        ...state,
        events: {
          ...state.events,
          data: action.data,
          dataLoaded: true,
        },
      };

    case "SET_USER_REMOVED_FROM_EVENT":
      var toReturn = { ...state }
      toReturn.userRemoved = action.boolean
      return toReturn

    case 'GET_CURRENT_ADVISOR':
      console.log('GET_CURRENT_ADVISOR');
      return {
        ...state,
        advisors: {
          ...state.currentAdvisor,
          dataRows: action.dataRows,
          dataLoaded: true,
        },
      };

    case 'SET_EVENT_ATTENDEES':
      var toReturn = { ...state };
      toReturn.events.attendees = action.attendees;
      toReturn.events.title = action.title;
      return toReturn;

    default:
      return state;
  }
};

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import loader from 'assets/img/loader.gif';
import FaceIcon from '@material-ui/icons/Face';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LockIcon from '@material-ui/icons/Lock';
import ArchiveIcon from '@material-ui/icons/Archive';
import CallIcon from '@material-ui/icons/Call';
import InfoIcon from '@material-ui/icons/Info';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'react-router-dom';
import Style from 'assets/jss/material-dashboard-pro-react/views/settingsStyle';

import { startLogout } from '../../store/actions/authActions';
import { startUpdateUser } from '../../store/actions/userActions';
import InviteAdvisorForm from '../../components/Forms/InviteAdvisorForm';

class Users extends React.Component {

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { user, classes, startLogout, startUpdateUser } = this.props;
    console.log(user)
    return (
      <div className={classes.overFlowHidden}>
        <GridContainer className={classes.container} justify="center">
          <GridItem xs={12}>
            <h2
              style={{
                margin: '-10px 0px 20px 0px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Invite Someone
            </h2>
            <InviteAdvisorForm className={classes.inviteAdvisorForm} />
            <h2
              style={{
                margin: '20px 0px 20px 0px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Quick Links & Policies
            </h2>
            <GridContainer className={classes.quickLinksContainer}>
              <GridItem xs={6} sm={6} md={6}>
                <Link to="/profile">
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <FaceIcon />
                    <b>Your profile</b>
                  </Button>
                </Link>
              </GridItem>
              <br />
              <GridItem xs={6} sm={6} md={6}>
                <a
                  href="https://www.wealthenv.com/my-account/orders/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <AttachMoneyIcon /> <b>Billing Account</b>
                  </Button>
                </a>
              </GridItem>
              <br />
              <GridItem xs={6} sm={6} md={6}>
                <a
                  href="https://www.wealthenv.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <AssignmentIcon /> <b>Privacy</b>
                  </Button>
                </a>
              </GridItem>
              <br />
              <GridItem xs={6} sm={6} md={6}>
                <a
                  href="https://cloud.google.com/firestore/docs/server-side-encryption"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <LockIcon /> <b>Security</b>
                  </Button>
                </a>
              </GridItem>
              <br />
              <GridItem xs={6} sm={6} md={6}>
                <a
                  href="https://www.wealthenv.com/faq/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <CallIcon /> <b>Help</b>
                  </Button>
                </a>
              </GridItem>
              <br />
              <GridItem xs={6} sm={6} md={6}>
                <a
                  href="http://www.wealthenv.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    style={{
                      width: '100%',
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                    }}
                  >
                    <InfoIcon /> <b>About WealthEnV</b>
                  </Button>
                </a>
              </GridItem>
            </GridContainer>
            <h2
              style={{
                margin: '20px 0px 20px 0px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Archived Items
            </h2>
            <GridContainer
              justify='left'
              className={classes.archivedItemsContainer}
            >
              <GridItem xs={6} sm={6} md={6}>
                <Link to="/investments/archived">
                  <Button
                    color="primary"
                    style={{
                      marginTop: '0px',
                      backgroundColor: '#436CFC',
                      marginBottom: '10px',
                      marginRight: '30px',
                      width: '100%',
                    }}
                  >
                    <ArchiveIcon /> <b>Archived investments</b>
                  </Button>
                </Link>
              </GridItem>
              {/* <GridItem xs={6} sm={6} md={6}>
                  <Link to="/threads/archived">
                    <Button
                      color="primary"
                      style={{
                        marginTop: '0px',
                        backgroundColor: '#0fb3d8',
                        marginBottom: '10px',
                        marginRight: '30px',
                        width: '100%',
                      }}
                    >
                      <ArchiveIcon /> <b>Archived threads</b>
                    </Button>
                  </Link>
                </GridItem>                 */}
            </GridContainer>
            <h2
              style={{
                margin: '20px 0px 20px 0px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Account Settings
            </h2>
            <GridContainer
              className={classes.toggleCards}
              style={{
                padding: '10px 10px 8px 10px',
                marginLeft: '0px',
                marginRight: '0px',
                backgroundColor: '#72E5E4',
                borderRadius: '10px',
                color: "white"
              }}
            >
              <GridItem xs={10} sm={10} md={10}>
                <p style={{ marginTop: '15px' }}>
                  <b>Advertiser Account: </b>
                </p>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        key={'advertiser:' + user.advertiser}
                        onChange={e => startUpdateUser(user.id, { advertiser: e.target.checked })}
                        // value={true}
                        checked={user.advertiser ? true : false}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.toggleCards}
              style={{
                padding: '10px 10px 8px 10px',
                marginTop: '20px',
                marginLeft: '0px',
                marginRight: '0px',
                backgroundColor: '#72E5E4',
                borderRadius: '10px',
                color: "white"
              }}
            >
              <GridItem xs={10} sm={10} md={10}>
                <p style={{ marginTop: '15px' }}>
                  <b>New Message Notifications: </b>
                </p>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={user && user.receiveMessageNotificationEmails ? true : false}
                        value={user && user.receiveMessageNotificationEmails ? true : false}
                        onChange={e => {
                          console.log('e.target.checked', e.target.value)
                          startUpdateUser(user.id, { receiveMessageNotificationEmails: e.target.checked })
                        }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>


            <GridContainer
              className={classes.toggleCards}
              style={{
                padding: '10px 10px 8px 10px',
                marginTop: '20px',
                marginLeft: '0px',
                marginRight: '0px',
                backgroundColor: '#72E5E4',
                borderRadius: '10px',
                color: "white"
              }}
            >
              <GridItem xs={10} sm={10} md={10}>
                <p style={{ marginTop: '15px' }}>
                  <b>New Event Notifications: </b>
                </p>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        value={user && user.receiveEventNotificaitonEmails ? true : false}
                        checked={user && user.receiveEventNotificaitonEmails ? true : false}
                        onChange={e => startUpdateUser(user.id, { receiveEventNotificaitonEmails: e.target.checked })}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>



            <GridContainer
              className={classes.toggleCards}
              style={{
                padding: '10px 10px 8px 10px',
                marginTop: '20px',
                marginLeft: '0px',
                marginRight: '0px',
                backgroundColor: '#72E5E4',
                borderRadius: '10px',
                color: "white"
              }}
            >
              <GridItem xs={10} sm={10} md={10}>
                <p style={{ marginTop: '15px' }}>
                  <b>Push Notifications: </b>
                </p>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        key={'pushNotifications:' + user.pushNotifications}
                        defaultValue={user.pushNotifications}
                        onChange={e => startUpdateUser(user.id, { pushNotifications: e.target.value })}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify='center' style={{ width: '94%', marginTop: '20px' }}>
              <GridItem xs={6} sm={6} md={6}>
                <div className={classes.blueText}>
                  <div onClick={startLogout} style={{ cursor: 'pointer' }}>
                    <Button
                      color="primary"
                      style={{
                        marginTop: '0px',
                        paddingBottom: '12px',
                        width: '100%',
                        backgroundColor: '#436CFC',
                        marginBottom: '10px',
                      }}
                    >
                      <b>Log Out</b>
                    </Button>
                  </div>
                  {/* <p>Deactivate account</p>
                                    <p>Delete account <b>(permanent)</b></p> */}
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          <div style={{ margin: '45px' }} />
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log('state.user.userxxx', state.user.user)
  return {
    user: state.user.user || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLogout: () => dispatch(startLogout()),
    startUpdateUser: (uid, data) => dispatch(startUpdateUser(uid, data))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(Style)
)(Users);

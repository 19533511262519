import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { NavLink } from "react-router-dom";
import loader from 'assets/img/loader.gif';
import { useHistory } from 'react-router';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SearchBox from 'components/SearchBox';
import MessagesSearchBox from 'components/SearchBox/MessagesSearchBox';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
// import AdvisorTable from 'components/Table/AdvisorTable.jsx';

import Style from 'assets/jss/material-dashboard-pro-react/views/advisorPageStyle';

import { db } from '../../firebase/fbConfig.js';

const advisors = [
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "pending",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "pending",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "pending",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "pending",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "accepted",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "accepted",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "accepted",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "denied",
    email: "deb@deb.com"
  },
  {
    name: "Deborah Shott",
    crdNum: "-",
    dateApplied: new Date(),
    status: "accepted",
    email: "deb@deb.com"
  }
];

export function AdvisorPage(props) {

    const [matchesDataLoaded, setMatchesDataLoaded] = useState(false)
    const [advisorList, setAdvisorList] = useState([]);
    const { classes, user } = props;
    const data = React.useMemo(() => advisorList, []);

    return (
        <div className={classes.container}>

            {/* mobile */}
            <Hidden mdUp implementation="css">
                <MatchesTitle className={classes.matchesTitle} />

            </Hidden>
            {/* Desktop */}
            <Hidden smDown implementation="css">
              <GridContainer>
                <h2>{advisorList.filter((a)=>a.status === 'pending')} Pending Advisors</h2>
                {/* <AdvisorTable data={data} /> */}
              </GridContainer>
            </Hidden>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    // nothing yet
});

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        user: state.auth.user,
        userType: state.user.user.userType
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AdvisorPage);
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/views/investmentsStyle.jsx';
import Search from '@material-ui/icons/Search';
import Card from 'components/Card/Card.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

export const MessagesSearchBox = ({ classes, onInput, id, matchesPage }) => (
  <Card
    className={classes.MessagesSearchBoxCard}
  >
    <div
      className={classes.MessagesSearchBoxDiv}
    >
      <Search
        style={{
          color: '#bbb',
          marginBottom: '-8px',
          marginRight: '10px',
          fontSize: '1rem',
        }}
        className={classes.headerLinksSvg + ' ' + classes.searchIcon}
      />

      <CustomInput
        searchBox={true}
        formControlProps={{
          className: classes.top + ' ' + classes.search,
          style: { width: '85%' },
        }}
        id={id || "query"}
        inputProps={{
          placeholder: 'Search...',
          onChange: ({ target: { value } }) => onInput(value),
          inputProps: {
            'aria-label': 'Search',
            className: classes.searchInput,
          },
        }}
      />
    </div>
  </Card>
);

MessagesSearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  onInput: PropTypes.func.isRequired,
};

export default withStyles(Style)(MessagesSearchBox);

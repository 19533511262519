import React, { useState } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";
import { push } from 'connected-react-router';
import { db, firebase } from "firebase/fbConfig.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import logo from "assets/img/WhiteLogo.png";
import { capitalize } from '../../util';
import config from 'config';

import appleLogo from "assets/img/apple-logo.png"

// actions
import { emailSignup, emailSignIn, startGoogleLogin, startFacebookLogin, startTwitterLogin, passwordReset } from "../../store/actions/authActions"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const SignupPage = (props) => {
  const { classes, authError, extraversionScore, agreeablenessScore, conscientiousnessScore, emotionalStabilityScore, opennessExperiencesScore, values } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordNotice, setPasswordNotice] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [passError, setPassError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('props.id', props.id);
    console.log('props.investmentID', props.investmentID);
    console.log("checking score: ", extraversionScore)
    if (code === '2021') {
      if (password !== confirmPassword) {
        setPassError(true)
        return;
      }
      // if (this.state.checked[0] !== 'accepted terms') {
      //   this.setState({
      //     termsError: true,
      //   });
      //   return;
      // }
      const authInfo = { email, password, confirmPassword }
      const scoreArr = [extraversionScore, agreeablenessScore, conscientiousnessScore, emotionalStabilityScore, opennessExperiencesScore, values]
      props.emailSignup(authInfo, scoreArr)


      // const values = values
      // return db.collection('global-test-results').doc('fiveFactorQuiz').get().then((snapshot) => {
      //   const results = snapshot.data()
      //   for (var key in results) {
      //     const scoresObj = results[key]
      //     scoresObj[values[key]]++
      //   }
      //   console.log('results post', results)
      //   return db.collection('global-test-results').doc('fiveFactorQuiz').update({ ...results }).then(() => {
      //     return db.collection("users").doc(props.id).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").add({
      //       quiz: "Five Factor Quiz",
      //       extraversionScore: extraversionScore,
      //       agreeablenessScore: agreeablenessScore,
      //       conscientiousnessScore: conscientiousnessScore,
      //       emotionalStabilityScore: emotionalStabilityScore,
      //       opennessExperiencesScore: opennessExperiencesScore,
      //       timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      //       quizID: "Placeholder"
      //     })
      //       .then((docRef) => {
      //         return db.collection("users").doc(props.id).collection("quizzes").doc("fiveFactorQuiz").collection("quizLists").doc(docRef.id).update({
      //           quizID: docRef.id
      //         })
      //       })
      //       .then(() => {
      //         this.props.addQuizXpPoints(props.id, this.props.events, 'Five Factor Quiz')
      //         // this.props.history.push('/pages/five-factor-success');
      //         dispatch(push(`/pages/usertype`));
      //       })
      //   })
      // })
    } else {
      setCodeError(true)
    }
  };



  return (
    <div style={{ padding: "100px 20px", }} className={classes.container} >
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
          <img style={{
            width: '60%',
            margin: '0px auto',
            marginBottom: '-25px',
            padding: '0px',
            display: 'flex'
          }} src={logo} alt="logo" className={classes.img} />
          <br /> <br />
          <p className={classes.subdescription}>
            <b style={{ color: "black" }}>Create an account with WealthEnv</b>
          </p>
          <br />
          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setEmail(e.target.value)}
                id="outlined-basic"
                label="Email"
                variant="standard"
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>

          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setPassword(e.target.value)}
                id="outlined-basic"
                label="Password"
                variant="standard"
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                type="password"
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>
          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="outlined-basic"
                label="Confirm Password"
                variant="standard"
                type="password"
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>
          <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <TextField
                style={{ width: '100%', color: 'black !important' }}
                onChange={(e) => setCode(e.target.value)}
                id="outlined-basic"
                label="Code"
                variant="standard"
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.inputColor,
                  },
                  disableUnderline: true

                }}
                formControlProps={{
                  fullWidth: true,
                  disableUnderline: true
                }}
              />
            </Paper>
          </Card>
          {/* <Card className={classes.signInCard}>
            <Paper className={classes.signInPaper}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  style={{ color: "black" }}
                  shrink={true}
                  htmlFor="userType"
                  className={classes.selectLabel}
                >
                  User Type:
                </InputLabel>
                <Select
                  style={{ color: "black" }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  required
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  inputProps={{
                    id: 'userType',
                    name: 'userType',
                  }}
                >
                  {config.userTypes.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={item}
                      key={item}
                    >
                      {capitalize(item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Paper>

          </Card> */}
          <div style={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "30px", marginBottom: "10px" }}>
            <Button onClick={handleSubmit} className={classes.signInBtn}>Create</Button>
          </div>
          <div style={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "30px", flexDirection: "column" }}>
            <span style={{ color: "black", textAlign: 'center' }}>Already have an account? <Link to='/pages/signup-page' className={classes.registerBtn}>Sign In</Link></span>
          </div>

        </GridItem>
      </GridContainer>
    </div>
  )
}

SignupPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.error,
    id: ownProps.match.params.id,
    investmentID: ownProps.match.params.investmentID,
    // extraversionScore: ownProps.location.state.state.extraversionScore,
    // agreeablenessScore: ownProps.location.state.state.agreeablenessScore,
    // conscientiousnessScore: ownProps.location.state.state.conscientiousnessScore,
    // emotionalStabilityScore: ownProps.location.state.state.emotionalStabilityScore,
    // opennessExperiencesScore: ownProps.location.state.state.opennessExperiencesScore,
    // values: ownProps.location.state.state.values
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(startGoogleLogin()),
  startFacebookLogin: () => dispatch(startFacebookLogin()),
  startTwitterLogin: () => dispatch(startTwitterLogin()),
  emailSignIn: (credentials) => dispatch(emailSignIn(credentials)),
  passwordReset: (email) => dispatch(passwordReset(email)),
  emailSignup: (authObject, scoreArr) => dispatch(emailSignup(authObject, scoreArr)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(SignupPage);

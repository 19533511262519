import React, { Component, useState, useEffect } from 'react';
import Style from 'assets/jss/material-dashboard-pro-react/views/intakeFormStyle';
import { db, firebase } from "firebase/fbConfig.js";
import { useParams } from 'react-router-dom'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loader from "assets/img/loader.gif";

import LandingPage from './LandingPage'
import FamilyQuestion from './Questions/FamilyQuestion'
import GoalsQuestion from './Questions/GoalsQuestion'
import ExpensesQuestion from './Questions/ExpensesQuestion'


const IntakeForm = (props) => {
  const [step, setStep] = useState(0)
  const [advisorData, setAdvisorData] = useState(false)
  const [familyData, setFamilyData] = useState([])
  const [goalsData, setGoalsData] = useState([])
  const [expenseData, setExpenseData] = useState([])
  const { id } = useParams();

  console.log('goalsData', goalsData)


  const nextStep = () => {
    setStep((prevState) => prevState + 1)
  };

  const prevStep = () => {
    setStep((prevState) => prevState - 1)
  };

  const firstStep = () => {
    setStep(0)
  }

  const addContact = () => {
    setFamilyData([...familyData, { firstName: '', lastName: '', type: '', relation: '', address: '', phoneNumber: '', email: '' }])
  }

  const addGoal = () => {
    setGoalsData([...goalsData, { name: '', timeframe: '', cost: '', criticality: '', priority: '', }])
  }

  const addExpense = () => {
    setExpenseData([...expenseData, { name: '', timeframe: '', cost: '', criticality: '', priority: '', }])
  }

  useEffect(() => {
    const getAdvisorData = async () => {
      return db.collection('users').doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        setAdvisorData(data)
      })
    }
    if (!advisorData) {
      getAdvisorData()
    }
  }, [])


  const { classes } = props;

  if (!advisorData) return (
    <div style={{ backgroundColor: "#f0f1f5", position: "absolute", width: "100%", height: "100%" }}>
      <img
        style={{ margin: "20% 0% 0% 41%", position: "relative", width: "20%" }}
        src={loader}
        alt="..."
      />
    </div>
  )

  switch (step) {
    case 0:
      return (
        <LandingPage
          nextStep={nextStep}
          prevStep={prevStep}
          classes={classes}
          advisorData={advisorData}
        />
      );
    case 1:
      return (
        <FamilyQuestion
          nextStep={nextStep}
          prevStep={prevStep}
          classes={classes}
          advisorData={advisorData}
          familyData={familyData}
          setFamilyData={setFamilyData}
          addContact={addContact}
        />
      );
    case 2:
      return (
        <GoalsQuestion
          nextStep={nextStep}
          prevStep={prevStep}
          classes={classes}
          advisorData={advisorData}
          addGoal={addGoal}
          goalsData={goalsData}
          setGoalsData={setGoalsData}
        />
      );
    case 3:
      return (
        <ExpensesQuestion
          nextStep={nextStep}
          prevStep={prevStep}
          classes={classes}
          advisorData={advisorData}
          addExpense={addExpense}
          expenseData={expenseData}
          setExpenseData={setExpenseData}
        />
      );
    // case 4:
    //   return (
    //   );
    // case 5:
    //   return (
    //   );
    // case 6:
    //   return (
    //   );
    // case 7:
    //   return (

    //   );
    // case 8:
    //   return (
    //   );
    // case 9:
    //   return (
    //   );
    // case 10:
    //   return (
    //   );
    // case 11:
    //   return (
    //   );
    // case 12:
    //   return (
    //   );
    // case 13:
    //   return (
    //   );
    // case 14:
    //   return (
    //   );
    // case 15:
    //   return (
    //   )
    default:
      (console.log('This is a multi-step form built with React.'))
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { uid, events } = auth.user || {}
  return { uid, user: user.user, events: user.user.events };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(IntakeForm);